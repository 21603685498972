import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, TemplateRef } from '@angular/core';
import { NbSidebarService, NbDialogService } from '@nebular/theme';
import { WebviewerSignService } from './webviewer-sign.service'
import { UserData } from '../../../data/users';
import { getRenderedText } from '@angular/core/src/render3';

@Component({
  selector: 'ngx-webviewer-sign',
  templateUrl: './webviewer-sign.component.html',
  styleUrls: ['./webviewer-sign.component.scss']
})
export class WebviewerSignComponent implements OnInit, AfterViewInit {
  @ViewChild('viewer') viewer: ElementRef;
  @ViewChild('savedSignature') public savedSignatureCanvas: ElementRef;
  @ViewChild('popupOpen') popupOpen: ElementRef<HTMLElement>;
  @ViewChild('popupClose') popupClose: ElementRef<HTMLElement>;
  @ViewChild('triangleButton') triangleButton: ElementRef<HTMLElement>;

allowDrop(event){}
drop(event){}
drop2(event){}
changeFieldProperties(d){
}
changeAnnotations(){}
  triggerFalseClick() {
    let el: HTMLElement = this.popupClose.nativeElement;
    el.click();
  }
  triggerFalseOpenClick() {
    let el: HTMLElement = this.popupOpen.nativeElement;
    el.click();
  }

  triggerTriangleClick() {
    let el: HTMLElement = this.triangleButton.nativeElement;
    el.click();
  }

  open(dialog: TemplateRef<any>) {
    //console.log(dialog);
    this.dialogService.open(dialog, { context: 'this is some additional data' });
  }
  point1;
  TriangleAnnotation

  public annotationsList

  public wvOriginalMouseLeftUp;
  public wvNewMouseLeftUp;
  public mouseLeftUpThis;
  public savedSignature;
  savedSignaturePublic
  savedSignaturePath
  clikedAddSavedAnnotation;

  wvInstance;
  wvTools;
  public pdfNetInitialize: any;
  currentPage
  pageCount
  currentSignatureEvent;
  constructor(
    private dialogService: NbDialogService,
    private wvSignService: WebviewerSignService
  ) { }

  documentData;
  memberData;

  ngOnInit() {
    this.wvSignService.currentDocumentData.subscribe(message => this.documentData = message)
    this.wvSignService.currentMemberData.subscribe(message => this.memberData = message)

  }

  async ngAfterViewInit() {
    await this.wvSignService.loadWebViewerSign(this.viewer.nativeElement, this.documentData.providerResponse.location);
    const instance = this.wvSignService.instance;

    const { PDFNet, docViewer } = instance;
    this.wvInstance = instance;
    //this.wvDocViewer = instance;
    const { Annotations } = this.wvInstance;
    this.wvTools = instance.Tools;
    // const tools = this.wvTools;
    const annotManager = instance.annotManager;
    const { Tools } = instance;

    annotManager.setCurrentUser('pcsmoak1@gmail.coom');


    instance.docViewer.on('annotationsLoaded', () => {
      console.log('annotations loaded');

    });


    instance.docViewer.on('documentLoaded', async (f) => {
      console.log('documentLoaded');

      this.pdfNetInitialize = await PDFNet.initialize();
      const doc = docViewer.getDocument();
      const pdfDoc = await doc.getPDFDoc();

      await annotManager.importAnnotations(this.documentData.annotations);

      //get page count, and Add thumbnails if first load
      console.log('Get Page Count' + docViewer.getPageCount());
      // this.pageCount = docViewer.getPageCount();
      // if (!this.documentData.pageCount || !this.documentData.pageThumbnails) {
      //   console.log('first Load, setting page count and generating thumbnails');
      //   this.documentData.pageCount = this.pageCount;
      //   //  await this.wvEditorService.getThumbnails(doc, this.pageCount);
      // }
      // else {
      //   console.log('return visit');
      // }

      // Ensure that we have our first page.
      await pdfDoc.requirePage(1);
      // Run our main function using 'runWithCleanup'
      await PDFNet.runWithCleanup(async () => await main(pdfDoc));
      // Refresh the cache with the newly updated document
      docViewer.refreshAll();
      // Update viewer with new document
      docViewer.updateView();
      // you can access docViewer object for low-level APIs
      docViewer.getDocument().refreshTextData();

      console.log('export annotations right after');
      //console.log(annotManager.getAnnotationsList());
      var annotations = annotManager.getAnnotationsList();
      getAnnotationList(annotations, annotManager);
      //changeXfdf(this.documentData.annotations);
      this.point1 = {
        x: 50,
        y: 100,
      }

      var point2 = {
        x: 150,
        y: 200,
      }
      var point3 = {
        x: 50,
        y: 400,
      }

      // this.createTriangleGroup(this.point1)
      // // this.triggerTriangleClick();
      // // this.point1 = point2;
      // // this.triggerTriangleClick();
      // // this.point1 = point3;
      // // this.triggerTriangleClick();
      // this.createTriangleGroup(point2)
      // this.createTriangleGroup(point3)
    });

    this.triangleTool(instance, Tools, Annotations);
    console.log('last of webviewer');
    //monsef test
    //   //end of this.viewer.nativeElement.then(instance =>{
    // });
    async function main(pdfDoc) {
      //alert("Hello WebViewer!");
    }

    annotManager.on('notify', async function (type) {
      console.log('notify annotManager');
      console.log(type);
      //originalMouseLeftUp(e)
    });

    //Event Listner Listen Page Change Add Visit Details
    this.viewer.nativeElement.addEventListener('pageChanged', (e) => {
      const [pageNumber] = e.detail + 1;
      console.log(`Current page is ${pageNumber}`);
      this.currentPage = pageNumber;
      //???? Add Save Visit Strategy
    });

    const getAnnotationList = (annotations, annotManager) => {
      console.log('annotationList');
      //console.log(annotations);
      var annotationsPrep = [];
      var annotationsPrepWidget = [];
      let iWidget = 0;
      var myWidgets = [];
      for (let i = 0; i < annotations.length; i++) {
        annotationsPrep[i] = {
          id: '',
          type: '',
          author: '',
          required: true,
          x: 0,
          y: 0,
          pageNumber: 0
        };
        annotationsPrep[i].id = annotations[i].wt;
        annotationsPrep[i].type = annotations[i].getContents();
        annotationsPrep[i].author = annotManager.getDisplayAuthor(annotations[i]);
        annotationsPrep[i].required = true;
        annotationsPrep[i].x = annotations[i].getX();
        annotationsPrep[i].y = annotations[i].getY();
        annotationsPrep[i].pageNumber =annotations[i].getPageNumber();
        // console.log('test');
        // console.log(annotations[a].wt);
        // console.log(annotations[a].getContents());
        // console.log(annotations[a].Subject);
        // console.log(annotManager.getDisplayAuthor(annotations[a]))
        if (annotations[i].Subject == 'Widget' && this.wvSignService.isMyWidget(annotations[i].fieldName, 'd0b6ca41')) {

          console.log(annotations[i].fieldName);
          //[add to if statement] && this.wvSignService.isMyWidget(annotations[i].fieldName, d0b6ca41);  ???
          console.log('Widget');
          console.log(annotations[i].getField());
          annotationsPrepWidget[iWidget] = {
            id: '',
            type: '',
            author: '',
            required: true,
            x: 0,
            y: 0,
            pageNumber: 0
          };
          annotationsPrepWidget[iWidget].id = annotations[i].wt;
          annotationsPrepWidget[iWidget].type = annotations[i].getContents();
          annotationsPrepWidget[iWidget].author = annotManager.getDisplayAuthor(annotations[i]);
          annotationsPrepWidget[iWidget].required = true;
          annotationsPrepWidget[i].x = annotations[i].getX();
          annotationsPrepWidget[i].y = annotations[i].getY();
          annotationsPrepWidget[i].pageNumber = annotations[i].getPageNumber();

          myWidgets[iWidget] = annotations[i];
          iWidget++;

          // Set to Field to Read only
          //annotations[i].fieldFlags.set('ReadOnly', true);

        }
      }
      this.annotationsList = annotationsPrep;
      console.log(this.annotationsList);
      console.log(annotationsPrepWidget);
      this.wvSignService.sortWidgetsByLocation(myWidgets);
      this.prepareGuides(this.annotationsList);
      //  this.triggerFSendAnnotationsToChild()

      //trigger button click to transfer data to child
    }

    const originalMouseLeftDown = Tools.AnnotationSelectTool.prototype.mouseLeftDown;
    Tools.AnnotationSelectTool.prototype.mouseLeftDown = function (e) {
      console.log('mouseleftdown')
      const annotationClicked = instance.docViewer.getAnnotationManager().getAnnotationByMouseEvent(e);
      console.log(annotationClicked)
      if (annotationClicked && annotationClicked.getContents() === 'Sign'){
        console.log('Sign Tool Open')
        console.log(e);
        // e.clientY = 92;
        // e.pageY = 92;
        //e.
        console.log(event);
        Tools.SignatureCreateTool.prototype.mouseLeftUp(event);

      }


      return originalMouseLeftDown.call(this, e);
    };

    //Signature Customization
    let lastSignatureClicked;

    const createSignHereElement = Annotations.SignatureWidgetAnnotation.prototype.createSignHereElement;

    Annotations.SignatureWidgetAnnotation.prototype.createSignHereElement = function () {
      const signatureWidget = this;

      // signHereElement is the default one with dark blue background
      const signHereElement = createSignHereElement.apply(this, arguments);

      signHereElement.addEventListener('click', () => {
        lastSignatureClicked = signatureWidget.fieldName;
        //signatureWidget.fieldName ="fullSignature.UserID.FieldID"
        //console.log(lastSignatureClicked);
        console.log('click sign element');
      });

      return signHereElement;
    }


    const originalMouseLeftUp = Tools.SignatureCreateTool.prototype.mouseLeftUp;
    this.wvOriginalMouseLeftUp = Tools.SignatureCreateTool.prototype.mouseLeftUp;

    //console.log(originalMouseLeftUp);
    Tools.SignatureCreateTool.prototype.mouseLeftUp = function (e) {
      console.log('mouse left up');
      console.log(e);
      saveMouseLeftUpThis(this);
      const sigs = this.getSavedSignatures();
      const sigsOld = this.getSavedSignatures();
      console.log(sigsOld);

      const lastSignatureNameSplit = lastSignatureClicked.split('-');
      console.log(lastSignatureNameSplit);
      const savedSignature = JSON.parse(getMemberSavedSignature('initial'));
      //const savedSignature = getMemberSavedSignature(lastSignatureNameSplit[0]);
      console.log(savedSignature);


      //checking if user clicked button in custom signature modal to add saved signature to widget
      if (addSavedIsClicked() === true) {
        console.log('click trigger');
        const sigss = this.getSavedSignatures();
        console.log(sigss);

        // this.deleteSavedSignature(0);
        this.importSignatures(savedSignature);
        const sigs = this.getSavedSignatures();
        console.log(sigs[0]);
        originalMouseLeftUp.call(this, e);
        this.setSignature(sigs[0]);
        this.addSignature();
        return;
      }

      console.log(sigs);
      setPublicSignature(sigs);
      //this.deleteSavedSignature(0);
      //console.log(this.getSavedSignatures());

      if (savedSignature) {
        //this.deleteSavedSignature(0);
        console.log('saved then don\'t show modal', savedSignature);
        console.log(sigs);
        return openSavedSignatureCustomModal.call(this, savedSignature, e, docViewer);

        // Old Way
        // console.log('saved then don\'t show modal', sigs);
        // openSavedSignatureCustomModal.call(this, sigs, e, docViewer);
        // originalMouseLeftUp.call(this, e);
        // this.setSignature(sigs[0]);
        // this.addSignature();
        // console.log('added signature');

      }
      else {
        console.log('no saved then show modal');
        return originalMouseLeftUp.call(this, e);
      }
    };

    //For Usage outside WebViewer
    this.wvNewMouseLeftUp = Tools.SignatureCreateTool.prototype.mouseLeftUp;


    const addSavedIsClicked = () => {
      if (this.clikedAddSavedAnnotation === true) {
        this.clikedAddSavedAnnotation = false;
        return true;
      }
      else { return false };
    }
    const saveMouseLeftUpThis = (data) => {
      console.log('saveMouseLeftUpThis');
      //console.log(data); 
      this.mouseLeftUpThis = data;
    };

    const setPublicSignature = (sigs) => {
      this.savedSignaturePublic = sigs;
    };

    const openSavedSignatureCustomModal = (sigs, e, docViewer) => {
      console.log('openSavedSignatureCustomModal');
      console.log(sigs);
      console.log(e);
      //this.savedSignature = sigs;
      this.currentSignatureEvent = e;
      // this.savedSignature.getPaths()
      this.triggerFalseOpenClick();
      //this.savedSignature[0].draw(this.savedSignatureCanvas,savedSignaturePageMatrix);

      const canvasEl: HTMLCanvasElement = this.savedSignatureCanvas.nativeElement;
      var cx = canvasEl.getContext('2d');
      cx.beginPath;

      //var savedSignaturePaths = sigs[0].getPaths();
      //var savedSignaturePaths = sigs[0];
      console.log(this.savedSignature);
      console.log(this.savedSignature[0].getPaths);
      var savedSignaturePaths = sigs[0];

      console.log(savedSignaturePaths);

      function getYs() {
        return savedSignaturePaths[0].map(d => d.y);
      }
      function getMinY() {
        return Math.min(...getYs());
      }
      function getXs() {
        return savedSignaturePaths[0].map(d => d.x);
      }
      function getMinX() {
        return Math.min(...getXs());
      }
      function getMaxY() {
        return Math.max(...getYs());
      }
      console.log('getMinY')
      console.log(getYs());
      console.log(getMinY());

      console.log(savedSignaturePaths[0]);
      var count = savedSignaturePaths[0].length;
      var xFactor;
      var yFactor;
      for (let i = 0; i < count; i++) {
        if (i === 0) {
          //??? How to get these dynamically.  
          //It is the position of the original sign widget where a signature is inserted.
          xFactor = 397;
          yFactor = 350;
          cx.moveTo(savedSignaturePaths[0][i].x - getMinX(), savedSignaturePaths[0][i].y - getMinY());
        }
        else {
          cx.lineTo(savedSignaturePaths[0][i].x - getMinX(), savedSignaturePaths[0][i].y - getMinY());
          //console.log(savedSignaturePaths[0][i].x)
        }
      }
      cx.stroke();
      //console.log(this.savedSignaturePublic)
    }

    //Event on Signature Saved
    docViewer.getTool('AnnotationCreateSignature').on('signatureSaved', function (annotation) {
      console.log('savedSignature')
      console.log(annotation);
      saveUserSignatureAnnotation(annotation);

      const savedSignatureJson = JSON.stringify(this.exportSignatures());
      console.log(savedSignatureJson);//event coordinates with offset
      saveUserSignature(savedSignatureJson, lastSignatureClicked);
    });

    const saveUserSignatureAnnotation = (annotation) => {
      this.savedSignature = annotation;
      console.log('saveusersignatureannotation');
      console.log(this.savedSignature);
    }
    docViewer.getTool('AnnotationCreateSignature').on('annotationAdded', function (annotation) {
      //console.log()
      console.log(annotation);

      const signatureWidgetAnnots = annotManager.getAnnotationsList().filter(
        annot => annot instanceof Annotations.SignatureWidgetAnnotation
      );

      console.log(signatureWidgetAnnots);
      signatureWidgetAnnots.forEach(annotSign => {
        console.log(annotSign);
        //annotSign.isSignedDigitally().then(isSigned => {
        if (annotSign.annot) {
          // if this signature field is signed initially
          console.log('isSigned')
        } else {
          console.log('notSigned')
          // if this signature field is not signed initially
        }
        //})
      })
      //flatten();
      //flatten2();

    });

    const getMemberSavedSignature = (signatureType) => {
      console.log('getMemberSavedSignature');
      if (!this.memberData.savedSignatures) {
        return false;
      }
      if (signatureType === 'fullSignature' && this.memberData.savedSignatures.fullSignature) {
        return this.memberData.savedSignatures.fullSignature
      }
      else if (signatureType === 'initial' && this.memberData.savedSignatures.initials) {
        return this.memberData.savedSignatures.initials
      }
      else {
        console.log('error no recognized signature type or no signature data');
        return false;
      }
    }


    const saveUserSignature = (annotation, lastSignatureClicked) => {

      console.log('saveUserSignature');
      const lastSignatureNameSplit = lastSignatureClicked.split('-');
      var signatureType
      console.log(lastSignatureNameSplit);
      if (lastSignatureNameSplit[0] === 'initial') {
        signatureType = 'initial';

      }
      else if (lastSignatureNameSplit[0] === 'fullSignature') {
        signatureType = 'fullSignature';
      }

      this.savedSignaturePath = annotation;
      console.log(annotation);
      console.log(annotation[0].x);
      var updatedAnnotation = this.remapSignature(annotation[0]);
      //Temporary for testing
      this.wvSignService.putSavedSignature(annotation, 'initial', this.memberData);
      //this.wvSignService.putSavedSignature(annotation[0].getPaths(), signatureType, this.memberData);
    };

    const flatten = async () => {
      //global instance call
      //const instance = WebViewer.getInstance();
      const webDoc = docViewer.getDocument();
      const doc = await webDoc.getPDFDoc();
      doc.initSecurityHandler();
      doc.lock();
      await docViewer.getTool('AnnotationCreateFreeHand').complete();
      const xfdfString = await annotManager.exportAnnotations({ links: false, widgets: false });
      const fdfDoc = await PDFNet.FDFDoc.createFromXFDF(xfdfString);
      await doc.fdfMerge(fdfDoc);
      await doc.flattenAnnotations();

      const docbuf = await doc.saveMemoryBuffer(1);
      instance.loadDocument(new Blob([docbuf], {
        type: 'application/pdf'
      }), {
          filename: 'myfile_flattened.pdf'
        });
      const blob = new Blob([docbuf], {
        type: 'application/pdf'
      });
      console.log('flatten file');
      console.log(blob);
      // this.uploadThumbnail(blob, 'flattenfile.pdf', 1);

    }

  }
  //Left Sidebar Functions
  changeCurrentPage(pageNumber) {
    //this.wvInstance.docViewer.setCurrentPage(pageNumber);
    this.wvInstance.docViewer.displayPageLocation(pageNumber, 0, 0, false)
  }
  addSavedSignatureToField(savedSignature, currentSignatureEvent) {
    console.log('addSavedSignatureToField');
    this.clikedAddSavedAnnotation = true;
    this.wvNewMouseLeftUp.call(this.mouseLeftUpThis, currentSignatureEvent);
    this.triggerFalseClick();
  }
  remapSignature(annotation) {
    var savedSignaturePaths = annotation;
    function getYs() {
      return savedSignaturePaths[0].map(d => d.y);
    }
    function getMinY() {
      return Math.min(...getYs());
    }
    function getXs() {
      return savedSignaturePaths[0].map(d => d.x);
    }
    function getMinX() {
      return Math.min(...getXs());
    }

    //console.log(getYs());
    //console.log(getMinY());
    //console.log(getXs());
    //console.log(getMinX());
    //console.log()
    var newCoordinates
    for (let i = 0; i < savedSignaturePaths[0].length; i++) {
      //newCoordinates[i] = this.wvInstance.doc.getViewerCoordinates(0, savedSignaturePaths[0][i].x, savedSignaturePaths[0][i].y)

      //      savedSignaturePaths[0][i].x = savedSignaturePaths[0][i].x - getMinX();
      // savedSignaturePaths[0][i].x = savedSignaturePaths[0][i].x - getMinX();
      // savedSignaturePaths[0][i].y = savedSignaturePaths[0][i].y - getMinY();

      //    savedSignaturePaths[0][i].x = getXs()[i];
      //    savedSignaturePaths[0][i].y = getYs()[i];
    }
    console.log(annotation[0]);
    console.log(newCoordinates);
  }
  triangleTool(instance, tools, Annotations) {
    //Triangle Tool Custom
    const TriangleAnnotation = function () {
      Annotations.MarkupAnnotation.call(this);
      this.Subject = 'Triangle';
    };
    TriangleAnnotation.prototype = new Annotations.MarkupAnnotation();
    //TriangleAnnotation.prototype = new Annotations.FreeTextAnnotation();

    TriangleAnnotation.prototype.elementName = 'triangle';

    TriangleAnnotation.prototype.draw = function (ctx, pageMatrix) {
      // the setStyles function is a function on markup annotations that sets up
      // certain properties for us on the canvas for the annotation's stroke thickness.
      this.setStyles(ctx, pageMatrix);

      // first we need to translate to the annotation's x/y coordinates so that it's
      // drawn in the correct location
      ctx.translate(this.X, this.Y);
      ctx.beginPath();
      ctx.moveTo(this.Width / 2, 0);
      ctx.lineTo(this.Width, this.Height);
      ctx.lineTo(0, this.Height);
      ctx.closePath();
      ctx.fill();
      ctx.stroke();
    };
    const TriangleCreateTool = function (docViewer) {
      // TriangleAnnotation is the constructor function for our annotation we defined previously
      tools.GenericAnnotationCreateTool.call(this, docViewer, TriangleAnnotation);
    };

    this.TriangleAnnotation = TriangleAnnotation.prototype;
    TriangleCreateTool.prototype = new tools.GenericAnnotationCreateTool();
    //const signatureToolVar = new tools.SignatureCreateTool(docViewer);
    //console.log(signatureToolVar);
    //console.log('signature tool created');

    const triangleToolName = 'AnnotationCreateTriangle';



    console.log('viewerLoaded');
    const am = instance.docViewer.getAnnotationManager();
    // register the annotation type so that it can be saved to XFDF files
    am.registerAnnotationType(TriangleAnnotation.prototype.elementName, TriangleAnnotation);

    const triangleTool = new TriangleCreateTool(instance.docViewer);
    instance.registerTool({
      toolName: triangleToolName,
      toolObject: triangleTool,
      buttonImage: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">' +
        '<path d="M12 7.77L18.39 18H5.61L12 7.77M12 4L2 20h20L12 4z"/>' +
        '<path fill="none" d="M0 0h24v24H0V0z"/>' +
        '</svg>',
      buttonName: 'triangleToolButton',
      tooltip: 'Triangle'
    }, TriangleAnnotation);

    instance.setHeaderItems(header => {
      const triangleButton = {
        type: 'toolButton',
        toolName: triangleToolName
      };
      header.get('freeHandToolGroupButton').insertBefore(triangleButton);
    });
    instance.setToolMode(triangleToolName);

    console.log('gettoolmodemap')
    console.log(instance.docViewer.getToolModeMap());
    //console.log(instance.docViewer.getToolModeMap());
    console.log(Annotations)
  }

  async createTriangleGroup(point, text) {
    console.log('createTriangleGroup');
    const { Annotations } = this.wvInstance;
    const docViewer = this.wvInstance.docViewer
    //for (let i = 0; i < count; i++) {
    let freeTexttwo = new Annotations.FreeTextAnnotation();
    //let freeTexttwo = new Annotations.AnnotationCreateTriangle();
    //  let freeTexttwo = this.TriangleAnnotation;
    // freeTexttwo.PageNumber = docViewer.getCurrentPage();
    freeTexttwo.PageNumber = point.pageNumber;

    freeTexttwo.X = point.x;
    freeTexttwo.Y = point.y;
    freeTexttwo.Width = 150;
    freeTexttwo.Height = 150;
    //freeTexttwo.setContents('thisis a test');
    freeTexttwo.setContents(text);
    console.log(point);
    // Free Text Annotation Example
    const freeText = new Annotations.FreeTextAnnotation();
    freeText.PageNumber = 1;
    freeText.X = point.x;
    freeText.Y = point.y;
    freeText.Width = 150;
    freeText.Height = 50;
    freeText.setPadding(new Annotations.Rect(0, 0, 0, 0));
    freeText.setContents('My Text');
    freeText.setContents(text);
    freeText.ReadOnly = true;
    freeText.FillColor = new Annotations.Color(0, 255, 255);
    freeText.FontSize = '16pt';
    freeText.Author = this.wvInstance.annotManager.getCurrentUser();
    this.wvInstance.annotManager.addAnnotation(freeText);
    this.wvInstance.annotManager.redrawAnnotation(freeText);

    //freeTexttwo.setPadding(new Annotations.Rect(0, 0, 0, 0));
    // freeTexttwo.setContents(dragElementId);
    //freeTexttwo.FillColor = new Annotations.Color(0, 255, 255);
    // freeTexttwo.FontSize = '16pt';
    // freeTexttwo.Author = 'unassigned';

    // freeTexttwo.FillColor = new Annotations.Color(255, 255, 255);
    // await this.wvInstance.annotManager.addAnnotation(freeTexttwo);
    // await this.wvInstance.annotManager.redrawAnnotation(freeTexttwo);
    console.log('finish createTriangleGroup');
    //}
  }
  prepareGuides(annotationsList){
  console.log('prepareGuides')
  console.log(annotationsList)
  for(let i=0;i<annotationsList.length;i++){
      var point = {
        x:0,
        y:0,
        pageNumber:0
      }
      point.x = annotationsList[i].x
      if(annotationsList[i].y < 200){
        point.y = annotationsList[i].y + 150
      }
      else{
        point.y = annotationsList[i].y - 100;
      }
      var text = 'this is point ' + i;
      point.pageNumber = annotationsList[i].pageNumber
      this.createTriangleGroup(point, text)
      console.log(text)
      console.log(point)

      point.x = point.x + 10;
      point.y = point.y - 20;
      text = 'Sign'
      this.createTriangleGroup(point, text)


      // point.x = point.x + 10;
      // //point.y = point.y - 50;
      // text = 'Next'
      // this.createTriangleGroup(point, text)


    }
  }
}
