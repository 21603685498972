import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ngx-member-visits-view',
  templateUrl: './member-visits-view.component.html',
  styleUrls: ['./member-visits-view.component.scss']
})
export class MemberVisitsViewComponent implements OnInit {
  @Input('parentDataDocuments') public documents;
  @Input('parentDataVisits') public visits;
  constructor() { }

  ngOnInit() {
  }

}
