import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserService } from './users.service';

const SERVICES = [
	UserService,
];

@NgModule({
	imports: [
		CommonModule,
	],
	providers: [
		...SERVICES,
	],
})
export class MockDataModule {
	static forRoot(): ModuleWithProviders {
		return <ModuleWithProviders>{
			ngModule: MockDataModule,
			providers: [
				...SERVICES,
			],
		};
	}
}
