import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {MembersVisitService } from '../../../../services/members/members-visit.service';
import { Documents } from '../../../../services/http/interfaces/documents';
import { FormrlyUsersContact } from '../../../../services/http/interfaces/formrly-users-contact';

@Component({
  selector: 'ngx-member-my-contact-get',
  templateUrl: './member-my-contact-get.component.html',
  styleUrls: ['./member-my-contact-get.component.scss']
})
export class MemberMyContactGetComponent implements OnInit {
  //public document: Documents;
  @Input('parentData') public memberId;
  @Output() notify: EventEmitter<FormrlyUsersContact> = new EventEmitter<FormrlyUsersContact>();

  constructor(
    private membersService: MembersVisitService
  ) { }

  ngOnInit() {
    this.getMyMembersContactInfo(this.memberId);
  }
  getMyMembersContactInfo(memberId) {
    this.membersService.getMyMembersContactInfo(memberId).subscribe(
      fileMeta => {
      // this.document = fileMeta;
        console.log('getDocumentDetails Success')
        //console.log(fileMeta);
        this.notify.emit(fileMeta);
      },
      err => console.error(err)
    );
  }
}
