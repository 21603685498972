import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {
	NbActionsModule,
	NbCardModule,
	NbLayoutModule,
	NbMenuModule,
	NbRouteTabsetModule,
	NbSearchModule,
	NbSidebarModule,
	NbTabsetModule,
	NbThemeModule,
	NbUserModule,
	NbCheckboxModule,
	NbPopoverModule,
	NbContextMenuModule,
	NbProgressBarModule,
	NbCalendarModule,
	NbCalendarRangeModule,
	NbStepperModule,
	NbButtonModule,
	NbInputModule,
	NbAccordionModule,
	NbDialogModule,
	NbWindowModule,
	NbListModule,
	NbToastrModule,
	NbAlertModule,
	NbSpinnerModule,
	NbRadioModule,
	NbSelectModule,
	NbTooltipModule,
} from '@nebular/theme';

import { NbSecurityModule } from '@nebular/security';

import {
	FooterComponent,
	HeaderComponent,
	SearchInputComponent,
//	ThemeSettingsComponent,
	SwitcherComponent,
	LayoutDirectionSwitcherComponent,
	ThemeSwitcherComponent,
	ThemeSwitcherListComponent,
	ToggleSettingsButtonComponent,
} from './components';
import {
	CapitalizePipe,
	PluralPipe,
	RoundPipe,
	TimingPipe,
	NumberWithCommasPipe,
	EvaIconsPipe,
} from './pipes';
import {
	OneColumnLayoutComponent,
	SampleLayoutComponent,
	ThreeColumnsLayoutComponent,
	TwoColumnsLayoutComponent,
} from './layouts';
// import { DEFAULT_THEME } from './styles/theme.default';
// import { COSMIC_THEME } from './styles/theme.cosmic';
import { CORPORATE_THEME } from './styles/theme.corporate';

import {SharedModule} from '../@core/components/shared.module';
const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];

const NB_MODULES = [
	NbCardModule,
	NbLayoutModule,
	NbTabsetModule,
	NbRouteTabsetModule,
	NbMenuModule,
	NbUserModule,
	NbActionsModule,
	NbSearchModule,
	NbSidebarModule,
	NbCheckboxModule,
	NbPopoverModule,
	NbContextMenuModule,
	NgbModule,
	NbSecurityModule, // *nbIsGranted directive,
	NbProgressBarModule,
	NbCalendarModule,
	NbCalendarRangeModule,
	NbStepperModule,
	NbButtonModule,
	NbListModule,
	NbToastrModule,
	NbInputModule,
	NbAccordionModule,
	NbDialogModule,
	NbWindowModule,
	NbAlertModule,
	NbSpinnerModule,
	NbRadioModule,
	NbSelectModule,
	NbTooltipModule,
];

const COMPONENTS = [
	SwitcherComponent,
	LayoutDirectionSwitcherComponent,
	ThemeSwitcherComponent,
	ThemeSwitcherListComponent,
	HeaderComponent,
	FooterComponent,
	SearchInputComponent,
//	ThemeSettingsComponent,
	OneColumnLayoutComponent,
	SampleLayoutComponent,
	ThreeColumnsLayoutComponent,
	TwoColumnsLayoutComponent,
	ToggleSettingsButtonComponent,
];

const ENTRY_COMPONENTS = [
	ThemeSwitcherListComponent,
];

const PIPES = [
	CapitalizePipe,
	PluralPipe,
	RoundPipe,
	TimingPipe,
	NumberWithCommasPipe,
	EvaIconsPipe,
];

const NB_THEME_PROVIDERS = [
	...NbThemeModule.forRoot(
		{
			name: 'corporate',
		},
		[ CORPORATE_THEME ],
	).providers,
	...NbSidebarModule.forRoot().providers,
	...NbMenuModule.forRoot().providers,
	...NbDialogModule.forRoot().providers,
	...NbWindowModule.forRoot().providers,
	...NbToastrModule.forRoot().providers,
];

@NgModule({
	imports: [...BASE_MODULES, ...NB_MODULES, SharedModule],
	exports: [...BASE_MODULES, ...NB_MODULES, ...COMPONENTS, ...PIPES],
	declarations: [...COMPONENTS, ...PIPES],
	entryComponents: [...ENTRY_COMPONENTS],
})
export class ThemeModule {
	static forRoot(): ModuleWithProviders {
		return <ModuleWithProviders>{
			ngModule: ThemeModule,
			providers: [...NB_THEME_PROVIDERS],
		};
	}
}
