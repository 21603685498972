import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validator } from '@angular/forms';
import { of, Observable } from 'rxjs';
import { FormrlyUsersService } from '../../../services/http/formrly-users.service';
import { UploadService } from '../../../services/common/upload.service';
import { FormrlyUsersContact } from '../../../services/http/interfaces/formrly-users-contact';
import { UserData } from '../../../data/users';
import { NbAuthService } from '@nebular/auth';
import { concatMap } from 'rxjs/operators';
import { UserService } from '../../../mock/users.service';

@Component({
  selector: 'ngx-formrly-user-contact-edit',
  templateUrl: './formrly-user-contact-edit.component.html',
  styleUrls: ['./formrly-user-contact-edit.component.scss']
})
export class FormrlyUserContactEditComponent implements OnInit {
  @Input('parentData') public profileData;
  @Output() notifyContactUpdate: EventEmitter<any> = new EventEmitter<any>();
	@Output() notifyTabChange: EventEmitter<string> = new EventEmitter<string>();
	@ViewChild('falseClickButton') falseClickButton: ElementRef<HTMLElement>;

	profile: FormGroup;
	//profileData: FormrlyUsersContact;
	newPhoto;
	newPhotoData;
	initialEmail;

	// Indicates if the form is being saved
	isSaving:boolean;

	// Indicates if a new picture is being uploaded
	isUploading:boolean;

	// Indicates if photo data is being downloaded
	isDownloadingPhotoData:boolean;

	triggerFalseClick() {
    let el: HTMLElement = this.falseClickButton.nativeElement;
    el.click();
  }
	falseClickAction(){
		console.log('change view');
	}
	private contactsList;

	contextNestedMenuItems = [
		{ title: 'Password' },
		{ title: 'Billing' },
	];
	constructor(
		private userService: FormrlyUsersService,
		private authService: NbAuthService,
		private fb: FormBuilder,
		private router: Router,
		private userSvc : UserData,
		private uploadService: UploadService,
		private cdRef: ChangeDetectorRef
	) {
	}

	changeTab(tabName) {
    //this.notifyContactUpdate.emit(this.contactData)
    this.notifyTabChange.emit(tabName);
    
	}
	ngOnInit() {
		this.isSaving = false;
		this.isDownloadingPhotoData = false;
		this.isUploading = false;

		if(this.profileData.image){
			this.isDownloadingPhotoData = true;
			this.uploadService.getAsDataUrl(this.profileData.image.providerResponse.location).then(
				dataUrl => { 
					this.newPhotoData = dataUrl; 
					this.isDownloadingPhotoData = false;
					this.cdRef.detectChanges();
				}
			);
		}
    
  //  this.profileData = this.contactData;
  }
  ngOnChanges(){
    this.getUserContactInfo(this.profileData.id);
  }
	getUserContactInfo(id) {
		this.userService.getMyContactListDetail(id).subscribe(
			userMeta => {
				this.profileData = userMeta;
				//this.initialEmail = userMeta.email;
				console.log(userMeta);
			},
			err => console.error(err)
		);
	}

	/**
	 * Handles the 'avatarChosen' event triggered by the Choose Avatar Popover component. 
	 * @param avatarSrc 
	 */
	onAvatarChosen(avatarSrc:string){
		this.uploadService.dataUrlToFile(avatarSrc).then(
			file => {
				this.newPhoto = file;
				this.newPhotoData = avatarSrc;
				this.isUploading = true;
				this.cdRef.detectChanges();
				this.uploadAvatar(this.newPhoto, null);
			}
		);
	}

	/**
	 * Updates a contact of the current user.
	 * @param value The contact to be updated.
	 */
	updateMyContactInfo(value){
		
		let hasEmptyFields = !value.name.first.length || !value.name.last.length || !value.email.length;

		if(hasEmptyFields){
			alert('Please enter a value in required fields.');
		}else{
			let isValidEmail = this.userService.validateEmailAddress(value.email);
			if(!isValidEmail){
				alert('Please enter a valid email address.');
			}else{
				let foundContactByEmail = this.contactsList.find((contact) => { return contact.email === value.email; });
				let isEmailOfAnotherContact = foundContactByEmail && (foundContactByEmail.id !== value.id);

				if(isEmailOfAnotherContact){
					alert('This email address is already in use by another contact.');
				}else{
					this.isSaving = true;
					this.userService.updateFormrlyUsersContact(value).subscribe(
						result 	=> { 
							this.notifyContactUpdate.emit(result); 
							this.isSaving = false;
						},
						err 	=> console.error(err)
					);	
				}
			}
		}
	  }
	  
	  /**
	   * Cancels the edit action and goes back to the contact screen. 
	   */
	  cancel(){
		this.router.navigate(['/blank/'], {skipLocationChange:true}).then(
            () => { this.router.navigateByUrl('contacts/view;id=' + this.profileData.id); }
		);
	  }

	  getContactsList(data) {
        console.log("This is data");
        console.log(data);
        this.contactsList = data;
        console.log('contactList tirgger');
    }

	
	selectImage(event, profileData) {
		this.newPhoto = event.target.files[0];
		console.log(this.newPhoto);
		this.uploadAvatar(event.target.files[0], profileData);
	  }

	  uploadAvatar(file, profileData) {
		this.userSvc.uploadAvatar(file, '')
		  .pipe(
			concatMap(fileMeta => {
				this.profileData.image = fileMeta.result.files.file[0];
				console.log(this.profileData);
				return this.userService.updateFormrlyUsersContact(this.profileData);
			})
		  ).subscribe(
			fileMeta => {
				this.isUploading = false;
				this.cdRef.detectChanges();
				this.triggerFalseClick();
			console.log('Update and File Meta Success Add');			
		  },
		  err => console.error(err)
		  );
	  }
}
