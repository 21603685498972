import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, TemplateRef } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { FormBuilder, FormGroup, Validator, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'ngx-name-change-popover',
  templateUrl: './name-change-popover.component.html',
  styleUrls: ['./name-change-popover.component.scss']
})
export class NameChangePopoverComponent implements OnInit {
  @Input('parentDataName') public initialFolderName;
  @Input('parentDataType') public type;
  @Input('parentDataClick') public click;
  @Output() notifyNameChange:EventEmitter<string>= new EventEmitter<string>();
  @ViewChild('popupDocumentNameClose') public popupDocumentNameClose: ElementRef<HTMLElement>;
  @ViewChild('buttonOpen') public buttonOpen: ElementRef<HTMLElement>;
  previousClick = 0;

  formFolderName: any;
  currentFolderForm = {
    folderName: ''
  };

  // Stores the extension of the current entity
  private entityExtension:string;
 
  constructor(
    private dialogService: NbDialogService,
    private fb: FormBuilder,

  ) { }

  ngOnInit() {
  }

ngOnChanges(){
  console.log(this.initialFolderName);
  if(this.click === this.previousClick){}
  else{
    if(this.IsDocOrTemplate){
      let fileNameParts = this.getFileNameParts(this.initialFolderName);
      this.currentFolderForm.folderName = fileNameParts.name;
      this.entityExtension = fileNameParts.extension;
    }else{
      this.currentFolderForm.folderName = this.initialFolderName;
    }
    this.triggerRenameOpen();
    //this.click++
  }
}
  open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, { context: 'this is some additional data' });
  }

  triggerRenameClose() {
    let el: HTMLElement = this.popupDocumentNameClose.nativeElement;
    el.click();
  }

  triggerRenameOpen() {
    let el: HTMLElement = this.buttonOpen.nativeElement;
    el.click();
  }

  renameCurrentFolder(name){
    if(this.initialFolderName === name){
      this.triggerRenameClose();
    }
    else{
      let newName = this.IsDocOrTemplate ? name.folderName + this.entityExtension : name.folderName;
      this.notifyNameChange.emit(newName);
      this.triggerRenameClose();
    }
  }

  /**
   * Indicates if the entity being handled is a document or a template.
   */
  private get IsDocOrTemplate():boolean{
    return (this.type === "Template") || (this.type === "Document");
  }

  /**
   * Returns the parts of a filename.
   * @param fileName The filename.
   */
  private getFileNameParts(fileName:string){

    fileName = fileName.trim();
    let sepIndex = fileName.lastIndexOf('.');
    if(sepIndex < 0){
      return {name: fileName, extension: ''};
    }

    return {
      name: fileName.substr(0, sepIndex), 
      extension: fileName.substr(sepIndex)
    };
  }
}
