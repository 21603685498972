import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-alert-popover',
  templateUrl: './alert-popover.component.html',
  styleUrls: ['./alert-popover.component.scss']
})
export class AlertPopoverComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
