import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Documents } from '../../../services/http/interfaces/documents';
import { WebviewerFinalService } from '../webviewer-final.service';

import { MembersVisitService } from '../../../services/members/members-visit.service';
import { VisitsAddService } from '../../webviewer/visits-add.service';
import { FileMemberVisits } from '../../../services/http/interfaces/file-members-visits';

declare const WebViewer: any;
@Component({
  selector: 'ngx-webviewer-preview',
  templateUrl: './webviewer-preview.component.html',
  styleUrls: ['./webviewer-preview.component.scss']
})
export class WebviewerPreviewComponent implements OnInit, AfterViewInit {
  @Input('documentData') public documentData: Documents;
  @ViewChild('viewer') viewer: ElementRef;

  /** ID of the user contact accesssing the document */
  @Input() public userContactId: any;

  public pdfNetInitialize: any;

  wvTools
  wvInstance
  wvDocViewer
  pageCount
  constructor(
    private webViewerService: WebviewerFinalService,
    private memberVisitsService: MembersVisitService,
    private visitsAddService: VisitsAddService
  ) { }

  ngOnInit() {
  }

  /**
   * Builds a new visit.
   */
  buildVisit(){

    let docData:any = this.documentData;
    let timestamp:any = this.visitsAddService.getTimestamp(); 

    let visit:FileMemberVisits = {
      memberVisitorId: '123',
      id: null,
      formrlyUserId: docData.formrlyUserId,
      formrlyUsersContactId: this.userContactId,
      filesMemberId: '123',
      fileMetaId: docData.id,
      visitData: [{}],
      timestamp: timestamp
    };

    this.memberVisitsService.createVisit(visit).subscribe(
      result => {
        console.log('Visit added:');
        console.log(visit);
      },
      error => console.log(error)
    );
  }

  async ngAfterViewInit(){
    await this.webViewerService.loadInstance(this.viewer.nativeElement, this.documentData.providerResponse.location);
    const instance = this.webViewerService.instance;
    const { PDFNet, docViewer } = instance;

    this.wvInstance = instance;
    this.wvDocViewer = instance;
    const { Annotations } = this.wvInstance;
    this.wvTools = instance.Tools;
    const tools = this.wvTools;
    const annotManager = instance.annotManager;
    const { Tools } = instance;
    annotManager.setCurrentUser('pcsmoak1@gmail.coom');
    annotManager.setIsAdminUser(false);
    instance.disableElements(['header']);


    instance.docViewer.on('documentLoaded', async (f) => {
      console.log('documentLoaded');
      //this.pdfNetInitialize = await PDFNet.initialize();
      const doc = docViewer.getDocument();
      //const pdfDoc = await doc.getPDFDoc();

      console.log(this.wvInstance)
      console.log(this.wvTools)
      console.log(tools)

      console.log('Import Annotations');
      console.log(this.documentData.annotations);
      await annotManager.importAnnotations(this.documentData.annotations);

      //get page count, and Add thumbnails if first load
      console.log('Get Page Count' + docViewer.getPageCount());
      this.pageCount = docViewer.getPageCount();

      if(this.userContactId){
        this.buildVisit();
      }

      // if (!this.documentData.pageCount || !this.documentData.pageThumbnails) {
      //   console.log('first Load, setting page count and generating thumbnails');
      //   this.documentData.pageCount = this.pageCount;
      //   await this.getThumbnails(doc, this.pageCount);
      // }
      // else {
      //   console.log('return visit');
      // }

      // Ensure that we have our first page.
      //await pdfDoc.requirePage(1);

      // Run our main function using 'runWithCleanup'
      //await PDFNet.runWithCleanup(async () => await main(pdfDoc));

      // Refresh the cache with the newly updated document
      //docViewer.refreshAll();
      // Update viewer with new document
      //docViewer.updateView();
      // you can access docViewer object for low-level APIs
      //docViewer.getDocument().refreshTextData();

      //console.log('export annotations right after');
     // console.log(annotManager.getAnnotationsList());
     // var annotations = annotManager.getAnnotationsList();
     // getAnnotationList(annotations, annotManager);
    })
    
    async function main(pdfDoc) {
      //alert("Hello WebViewer!");
    }

  }

}
