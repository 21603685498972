import { Component, Input, OnInit, TemplateRef, ElementRef } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { Router } from '@angular/router';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { NbAuthService } from '@nebular/auth';
import { UserData } from '../../../@core/data/users';
import { FormrlyUsersService } from '../../../@core/services/http/formrly-users.service';
import { AnalyticsService } from '../../../@core/utils';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { element } from '@angular/core/src/render3';
import { concatMap } from 'rxjs/operators';
//import { ContactsModule } from '../../../pages/contacts/contacts.module';
import { HeaderService } from './header.service';
@Component({
	selector: 'ngx-header',
	styleUrls: ['./header.component.scss'],
	templateUrl: './header.component.html',
})

export class HeaderComponent implements OnInit {
	images = new Array('/assets/images/tutorial-step-1.png','/assets/images/tutorial-step-2.png','/assets/images/tutorial-step-3.png') ;

	myContactInfo

	tabUpload = 'upload';
	addNewHeader="normal";
	ready = false;
	changeTabUpload(tabName) {
		this.tabUpload = tabName;
	}
	@Input() position = 'normal';
	user: any;
	userMenu = [{ title: 'Account', link: '/account' }, { title: 'Log out', link: '/auth/logout' }];

	constructor(private sidebarService: NbSidebarService,
		private authService: NbAuthService,
		private menuService: NbMenuService,
		private userService: UserData,
		private formrlyUsersService: FormrlyUsersService,
		private analyticsService: AnalyticsService,
		private router: Router,
		private dialogService: NbDialogService,
		public breakpointObserver: BreakpointObserver,
		private elementRef: ElementRef,
		private headerService: HeaderService
	) {
	}

	open(dialog: TemplateRef<any>) {
		this.dialogService.open(dialog, { context: 'this is some additional data' });
	}

	//@HostListener('window:resize', ['$event'])
	ngOnResize(event) {
		//console.log('test ngonresize');
		//console.log(event.target.innerWidth);
	}
	displayWindowSize() {

		// Get width and height of the window excluding scrollbars
		var w = document.documentElement.clientWidth;
		var h = document.documentElement.clientHeight;

		// Display result inside a div element
		//console.log("Width: " + w + ", " + "Height: " + h);
	}
	

	ngOnInit() {
		this.headerService.currentMyContactInfo.subscribe(message => {this.myContactInfo = message
			this.updateMyAvatarDetails();
		})

		this.userService.getCurrentUser()
		.pipe( 
			concatMap( (user: any) => {
			this.user = user;
			return  this.formrlyUsersService.getMyContactInfo();
		}) ).subscribe(
			contactMeta =>{
				if(contactMeta.image && contactMeta.image.providerResponse && contactMeta.image.providerResponse.location){
					this.user.picture = contactMeta.image.providerResponse.location;
				}
				this.headerService.changeMyContactInfo(contactMeta);
				this.ready =true
			},
			err => console.log(err)

		)


			
		function hasClass(element, className) {
			return (' ' + element.className + ' ').indexOf(' ' + className + ' ') > -1;
		}
		// Unneeded custom functiopn for menusidebar
		// function onItemSelection(eventlink, thisurl) {
		// 	if (eventlink === '/contacts' && (thisurl == eventlink || thisurl == '/contacts/view' || thisurl == '/contacts/add' || thisurl == '/contacts/edit')) {

		// 		console.log("Don't close the menu. contacts");
		// 	}
		// 	else if (document.activeElement.childElementCount === 2) {
		// 		console.log("This should be the case if the sidebar doesn't close but should");
		// 		//	this.sidebarService.toggle(true, 'menu-sidebar');
		// 		console.log(document.querySelectorAll('.menu-sidebar')[0]);
		// 		//document.getElementById("header-sidebar").classList.remove("expand");
		// 		//this.sidebarService.collapse();
		// 		//document.querySelectorAll('.menu-sidebar')[0].classList.remove("expanded");
		// 		//document.querySelectorAll('.menu-sidebar')[0].classList.add("collapsed");
		// 	}
		// 	/*	else if ( eventlink === 'contacts' ) {
		// 		}*/
		// 	else if (eventlink === thisurl) {
		// 		// Do something on Log out
		// 		console.log("Don't close the menu.");
		// 	}
		// 	else {
		// 		// Do something on Profile
		// 		document.getElementById("header-sidebar").classList.remove("expand");
		// 		console.log('Close the menu')
		// 	}
		// }
		this.menuService.onItemSelect()
			.subscribe((event: { tag: string, item: any }) => {
				if (document.documentElement.clientWidth < 576){
					this.sidebarService.toggle(true, 'menu-sidebar');
					console.log('this.menuservices.onitemselect');
					document.getElementById("header-sidebar").classList.remove("expand");	
				}
			});
		this.menuService.onItemClick()
			.subscribe((event) => {
				//console.log(this);
				//console.log(event.item.children);
				//console.log(this.router);
				//	console.log(this.router.url);
				//	console.log(event.item.link);  
				//console.log(document.activeElement.childElementCount);
				//console.log(document.activeElement.children);
				//console.log(event.target.attributes.id)
				//console.log('that was this suscribe');
				//	onItemSelection(event.item.link,this.router.url);
			});
		// this.authService.onTokenChange()
		// 	.subscribe(token => {
		// 		if (token.isValid()) {
		// 		}
		// 	});
		
		//Adjusts Header portion of Navigation to match breakpoint behavior of nebular menu
		this.breakpointObserver
			.observe(['(min-width: 576px)'])
			.subscribe((state: BreakpointState) => {
				if (state.matches) {
				} else {
					if (hasClass(document.getElementById("header-sidebar"), 'expand')) {
						document.getElementById("header-sidebar").classList.remove("expand");
					}
					else {
					}
				}
			});

		//this.elementRef.nativeElement.querySelector('.menu-item')
		//                            .addEventListener('click', this.menuService.bind(this));
	}

	updateMyAvatarDetails(){
		if(this.myContactInfo.image && this.myContactInfo.image.providerResponse && this.myContactInfo.image.providerResponse.location){
			this.user.picture = this.myContactInfo.image.providerResponse.location;
		}
	}
	onItemSelection(title: any) {
		throw new Error("Method not implemented.");
	}
	onClick(event) {
		//console.log(event);
	}
	goToUpgrade() {
		this.router.navigate(['/upgrade']);
	}

	toggleSidebar(): boolean {
		this.sidebarService.toggle(true, 'menu-sidebar');
		console.log('toggleSidebar trigger');

		//Toggle Header Menu/Logo Area with Toggle Bar to match behavior of Nebular Sidebar Menu
		var thiselement = document.getElementById("header-sidebar");
		function hasClass(element, className) {
			return (' ' + element.className + ' ').indexOf(' ' + className + ' ') > -1;
		}
		if (hasClass(thiselement, 'expand')) {
			
			thiselement.classList.remove("expand");
		}
		else {
			thiselement.classList.add("expand");
		}
		console.log(this);
		return false;
	}
	goToHome() {
		this.menuService.navigateHome();
	}
	startSearch() {
		this.analyticsService.trackEvent('startSearch');
	}
}
