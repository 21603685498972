import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WebviewerEditorService } from '../webviewer-editor/webviewer-editor.service';

@Injectable({
  providedIn: 'root'
})
export class WebviewerTemplateEditorService {

  constructor(
    private wvEditorService: WebviewerEditorService
  ) { }

  private templateAnnotations = new BehaviorSubject<any[]>([]);
  currentTemplateAnnotations = this.templateAnnotations.asObservable();

  changeTemplateAnnotations(data:any) {
    this.templateAnnotations.next(data)
  }
  private allAnnotationsPrep = new BehaviorSubject<any[]>([]);
  currentAllAnnotationsPrep = this.allAnnotationsPrep.asObservable();

  changeAllAnnotationsPrep(data:any) {
    this.allAnnotationsPrep.next(data)
  }

  async buildTemplateAnnotationList(annotations, annotManager, documentMembers){
    console.log(annotations);
    var annotationsPrep = [];
    var annotationsAllPrep = [];
    var a = 0;
    var isFirstTime = false;

    let templateFieldsCount = 0;
    let blankTemplateFieldsCount = 0;
    annotations.forEach(
      item => {
        if(item.CustomData && item.CustomData.isTempField){
          templateFieldsCount++;
          if(!item.getContents().trim()){
            blankTemplateFieldsCount++;
          }
        }
      }
    );
    let loadDefaultValues = (templateFieldsCount == blankTemplateFieldsCount);

    for (let i = 0; i < annotations.length; i++) {
      if (annotations[i].CustomData && annotations[i].CustomData.isTempField === true) {
        console.log('template List'+ i)
        annotationsPrep[a] = {
          id: '',
          type: '',
          author: '',
          required: true,
          contents: ''
        };
        annotationsPrep[a].id = annotations[i].ou;

        annotationsPrep[a].type = annotations[i].CustomData.type;
        annotationsPrep[a].author = annotManager.getDisplayAuthor(annotations[i]);
        annotationsPrep[a].required = annotations[i].CustomData.required;
        annotationsPrep[a].contents = annotations[i].getContents();

        if(loadDefaultValues && !annotationsPrep[a].contents.trim()){
          let memberIndex = (annotations[i].Author==='Sender') ? 0 : 1;
          switch(annotationsPrep[a].type){
            case 'template-date':
              let todayDateTime = new Date().toISOString();
              let todayDate = todayDateTime.substr(0,todayDateTime.indexOf('T'));
              annotationsPrep[a].contents = todayDate;
              break;
            case 'template-name':
              annotationsPrep[a].contents = documentMembers[memberIndex].name.first + ' ' + documentMembers[memberIndex].name.last;
              break;
            case 'template-company':
              annotationsPrep[a].contents = documentMembers[memberIndex].company.name;
              break;
            case 'template-title':
              annotationsPrep[a].contents = documentMembers[memberIndex].company.position;
              break;
            case 'template-email':
              annotationsPrep[a].contents = documentMembers[memberIndex].email;
              break;
          }
        }

        a++;
      }
      else if(annotations[i].Author==='Sender'){
        isFirstTime = true;
        console.log('sender test')
        this.wvEditorService.updateField(annotations[i].ou, 'memberFromTemplate', documentMembers[0].email, documentMembers)
      }
      else if (annotations[i].Author==="Receiver"){
        console.log('receiver test')
        this.wvEditorService.updateField(annotations[i].ou, 'memberFromTemplate', documentMembers[1].email, documentMembers)
      }

    }
    if(isFirstTime === false){
      a = 0;
      for (let i = 0; i < annotations.length; i++) {
        if ((annotations[i].CustomData && annotations[i].CustomData.isTempField === true )|| annotations[i].Author ===documentMembers[0].email || annotations[i].Author ===documentMembers[1].email) {
          console.log('all List'+ i)
          annotationsAllPrep[a] = {
            id: '',
            type: '',
            author: '',
            required: true,
            contents: ''
          };
          annotationsAllPrep[a].id = annotations[i].ou;
  
          annotationsAllPrep[a].type = annotations[i].CustomData.type;
          annotationsAllPrep[a].author = annotManager.getDisplayAuthor(annotations[i]);
          annotationsAllPrep[a].required = annotations[i].CustomData.required;
          annotationsAllPrep[a].contents = annotations[i].getContents();
          a++;
        }

        
      }
      for (let i = 0; i < annotationsAllPrep.length; i++) {
        console.log('update loop 1')
        //this.wvEditorService.updateField(annotationsAllPrep[i].id, 'memberFromTemplate', annotationsAllPrep[i].author, documentMembers)
      }

      
    }
    else{
      for (let i = 0; i < annotationsPrep.length; i++) {
        console.log('update loop 2')
        //this.wvEditorService.updateField(annotationsPrep[i].id, 'memberFromTemplate', annotationsPrep[i].author, documentMembers)
      }

    }
    console.log('Template annotation List');
    console.log(annotationsPrep);
    this.changeAllAnnotationsPrep(annotationsAllPrep);
    this.changeTemplateAnnotations(annotationsPrep);
    this.wvEditorService.instance.annotManager.deselectAllAnnotations();
    this.wvEditorService.instance.hotkeys.off();


    
  }
  getFieldCounts(annotations, documentMembers) {
    // console.log('getFieldCounts');
    // this.fieldCounts[0].author = 'unassigned';
    // this.fieldCounts[0].required = 0;
    // this.fieldCounts[0].optional = 0;
    // var memberAnnotations = annotations.filter((e) => {
    //   return (e.author === null || e.author === 'unassigned');
    // });
    // for (let ii = 0; ii < memberAnnotations.length; ii++) {
    //   if (memberAnnotations[ii].required === true) {
    //     this.fieldCounts[0].required = this.fieldCounts[0].required + 1;
    //   }
    //   else {
    //     this.fieldCounts[0].optional = this.fieldCounts[0].optional + 1;
    //   }
    // }
    // for (let i = 0; i < documentMembers.length; i++) {
    //   this.fieldCounts[i + 1] = {
    //     author: '',
    //     required: 0,
    //     optional: 0
    //   };
    //   this.fieldCounts[i + 1].author = documentMembers[i].id;
    //   this.fieldCounts[i + 1].required = 0;
    //   this.fieldCounts[i + 1].optional = 0;
    //   var memberAnnotations = annotations.filter((e) => {
    //     return e.author === documentMembers[i].email;
    //   });
    //   for (let ii = 0; ii < memberAnnotations.length; ii++) {
    //     if (memberAnnotations[ii].required === true) {
    //       this.fieldCounts[i + 1].required = this.fieldCounts[i + 1].required + 1;
    //     }
    //     else {
    //       this.fieldCounts[i + 1].optional = this.fieldCounts[i + 1].optional + 1;
    //     }
    //   }
    // }
    // this.changeFieldCountList(this.fieldCounts);
  }
  setTemplateFieldContents(annotManager,templateAnnotations,answers){
    var currentAnnotation;
    var currentAnnotationId
    for(let i =0; i<templateAnnotations.length;i++){
      //currentAnnotationId =templateAnnotations[i].id
      console.log(answers[templateAnnotations[i].id])
      currentAnnotation = annotManager.getAnnotationById(templateAnnotations[i].id);
      
      currentAnnotation.setContents(answers[templateAnnotations[i].id])
      annotManager.updateAnnotation(currentAnnotation);
      annotManager.redrawAnnotation(currentAnnotation);
      annotManager.trigger('annotationChanged', [[currentAnnotation], 'modify', {}]);
    }

  }
  async setAnnotationsAsLocked(annotations){
    console.log('set Annotations as locked');
    console.log(annotations)
    for(let i = 0; i>annotations.length;i++){
      if (annotations[i].lockedContents === true) {
        await this.wvEditorService.annotManager.deleteAnnotation(annotations[i]);
        annotations[i].NoMove = true;
        annotations[i].lockedContents = true;
        annotations[i].Locked = true;
        await this.wvEditorService.annotManager.addAnnotation(annotations[i]);
        await this.wvEditorService.annotManager.redrawAnnotation(annotations[i]);
      }
      else{
        annotations[i].NoMove = true;
        annotations[i].lockedContents = true;
        annotations[i].Locked = true;
        await this.wvEditorService.annotManager.updateAnnotation(annotations[i]);
        await this.wvEditorService.annotManager.redrawAnnotation(annotations[i]);
        console.log('did it work?');
      }

    }
    
  }
}
