import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-signature-completed',
  templateUrl: './signature-completed.component.html',
  styleUrls: ['./signature-completed.component.scss']
})
export class SignatureCompletedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
