import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-confirmation-popover',
  templateUrl: './confirmation-popover.component.html',
  styleUrls: ['./confirmation-popover.component.scss']
})
export class ConfirmationPopoverComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
