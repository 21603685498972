import { Component, OnInit, Input, ViewChild, ElementRef, TemplateRef, AfterViewInit } from '@angular/core';
import { NbSidebarService, NbDialogService, NbDialogRef } from '@nebular/theme';
import { concatMap } from 'rxjs/operators';
import { Documents } from '../../../services/http/interfaces/documents';
import { DocumentService } from '../../../services/http/document.service';
import { UserData } from '../../../data/users';
import { WebviewerEditorService } from './webviewer-editor.service';
import { WebviewerFinalService } from '../webviewer-final.service'
import { WebviewerSignService } from '../webviewer-sign/webviewer-sign.service';

@Component({
  selector: 'ngx-webviewer-editor',
  templateUrl: './webviewer-editor.component.html',
  styleUrls: ['./webviewer-editor.component.scss']
})
export class WebviewerEditorComponent implements OnInit, AfterViewInit {

  @Input('documentMembers') public documentMembers: any;
  @Input('webviewerMode') public webviewerMode: string;

  @ViewChild('viewer') viewer: ElementRef;
  @ViewChild('selectedAnnotation') selectedAnnotation: ElementRef<HTMLElement>;
  @ViewChild('sendAnnotationsToChild') sendAnnotationsToChild: ElementRef<HTMLElement>;
  @ViewChild('savedSignature') public savedSignatureCanvas: ElementRef;
  @ViewChild('popupClose') popupClose: ElementRef<HTMLElement>;
  @ViewChild('popupOpen') popupOpen: ElementRef<HTMLElement>;
  @ViewChild('popupnewdoc') popupnewdoc: TemplateRef<HTMLElement>;
  @ViewChild('popupUpdateField') popupUpdateField: TemplateRef<HTMLElement>;
  @ViewChild('openPopupUpdateField') openPopupUpdateField: ElementRef<HTMLElement>;


  drop2(event) { }
  drop(event) { }
  AddAnnot

  click
  wvOriginalMouseLeftUp
  wvNewMouseLeftUp
  clikedAddSavedAnnotation
  mouseLeftUpThis
  savedSignatureFullPublic
  savedSignatureInitialPublic
  currentSignatureEvent
  memberData = {
    savedSignatures: {
      initials: '',
      fullSignature: ''
    }
  }//!!! check to remove

  savedSignaturePath
  lastSignatureClicked

  public savedSignature;
  signToolTipTitle
  public testInitial = false;

  public initialLoad = true;
  wvInstance: any;
  wvTools: any;
  wvDocViewer: any;
  public pdfNetInitialize: any;

  public selectedAnnotaionFE;
  public xfdfStringLoader;
  public annotationsList;
  public annotationsSend;

  public dropPoint;
  public dragElementId;
  public fieldProperties;

  public currentPage = 1;
  public pageCount;
  eValue
  thisValue
  selectedSignatureAnnotaion
  selectedSignatureAnnotationField

  signatureModalShow = false;

  constructor(
    private documentService: DocumentService,
    private userSvc: UserData,
    private dialogService: NbDialogService,
    private webViewerService: WebviewerFinalService,
    private wvEditorService: WebviewerEditorService,
    public wvSignService: WebviewerSignService
  ) { }
  currentFieldType

  //Temporary trigger
  changeFieldProperties(data) {
    this.fieldProperties = data;
  }
  changeAnnotations(annotationsList) {
    this.annotationsSend = annotationsList;
  }
  triggerOpenPopupUpdateFieldClick(fieldType){
    this.currentFieldType = fieldType
    let el: HTMLElement = this.openPopupUpdateField.nativeElement
    el.click();
  }
  triggerFalseClick() {
    let el: HTMLElement = this.popupClose.nativeElement;
    el.click();
  }
  triggerFalseOpenClick() {
    let el: HTMLElement = this.popupOpen.nativeElement;
    el.click();
  }
  triggerFalseSelectAnnotation() {
    let el: HTMLElement = this.selectedAnnotation.nativeElement;
    el.click();
  }
  triggerFSendAnnotationsToChild() {
    let el: HTMLElement = this.sendAnnotationsToChild.nativeElement;
    el.click();
  }

  open(dialog: TemplateRef<any>) {
    //console.log(dialog);
    this.dialogService.open(dialog, { context: 'this is some additional data' })
      .onClose.subscribe(name => this.checkCloseDialog(dialog)
      );
    console.log('test')
  }
  openUpdateField(dialog: TemplateRef<any>) {
    //console.log(dialog);
    this.updateFieldData = {};
    this.dialogService.open(dialog, { context: 'this is some additional data' })
    //this.formUpdateField.reset();
      //.onClose.subscribe(name => this.checkCloseDialog(dialog)
      //);
      
    console.log('test')
  }
  checkCloseDialog(dialog) {
    if (dialog === this.popupnewdoc) {
      console.log('checkClose Dialog')
      //this.wvOriginalMouseLeftUp(this.mouseLeftUpThis, this.eValue);
      this.wvDocViewer.setToolMode('TextSelect');
    }
  }
  //Drag Drop Basics
  onDragElement(element) {
    this.dragElementId = element;
  }
  allowDrop(ev) {
    ev.preventDefault();
  }
  message: string
  dragId;
  docMembers;
  documentData;
  xfdfString;
  annotationList
  myContactInfo
  mobileClickElementId

  mobileE
  //Remove new message test  !!! Verify if unused
  newMessage() {
    this.wvEditorService.changeMessage("Hello from Parent")
  }
  selectedAnnotationId
  ngOnInit() {
    this.wvEditorService.currentMessage.subscribe(message => this.message = message)
    this.wvEditorService.currentDragElementId.subscribe(message => this.dragId = message)
    this.wvEditorService.currentMobileClickElementId.subscribe(message => this.mobileClickElementId = message)
    this.wvEditorService.currentXfdfString.subscribe(message => this.xfdfString = message)
    this.wvEditorService.changeDocumentMembers(this.documentMembers)
    this.wvEditorService.currentDocumentMembers.subscribe(message => this.docMembers = message)
    this.wvEditorService.currentDocumentData.subscribe(message => this.documentData = message)
    this.wvEditorService.currentClick.subscribe(message => {
      this.click = message
      this.triggerFalseSelectAnnotation()
    })
    this.wvEditorService.currentAnnotationList.subscribe(message => this.annotationList = message)
    this.wvEditorService.currentMyContactInfo.subscribe(message => this.myContactInfo = message)
    this.wvEditorService.currentSelectedAnnotationId.subscribe(message => this.selectedAnnotationId = message);
  }
  async ngAfterViewInit() {
    await this.wvEditorService.loadWebViewerEditor(this.viewer.nativeElement, this.documentData.providerResponse.location);
    const instance = this.wvEditorService.instance;
    this.wvInstance = instance;
    this.wvDocViewer = instance;
    this.wvTools = instance.Tools;
    const tools = this.wvTools;
    const annotManager = instance.annotManager;
    const { PDFNet, docViewer } = instance;
    const { Annotations } = this.wvInstance;
    const { Tools } = instance;

    annotManager.setCurrentUser(this.myContactInfo.email);
    instance.hotkeys.off();
    //docViewer.setReadyOnly(true);
    //Add to service
    instance.annotationPopup.add({
      type: 'actionButton',
      title: 'Duplicate',
      img: '/assets/images/icons/pdftron-icons/copy-outline.svg',
      onClick: () => {
        console.log(annotManager.getAnnotationById(this.selectedAnnotaionFE));
        const originalAnnotation = annotManager.getAnnotationById(this.selectedAnnotaionFE);
        const newAnnotationPosition = {
          x: originalAnnotation.getX(),
          y: originalAnnotation.getY() + originalAnnotation.getHeight() + 10
        }
        const originalAnnotationType = originalAnnotation.CustomData.type;
        console.log(newAnnotationPosition)
        this.wvEditorService.dropFreeTextAnnotation(newAnnotationPosition, originalAnnotationType)
      },
    });
    //Add to service
    instance.annotationPopup.add({
      type: 'actionButton',
      title: 'Settings',
      img: '/assets/images/icons/pdftron-icons/settings-2-outline.svg',
      onClick: () => {
        this.fieldProperties = null;
        this.wvEditorService.changeTab('settings')
        //this.wvEditorService.changeSidebarState(false);
        this.wvEditorService.changeSidebarState(true);
        this.triggerFalseSelectAnnotation()
        this.fieldProperties = this.selectedAnnotaionFE;
        this.triggerFalseSelectAnnotation()
      },
    });
    //Add to service
    //textsignature disable
    instance.disableElements([ 'zoomOutButton', 'zoomInButton', 'annotationUngroupButton', 'textSignaturePanelButton','imageSignaturePanelButton', 'linkButton'])

    let lastSignatureClicked;

    const renderSliderNameMember = () => {
      var selectedAnnotation;

      if (this.eValue || this.selectedAnnotationId) {
        console.log(this.selectedAnnotationId)
        if(this.selectedAnnotationId){selectedAnnotation = annotManager.getAnnotationById(this.selectedAnnotationId);}
        else{selectedAnnotation = annotManager.getAnnotationByMouseEvent(this.eValue);}
        if (selectedAnnotation) {
          //??? no custom data
          console.log(selectedAnnotation.getCustomData());
          console.log(selectedAnnotation.CustomData);
          if (selectedAnnotation.Subject === 'Signature' || selectedAnnotation.CustomData && (selectedAnnotation.CustomData.type === 'full-signature' || selectedAnnotation.CustomData.type === 'initial-signature')) {
            this.selectedSignatureAnnotaion = selectedAnnotation;
            lastSignatureClicked = selectedAnnotation.CustomData.type;
            this.lastSignatureClicked = lastSignatureClicked
            console.log('sig selected set');
          }
          else {
            this.selectedSignatureAnnotaion = null;
            lastSignatureClicked = null;
            this.lastSignatureClicked = null;
            console.log('sig selected not set')
          }
        }
      }
      else {
        const span = document.createElement("span");
        return span;
      }

      var innerHTML;
      //If Signature Field

      if (this.selectedSignatureAnnotaion) {
        if((this.selectedSignatureAnnotaion.Subject === 'Signature') && this.selectedSignatureAnnotaion.isGrouped()){
          let children  = annotManager.getGroupAnnotations(this.selectedSignatureAnnotaion);
          let targetChild = children.find( item => { return item.Subject === 'Free text'; });
          if(targetChild.CustomData.type === 'full-signature'){
            innerHTML = 'Signature';  
          }else if(targetChild.CustomData.type === 'initial-signature'){
            innerHTML = 'Initials';  
          }
        }else if(this.selectedSignatureAnnotaion.CustomData && this.selectedSignatureAnnotaion.CustomData.type==='full-signature'){
          console.log(this.selectedSignatureAnnotaion.CustomData.type)
          innerHTML = 'Signature';
        }
        else if(this.selectedSignatureAnnotaion.CustomData && this.selectedSignatureAnnotaion.CustomData.type==='initial-signature' ){
          innerHTML = 'Initials';
        }
          //
        

      }
      //If Date Field
      else if (selectedAnnotation && selectedAnnotation.CustomData && selectedAnnotation.CustomData.type === 'date-field') {
        innerHTML = 'Date';
      }
      //If User Data Field
      else if (selectedAnnotation && selectedAnnotation.CustomData &&
         (selectedAnnotation.CustomData.type === 'name-field' || selectedAnnotation.CustomData.type === 'company-field' || selectedAnnotation.CustomData.type === 'title-field' || selectedAnnotation.CustomData.type === 'email-field')) {
          
          switch(selectedAnnotation.CustomData.type){
            case 'name-field':
            innerHTML = 'Name';
            break;
            case 'company-field':
            innerHTML = 'Company';
            break;
            case 'title-field':
            innerHTML = 'Title';
            break;
            case 'email-field':
            innerHTML = 'Email';
            break;
            default:
            innerHTML = 'Name';
            break;
            
          }
      }
      //If Standard Field
      else if (selectedAnnotation && selectedAnnotation.CustomData &&
      (selectedAnnotation.CustomData.type === 'text-field' || selectedAnnotation.CustomData.type === 'number-field' )) {

        var innerHTML;
          switch(selectedAnnotation.CustomData.type){
            case 'text-field':
            innerHTML = 'Text';
            break;
            case 'number-field':
            innerHTML = 'Number';
            break;
            default:
            innerHTML = 'text';
            break;
            
          }
      }
      else {
        const span = document.createElement("span");
        return span;
      }

      let authorEmail = ((selectedAnnotation) && (selectedAnnotation.Author) && (selectedAnnotation.Author !== 'unassigned')) ? selectedAnnotation.Author : '(unassigned)';
      const div = document.createElement("div"); 
      div.innerHTML = `<p class="field-details text-center mb-0"><span class="field-type">${innerHTML}</span><br><span class="field-member-email">${authorEmail}</span></p>`;
      div.className ='popupLabel';
      return div; 
    };

    const renderSlider = () => {
      console.log('renderSlider')
      var selectedAnnotation
      if (this.eValue || this.AddAnnot) {
        if (this.eValue){
          selectedAnnotation = annotManager.getAnnotationByMouseEvent(this.eValue);
        }
        else{
          selectedAnnotation = this.AddAnnot;
          console.log(selectedAnnotation);
          console.log(this.AddAnnot);
          //clearAddAnnot();
        }
        
        console.log(selectedAnnotation);
        console.log(this.eValue);
        if (selectedAnnotation) {
          //??? no custom data
          console.log(selectedAnnotation.getCustomData());
          console.log(selectedAnnotation.CustomData);
          if (selectedAnnotation.Subject === 'Signature' || selectedAnnotation.CustomData && (selectedAnnotation.CustomData.type === 'full-signature' || selectedAnnotation.CustomData.type === 'initial-signature')) {
            this.selectedSignatureAnnotaion = selectedAnnotation;
            lastSignatureClicked = selectedAnnotation.CustomData.type;
            this.lastSignatureClicked = lastSignatureClicked
            console.log('sig selected set');
          }
          else {
            this.selectedSignatureAnnotaion = null;
            lastSignatureClicked = null;
            this.lastSignatureClicked = null;
            console.log('sig selected not set')
          }
        }

      }
      else {
        const span = document.createElement("span");
        return span;
      }
      //If Signature Field
      if (this.selectedSignatureAnnotaion && this.selectedSignatureAnnotaion.Author === annotManager.getCurrentUser()) {
        console.log(this.selectedSignatureAnnotaion)
        //if has annotation signature, show clear box
        if (this.selectedSignatureAnnotaion && (this.selectedSignatureAnnotaion.isGrouped() || this.selectedSignatureAnnotaion.getGroupedChildren()[0])) {
          const imageIcon = document.createElement("img");     // Create a <button> element
          imageIcon.src = '/assets/images/icons/pdf-mobile-icons/times-solid.svg';
          const div = document.createElement("div");
          div.onclick = () => {
            // Do something: Clearn Signature, ungroup and delete the proper field.???
            console.log('clicked the click me')
            //var groupedAnnotation = annotManager.getGroupAnnotations(this.selectedSignatureAnnotaion);
            var groupedAnnotation = annotManager.getSelectedAnnotations();
            var signatureToDelete;
            console.log(groupedAnnotation);
            if (isSignatureOrWidget(groupedAnnotation[0]) === 'full-signature' || isSignatureOrWidget(groupedAnnotation[0]) === 'initial-signature') {
              signatureToDelete = groupedAnnotation[1];
              var signatureWidget = groupedAnnotation[0];

              console.log('delete second')
            }
            else {
              signatureToDelete = groupedAnnotation[0]
              var signatureWidget = groupedAnnotation[1];
              console.log('delete first')
            }
            console.log(groupedAnnotation)
            annotManager.ungroupAnnotations(groupedAnnotation);
            annotManager.deleteAnnotation(signatureToDelete);

            signatureWidget.FillColor = new Annotations.Color(255, 251, 204);
            signatureWidget.StrokeThickness = 2;

            annotManager.deselectAnnotation(signatureWidget)
            annotManager.selectAnnotation(signatureWidget)

          };
          div.className = "Button";
          div.appendChild(imageIcon);
          this.signToolTipTitle = 'Clear Signature';
          return div;
        }
        //if does not have annotation signature, show Add siganture button
        else {
          console.log(this.selectedSignatureAnnotaion);
          const imageIcon = document.createElement("img");     // Create a <button> element
          imageIcon.src = '/assets/images/icons/pdf-mobile-icons/pdf-signature.png';
          const div = document.createElement("div");
          div.onclick = () => {
            const signatureCreateTool = docViewer.getTool('AnnotationCreateSignature');
            docViewer.setToolMode(signatureCreateTool);
            this.wvNewMouseLeftUp.call(signatureCreateTool, this.eValue);
          };
          div.className = "Button";
          div.appendChild(imageIcon);
          this.signToolTipTitle = 'Sign';
          return div;
        }
      }
      //If Date Field
      else if (selectedAnnotation && selectedAnnotation.CustomData && selectedAnnotation.CustomData.type === 'date-field' && selectedAnnotation.Author === annotManager.getCurrentUser()) {
        if (selectedAnnotation.getContents() === '' || !selectedAnnotation.getContents()) {
          console.log('get contents');
          console.log(selectedAnnotation.getContents())
          const imageIcon = document.createElement("img");     // Create a <button> element
          imageIcon.src = '/assets/images/icons/pdf-mobile-icons/pdf-date.png';
          const div = document.createElement("div");
          div.onclick = () => {
            console.log('date add click')
            var today = new Date();
            var setDate = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear();
            selectedAnnotation.setContents(setDate);
            selectedAnnotation.FillColor = new Annotations.Color(255, 255, 255);
            selectedAnnotation.StrokeThickness = 0;   
            annotManager.updateAnnotation(selectedAnnotation);
            annotManager.redrawAnnotation(selectedAnnotation);
            annotManager.trigger('annotationChanged', [[selectedAnnotation], 'modify', {}]);
            annotManager.trigger('updateAnnotationPermission', selectedAnnotation);
          };
          div.className = "Button";
          div.appendChild(imageIcon);
          this.signToolTipTitle = 'Clear Signature';
          return div;
        }
        else {
          const imageIcon = document.createElement("img");     // Create a <button> element
          imageIcon.src = '/assets/images/icons/pdf-mobile-icons/times-solid.svg';
          const div = document.createElement("div");
          div.onclick = () => {
            console.log('date add click')
            var today = new Date();
            var setDate = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear();
            selectedAnnotation.setContents('');
            selectedAnnotation.FillColor = new Annotations.Color(255, 251, 204);
            selectedAnnotation.StrokeThickness = 2;         
            annotManager.updateAnnotation(selectedAnnotation);
            annotManager.redrawAnnotation(selectedAnnotation);
            annotManager.trigger('annotationChanged', [[selectedAnnotation], 'modify', {}]);
            annotManager.trigger('updateAnnotationPermission', selectedAnnotation);
          };
          div.className = "Button";
          div.appendChild(imageIcon);
          this.signToolTipTitle = 'Clear Signature';
          return div;
        }
      }
      //If User Data Field
      else if (selectedAnnotation && selectedAnnotation.Author === annotManager.getCurrentUser() && selectedAnnotation.CustomData &&
        (selectedAnnotation.CustomData.type === 'name-field' || selectedAnnotation.CustomData.type === 'company-field' || selectedAnnotation.CustomData.type === 'title-field' || selectedAnnotation.CustomData.type === 'email-field')) {
        //if has data, show clear button
        if (selectedAnnotation.getContents() === '' || !selectedAnnotation.getContents()) {
          console.log('get contents');
          console.log(selectedAnnotation.getContents())
          const imageIcon = document.createElement("img");     // Create a <button> element
          var imgType;
          switch(selectedAnnotation.CustomData.type){
            case 'name-field':
            imgType = 'name';
            break;
            case 'company-field':
            imgType = 'company';
            break;
            case 'title-field':
            imgType = 'title';
            break;
            default:
            imgType = 'name';
            break;
            
          }
          imageIcon.src = '/assets/images/icons/pdf-mobile-icons/pdf-'+ imgType +'.png';
          
          const div = document.createElement("div");
          div.onclick = () => {
            var setContents = setFormrlyContents(selectedAnnotation.CustomData.type);

            setContents = setContents ? setContents.trim() : '';
            let launchPopup = ((imgType == 'company') || (imgType == 'title')) && !setContents.length;
            if(launchPopup){
              this.triggerOpenPopupUpdateFieldClick(imgType);
            }else{
              selectedAnnotation.setContents(setContents);
              selectedAnnotation.FillColor = new Annotations.Color(255, 255, 255);
              selectedAnnotation.StrokeThickness = 0;   
              annotManager.updateAnnotation(selectedAnnotation);
              annotManager.redrawAnnotation(selectedAnnotation);
              annotManager.trigger('annotationChanged', [[selectedAnnotation], 'modify', {}]);
              annotManager.trigger('updateAnnotationPermission', selectedAnnotation);
            }
          };
          div.className = "Button";
          div.appendChild(imageIcon);
          this.signToolTipTitle = 'Clear Signature';
          return div;
        }
        //If has no data, show add button
        else {
          const imageIcon = document.createElement("img");     // Create a <button> element
          imageIcon.src = '/assets/images/icons/pdf-mobile-icons/times-solid.svg';
          const div = document.createElement("div");
          div.onclick = () => {
            console.log('date add click')
            selectedAnnotation.setContents('');
            selectedAnnotation.FillColor = new Annotations.Color(255, 251, 204);
            selectedAnnotation.StrokeThickness = 2;  
            annotManager.updateAnnotation(selectedAnnotation);
            annotManager.redrawAnnotation(selectedAnnotation);
            annotManager.trigger('annotationChanged', [[selectedAnnotation], 'modify', {}]);
            annotManager.trigger('updateAnnotationPermission', selectedAnnotation);
          };
          div.className = "Button";
          div.appendChild(imageIcon);
          this.signToolTipTitle = 'Clear Signature';
          return div;
        }
      }
      //If Standard Field
      else if (selectedAnnotation && selectedAnnotation.Author === annotManager.getCurrentUser() && selectedAnnotation.CustomData &&
      (selectedAnnotation.CustomData.type === 'text-field' || selectedAnnotation.CustomData.type === 'number-field' )) {
        //If Has No Data Show Add Button
        if (selectedAnnotation.getContents() === '' || !selectedAnnotation.getContents()) {
          console.log('get contents');
          console.log(selectedAnnotation.getContents())
          const imageIcon = document.createElement("img"); 
          var imgType;
          switch(selectedAnnotation.CustomData.type){
            case 'text-field':
            imgType = 'text';
            break;
            case 'number-field':
            imgType = 'number';
            break;
            default:
            imgType = 'text';
            break;
            
          }
          imageIcon.src = '/assets/images/icons/pdf-mobile-icons/pdf-'+ imgType +'.png';
          
          const div = document.createElement("div");
          div.onclick = () => {
            //Trigger popup
            this.triggerOpenPopupUpdateFieldClick(imgType);
            // var setContents = setFormrlyContents(selectedAnnotation.CustomData.type);
            // selectedAnnotation.setContents(setContents);
            // selectedAnnotation.FillColor = new Annotations.Color(255, 255, 255);
            // selectedAnnotation.StrokeThickness = 0;   
            // annotManager.updateAnnotation(selectedAnnotation);
            // annotManager.redrawAnnotation(selectedAnnotation);
            // annotManager.trigger('annotationChanged', [[selectedAnnotation], 'modify', {}]);
            // annotManager.trigger('updateAnnotationPermission', selectedAnnotation);
          };
          div.className = "Button";
          div.appendChild(imageIcon);
          this.signToolTipTitle = 'Clear Signature';
          return div;
        }
        //If Has Data Show Clear
        else {
          const imageIcon = document.createElement("img");     // Create a <button> element
          imageIcon.src = '/assets/images/icons/pdf-mobile-icons/times-solid.svg';
          const div = document.createElement("div");
          div.onclick = () => {
            console.log('date add click')
            selectedAnnotation.setContents('');
            selectedAnnotation.FillColor = new Annotations.Color(255, 251, 204);
            selectedAnnotation.StrokeThickness = 2;  
            annotManager.updateAnnotation(selectedAnnotation);
            annotManager.redrawAnnotation(selectedAnnotation);
            annotManager.trigger('annotationChanged', [[selectedAnnotation], 'modify', {}]);
            annotManager.trigger('updateAnnotationPermission', selectedAnnotation);
          };
          div.className = "Button";
          div.appendChild(imageIcon);
          this.signToolTipTitle = 'Clear Signature';
          return div;
        }
      }
      else {
        const span = document.createElement("span");
        return span;
      }
    }
    const newCustomElement = {
      type: 'customElement',
      render: renderSlider,
      title: 'Test Title',
    };
    instance.annotationPopup.add(newCustomElement);

    const newCustomElementNameMember = {
      type: 'customElement',
      render: renderSliderNameMember,
    }
    instance.annotationPopup.add(newCustomElementNameMember);

    instance.enableElements(['customElement'])
    const isSignatureOrWidget = (selectedAnnotation) => {
      console.log(selectedAnnotation);
      if (selectedAnnotation.Subject === 'Signature') {
        return 'user-signature'
      }
      else if (selectedAnnotation.Subject === 'Free text') {
        if (selectedAnnotation.CustomData.type === 'initial-signature') {
          return 'initial-signature'
        }
        else if (selectedAnnotation.CustomData.type === 'full-signature') {
          return 'full-signature'
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    }
    const setFormrlyContents = (annotationType) => {
      var result;
      switch (annotationType) {
        case 'name-field':
          result = this.myContactInfo.name.first + ' ' + this.myContactInfo.name.last;
          break;
        case 'company-field':
          result = this.myContactInfo.company.name;
          break;
        case 'title-field':
          result = this.myContactInfo.company.position;
          break;
        case 'email-field':
          result = this.myContactInfo.email;
          break;
      }
      return result;
    };
    const annotSelectOriginalMouseLeftDown = Tools.AnnotationSelectTool.prototype.mouseLeftDown;
    Tools.AnnotationSelectTool.prototype.mouseLeftDown = function (e) {
      console.log('override FreeTextCreateTool mouseLeftUp');
      setEValue(e);
      console.log(this);
      console.log(this.pc);
      //mobileDraw(e);
      const windowCoordinates = this.getMouseLocation(e);
      const displayMode = docViewer.getDisplayModeManager().getDisplayMode();
// takes a start and end point but we just want to see where a single point is located
const page = displayMode.getSelectedPages(windowCoordinates, windowCoordinates);
const clickedPage = (page.first !== null) ? page.first : docViewer.getCurrentPage() - 1;
console.log('Page' + page);
console.log('Clicked Page' + clickedPage);
      console.log(annotManager.getAnnotationByMouseEvent(e));
      return annotSelectOriginalMouseLeftDown.call(this, e);
    }

    const mobileDraw = (e) =>{

      console.log(e)
      console.log(e.data)
      console.log(docViewer.getZoom())
      const zoomRatio = docViewer.getZoom();
      
      
      if(annotManager.getAnnotationByMouseEvent(e)){
        //alert('You cannot on an annotation')
        this.wvEditorService.changeMobileClickElementId('');
      }
      if(this.mobileClickElementId && !annotManager.getAnnotationByMouseEvent(e)){
        //alert('you touched the screen x is: ' + JSON.stringify(e) );
        //alert('you touched the screen x is: ' + e.data.pageCoordinate.x + ' and y is' + e.mouseX);
        //alert('you touched the screen' + e.pageX);
        //alert('you touched the screen x is: ' + e.data.pageCoordinate.x + ' and y is' + e.mouseX);
        this.dropPoint = { x: e.pageX, y: e.pageY };
        this.dropPoint = { x:e.data.pageCoordinate.x, y:e.data.pageCoordinate.y}
        //this.dropPoint = { x: e.pageX/zoomRatio, y: e.pageY/zoomRatio };
        this.wvEditorService.dropFreeTextAnnotation(this.dropPoint, this.mobileClickElementId)
        this.wvEditorService.changeMobileClickElementId('');
        //alert('you touched the screen' +  e.data.pageCoordinate.x + ' and y is' + e.mouseX);
      }
      else{
        console.log('no mobile item');
      }
    };
    // const annotSelectOriginalKeyDown = Tools.AnnotationSelectTool.prototype.keyDown;
    // Tools.AnnotationSelectTool.prototype.keyDown = function (e) {
    //   console.log('override keyDown mouseLeftUp');
    //   console.log(e);
    //   console.log(this);
    //   //return annotSelectOriginalKeyDown.call(this, e);
    // }


    const setEValue = (e) => {
      this.eValue = e;
      //clearAddAnnot();
    }
    instance.iframeWindow.document.body.ondragover = function (e) {
      e.preventDefault();
      console.log('on dragover');
      return false;
    };

    instance.iframeWindow.document.body.ondrop = (e) => {
      console.log('on drop');

      //
      var scrollElement = instance.docViewer.getScrollViewElement();
      var scrollLeft = scrollElement.scrollLeft || 0;
      var scrollTop = scrollElement.scrollTop || 0;
      var dropPoint2 = { x: e.pageX + scrollLeft, y: e.pageY + scrollTop };
      const displayMode = docViewer.getDisplayModeManager().getDisplayMode();
      const page = displayMode.getSelectedPages(dropPoint2, dropPoint2);
      if (!!dropPoint2.x && page.first == null) {
        return; // don't add to an invalid page location
      }
      console.log(displayMode);
      console.log(page);
      const pageNumber = page.first !== null ? page.first : docViewer.getCurrentPage();
      console.log(pageNumber);


      this.dropPoint = { x: e.offsetX, y: e.offsetY };
      
      console.log(e)
      //this.dropPoint = { x:e.data.pageCoordinate.x, y:e.data.pageCoordinate.y}
      
      const zoomRatio = docViewer.getZoom();
      this.dropPoint = { x: e.offsetX/zoomRatio, y: e.offsetY/zoomRatio };
      //this.dropPoint = dropPoint2;
      this.dropPoint = { x: e.pageX + scrollLeft, y: e.pageY + scrollTop, xAdjusted: e.offsetX/zoomRatio, yAdjusted: e.offsetY/zoomRatio }
      console.log(scrollElement.scrollLeft)
      console.log(scrollElement.scrollTop)
      
      //console.log('Page Width:' + docViewer.getPageWidth(docViewer.getCurrentPage()));
      //console.log('Page Height:' + docViewer.getPageHeight(docViewer.getCurrentPage()));
      console.log(this.dropPoint);
      //this.dropPoint = { x: e.pageX/zoomRatio, y: e.pageY/zoomRatio };
      //this.eValue = e;
      e.preventDefault();
      this.wvEditorService.dropFreeTextAnnotation(this.dropPoint, this.dragId);
      this.triggerFSendAnnotationsToChild();
      //???
      console.log(this.eValue);
      
      return false;
    };
    instance.iframeWindow.document.body.ontouchstart = (e) =>{
      this.mobileE = e
      //alert('you touched the screen' + e);
    }
    
    instance.iframeWindow.document.body.ontouchend = (e) =>{
      //this.mobileE = e
      mobileDraw(this.mobileE);
      //alert('you touched the screen' + e);
    }
    annotManager.on('updateAnnotationPermission', async (annotation) => {
      console.log('updateAnnotationPermission');
      console.log(annotation);
      annotManager.deselectAnnotation(annotation);
      annotManager.selectAnnotation(annotation);
    });
    annotManager.on('annotationChanged', async function (annots, action, options) {
      console.log('annotationChanged');
      console.log(annots);
      console.log(action);
      console.log(options);
      console.log(this.xfdfString)
      if (options.imported === true) {
        console.log('First Change not real')
      }
      else {

        triggerChangeAnnotationBackground(annots, action);
        console.log('find error1');
        if (action === 'delete') {
          changeTab('fields');
        }

        if (action === 'add' && annots[0].Subject === 'Signature') {
          console.log('add annotation Signature')
          //console.log(annots);
          groupSignatureToFreeText(annots[0]);
        }
        const xfdfString = await annotManager.exportAnnotations();
        console.log(xfdfString);
        updateAnnotations(xfdfString);
        buildAnnotations(annotManager.getAnnotationsList(), annotManager);

        if((action === 'add') && (annots[0].Subject != 'Signature')){
          annotManager.deselectAllAnnotations();
          setEValue(null);
          //setAddAnnot(annots[0]);
          //annotManager.selectAnnotation(annots[0]);
        }
        //this.wvEditorService.buildAnnotationList(annotManager.getAnnotationsList(), annotManager)
      }
    });
    const setAddAnnot = (annot) =>{
      this.AddAnnot = annot;
    };
    const clearAddAnnot = () =>{
      this.AddAnnot = null;
    };
    const triggerChangeAnnotationBackground = (annots, action) =>{
      this.changeAnnotationBackground(annots,action);
    };
    const changeTab = (tab) => {
      this.wvEditorService.changeTab(tab);
    }
    const groupSignatureToFreeText = async (signatureAnnotation) => {
      console.log('group signature annotation')
      
      console.log(docViewer.getDocument().getPageMatrix(0));

     // console.log(selectedSignatureAnnotationField)
      console.log(this.selectedSignatureAnnotationField.getX())
      console.log(this.selectedSignatureAnnotationField.getY())
      console.log(this.selectedSignatureAnnotationField.getWidth())
      console.log(this.selectedSignatureAnnotationField.getHeight())
      console.log(this.selectedSignatureAnnotationField.getPageNumber())
      
      const annotationCoordinates = {
        annotation: {
          Left: this.selectedSignatureAnnotationField.getLeft(),
          Bottom: this.selectedSignatureAnnotationField.getY(),
          Width: this.selectedSignatureAnnotationField.getWidth(),
          Height: this.selectedSignatureAnnotationField.getHeight(),
          PageNumber: this.selectedSignatureAnnotationField.getPageNumber(),
        },

        signature: {
          Left: signatureAnnotation.getLeft(),
          Bottom: signatureAnnotation.getY(),
          Width: signatureAnnotation.getWidth(),
          Height: signatureAnnotation.getHeight(),
          PageNumber: signatureAnnotation.getPageNumber(),
        }
      };
      console.log(annotationCoordinates);

      console.log(signatureAnnotation.getX())
      console.log(signatureAnnotation.getLeft())
      console.log(signatureAnnotation.getBottom())
      console.log(signatureAnnotation.getWidth())
      console.log(signatureAnnotation.getHeight())
      //console.log(signatureAnnotation.getPaths())
      var oldPath = signatureAnnotation.getPaths();
      console.log(oldPath);

      const newPaths = changeSignaturePaths(signatureAnnotation.getPaths(), annotationCoordinates);
      signatureAnnotation.setPath(newPaths[0], 0)
      console.log('new Paths')
      console.log(newPaths);
      signatureAnnotation.setWidth(this.selectedSignatureAnnotationField.getWidth())
      signatureAnnotation.setHeight(this.selectedSignatureAnnotationField.getHeight())
      
      
      //Wrong Page Fix
      if(this.selectedSignatureAnnotationField.getPageNumber() !== signatureAnnotation.getPageNumber() ){
        console.log('wrong page patch');
        await annotManager.deleteAnnotation(signatureAnnotation);
        signatureAnnotation.setPageNumber(this.selectedSignatureAnnotationField.getPageNumber())
        await annotManager.addAnnotation(signatureAnnotation);
        await annotManager.redrawAnnotation(signatureAnnotation);
      }
      else{
        console.log('update on correct page');
        await annotManager.updateAnnotation(signatureAnnotation); 
        await annotManager.redrawAnnotation(signatureAnnotation);
      }
      console.log(signatureAnnotation.getX())
      console.log(signatureAnnotation.getY())
      console.log(signatureAnnotation.getWidth())
      console.log(signatureAnnotation.getHeight())
      

      this.selectedSignatureAnnotationField.StrokeThickness = 0;
      this.selectedSignatureAnnotationField.FillColor = new Annotations.Color(255, 255, 255);
      await annotManager.updateAnnotation(this.selectedSignatureAnnotationField);
      await annotManager.redrawAnnotation(this.selectedSignatureAnnotationField);

      console.log(signatureAnnotation);
      console.log(this.selectedSignatureAnnotationField);
      annotManager.groupAnnotations(this.selectedSignatureAnnotationField, [signatureAnnotation]);
      console.log(signatureAnnotation.isGrouped());
      console.log(this.selectedSignatureAnnotationField.isGrouped());
    };

    const changeSignaturePaths = (signatureAnnotationPaths, annotationCoordinates) => {
      console.log('changeSignaturePaths');
      console.log(annotationCoordinates)
      var annotationRatio = annotationCoordinates.annotation.Width / annotationCoordinates.annotation.Height
      var signatureRatio = annotationCoordinates.signature.Width / annotationCoordinates.signature.Height
      console.log(annotationRatio);
      console.log(signatureRatio);
      var scaling;
      if (annotationRatio < signatureRatio) {
        console.log('width scaling');
        scaling = annotationCoordinates.annotation.Width / annotationCoordinates.signature.Width;
      }
      else {
        //if ratios equal doesn't matter for scaling
        console.log('height scaling');
        scaling = annotationCoordinates.annotation.Height / annotationCoordinates.signature.Height;
      }

      var newPath = signatureAnnotationPaths;
      for (let i = 0; i < signatureAnnotationPaths.length; i++) {
        for (let ii = 0; ii < signatureAnnotationPaths[i].length; ii++) {
          newPath[i][ii].x = annotationCoordinates.annotation.Left + scaling * (signatureAnnotationPaths[i][ii].x - annotationCoordinates.signature.Left);
          newPath[i][ii].y = annotationCoordinates.annotation.Bottom + scaling * (signatureAnnotationPaths[i][ii].y - annotationCoordinates.signature.Bottom);
        }
      }
      return newPath;
    };
    annotManager.on('annotationDrawn', async function (page) {
      console.log('annotationDrawn');
      const xfdfString = await annotManager.exportAnnotations();
      this.triggerFSendAnnotationsToChild()
    });

    const updateAnnotations = (xfdfString) => {
      console.log('changeannotations')
      this.wvEditorService.changeXfdfString(xfdfString);
      this.wvEditorService.putAnnotations(this.documentData.id, this.documentData, xfdfString)
    }
    const buildAnnotations = (annotationList, annotManager) => {
      this.wvEditorService.buildAnnotationList(annotationList, annotManager, this.docMembers);
      this.annotationsList = this.wvEditorService.annotationsList;
      this.triggerFSendAnnotationsToChild();
    }
    //  Selects Only One Annotation, if 2 are selected it deslects the first selected item
    const selectedAnnots = annotManager.getSelectedAnnotations();
    if (selectedAnnots.length > 1) {
      //console.log(selectedAnnots);
      const firstSelectedAnnot = selectedAnnots[0];
      //annotManager.deselectAnnotation(firstSelectedAnnot);

    }
    annotManager.on('annotationSelected', async (annotations, action) => {
      console.log('annotationSelected');
      console.log(action);

      let currentlySelectedAnnots = annotManager.getSelectedAnnotations();
      let multipleAnnotsSelected = currentlySelectedAnnots.length > 1;
      if((currentlySelectedAnnots.length === 2) && currentlySelectedAnnots[0].isGrouped()){
        multipleAnnotsSelected = false;
      }

      if(multipleAnnotsSelected){
        annotManager.deselectAllAnnotations();
        annotManager.selectAnnotation(annotations[0]);
        return;
      }

      if (annotations) {
        console.log('action selected');
        if (annotations[0].CustomData.type === 'full-signature' || annotations[0].CustomData.type === 'initial-signature') {
          console.log('full signature selected');
          this.selectedSignatureAnnotationField = annotations[0];
          console.log(this.selectedSignatureAnnotationField);
        }
        else if (annotations[0].Subject === 'Signature') {
          console.log('signature')
          console.log(annotations);
          return;
        }
        else {
          this.selectedSignatureAnnotationField = null;
        }
        this.selectedAnnotaionFE = annotations[0].ou;
        this.fieldProperties = annotations[0].ou;
        this.wvEditorService.changeSelectedAnnotationId(annotations[0].ou);
        console.log(this.annotationList);
        console.log(annotations[0].ou);
        const selectedAnnotation = this.annotationList.find((e) => {
          return e.id === annotations[0].ou;
        });
        this.wvEditorService.changeSelectedAnnotation(selectedAnnotation);
        this.wvEditorService.changeTab('settings');
        this.triggerFalseSelectAnnotation();
      }
      else if (!annotations) {
        console.log('action deselected');
        this.fieldProperties = null;
        //this.selectedSignatureAnnotationField = null;
        this.wvEditorService.changeSelectedAnnotationId(null);
        this.wvEditorService.changeTab('fields')
        this.triggerFalseSelectAnnotation();
      }
    });

    //Event Listner Listen Page Change Add Visit Details
    this.viewer.nativeElement.addEventListener('pageChanged', (e) => {
      const pageNumber = e.detail[0];
      console.log(`Current page is ${pageNumber}`);
      this.currentPage = pageNumber;
    });
    instance.docViewer.on('annotationsLoaded', async () => {
      console.log('annotations loaded');
      var annotationsTestAfter = annotManager.getAnnotationsList();
      annotationsTestAfter = annotationsTestAfter.filter(item => item.Subject !== 'Widget'); 
      await annotManager.deleteAnnotations(annotationsTestAfter);
      await annotManager.importAnnotations(this.documentData.annotations);
      var annotations = annotManager.getAnnotationsList();
      this.wvEditorService.buildAnnotationList(annotations, annotManager, this.documentMembers);
      changeXfdf(this.documentData.annotations);
      
      docViewer.setToolMode(docViewer.getTool('TextSelect'))
      console.log(docViewer.getToolMode());
      docViewer.setToolMode(docViewer.getTool('AnnotationEdit'))
      console.log(docViewer.getToolMode());
      console.log(instance.Tools);
      console.log(docViewer.tools);
      // console.log(docViewer.getToolMode());
      //docViewer.setToolMode()
    });
    //Signature Start
    //Signature Customization
    const createSignHereElement = Annotations.SignatureWidgetAnnotation.prototype.createSignHereElement;

    Annotations.SignatureWidgetAnnotation.prototype.createSignHereElement = function () {
      const signatureWidget = this;

      // signHereElement is the default one with dark blue background
      const signHereElement = createSignHereElement.apply(this, arguments);

      signHereElement.addEventListener('click', () => {
        lastSignatureClicked = signatureWidget.fieldName;
        //signatureWidget.fieldName ="fullSignature.UserID.FieldID"
        //console.log(lastSignatureClicked);
        console.log('click sign element');
      });
      return signHereElement;
    }
    const originalMouseLeftUp = Tools.SignatureCreateTool.prototype.mouseLeftUp;
    this.wvOriginalMouseLeftUp = Tools.SignatureCreateTool.prototype.mouseLeftUp;
    let addSignatureFromDB = false;
    //console.log(originalMouseLeftUp);
    Tools.SignatureCreateTool.prototype.mouseLeftUp = async function (e) {
      console.log('mouse left up');
      console.log(e);
      console.log(this);
      saveMouseLeftUpThis(this);
      console.log(lastSignatureClicked);

      const sigsTest = docViewer.getTool('AnnotationCreateSignature').getSavedSignatures();
      console.log(sigsTest);

      const savedSig = getMemberSavedSignature(lastSignatureClicked);
      console.log(savedSig);

      if (savedSig) {
        var savedSignature = JSON.parse(savedSig)
      }
      console.log(savedSignature);

      //checking if user clicked button in custom signature modal to add saved signature to widget
      if (addSavedIsClicked() === true) {
        console.log('click trigger');
        const sigss = this.getSavedSignatures();
        console.log(sigss);
        addSignatureFromDB = true;
        this.deleteSavedSignature(1);
        this.deleteSavedSignature(0);
        const empty = this.getSavedSignatures();
        console.log(empty);
        await importSavedSignature(this, savedSignature);
        const publicSavedSignature = getPublicSavedSignature(lastSignatureClicked);

        const sigs = this.getSavedSignatures();
        console.log(sigs);
        console.log(publicSavedSignature)
        originalMouseLeftUp.call(this, e);
        if (sigs[0]) {
          console.log('db sig imported')
          this.setSignature(sigs[0]);
          this.addSignature();
          originalMouseLeftUp.call(this, e);
          docViewer.setToolMode(docViewer.getTool('AnnotationEdit'))
          docViewer.setToolMode(docViewer.getTool('AnnotationEdit'))
          console.log(docViewer.getToolMode())
          console.log('dbSig added')
        }
        else if (publicSavedSignature && publicSavedSignature[0]) {
          console.log('db sig option 2')
          this.setSignature(publicSavedSignature[0]);
          this.addSignature();
          originalMouseLeftUp.call(this, e);
          docViewer.setToolMode(docViewer.getTool('AnnotationEdit'))
          docViewer.setToolMode(docViewer.getTool('AnnotationEdit'))
          console.log(docViewer.getToolMode())
          console.log('dbSig added')
        }
        else {
          console.log('error signature not added');
          originalMouseLeftUp.call(this, e);
          docViewer.setToolMode(docViewer.getTool('AnnotationEdit'))
          docViewer.setToolMode(docViewer.getTool('AnnotationEdit'))
          console.log(docViewer.getToolMode())
        }
        return;
      }
      if (savedSignature) {
        console.log('saved then don\'t show modal', savedSignature);
        console.log(sigsTest);
        return openSavedSignatureCustomModal.call(this, savedSignature, e, docViewer);
      }
      else {
        console.log('no saved then show modal');
        this.deleteSavedSignature(0);
        this.deleteSavedSignature(0);
        addSignatureFromDB = false;
        return originalMouseLeftUp.call(this, e);
      }
    };
    //For Usage outside WebViewer
    this.wvNewMouseLeftUp = Tools.SignatureCreateTool.prototype.mouseLeftUp;
    const getPublicSavedSignature = (lastSignatureClicked) => {
      console.log('getPublicSavedSignature')
      console.log(lastSignatureClicked);
      if (lastSignatureClicked === 'full-signature') {
        console.log('return full signature')
        return this.savedSignatureFullPublic;
      }
      else if (lastSignatureClicked === 'initial-signature') {
        console.log('return initial signature')
        console.log(this.savedSignatureInitialPublic)
        return this.savedSignatureInitialPublic;
      }
    }
    const addSavedIsClicked = () => {
      if (this.clikedAddSavedAnnotation === true) {
        this.clikedAddSavedAnnotation = false;
        return true;
      }
      else { return false };
    }
    const saveMouseLeftUpThis = (data) => {
      console.log('saveMouseLeftUpThis');
      //console.log(data); 
      this.mouseLeftUpThis = data;
    };
    const importSavedSignature = async (signatureToolThis, savedSignature) => {
      await signatureToolThis.importSignatures(savedSignature);
      await signatureToolThis.importSignatures(savedSignature);
      const sigs = signatureToolThis.getSavedSignatures();
      console.log(sigs);
      console.log('importSavedSiganture Success')
    }
    const openSavedSignatureCustomModal = async (sigs, e, docViewer) => {
      console.log('openSavedSignatureCustomModal');
      await this.triggerFalseOpenClick();
      console.log(this.savedSignatureCanvas);
      console.log(sigs);
      this.currentSignatureEvent = e;
      var savedSignaturePaths = sigs[0];
      function getYs() {
        var result = [];
        savedSignaturePaths.forEach(item => {
          result = result.concat(item.map(point => point.y));
        });
        return result;
      }
      function getMinY() {
        return Math.min(...getYs());
      }
      function getMaxY() {
        return Math.max(...getYs());
      }
      function getXs() {
        var result = [];
        savedSignaturePaths.forEach(item => {
          result = result.concat(item.map(point => point.x));
        });
        return result;
      }
      function getMinX() {
        return Math.min(...getXs());
      }
      function getMaxX() {
        return Math.max(...getXs());
      }
      const annotationCoordinates = {
        annotation: {
          Left: 0,
          Bottom: 50,
          Width: 150,
          Height: 50,
          //PageNumber: this.selectedSignatureAnnotaion.getPageNumber(),
        },

        signature: {
          Left: getMinX(),
          Bottom: getMaxY(),
          Width: getMaxX() - getMinX(),
          Height: getMaxY() - getMinY(),
          //PageNumber: savedSignaturePaths.getPageNumber(),
        }
      };
      const newSavedSignaturePaths = changeSignaturePaths(savedSignaturePaths, annotationCoordinates)
      console.log(savedSignaturePaths[0]);
      setTimeout(() => {
        console.log("Hello from setTimeout");
        const canvasEl: HTMLCanvasElement = this.savedSignatureCanvas.nativeElement;
        var cx = canvasEl.getContext('2d');
        cx.beginPath;
        for (let i = 0; i < newSavedSignaturePaths.length; i++) {
          for (let ii = 0; ii < newSavedSignaturePaths[i].length; ii++) {
            if (ii === 0) {
              cx.moveTo(newSavedSignaturePaths[i][ii].x, newSavedSignaturePaths[i][ii].y);
            }
            else {
              cx.lineTo(newSavedSignaturePaths[i][ii].x, newSavedSignaturePaths[i][ii].y);
            }
          }
        }
        cx.stroke();
      }, 10);
    }
    //Event on Signature Saved
    docViewer.getTool('AnnotationCreateSignature').on('signatureSaved', function (annotation) {
      console.log('savedSignature')
      //console.log(annotation);
      //console.log(addSignatureFromDB);
      //console.log(lastSignatureClicked);
      const savedSignatureJson = JSON.stringify(this.exportSignatures());
      // console.log(savedSignatureJson);//event coordinates with offset

      if (addSignatureFromDB === false) {
        console.log('new saved signature')
        saveUserSignature(savedSignatureJson, lastSignatureClicked);
        saveUserSignatureFalse(annotation, lastSignatureClicked);
      }
      else {
        console.log('db saved signature')
        saveUserSignatureFalse(annotation, lastSignatureClicked);
      }
    });
    const saveUserSignatureFalse = (annotation, lastSignatureClicked) => {
      console.log('save User Sugnature');
      //console.log(annotation)
      if (lastSignatureClicked === 'full-signature') {
        this.savedSignatureFullPublic = annotation;
        //console.log(this.savedSignatureFullPublic);
      }
      else if (lastSignatureClicked === 'initial-signature') {
        this.savedSignatureInitialPublic = annotation;
        // console.log(this.savedSignatureInitialPublic);
      }
    };
    docViewer.getTool('AnnotationCreateSignature').on('annotationAdded', function (annotation) {
      console.log(annotation);
      const signatureWidgetAnnots = annotManager.getAnnotationsList().filter(
        annot => annot instanceof Annotations.SignatureWidgetAnnotation
      );
      //Update or remove????
      console.log(signatureWidgetAnnots);
      signatureWidgetAnnots.forEach(annotSign => {
        console.log(annotSign);
        if (annotSign.annot) {
          // if this signature field is signed initially
          console.log('isSigned')
        } else {
          console.log('notSigned')
        }
      })
      annotManager.deselectAllAnnotations();
    });
    const getMemberSavedSignature = (signatureType) => {
      console.log('getMemberSavedSignature');
      //console.log(signatureType)
      //console.log(this.memberData.savedSignatures)
      //console.log(this.myContactInfo.savedSignatures)
      if (!this.myContactInfo.savedSignatures) {
        return null;
      }
      else {

      }
      if (signatureType === 'full-signature' && this.myContactInfo.savedSignatures.fullSignature) {
        return this.myContactInfo.savedSignatures.fullSignature
      }
      else if (signatureType === 'initial-signature' && this.myContactInfo.savedSignatures.initials) {
        return this.myContactInfo.savedSignatures.initials
      }
      else {
        console.log('error no recognized signature type or no signature data');
        return null;
      }
    }
    const saveUserSignature = (annotation, lastSignatureClicked) => {

      console.log('saveUserSignature');
      //const lastSignatureNameSplit = lastSignatureClicked.split('-');
      const lastSignatureNameSplit = lastSignatureClicked;
      var signatureType
      console.log(lastSignatureNameSplit);
      if (lastSignatureNameSplit === 'initial-signature') {
        signatureType = 'initial-signature';

      }
      else if (lastSignatureNameSplit === 'full-signature') {
        signatureType = 'full-signature';
      }
      else {
        console.log('signature type not recognized')
        return false;
      }
      this.savedSignaturePath = annotation;
      console.log(annotation);
      console.log(annotation[0].x);
      //var updatedAnnotation = this.remapSignature(annotation[0]);
      //Temporary for testing
      //this.wvSignService.putSavedSignature(annotation, 'initial', this.memberData);
      this.wvEditorService.putSavedSignature(annotation, signatureType, this.myContactInfo);
    };
    ///Signature End
    instance.docViewer.on('documentLoaded', async (f) => {
      console.log('documentLoaded');

      //this.pdfNetInitialize = await PDFNet.initialize();
      const doc = docViewer.getDocument();
      //const pdfDoc = await doc.getPDFDoc();
      
      // Raj Note: Import Annotations Moved to Event on.('annotationsLoaded' function

      //const annotationsFromDB = this.documentData.annotations;
      //await annotManager.importAnnotations(this.documentData.annotations);
      //await annotManager.importAnnotations(annotationsFromDB);
      
      //get page count, and Add thumbnails if first load
      //console.log('Get Page Count' + docViewer.getPageCount());
      //console.log(doc.getPageMatrix(0))
      this.pageCount = docViewer.getPageCount();
      if (true || !this.documentData.pageCount || !this.documentData.pageThumbnails) {
        console.log('first Load, setting page count and generating thumbnails');
        this.documentData.pageCount = this.pageCount;
        await this.wvEditorService.getThumbnails(doc, this.pageCount, this.documentData);
        console.log('thumbnail success')
      }
      else {
        console.log('return visit');
      }
      //var annotations = annotManager.getAnnotationsList();
      //this.wvEditorService.buildAnnotationList(annotations, annotManager, this.documentMembers)
      //changeXfdf(this.documentData.annotations);
      console.log('after xfdf change');
      if (this.documentData.pageCount === this.documentData.pageThumbnails.length) {
        this.triggerFalseSelectAnnotation;
      }
    })
    const changeXfdf = async (xfdfString) => {
      this.wvEditorService.changeXfdfString(xfdfString);
    };
  }
  updateFieldData={}
  formUpdateField:any;
  
  updateStandardField(updateFieldData, currentFieldType){
    console.log('updateStandardField')
    console.log(updateFieldData);
    console.log(currentFieldType);
    console.log(this.wvInstance.annotManager.getSelectedAnnotations()[0]);
    var annotationToUpdate = this.wvInstance.annotManager.getSelectedAnnotations()[0];
    if(currentFieldType === 'number'){
      console.log(updateFieldData.number.toString());
      annotationToUpdate.setContents(updateFieldData.number.toString());
      //let number = parseInt('1234', 10);//string to int example
      this.wvInstance.annotManager.updateAnnotation(annotationToUpdate);
      this.wvInstance.annotManager.redrawAnnotation(annotationToUpdate);
      this.wvInstance.annotManager.trigger('annotationChanged', [[annotationToUpdate], 'modify', {}]);
      this.wvInstance.annotManager.deselectAnnotation(annotationToUpdate)
      this.wvInstance.annotManager.selectAnnotation(annotationToUpdate)
      //this.formUpdateField.reset()
    }
    else{
      annotationToUpdate.setContents(updateFieldData.text)
      this.wvInstance.annotManager.updateAnnotation(annotationToUpdate);
      this.wvInstance.annotManager.redrawAnnotation(annotationToUpdate);
      this.wvInstance.annotManager.trigger('annotationChanged', [[annotationToUpdate], 'modify', {}]);
      this.wvInstance.annotManager.deselectAnnotation(annotationToUpdate)
      this.wvInstance.annotManager.selectAnnotation(annotationToUpdate)
      //this.formUpdateField.reset()
    }
    
  }

  changeAnnotationBackground(annotation, action) {
    const { Annotations } = this.wvInstance
    console.log(annotation);
    console.log('changeAnnotationsBackground');

    if (action === 'modify' && (annotation[0].CustomData.type === 'text-field' || annotation[0].CustomData.type === 'number-field')) {
      console.log('change the background');
      if (annotation[0].getContents() === '' || !(annotation[0].getContents()) ) {
        annotation[0].FillColor = new Annotations.Color(255, 251, 204);
        annotation[0].StrokeThickness = 2;
        //this.wvSignerService.annotManager.trigger('annotationChanged', [[annotation[0]], 'modifyStyle', {}]);
        //this.wvSignerService.annotManager.updateAnnotation(annotation[0])
      }
      else {
        annotation[0].FillColor = new Annotations.Color(255, 255, 255);
        annotation[0].StrokeThickness = 0;
        //this.wvSignerService.annotManager.trigger('annotationChanged', [[annotation[0]], 'modifyStyle', {}]);
        //this.wvSignerService.annotManager.updateAnnotation(annotation[0])
      }
    }
  }
  //Left Sidebar Functions
  changeCurrentPage(pageNumber) {
    this.wvInstance.docViewer.displayPageLocation(pageNumber, 0, 0, false)
  }
  addSavedSignatureToField(savedSignature, currentSignatureEvent) {
    console.log('addSavedSignatureToField');
    this.clikedAddSavedAnnotation = true;
    this.wvNewMouseLeftUp.call(this.mouseLeftUpThis, currentSignatureEvent);
    this.triggerFalseClick();
  }
  deleteMySavedSignature(lastSignatureClicked) {
    this.wvEditorService.deleteSavedSignature(lastSignatureClicked, this.myContactInfo);
    this.triggerFalseClick();
    this.wvNewMouseLeftUp.call(this.mouseLeftUpThis, this.eValue)

  }
  changeDisplayName(name) {
    var documentData = this.documentData;
    documentData.displayName = name;
    this.wvEditorService.putAnnotations(documentData.id, documentData, this.xfdfString);
    this.wvEditorService.changeDocumentData(documentData);
  }
}
