import { Component } from '@angular/core';

@Component({
	selector: 'ngx-footer',
	styleUrls: ['./footer.component.scss'],
  template: `
  <div class="container">
    <p class="copyright-text text-right">Formrly LLC &copy; 2019</p>
    </div>
  `,
})
export class FooterComponent {
}
