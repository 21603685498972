import { Component, OnInit, AfterViewInit, Input, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { Documents } from '../../services/http/interfaces/documents';

//import { NbDialogService } from '@nebular/theme';

declare const WebViewer: any;
@Component({
  selector: 'ngx-webviewer-sign-test',
  templateUrl: './webviewer-sign-test.component.html',
  styleUrls: ['./webviewer-sign-test.component.scss']
})
export class WebviewerSignTestComponent implements OnInit, AfterViewInit {
  @Input('parentDataDocument') public documentData: Documents;

  memberData = {
    savedSignatures: {
      initials: '',
      fullSignature: ''
    }
  }
  drop2(event){}
drop(event){}
allowDrop(event){}
   @ViewChild('savedSignature') public savedSignatureCanvas: ElementRef;
   @ViewChild('viewer') viewer: ElementRef;

  licenseKey = 'Formrly LLC(formrly.co):OEM:Formrly::B+:AMS(20200529):12A5746204B7C80A3360B13AC9A2737860612FE3A926CDEAC56C051BD7B500CE64AAB6F5C7';
  wvInstance: any;
  wvTools: any;
  public pdfNetInitialize: any;
  public xfdfStringLoader;
  pageCount;

  wvOriginalMouseLeftUp
  mouseLeftUpThis
  wvNewMouseLeftUp
  clikedAddSavedAnnotation
  savedSignaturePublic
  currentSignatureEvent
  savedSignature
  savedSignaturePath

  constructor(
  ) { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    const location= 'https://formrly-public.s3.us-east-2.amazonaws.com/3-new%20revised%201-29-03.pdf';
    this.xfdfStringLoader = this.documentData.annotations
    WebViewer({
      path: '../../../../lib',
      initialDoc: `https://cors-anywhere.herokuapp.com/${location}`,
      //initialDoc: `https://cors-anywhere.herokuapp.com/${this.documentData.providerResponse.location}`,
      fullAPI: true,
      licenseKey: this.licenseKey,
    },
      this.viewer.nativeElement).then(instance => {
        const { PDFNet, docViewer } = instance;

        this.wvInstance = instance;
        const { Annotations } = this.wvInstance;
        this.wvTools = instance.Tools;
        const tools = this.wvTools;
        const annotManager = instance.annotManager;
        const { Tools } = instance;
        annotManager.setCurrentUser('johny');

        instance.docViewer.on('documentLoaded', async (f) => {
          console.log('documentLoaded');
          this.pdfNetInitialize = await PDFNet.initialize();
          const doc = docViewer.getDocument();
          const pdfDoc = await doc.getPDFDoc();

          
          //this.xfdfStringLoader = this.documentData.annotations

          console.log('Import Annotations');
          console.log(this.xfdfStringLoader);
          await annotManager.importAnnotations(this.xfdfStringLoader);

          //get page count, and Add thumbnails if first load
          console.log('Get Page Count' + docViewer.getPageCount());
          this.pageCount = docViewer.getPageCount();

          // Ensure that we have our first page.
          await pdfDoc.requirePage(1);

          // Run our main function using 'runWithCleanup'
          await PDFNet.runWithCleanup(async () => await main(pdfDoc));

          // Refresh the cache with the newly updated document
          docViewer.refreshAll();
          // Update viewer with new document
          docViewer.updateView();
          // you can access docViewer object for low-level APIs
          docViewer.getDocument().refreshTextData();

          console.log('export annotations right after');
          console.log(annotManager.getAnnotationsList());
          var annotationTest = annotManager.getAnnotationsList();
        });
        async function main(pdfDoc) {
          //alert("Hello WebViewer!");
        }

        //??? How to disable the Toolbar signature modal
        instance.disableElement(['header']);

        //Signature Customization
        let lastSignatureClicked;

        const createSignHereElement = Annotations.SignatureWidgetAnnotation.prototype.createSignHereElement;

        Annotations.SignatureWidgetAnnotation.prototype.createSignHereElement = function () {
          const signatureWidget = this;

          // signHereElement is the default one with dark blue background
          const signHereElement = createSignHereElement.apply(this, arguments);

          signHereElement.addEventListener('click', () => {
            console.log('click sign element');
            lastSignatureClicked = signatureWidget.fieldName;
            
          });

          return signHereElement;
        }

        const originalMouseLeftUp = Tools.SignatureCreateTool.prototype.mouseLeftUp;
        this.wvOriginalMouseLeftUp = Tools.SignatureCreateTool.prototype.mouseLeftUp;

        //console.log(originalMouseLeftUp);
        Tools.SignatureCreateTool.prototype.mouseLeftUp = function (e) {
          console.log('mouse left up');
          saveMouseLeftUpThis(this);
          const sigs = this.getSavedSignatures();
          const sigsOld = this.getSavedSignatures();
          console.log(sigsOld);

          const lastSignatureNameSplit = lastSignatureClicked.split('-');
          console.log(lastSignatureNameSplit);
          
          const savedSignature = getMemberSavedSignature('initial');
          //const savedSignature = getMemberSavedSignature(lastSignatureNameSplit[0]);
          console.log(savedSignature);


          //checking if user clicked button in custom signature modal to add saved signature to widget
          if (addSavedIsClicked() === true) {
            console.log('click trigger');
            const sigss = this.getSavedSignatures();
            console.log(sigss);

            this.deleteSavedSignature(0);
            this.deleteSavedSignature(0);
            this.importSignatures(savedSignature);


            const sigs = this.getSavedSignatures();
            console.log(sigs[0]);
            originalMouseLeftUp.call(this, e);

            
            //Exported and Imported Saved Signature Value.
            this.setSignature(sigs[0]);

            //System Saved Signature value before exporting then importing
            //this.setSignature(sigsOld[0]);
            this.addSignature();
            return;
          }
          else if (savedSignature) {
            console.log('saved then don\'t show modal', savedSignature);
            console.log(sigs);
            return openSavedSignatureCustomModal.call(this, savedSignature, e, docViewer);
          }
          else {
            console.log('no saved then show modal');
            return originalMouseLeftUp.call(this, e);
          }
        };

        //For Usage outside WebViewer
        this.wvNewMouseLeftUp = Tools.SignatureCreateTool.prototype.mouseLeftUp;


        const addSavedIsClicked = () => {
          if (this.clikedAddSavedAnnotation === true) {
            this.clikedAddSavedAnnotation = false;
            return true;
          }
          else { return false };
        }
        const saveMouseLeftUpThis = (data) => {
          console.log('saveMouseLeftUpThis');
          //console.log(data); 
          this.mouseLeftUpThis = data;
        };

        const openSavedSignatureCustomModal = (sigs, e, docViewer) => {
          console.log('openSavedSignatureCustomModal');
          console.log(sigs);
          console.log(e);

          //Save e Value for usage outside webviewer
          this.currentSignatureEvent = e;

          
          const canvasEl: HTMLCanvasElement = this.savedSignatureCanvas.nativeElement;
          var cx = canvasEl.getContext('2d');
          cx.beginPath;

          //Draw Signature With offset For Custom Signature Modal
          var savedSignaturePaths = sigs;

          console.log(savedSignaturePaths);

          function getYs() {
            return savedSignaturePaths[0].map(d => d.y);
          }
          function getMinY() {
            return Math.min(...getYs());
          }
          function getXs() {
            return savedSignaturePaths[0].map(d => d.x);
          }
          function getMinX() {
            return Math.min(...getXs());
          }
          function getMaxY() {
            return Math.max(...getYs());
          }

          console.log(savedSignaturePaths[0]);
          var count = savedSignaturePaths[0].length;
          
          for (let i = 0; i < count; i++) {
            if (i === 0) {
              cx.moveTo(savedSignaturePaths[0][i].x - getMinX(), savedSignaturePaths[0][i].y - getMinY());
            }
            else {
              cx.lineTo(savedSignaturePaths[0][i].x - getMinX(), savedSignaturePaths[0][i].y - getMinY());
            }
          }
          cx.stroke();
        }

        //Event on Signature Saved
        docViewer.getTool('AnnotationCreateSignature').on('signatureSaved', function (annotation) {
          console.log('savedSignature')
          console.log(annotation);
          const savedSignatureJson = this.exportSignatures();
          console.log(savedSignatureJson);//event coordinates with offset
          saveUserSignature(savedSignatureJson, lastSignatureClicked);
        });

        docViewer.getTool('AnnotationCreateSignature').on('annotationAdded', function (annotation) {
          console.log(annotation);

          const signatureWidgetAnnots = annotManager.getAnnotationsList().filter(
            annot => annot instanceof Annotations.SignatureWidgetAnnotation
          );

          console.log(signatureWidgetAnnots);
          signatureWidgetAnnots.forEach(annotSign => {
            console.log(annotSign);
            if (annotSign.annot) {

              console.log('isSigned')
            } else {
              console.log('notSigned')
            }

          })

        });

        const getMemberSavedSignature = (signatureType) => {
          console.log('getMemberSavedSignature');
          if (!this.memberData.savedSignatures) {
            return false;
          }
          if (signatureType === 'fullSignature' && this.memberData.savedSignatures.fullSignature) {
            return this.memberData.savedSignatures.fullSignature
          }
          else if (signatureType === 'initial' && this.memberData.savedSignatures.initials) {
            return this.memberData.savedSignatures.initials
          }
          else {
            console.log('error no recognized signature type or no signature data');
            return false;
          }
        }


        const saveUserSignature = (annotation, lastSignatureClicked) => {

          console.log('saveUserSignature');
          const lastSignatureNameSplit = lastSignatureClicked.split('-');
          var signatureType
          console.log(lastSignatureNameSplit);
          if (lastSignatureNameSplit[0] === 'initial') {
            signatureType = 'initial';

          }
          else if (lastSignatureNameSplit[0] === 'fullSignature') {
            signatureType = 'fullSignature';
          }

          this.savedSignaturePath = annotation;

          this.memberData.savedSignatures.initials = annotation[0];
          //??? To update for this test
          //this.wvSignService.putSavedSignature(annotation[0], 'initial', this.memberData);
          //this.wvSignService.putSavedSignature(annotation[0].getPaths(), signatureType, this.memberData);
        };
      });
  }
  addSavedSignatureToField(savedSignature, currentSignatureEvent) {
    console.log('addSavedSignatureToField');
    this.clikedAddSavedAnnotation = true;
    this.wvNewMouseLeftUp.call(this.mouseLeftUpThis, currentSignatureEvent);
  
  }
}
