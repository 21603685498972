import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserData } from '../../data/users';
import { concatMap } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { DocumentService } from '../../services/http/document.service';
import { FormrlyUsersService } from '../../services/http/formrly-users.service';
import { stat } from 'fs';


@Injectable({
  providedIn: 'root'
})
export class DocumentContextService {

  constructor(
    private router: Router,
    private userSvc: UserData,
    private documentService: DocumentService, 
    private userService: FormrlyUsersService
  ) { }

  goToView(fileMeta) {
    if (fileMeta.fileType) {
      this.router.navigate(['/my-documents/', fileMeta.id]);
    }
    else {
      this.router.navigate(['/documents/view/', fileMeta.id]);
    }
  }
  goToMyDocumentsView(folderId) {
    if (!folderId) {
      folderId = 0
    }
    this.router.navigate(['/my-documents/folders/', folderId]);
  }
  goToWebEditor(pdfLocation, fileMetaId) {
    this.router.navigate(['/editor/', fileMetaId]);
  }
  goToWebSigner(fileMetaId) {
    this.router.navigate(['/signer/', fileMetaId]);
  }
  goToWebViewer(pdfLocation, fileMetaId) {
    this.router.navigate(['/view', { location: pdfLocation, id: fileMetaId }]);
  }
  downloadFile(document, downloader) {
    this.userSvc.downloadFile(document.name).subscribe(
      data => {
        const URL = window.URL.createObjectURL(data);
        const link = downloader.nativeElement;
        link.href = URL;
        link.download = document.name;
        link.click();
        window.URL.revokeObjectURL(URL);
      },
      err => console.error(err)
    );
  }

  deleteFile(fileName, fileId) {
    this.userSvc.deleteFile(fileName)
      .pipe(
        concatMap(response => {
          let result$;
          switch (response.status) {
            case 204:
              // bug?: are 204s being converted to 200 by angular http client?
              result$ = this.userSvc.deleteFileMeta(fileId);
              break;
            case 200:
              // bug: loopback logs errors and still returns 200 status code?
              result$ = this.userSvc.deleteFileMeta(fileId);
              break;
            default:
              result$ = observableOf(Error('unhandled response code'));
          }
          return result$;
        })
      ).subscribe(
        data => {
          // TODO: remove single item from UI instead of refreshing
          //this.getFileMetas();
          console.log(data);
          console.log('file deleted');
        },
        err => console.error(err)
      );
  }

  addToMyDocuments(document, redirect = false) {
    if (document.fileType) {
      this.goToMyDocumentsView(document.folderId)
    }
    else {
      console.log('addToMyDocuments')
      document.fileType = 'myDocument';
      this.documentService.UpdateDocumentDetails(document.id, document).subscribe(
        fileMeta => {
          console.log('Update Success');
          if(redirect){
            this.goToMyDocumentsView(0);
          }
        },
        err => console.log(err)
      )
    }

  }
  async voidDocument(document) {
    console.log('voidDocument')
    if (document.status === 'completed') { 
      alert('You cannot void a completed document.')
      return false;
    }
    else {
      if (confirm('Are you sure you want to void the document: ' + document.displayName + '?')) {
        document.status = 'void';
        this.documentService.UpdateDocumentDetails(document.id, document).subscribe(
          fileMeta => {
            console.log('Void Success');
            //console.log(fileMeta);
            return true;
            //alert('Success: Your document has been voided.');
          },
          err => console.log(err)
        )
      }
      else{
        return false;
      }
    }
  }

  /**
   * Send a reminder to the next member who has not signed a document.
   * @param document The document.
   * @param document The sequence of members.
   */
  sendReminder(document, membersSequence){
    for(let i = 0; i < membersSequence.length; i++) {
      if(membersSequence[i].signatureDate === 0){
        let data = {
          userEmail:  membersSequence[i].email, //'raj@rkanswers.com', // Tip: Use an internal email address during testing
          userFirstName: membersSequence[i].name.first,
          senderFirstName: document.email.senderFirstName,
          senderLastName: document.email.senderLastName,
          documentName: document.displayName,
          signDocumentUrl: window.location.origin + '/signer/' + document.id + '/' + membersSequence[i].id + '/'
        };
        this.userService.sendReminder(data).subscribe(
          success => {
            console.log('Send Reminder Email result:');
            console.log(success);
            if(success){
              alert('Your message has been sent successfully.');
            }
          },
          err => console.log(err)
        );
        return; // Just the first coincidence is processed
      }
    }
  }

  /**
   * Sends a download link to a member of a document.
   * @param document The document.
   * @param member The member. 
   */
  sendDownloadLinkToMember(document, member){
    let data = {
      userEmail:  member.email, //'raj@rkanswers.com',  // Tip: Use an internal email address during testing
      userFirstName: member.name.first, 
      senderFirstName: document.email.senderFirstName,
      senderLastName: document.email.senderLastName,
      documentName: document.displayName,
      downloadDocumentUrl: window.location.origin + '/signer/download/' + document.id + '/' + member.id + '/'
    };

    this.userService.sendDownloadLink(data).subscribe(
      success => {
        console.log('Send Download Link email result:');
        console.log(success);
        if(success){
          alert('The download link has been sent successfully.');
        }
      },
      err => console.log(err)
    );
  }

  /**
   * Sends a download link to all members of a document.
   * @param document The document.
   * @param showAlert Indicates if an alert should be shown when the operation completes.
   */
  sendDownloadLinkToAllMembers(document, showAlert = true){

    this.documentService.getDocumentMembers(document.id).subscribe(
      (m) => {
        let members:any = m; 
        let emailsSent:number = 0;
        members.forEach(
          (currentMember) => {
            let data = {
              userEmail: currentMember.email, //'raj@rkanswers.com', // Tip: Use an internal email address during testing
              userFirstName: currentMember.name.first, 
              senderFirstName: document.email.senderFirstName,
              senderLastName: document.email.senderLastName,
              documentName: document.displayName,
              downloadDocumentUrl: window.location.origin + '/signer/download/' + document.id + '/' + currentMember.id + '/'
            };
            this.userService.sendDownloadLink(data).subscribe(
              success => {
                console.log('Send Download Link email result:');
                console.log(success);
                if(success){
                  emailsSent++; 
                }
              },
              err => console.log(err)
            );
          }
        );
        /** TODO: Display this alert only when 'emailsSent' is equal to members.length.
         * This code is run async so a direct comparison will not work. A different architecture may be needed. */ 
        if(showAlert){
          alert('The download link has been sent successfully to all members.');
        }
      }
    );
  }

  /**
   * Sends a message indicating that a share link to view a document has been enabled.
   * @param document The document object. 
   * @param contactId The contact who will receive the share link.
   * @param showAlert Indicates if an alert should be shown when the operation completes.
   */
  sendShareLinkEnabled(document, contactId, showAlert = true){

    let shareLinksBaseUrl = window.location.origin + '/signer/view-share/';

    this.userService.getUserContactDetails(contactId).subscribe(
      contactDetails => {
        let data = {
          userEmail: contactDetails.email, // 'raj@rkanswers.com', // // Tip: Use an internal email address during testing
          userFirstName: contactDetails.name.first,
          senderFirstName: document.email.senderFirstName,
          senderLastName: document.email.senderLastName,
          documentName: document.displayName,
          shareLinkUrl: shareLinksBaseUrl + document.id + '/' + contactId
        };

        this.userService.sendShareLinkEnabled(data).subscribe(
          success => {
            console.log('Send Share Link Enabled email result:');
            console.log(success);
            if(showAlert){
              alert('The share link has been sent successfully.');
            }
          }, 
          sendError => console.log(sendError)
        );
      },
      getDetailsError => console.log(getDetailsError)
    );
  }

  /**
   * Sends a message indicating that access to a document has been disabled.
   * @param document The document object. 
   * @param contactId The contact who will receive the message.
   * @param showAlert Indicates if an alert should be shown when the operation completes.
   */
  sendShareLinkDisabled(document, contactId, showAlert = true){

    this.userService.getUserContactDetails(contactId).subscribe(
      contactDetails => {
        let data = {
          userEmail: contactDetails.email, // 'raj@rkanswers.com', // // Tip: Use an internal email address during testing
          userFirstName: contactDetails.name.first,
          senderFirstName: document.email.senderFirstName,
          senderLastName: document.email.senderLastName,
          documentName: document.displayName
        };

        this.userService.sendShareLinkDisabled(data).subscribe(
          success => {
            console.log('Send Share Link Disabled email result:');
            console.log(success);
            if(showAlert){
              alert('Access to the share link has been restricted successfully.');
            }
          }, 
          sendError => console.log(sendError)
        );
      },
      getDetailsError => console.log(getDetailsError)
    );
  }
}
