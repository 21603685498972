import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { WebviewerSignerService } from '../webviewer-signer/webviewer-signer.service';
import { NbDialogService } from '@nebular/theme';
import { DocumentService } from '../../../services/http/document.service';
import { MembersVisitService } from '../../../services/members/members-visit.service';
import { concatMap } from 'rxjs/operators';
import { FormrlyUsersService } from '../../../services/http/formrly-users.service';
import { WvMemberSignatureService } from '../wv-signature/wv-member-signature.service';
import { DocumentContextService } from '../../document/document-context.service';


@Component({
  selector: 'ngx-wv-sign-guide',
  templateUrl: './wv-sign-guide.component.html',
  styleUrls: ['./wv-sign-guide.component.scss']
})
export class WvSignGuideComponent implements OnInit {
  @ViewChild('popupClose') popupClose: ElementRef<HTMLElement>;
  @ViewChild('clickButton') clickButton: ElementRef<HTMLElement>;
  
  myAnnotationList
  selectedAnnotationNumber = 1;
  isComplete
  xfdfString
  documentData
  myContactInfo
  constructor(
    private wvSignerService: WebviewerSignerService,
    private signatureService: WvMemberSignatureService,
    private dialogService: NbDialogService,
    private documentService: DocumentService,
    private membersService: MembersVisitService,
    private formrlyUsersService: FormrlyUsersService,
    private router: Router,
    private docContextService: DocumentContextService
  ) { }
  selectedAnnotation
  isCompleteCounter = 0;
  ngOnInit() {
    this.wvSignerService.currentMyAnnotationList.subscribe(message => this.myAnnotationList = message)
    this.wvSignerService.currentIsComplete.subscribe(message => {
      this.isComplete = message
      this.isCompleteCounter++;
      this.triggerClickButton();
    })
    this.wvSignerService.currentXfdfString.subscribe(message => this.xfdfString = message)
    this.wvSignerService.currentDocumentData.subscribe(message => this.documentData = message)
    this.signatureService.currentMyContactInfo.subscribe(message => this.myContactInfo = message)
    this.wvSignerService.currentSelectedAnnotation.subscribe(message => {
      let isOwnAnnotation = this.myAnnotationList.findIndex((item)=>{ return item.id === message.ou; }) >= 0;
      if(isOwnAnnotation){
        this.selectedAnnotation = message
        this.updateStepperPosition();
      }
    });
    

  }
firstLoad= false;
  updateStepperPosition(){
    if (this.firstLoad === false){
      this.firstLoad = true;

    }
    else{
    var index = this.myAnnotationList.findIndex( (item)=>{ return item.id === this.selectedAnnotation.ou})
    //var index = 0;
    this.selectedAnnotationNumber = index + 1;
    this.triggerClickButton()
  }
  }
  ngOnChanges(){

  }

  /**
   * Handles 'KeyUp' events.
   * @param event Data about the event.
   */
  onKeyUp(event:KeyboardEvent){
    let moveBack = (this.selectedAnnotationNumber > 1) && ((event.key === 'ArrowLeft') || (event.key === 'ArrowDown'));
    let moveNext = (this.selectedAnnotationNumber < this.myAnnotationList.length) && ((event.key === 'ArrowRight') || (event.key === 'ArrowUp'));

    if(moveBack){
      this.selectPreviousAnnotation();
    }

    if(moveNext){
      this.selectNextAnnotation();
    }
  }

  selectPreviousAnnotation() {
    if (this.selectedAnnotationNumber === 1) {
      // this.selectedAnnotationNumber = this.myAnnotationList.length;
      alert('You have reached your first field in the document')
    }
    else {
      this.selectedAnnotationNumber = this.selectedAnnotationNumber - 1
    }
    this.changeSelectedAnnotation(this.selectedAnnotationNumber)

  }
  selectNextAnnotation() {
    if (this.selectedAnnotationNumber === this.myAnnotationList.length) {
      //this.selectedAnnotationNumber = this.myAnnotationList.length;
      alert('You have reached your last field in the document')
    }
    else {
      this.selectedAnnotationNumber = this.selectedAnnotationNumber + 1
      
    }
    this.changeSelectedAnnotation(this.selectedAnnotationNumber)

  }
  clickRange(event){
    event.preventDefault()
    console.log(event)

  }
  changeSelectedAnnotation(selectedAnnotationNumber) {
    console.log('changeSelectedAnnotation');
    var oldSelectedAnnotation = this.wvSignerService.annotManager.getSelectedAnnotations();
    if (oldSelectedAnnotation[0]) {
      this.wvSignerService.annotManager.deselectAnnotation(oldSelectedAnnotation[0]);
    }
    var selectedAnnotationFromList = this.myAnnotationList[selectedAnnotationNumber - 1]
    var selectedAnnotation = this.wvSignerService.annotManager.getAnnotationById(selectedAnnotationFromList.id)
    var pageNumber = selectedAnnotation.getPageNumber();
    var y = selectedAnnotation.getY();
    var yPosition
    if (y > 400) {
      yPosition = 300;
    }
    else {
      yPosition = 0;
    }
    this.wvSignerService.docViewer.displayPageLocation(pageNumber, 0, yPosition)
    this.wvSignerService.annotManager.selectAnnotation(selectedAnnotation)
    this.wvSignerService.changeSelectedAnnotationId(selectedAnnotation.ou);
    setTimeout(() => {
     // this.wvSignerService.annotManager.selectAnnotation(selectedAnnotation)
      this.wvSignerService.annotManager.deselectAnnotation(selectedAnnotation)
      this.wvSignerService.annotManager.selectAnnotation(selectedAnnotation)
    }, 10);
    //this.wvSignerService.annotManager.selectAnnotation(selectedAnnotation)

  }

  open(dialog: TemplateRef<any>) {
    //console.log(dialog);
    this.dialogService.open(dialog, { context: 'this is some additional data' })
    console.log('test')
  }
  triggerFalseClick() {
    let el: HTMLElement = this.popupClose.nativeElement;
    el.click();
  }
  triggerClickButton(){
    let el: HTMLElement = this.clickButton.nativeElement;
    el.click();
    
  }
  clickNothing(){
    console.log('test');
  }
  saveAnnotations(xfdfString, documentData) {
    //??? trigger permission changes to trigger a write of visits
    this.wvSignerService.annotManager.trigger('updateAnnotationPermission', this.selectedAnnotation);


    console.log('saveAnnotations');
    console.log(documentData)
    //console.log(xfdfString)
    var status
    var signerCount
    var fileMembersData = {
      annotationData: xfdfString,
      timeStamp: '2020-03-03T16:00:06.468Z',
      ipAddress: '123',
      formrlyUsersContactId: this.myContactInfo.id,
      filesMemberId:'5a4282f0-58d8-11ea-836e-45a4d5708ac3',
      fileMetaId:this.documentData.id
    };
    documentData.annotations = xfdfString;
    


    // this.myContactInfo.id ='5a4282f0-58d8-11ea-836e-45a4d5708ac3'

    this.membersService.getDocumentMembersAndDataCount(this.myContactInfo.id, documentData.id).pipe(
      concatMap(counts => {
        console.log('getCounts');
        console.log(counts);
        signerCount = counts[1].count + 1;
        if (counts[0].count === counts[1].count + 1) {
          status = 'completed';
        }
        else if (counts[0].count > counts[1].count + 1) {
          status = 'awaiting';
        }
        return this.membersService.getMyDocumentMembers(this.myContactInfo.id, documentData.id);
      })
    ).pipe(
      concatMap(documentMembers => {
        console.log('getMyDocumentMembers');
        console.log(documentMembers);
        console.log(documentMembers[0].id)
        console.log(fileMembersData.filesMemberId)
        fileMembersData.filesMemberId = documentMembers[0].id;
        console.log(fileMembersData);
        return this.membersService.createFileMembersData(this.myContactInfo.id, documentData.id, fileMembersData);
      })
    ).pipe(
      concatMap(fileMembersData => {
        console.log('Create fileMembersData Success')
        documentData.status = status;
        documentData.annotations = xfdfString;
        console.log(documentData)
        //return this.documentService.UpdateDocumentDetails(documentData.id, documentData);
        return this.membersService.updateDocumentDetails(this.myContactInfo.id, documentData.id, documentData);
      })
    ).pipe(
      concatMap(documentMeta => {
        console.log('Update FileMeta Success')
        console.log(signerCount)
        return this.membersService.getNextDocumentMember(this.myContactInfo.id, documentData.id, signerCount);
      })
    ).subscribe(nextDocumentMember => {
        console.log('getNextDocumentMember')
        console.log(nextDocumentMember);
        if( status != 'completed'){
          var nextMemberId = nextDocumentMember[0].FormrlyUsersContactId;
          this.getNextDocumentMember(documentData, nextMemberId)
        }
        else{
          //this.flattenPdf()
          //alert('You have finished signing and document is completed.  Awaiting Email Functionality.  Flattening and download about to start.');
          this.triggerFalseClick();
          this.docContextService.sendDownloadLinkToAllMembers(this.documentData, false);
          this.router.navigate(['/signer/download/'+ this.documentData.id+ '/'+ this.myContactInfo.id]);    
          
    
        }
      });
  }


  getNextDocumentMember(documentData, nextMemberId){
    var nextMemberContact;
    this.membersService.getNextDocumentMemberContact(this.myContactInfo.id, documentData.id, nextMemberId).subscribe(
      nextMemberContactMeta => {
        console.log('getNextMemberContact');
        nextMemberContact = nextMemberContactMeta;
        if(documentData.status === 'awaiting'){
          this.greet(nextMemberContact);
          this.triggerFalseClick()
          //alert('Document Has Been Signed.  I will Setup a redirect to confirmation page later.');
          this.triggerFalseClick()
          this.router.navigate(['/completed/']);        
          //???redirect
        }
        else{
          this.triggerFalseClick()
          alert('I am not sure what happened.');
        }
      },
      err => console.log(err)
    )
    //????signerCount

  }
  greet(nextMemberContact) {
    var documentUrl = 'http://formrly.adawebexperts.com/signer/' + this.documentData.id + '/' + nextMemberContact.id;
    //var documentUrl = 'http://localhost:4200/signer/' + this.documentData.id + '/' + nextMemberContact.id;
    var data =
    {
      userFirstName: nextMemberContact.name.first,
      userEmail: nextMemberContact.email,
      senderFirstName: this.documentData.email.senderFirstName,
      senderLastName: this.documentData.email.senderLastName,
      documentUrl: documentUrl,
      //emailBody: this.documentData.email.body,
      //emailSubject: this.documentData.email.subject
      emailBody: this.documentData.email.body,
      emailSubject: this.documentData.email.subject
    }
    //data.userEmail = 'raj.narayan.sitar@gmail.com,raj@rkanswers.com';
    this.formrlyUsersService.greet(data).subscribe(greet => {
      console.log('greet Success');
      console.log(greet);
    },
      err => console.log(err)
    )
  }

  async flattenPdf(){
    const instance = this.wvSignerService.instance
    const annotManager = this.wvSignerService.annotManager
    const docViewer = this.wvSignerService.docViewer

    const webDoc = docViewer.getDocument();
    const doc = await webDoc.getPDFDoc();
    doc.initSecurityHandler();
    doc.lock();
    await docViewer.getTool('AnnotationCreateFreeHand').complete();
    const xfdfString = await annotManager.exportAnnotations({ links: false, widgets: false });
    const fdfDoc = await PDFNet.FDFDoc.createFromXFDF(xfdfString);
    await doc.fdfMerge(fdfDoc);
    await doc.flattenAnnotations();

    const docbuf = await doc.saveMemoryBuffer(1);
    instance.loadDocument(new Blob([docbuf], {
      type: 'application/pdf'
    }), {
        filename: 'myfile_flattened.pdf'
      });
    const blob = new Blob([docbuf], {
      type: 'application/pdf'
    });
    console.log('flatten file');
    console.log(blob);
  }
  // sendDocument(documentSendMessage) {

  //   console.log(documentSendMessage)
  //   documentSendMessage.senderFirstName = this.documentMembers[0].name.first;
  //   documentSendMessage.senderLastName = this.documentMembers[0].name.last;
  //   documentSendMessage.documentUrl = this.documentData.providerResponse.location;
  //   console.log(documentSendMessage)
  
  //  else if (!this.annotationList[0]) {
  //     console.log('error Message: Unassigned Fields Exist');
  //     alert('Cannot send document. You must have at least one field for each member.')
  //   }
  //   else if (this.checkAllMembersHaveFields() === false) {
  //     console.log('error Message: Members without fields');
  //     alert('Cannot send document. You must assign at least one field to each member before sending.')
  //     console.log(this.annotationList);
  //   }
  //   else if (!this.documentSendMessage.emailSubject) {
  //     alert('Cannot send document. You must have an email subject.')
  //     this.documentSendMessage.emailSubject = 'Action Needed: ' + this.documentData.displayName
  //   }
  //   else {

  //     console.log('sendDocument Success No Unassigned Fields');
  //     console.log(this.annotationList);
  //     var documentData = this.documentData;
  //     documentData.email = {
  //       subject: documentSendMessage.emailSubject,
  //       body: documentSendMessage.emailBody
  //     };
  //     if (this.wvEditorService.IsMyAnnotationsComplete(this.annotationList)) {
  //       console.log('ready for Members');
  //       documentData.status = 'awaiting';
  //       this.wvEditorService.changeDocumentData(documentData);
  //       documentSendMessage.userFirstName = this.documentMembers[0].name.first;
  //       documentSendMessage.userEmail = this.documentMembers[0].email;

  //       //    this.triggerFalseClickDocumentName('send document');
  //       //    this.router.navigate(['/documents/', documentData.status ]);
  //     }
  //     else {
  //       console.log('ready for owner');
  //       documentData.status = 'ready';
  //       this.wvEditorService.changeDocumentData(documentData);
  //       documentSendMessage.userFirstName = this.documentMembers[1].name.first;
  //       documentSendMessage.userEmail = this.documentMembers[1].email;

  //     }
  //     console.log(documentSendMessage)
  //     ///!!!RK!!! Email Change
  //     documentSendMessage.userEmail = 'raj.narayan.sitar@gmail.com,rafael@rafaelcardero.com';
  //     this.wvEditorService.putAnnotations(this.documentData.id, this.documentData, this.xfdfString);
  //     this.triggerFalseClickDocumentName('send document');
  //     this.greet(documentSendMessage);
  //     this.router.navigate(['/documents/', documentData.status]);

  //     console.log('this.documentData before save')
  //     console.log(this.documentData);
  //   }
  // }
}
