import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DocumentService } from '../../../../services/http/document.service';
//import { EventEmitter } from 'events';
import { Documents } from '../../../../services/http/interfaces/documents';
@Component({
  selector: 'ngx-documents-status-get',
  templateUrl: './documents-status-get.component.html',
  //styleUrls: ['./document-status-get.component.scss']
})
export class DocumentsStatusGetComponent implements OnInit {
public documentsByStatus:Documents;
@Input('parentData') public status;
@Output() notify: EventEmitter<Documents> = new EventEmitter<Documents>();

/** Emits when a page of data is loaded */
@Output() pagedDataLoaded: EventEmitter<Documents> = new EventEmitter<Documents>();

/** Indicates if pagination is enabled by default */
@Input() pagingEnabled:boolean = false;

/** Indicates the page being retrieved */
@Input() currentPage:number = 1;

/** Indicates how many items to retrieve per page */
@Input() itemsPerPage:number = 10;

  constructor(
    private documentService: DocumentService
  ) { }

  ngOnInit() {
    if(this.pagingEnabled){
      this.loadDocumentsPaginatedByStatus(this.status, this.currentPage, this.itemsPerPage);
    }else{
      var filemeta = '{"where":{"status":"' + this.status + '"},"order":"id DESC"}';
      //var filemeta = '{"where":{"status":"' + this.status + '"}, "order": "DATE_FIELD DESC"}';
      console.log ('First Statement' + this.documentsByStatus);
      
      this.getDocumentsByStatus(filemeta);
      //this.fireEvent(this.documentsByStatus);
    // this.childEvent.emit('documentsByStatus');
      console.log('This is the status'+ this.status);
      console.log('This is the document'+ this.documentsByStatus);
    }
  }

  getDocumentsByStatus(status){
		this.documentService.getDocumentsByStatus(status).subscribe(
      fileMeta => {
        this.documentsByStatus = fileMeta;
        console.log('The file meta' + fileMeta);
        console.log (this.documentsByStatus.name);
        this.notify.emit(this.documentsByStatus);
        //console.log('The docstatus' + this.documentsByStatus);
      },
        err => console.error(err)
    );
  }

  /**
   * Retrieves documents paginated by status.
   * @param status The status of the documents to retrieve.
   * @param page The page to retrieve. First index is 1.
   * @param itemsPerPage The number of items to return per page.
   */
  loadDocumentsPaginatedByStatus(status:string, page:number, itemsPerPage:number){
    this.documentService.getDocumentsPaginatedByStatus(status, page, itemsPerPage).subscribe(
      documents => { this.pagedDataLoaded.emit(documents); },
      err => console.error(err)
    );
  }

  fireEvent(data:Documents){
    console.log('notify');
    this.notify.emit(data);
    
  }

}
