import { Component, OnInit, OnChanges, AfterViewInit, Input, Output, EventEmitter, ViewChild, TemplateRef, ElementRef } from '@angular/core';
//import { Documents } from '../../../services/http/interfaces/documents';
import { ParentFolderComponent } from '../../folder/crud/parent-folder/parent-folder.component';
import { Folders } from '../../../services/http/interfaces/folders';
import { Router } from '@angular/router';
import { FolderService } from '../../../services/http/folder.service';
import { DocumentService } from '../../../services/http/document.service';
import { concatMap } from 'rxjs/operators';
import { NbDialogService } from '@nebular/theme';
import { FormBuilder, FormGroup, Validator, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'ngx-sidebar-my-documents',
  templateUrl: './sidebar-my-documents.component.html',
  styleUrls: ['./sidebar-my-documents.component.scss']
})
export class SidebarMyDocumentsComponent implements OnInit {
  @Input('parentData') public currentFolderId;
  @Input('parentDataFolderAdd') public addFolderType;
  @Input('parentDataFolderCount') public parentDataFolderCount;
  @Input('parentHasDocuments') public hasDocuments;
  @Output() notifyFolderName: EventEmitter<string> = new EventEmitter<string>();
  @Output() notifyFolderChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() notifyHasParentFolder: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('popupRenameFolder') popupRenameFolder: TemplateRef<HTMLElement>;
  @ViewChild('popupDocumentNameClose') popupDocumentNameClose: ElementRef<HTMLElement>;

  latestCurrentFolderId;
  public parentFolders;
  public childFolders;
  public currentFolderName;
  public currentParentId
  public parentRefresh = 0;
  public childRefresh = 0;
  currentFolder;
  constructor(
    private router: Router,
    private folderService: FolderService,
    private documentService: DocumentService,
    private dialogService: NbDialogService,
    private fb: FormBuilder,
  ) { }
  open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, { context: 'this is some additional data' });
  }
  ngOnInit() { }
  ngOnChanges() {


    console.log('SidebarMy Documents' + this.currentFolderId);
    console.log(this.parentFolders);
    console.log(this.addFolderType);
    if (this.addFolderType === 'parent') {
      this.parentRefresh++;

    }
    else if (this.addFolderType === 'child') {
      console.log('ngChanges Else')
      this.childRefresh++;
    }

    else if (this.latestCurrentFolderId != this.currentFolderId) {
      this.latestCurrentFolderId = this.currentFolderId;
      //this.currentParentId = null;
      this.parentRefresh++;

    }
    
    //this.getCurrentFolderName;
  }
  getParentFolders(data: Folders) {
    console.log("This is data of parent folders");
    console.log(data);
    this.parentFolders = data;
    console.log(this.parentFolders[0]);
    console.log('getparent folders tirgger');
    console.log(this.currentFolderId);
    this.getCurrentFolderName(this.currentFolderId, this.parentFolders)
  }
  getChildFolders(data: Folders) {
    console.log("This is data of child folders");
    console.log(data);
    this.childFolders = data;
    //this.currentParentId = data.folderId;
    console.log('getdocuments tirgger');
  }

  getCurrentFolderName(currentFolderId, parentFolders) {
    console.log(currentFolderId);
    if (currentFolderId === 0 || currentFolderId === null || currentFolderId === '' || currentFolderId === '0') {
      this.currentParentId = null;
      this.notifyHasParentFolder.emit(false);
      this.notifyFolderName.emit('My Documents');
    }
    else {
      this.folderService.getCurrentFolder(currentFolderId).subscribe(
        folderMeta => {
          this.currentFolder = folderMeta;
          if (folderMeta.folderId) {
            this.currentParentId = folderMeta.folderId;
            this.notifyHasParentFolder.emit(true);
          }
          else {
            this.currentParentId = folderMeta.id;
            this.notifyHasParentFolder.emit(false);
          }
          console.log("This is the folder name");
          console.log(this.currentParentId);
          console.log(folderMeta);
          this.notifyFolderName.emit(folderMeta.folderName);
        },
        err => console.error(err)

      );
    }
  }
  deleteFolderCheck(folder) {

    if (!folder.folderId && this.childFolders[0]) {
      alert('You cannot delete a folder which contains Sub Folders.')
    }
    else if (this.hasDocuments === true) {
      alert('You cannot delete a folder which contains Documents.')
    }
    else {
      if (confirm('Are you sure you want to delete this folder?')) {
        this.documentService.getMyFolderChildDocuments(folder.id).subscribe(
          filesMeta => {
            if (!filesMeta[0]) {
              //console.log()
              this.deleteFolder(folder);
            }
            else {
              alert('You cannot delete a folder which contains documents.')
            }

          },
          err => console.log(err)
        );
      }
    }
  }
  deleteFolder(folder) {

    this.folderService.deleteFolder(folder.id).subscribe(
      data => {
        console.log(data);
        console.log('your file has been deleted');
        if (folder.folderId) {
          var spliceId = this.childFolders.findIndex(function (item) {
            return item.id === folder.id;
          })
          this.childFolders.splice(spliceId, 1);
        }
        else {
          var spliceId = this.parentFolders.findIndex(function (item) {
            return item.id === folder.id;
          })
          this.parentFolders.splice(spliceId, 1);
        }
        //this.currentFolder = 0;
        this.router.navigate(['/my-documents/folders/0']);
        // TODO: remove single item from UI instead of refreshing
        //		this.getFileMetas();
      },
      err => console.error(err)
    );

  }
  formFolderName: any;
  currentFolderForm = {
    folderName: ''
  };

  triggerRenameClose() {
    let el: HTMLElement = this.popupDocumentNameClose.nativeElement;
    el.click();
  }
  renameFolder(folder) {
    console.log(folder)
    this.currentFolder = folder;
    this.currentFolderForm.folderName = folder.folderName;
    console.log(this.currentFolderForm.folderName);
    //this.currentFolderForm.id = folder.id;
    //this.openRenameFolderPopup();
    this.open(this.popupRenameFolder);

  }

  renameCurrentFolder(folder) {
    console.log(folder)
    console.log(this.currentFolder)
    console.log(this.currentFolderForm)
    this.currentFolder.folderName = folder.folderName;
    this.folderService.updateFolder(this.currentFolder.id, this.currentFolder).subscribe(
      folderMeta => {
        console.log('rename Success');
        if (folder.folderId) {
          var spliceId = this.childFolders.findIndex(function (item) {
            return item.id === folder.id;
          })
          //this.childFolders[spliceId].folderName = folder.folderName
        }
        else {
          var spliceId = this.childFolders.findIndex(function (item) {
            return item.id === folder.id;
          })
          //this.parentFolders[spliceId].folderName = folder.folderName;
        }
        this.notifyFolderName.emit(folderMeta.folderName);
        this.triggerRenameClose();

      }
    );
    // this.
  }

  //   //??? Add Child Folders
  //   //console.log('function open');
  //   //console.log(parentFolders.length);
  //   var i=0;
  //   var keepGoing =true;
  //   for(i=0;i<parentFolders.length;i++){
  //     //console.log('function open for');
  //     if (keepGoing){
  //       // console.log('function open keep going');
  //       // console.log('c=' +currentFolderId)
  //       // console.log('p=' +parentFolders[i].id)
  //       if (currentFolderId===parentFolders[i].id){
  //         this.currentFolderName = parentFolders[i].folderName;
  //         if (parentFolders[i].folderId){
  //           this.currentParentId = parentFolders[i].folderId;
  //           console.log(' this.currentParentId ');
  //           console.log( this.currentParentId );
  //         }
  //         else{
  //           this.currentParentId = parentFolders[i].id;
  //         }
  //         this.notifyFolderName.emit(parentFolders[i].folderName);
  //         keepGoing=false;
  //         console.log('current folder name is:' + this.currentFolderName);
  //       }
  //     }
  //   }
  // }

  changeCurrentFolder(folderId, folderName) {
    console.log(folderId);
    if (this.currentParentId === folderId){

    }
    else{
      this.currentParentId = folderId;
      this.childFolders = null;
    }
    
    this.notifyFolderChange.emit(folderId);
    this.notifyFolderName.emit(folderName);
    this.notifyHasParentFolder.emit(false);
  }
  changeChildFolder(folderId, folderName, parentId) {
    console.log(folderId);
    this.currentParentId = parentId;
    this.notifyFolderChange.emit(folderId);
    this.notifyFolderName.emit(folderName);
    this.notifyHasParentFolder.emit(true);
  }

}
