import { Component, OnInit, Input } from '@angular/core';
import {VisitService} from '../../../../services/http/visit.service';
@Component({
  selector: 'ngx-document-visits-count',
  templateUrl: './document-visits-count.component.html',
  styleUrls: ['./document-visits-count.component.scss']
})
export class DocumentVisitsCountComponent implements OnInit {
  @Input('parentData') public documentId;
  documentsVisitCount:string;
//documentId:any;
  constructor(
    private visitService : VisitService
  ) { }

  ngOnInit() {
    console.log('Input data Document Visits Count Component:' + this.documentId);
    this.getDocumentVisitsCount(this.documentId);
  }
  getDocumentVisitsCount(documentId) {
		this.visitService.getDocumentsVisitsCount(documentId).subscribe(
			count => {
        console.log(count.count);
				this.documentsVisitCount = count.count;
			},
			err => console.error(err)
		);
	}
}
