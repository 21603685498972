import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { forkJoin } from 'rxjs';
import {Visits} from './interfaces/visits';
import {FormrlyUsersContact} from './interfaces/formrly-users-contact';

@Injectable({
  providedIn: 'root'
})
export class VisitService {
  private api: string = environment.rest;

  constructor(
    private http: HttpClient

  ) { }
  //Working
  getRecentVisitsbyOwner(paginationPage: any, totalDisplayedCount: any): Observable<any> {
    let documents = this.http.get(`${this.api}/FormrlyUsers/me/FileMeta/`);
    //let members = this.http.get<Visits>(`${this.api}/FormrlyUsers/me/FormrlyUserMyContactList/`);
    let members = this.http.get<FormrlyUsersContact>(`${this.api}/FormrlyUsersContacts/`);
    let visits = this.http.get<FormrlyUsersContact>(`${this.api}/FormrlyUsers/me/FilesMembersVisit?filter={"order":"timestamp DESC"}`);
    return forkJoin([visits,members,documents]);
  }

  //Working
  getDocumentMembersVisits(documentId: any): Observable<any> {
    
    //return this.http.get(`${this.api}/FormrlyUsers/me/FileMeta/${documentId}/FileMembersVisit/`);
    let visits = this.http.get(`${this.api}/FormrlyUsers/me/FileMeta/${documentId}/FilesMembersVisit/`);
    let members = this.http.get(`${this.api}/FormrlyUsers/me/FileMeta/${documentId}/FormrlyUsersContact/`);
     let fileMembers = this.http.get(`${this.api}/FormrlyUsers/me/FileMeta/${documentId}/FilesMember?filter={"where":{"or":[{"access":"member"},{"order":{ "neq":  null }}]}}`);
    let membersData = this.http.get(`${this.api}/FormrlyUsers/me/FileMeta/${documentId}/FilesMembersData/`);
   
    return forkJoin([visits,members,fileMembers, membersData]);
  }

  //Determine if there is a better way for this... This may be done externally using the above function.
  //Follow the cross reference method forkJoin or the likes
  // This method will not be used most likely, as we don't have the query.

//  getVisitsbyDocumentbyMember(documentId: any, memberId: any): Observable<any> {
//    return this.http.get(`${this.api}/FormrlyUsers/me/FileMeta/${documentId}/FileMembersVisit/`);
//  }

  //This one needs to be applied
  getVisitCountByOwnerByDateRange(startDate, endDate): Observable<any> {
        return this.http.get(`${this.api}/FormrlyUsers/me/FilesMembersVisit?filter={"where":{"and":[{"timestamp": {"gte":${startDate}}},{"timestamp": {"lte":${endDate}}}]}}`);
  }

  //applied
  getDocumentsVisitsCount(documentId: any): Observable<any> {
		return this.http.get(`${this.api}/FormrlyUsers/me/FileMeta/${documentId}/FilesMembersVisit/count`);
  }
  getVisitsByMember(memberId: any): Observable<any> {
    let visits = this.http.get<Visits>(`${this.api}/FormrlyUsers/me/FilesMembersVisit?filter={"where":{"formrlyUsersContactId":"${memberId}"}}`);
    let members = this.http.get(`${this.api}/FormrlyUsers/me/FileMeta/`);
    return forkJoin([visits,members]);
 
  }

  /**
   * Returns data about visits to a document by a contact. 
   * @param documentId The ID of the document. 
   * @param contactId The ID of the contact.
   */  
  getVisitsToDocumentbyContact(documentId, contactId):Observable<any>{
    let filter = `{"where":{"formrlyUsersContactId":"${contactId}"}}`;
    return this.http.get(`${this.api}/FormrlyUsers/me/FileMeta/${documentId}/FilesMembersVisit?filter=${filter}`);
  }
}
//ADD Visits is within the FormrlyUserContact.service