import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TemplateService } from '../../../../services/http/template.service';
import { Templates } from '../../../../services/http/interfaces/templates';

@Component({
  selector: 'ngx-my-templates-get',
  templateUrl: './my-templates-get.component.html',
  styleUrls: ['./my-templates-get.component.scss']
})
export class MyTemplatesGetComponent implements OnInit {
  public templates: Templates;
  @Output() notify: EventEmitter<Templates> = new EventEmitter<Templates>();
  @Input('parentDataStatus') public status;
  
  constructor(
    private templateService: TemplateService

  ) { }

  ngOnInit() {
    this.getMyTemplates(this.status);
  }
  getMyTemplates(status) {
    this.templateService.getMyTemplates(status).subscribe(
      templateMeta => {
        this.templates = templateMeta;
        console.log('getMyTemplates Success')
        //console.log(fileMeta);
        this.notify.emit(this.templates);
      },
      err => console.error(err)
    );
  }
}
