import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-formrly-user-contact-remove',
  templateUrl: './formrly-user-contact-remove.component.html',
  styleUrls: ['./formrly-user-contact-remove.component.scss']
})
export class FormrlyUserContactRemoveComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
