import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NbAuthModule,
					NbPasswordAuthStrategy,
					NbAuthSimpleToken,
					NbAuthSimpleInterceptor } from '@nebular/auth';
import { NbSecurityModule,
					NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import {
	AnalyticsService,
	StateService,
} from './utils';
import { UserData } from './data/users';
import { UserService } from './mock/users.service';
import { MockDataModule } from './mock/mock-data.module';
//import { DocumentStatusViewComponent } from './components/document/document-status-view/document-status-view.component';
import { DocumentsStatusGetComponent } from './components/document/crud/documents-status-get/documents-status-get.component';
import { VisitsViewComponent } from './components/visit/visits-view.component';
import { VisitsGetComponent } from './components/visit/crud/visits-get/visits-get.component';
import { DocumentVisitsCountComponent } from './components/visit/crud/document-visits-count/document-visits-count.component';
import { OwnerVisitCountDaterangeComponent } from './components/visit/crud/owner-visit-count-daterange/owner-visit-count-daterange.component';
import { ParentFolderComponent } from './components/folder/crud/parent-folder/parent-folder.component';
import { ChildFolderComponent } from './components/folder/crud/child-folder/child-folder.component';
import { FolderChildDocumentsComponent } from './components/folder/crud/folder-child-documents/folder-child-documents.component';
import { CreateFolderComponent } from './components/folder/crud/create-folder/create-folder.component';
import { UpdateFolderComponent } from './components/folder/crud/update-folder/update-folder.component';
import { SidebarDocumentsComponent } from './components/sidebar/sidebar-documents/sidebar-documents.component';
import { SidebarMyDocumentsComponent } from './components/sidebar/sidebar-my-documents/sidebar-my-documents.component';
import { DocumentsMembersViewComponent } from './components/document/documents-members-view/documents-members-view.component';
import { DocumentsMembersGetComponent } from './components/document/crud/documents-members-get/documents-members-get.component';
import { DocumentVisitsMembersComponent } from './components/visit/crud/document-visits-members/document-visits-members.component';
import { DocumentVisitsMembersViewComponent } from './components/visit/document-visits-members-view/document-visits-members-view.component';
import { TooltipDocumentComponent } from './components/chart/tooltip-document/tooltip-document.component';
import { ChartBarComponent } from './components/chart/chart-bar/chart-bar.component';
import { ChartLineComponent } from './components/chart/chart-line/chart-line.component';
import { MemberVisitsGetComponent } from './components/visit/crud/member-visits-get/member-visits-get.component';
import { MemberVisitsViewComponent } from './components/visit/member-visits-view/member-visits-view.component';
import {ThemeSettingsComponent } from '../@theme/components/theme-settings/theme-settings.component';

//import { MinuteSecondsPipe } from './pipes/minute-seconds.pipe';
// import { FormrlyUserContactsGetComponent } from './components/formrly-user-contact/crud/formrly-user-contacts-get/formrly-user-contacts-get.component';
// import { FormrlyUserContactsViewComponent } from './components/formrly-user-contact/formrly-user-contacts-view/formrly-user-contacts-view.component';
// import { FormrlyUserContactDetailComponent } from './components/formrly-user-contact/formrly-user-contact-detail/formrly-user-contact-detail.component';
// import { FormrlyUserContactAddComponent } from './components/formrly-user-contact/formrly-user-contact-add/formrly-user-contact-add.component';
// import { FormrlyUserContactEditComponent } from './components/formrly-user-contact/formrly-user-contact-edit/formrly-user-contact-edit.component';
// import { FormrlyUserContactUpdateComponent } from './components/formrly-user-contact/crud/formrly-user-contact-update/formrly-user-contact-update.component';
// import { FormrlyUserContactRemoveComponent } from './components/formrly-user-contact/crud/formrly-user-contact-remove/formrly-user-contact-remove.component';

// const socialLinks = [
//   {
//     url: 'https://github.com/akveo/nebular',
//     target: '_blank',
//     icon: 'socicon-github',
//   },
//   {
//     url: 'https://www.facebook.com/akveo/',
//     target: '_blank',
//     icon: 'socicon-facebook',
//   },
//   {
//     url: 'https://twitter.com/akveo_inc',
//     target: '_blank',
//     icon: 'socicon-twitter',
//   },
// ];

const DATA_SERVICES = [
	{ provide: UserData, useClass: UserService },
];

export class NbSimpleRoleProvider extends NbRoleProvider {
	getRole() {
		// here you could provide any role based on any auth flow
		return observableOf('user');
	}
}

export const NB_CORE_PROVIDERS = [
	...MockDataModule.forRoot().providers,
	...DATA_SERVICES,
	...NbAuthModule.forRoot({

		strategies: [
			NbPasswordAuthStrategy.setup({
				name: 'email',
				baseEndpoint: 'https://formrly-api.herokuapp.com',
				//baseEndpoint: 'https://rkformrly-api-sandbox.herokuapp.com',
				//baseEndpoint: 'http://localhost:3000',
				token: {
					class: NbAuthSimpleToken,
					key: 'id',
				},
					login: {
						endpoint: '/api/FormrlyUsers/login',
						method: 'post',
						redirect: {
							success: '/dashboard',
							failure: '/auth/register',
						},
					},
					register: {
						endpoint: '/api/FormrlyUsers',
						method: 'post',
					},
					logout: {
						endpoint: '/api/FormrlyUsers/logout',
						method: 'post',
						redirect: {
							success: '/auth/login',
							failure: '/auth/login',
						},
					},
					requestPass: {
						endpoint: '/api/FormrlyUsers/reset',
						method: 'post',
					},
					resetPass: {
						endpoint: '/api/FormrlyUsers/reset-password',
						method: 'post',
					},
			}),
		],
		forms: {
		},
	}).providers,

	NbSecurityModule.forRoot({
		accessControl: {
			guest: {
				view: '*',
			},
			user: {
				parent: 'guest',
				create: '*',
				edit: '*',
				remove: '*',
			},
		},
	}).providers,
	// {
	//   provide: NbRoleProvider,
	//   useValue: {
	//     getRole: () => {
	//       return observableOf('user');
	//     },
	//   },
	// },
	{
		provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
	},
	// {
	//   provide: AuthGuard, deps: [AuthGuard],
	// },
	AnalyticsService,
	StateService,
	{ provide: HTTP_INTERCEPTORS, useClass: NbAuthSimpleInterceptor, multi: true },
];

@NgModule({
	imports: [
		CommonModule,
	],
	exports: [
		NbAuthModule,
		ThemeSettingsComponent
	],
	declarations: [
		ThemeSettingsComponent,
		//WebviewerSignTestComponent,
		//MinuteSecondsPipe
	//	DocumentStatusViewComponent, DocumentStatusGetComponent
//	VisitsGetComponent,
//	VisitsViewComponent
//DocumentVisitsCountComponent,

//OwnerVisitCountDaterangeComponent,
//	ParentFolderComponent,
//	ChildFolderComponent,
//	FolderChildDocumentsComponent,
//	CreateFolderComponent,
//	UpdateFolderComponent,
//	SidebarDocumentsComponent,
//	SidebarMyDocumentsComponent
//DocumentsMembersViewComponent,
//	DocumentsMembersGetComponent
// DocumentVisitsMembersComponent,
// 	DocumentVisitsMembersViewComponent
//	TooltipDocumentComponent,
//	ChartBarComponent,
// //	ChartLineComponent
// FormrlyUserContactGetComponent,
// 	FormrlyUserContactsGetComponent,
// 	FormrlyUserContactsViewComponent,
// 	FormrlyUserContactDetailComponent,
// 	FormrlyUserContactAddComponent,
// 	FormrlyUserContactEditComponent,
// 	FormrlyUserContactUpdateComponent,
// 	FormrlyUserContactRemoveComponent






// MemberVisitsGetComponent,
// 	MemberVisitsViewComponent
],
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		throwIfAlreadyLoaded(parentModule, 'CoreModule');
	}

	static forRoot(): ModuleWithProviders {
		return <ModuleWithProviders>{
			ngModule: CoreModule,
			providers: [
				...NB_CORE_PROVIDERS,
			],
		};
	}
}
