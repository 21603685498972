import { Injectable } from '@angular/core';
import { concatMap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { WebviewerFinalService } from '../webviewer-final.service'
import { Documents } from '../../../services/http/interfaces/documents';
import { DocumentService } from '../../../services/http/document.service';
import { UserData } from '../../../data/users';
import { FormrlyUsersService } from '../../../services/http/formrly-users.service';

declare const WebViewer: any;

@Injectable({
  providedIn: 'root'
})
export class WebviewerEditorService {
  licenseKey = 'Formrly LLC(formrly.co):OEM:Formrly::B+:AMS(20200529):12A5746204B7C80A3360B13AC9A2737860612FE3A926CDEAC56C051BD7B500CE64AAB6F5C7';

  public mimeType
  public instance
  public annotManager
  public docViewer

  public annotationsList
  public dropPoint

  private click = new BehaviorSubject(0);
  currentClick = this.click.asObservable();

  private tab = new BehaviorSubject('fields');
  currentTab = this.tab.asObservable();

  private sidebarState = new BehaviorSubject(false);
  currentSidebarState = this.sidebarState.asObservable();

  private dragElementId = new BehaviorSubject('');
  currentDragElementId = this.dragElementId.asObservable();

  private mobileClickElementId = new BehaviorSubject('');
  currentMobileClickElementId = this.mobileClickElementId.asObservable();

  private selectedAnnotationId = new BehaviorSubject('');
  currentSelectedAnnotationId = this.selectedAnnotationId.asObservable();

  private xfdfString = new BehaviorSubject('');
  currentXfdfString = this.xfdfString.asObservable();

  private documentData = new BehaviorSubject<Documents[]>([]);
  currentDocumentData = this.documentData.asObservable();

  private selectedAnnotation = new BehaviorSubject<any[]>([]);
  currentSelectedAnnotation = this.selectedAnnotation.asObservable();

  private documentMembers = new BehaviorSubject<any[]>([]);
  currentDocumentMembers = this.documentMembers.asObservable();

  private fieldCountList = new BehaviorSubject<any[]>([]);
  currentFieldCountList = this.fieldCountList.asObservable();
  public fieldCounts = [{
    author: '',
    required: 0,
    optional: 0
  }];

  private annotationList = new BehaviorSubject<any[]>([]);
  currentAnnotationList = this.annotationList.asObservable();

  private myContactInfo = new BehaviorSubject<any[]>([]);
  currentMyContactInfo = this.myContactInfo.asObservable();

  changeClick(data) {
    this.click.next(data)
  }
  changeTab(data: string) {
    this.tab.next(data)
  }

  changeSidebarState(data: boolean) {
    this.sidebarState.next(data)
  }
  changeDragElementId(data: any) {
    this.dragElementId.next(data)
  }
  changeMobileClickElementId(data: any) {
    this.mobileClickElementId.next(data)
  }

  changeSelectedAnnotationId(data: string) {
    this.selectedAnnotationId.next(data)
  }
  changeSelectedAnnotation(data: any) {
    this.selectedAnnotation.next(data)
  }
  changeXfdfString(data: string) {
    this.xfdfString.next(data)
  }
  changeDocumentData(message: any) {
    this.documentData.next(message)
  }
  changeDocumentMembers(message: any) {
    this.documentMembers.next(message)
  }
  changeFieldCountList(message: any) {
    this.fieldCountList.next(message)
  }
  changeAnnotationList(message: any) {
    this.annotationList.next(message)
  }
  changeMyContactInfo(data: any) {
    this.myContactInfo.next(data)
  }
  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  constructor(
    private webViewerService: WebviewerFinalService,
    private documentService: DocumentService,
    private userSvc: UserData,
    private formrlyUserService: FormrlyUsersService
  ) { }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  async loadWebViewerEditor(viewerNativeElement, location) {
    //await this.webViewerService.loadInstance(viewerNativeElement, location);
    //this.instance = this.webViewerService.instance;

    await this.loadInstance(viewerNativeElement, location);
    
    this.annotManager = this.instance.annotManager;
    this.docViewer = this.instance.docViewer;
    // this.instance.hotkeys.off();
    //this.instance.hotkeys.off('f');
    //this.instance.hotkeys.off();

    this.annotManager.setIsAdminUser(true);
    console.log('isadminuser?')
    console.log(this.annotManager.getIsAdminUser());
    this.instance.disableElements(['contextMenuPopup', 'annotationCommentButton', 'annotationStyleEditButton']);
    this.instance.disableElements([
      'linkButton',
      'textHighlightToolButton',
      'textUnderlineToolButton',
      'textSquigglyToolButton',
      'textStrikeoutToolButton',
      'panToolButton',
      'stickyToolButton',
      'highlightToolButton',
      'freeHandToolButton',
      'freeTextToolButton',
      'zoomOutButton',
      'zoomInButton',
      'annotationUngroupButton',
      'freeHandToolGroupButton',
      'textToolGroupButton','shapeToolGroupButton','miscToolGroupButton',
      'annotationGroupButton',
    ]);

  }

  async loadInstance(viewerNativeElement, location) {
    await WebViewer({
      path: '../../../../lib',
      //initialDoc: `https://cors-anywhere.herokuapp.com/${location}`,
      initialDoc: `https://cors-anywhere.herokuapp.com/${location}`,
      //initialDoc: `${location}`,
      //initialDoc: `https://cors-anywhere.herokuapp.com/${this.documentData.providerResponse.location}`,
     // fullAPI: true,
     
      licenseKey: this.licenseKey,
      css: window.location.origin + '/assets/web-viewer/custom-styles.css?ver=2'
    },
      viewerNativeElement).then(instance => {
        //instance.disableElements(['header'])
        // instance.hotkeys.off('F');
        // instance.hotkeys.off('f');
        instance.hotkeys.off();
        this.instance = instance;
        console.log('load webviewer service')
        
        //return this.instance;
      });
      
      
  }

  //Initial Load Functions
  async getThumbnails(doc, pageCount, documentData) {
    console.log('get Thumbbnails function' + pageCount)
    var i;
    var thumbNail = []
    documentData.pageThumbnails = [{}];
    for (i = 0; i < pageCount; i++) {
      console.log('looop' + i);
      thumbNail[i] = i
      console.log('test await' + i)
      documentData.pageThumbnails[i] = {};
    }
    for (const item of thumbNail) {
      console.log('async loop test for')
      await doc.loadThumbnailAsync(item, async (result) => this.displayThumbnail(result, item, documentData));
      console.log('async loop test for end')
    }

  }

  async displayThumbnail(result, iCount, documentData) {
    await console.log('Async Display Thumbnail' + iCount);
    console.log(result);
    var test2 = result.toDataURL();
    console.log('end test 2');
    result.toBlob(data => this.blober(data, iCount, documentData));
  }

  blober(data, iCount, documentData) {
    console.log('blobber');
    var fileName;
    fileName = documentData.id + '-TH-' + iCount + '.png';
    this.uploadThumbnail(data, fileName, iCount, documentData);
  }
  //S3 Conector function
  uploadThumbnail(file, fileName, iCount, documentData) {
    console.log('upload Avatar' + iCount);
    this.userSvc.uploadThumbnail(file, fileName)
      .pipe(
        concatMap(fileMeta => {
          console.log(iCount + 'concatmap');
          documentData.pageThumbnails[iCount] = fileMeta.result.files.file[0];
          return this.documentService.UpdateDocumentDetails(documentData.id, documentData);
        })
      )
      .subscribe(
        fileMeta => {
          console.log('Upload thumbnail ' + iCount + ' success');
          // this.changeDocumentData(fileMeta)
          if (fileMeta.pageThumbnails.length === fileMeta.pageCount) {
            this.changeClick(1);
          }
        },
        err => console.error(err)
      );
  }

  dropFreeTextAnnotation(point, dragElementId) {
    var options = {
      width: 0,
      height: 0,
      lockedContents: true
    };
    switch (dragElementId) {
      case 'text-field':
        console.log('text field case');
        options.width = 150;
        options.height = 25;
        break;

      case 'number-field':
        console.log('number field case');
        options.width = 150;
        options.height = 25;
        break;

      case 'full-signature':
        console.log('fullSignature case');
        options.width = 150;
        options.height = 50;
        break;
      case 'initial-signature':
        console.log('initials case');
        options.width = 100;
        options.height = 75;
        break;
      case 'date-field':
        console.log('date case');
        options.width = 150;
        options.height = 25;
        break;
      default:
        console.log('date case');
        options.width = 150;
        options.height = 25;
        //  options.lockedContents = true;
        break;
    }
    ///???Ask Rafael how to fix
    
    if( this.mimeType.slice(0,5) === 'aimage'){
    }
    else{
      console.log(this.docViewer.getCurrentPage());
      var currentPageWidth = this.docViewer.getPageWidth(this.docViewer.getCurrentPage() - 1);
      var currentPageHeight = this.docViewer.getPageHeight(this.docViewer.getCurrentPage() - 1);
      if(options.width + point.xAdjusted > currentPageWidth){
        point.xAdjusted = currentPageWidth - options.width;
      }

      // This should be next page 2020-10-08
      if(!point.xAdjusted && options.height + point.y > currentPageHeight){
        point.y = currentPageHeight - options.height;
      }
    }
    this.drawFormrlyAnnotation(point, dragElementId, options);
  }

  /**
   * Maps the coordinates of a point to a zero-based page index.
   * @param pagePoint 
   */
  private getPageIndexFromPoint(pagePoint):number{
    const scrollElement = this.docViewer.getScrollViewElement();
    const scrollLeft = scrollElement.scrollLeft || 0;
    const scrollTop = scrollElement.scrollTop || 0;
    //const p = {x: pagePoint.x + scrollLeft, y: pagePoint.y + scrollTop};

    const displayMode = this.docViewer.getDisplayModeManager().getDisplayMode();
    const range = displayMode.getSelectedPages(pagePoint, pagePoint);
    if (!!pagePoint.x && range.first == null) {
      return; // don't add to an invalid page location
    }
    console.log(displayMode);
    console.log(range);
    return (range.first !== null) ? range.first : this.docViewer.getCurrentPage();
  }

  drawFormrlyAnnotation(point, dragElementId, options) {
    let drawPage = this.getPageIndexFromPoint(point);

    console.log(drawPage);
    const docViewer = this.instance.docViewer;
    const annotManager = this.instance.annotManager;
    const { Annotations } = this.instance;
    const freeTexttwo = new Annotations.FreeTextAnnotation();
    //freeTexttwo.PageNumber = docViewer.getCurrentPage();
    freeTexttwo.PageNumber = drawPage+1;
    freeTexttwo.X = point.xAdjusted;
    freeTexttwo.Y = point.yAdjusted;
    if(!point.xAdjusted){
      console.log('xAdjusted Not Set')
      console.log(docViewer.getCurrentPage());
      freeTexttwo.PageNumber = docViewer.getCurrentPage();
      freeTexttwo.X = point.x;
      freeTexttwo.Y = point.y;
    }
    
    freeTexttwo.Width = options.width;
    freeTexttwo.Height = options.height;
    freeTexttwo.LockedContents = options.lockedContents;
    freeTexttwo.setPadding(new Annotations.Rect(0, 0, 0, 0));
    freeTexttwo.FillColor = new Annotations.Color(255, 251, 204);
    freeTexttwo.StrokeColor = new Annotations.Color(255, 56, 106)
    freeTexttwo.StrokeThickness = 2
    freeTexttwo.CustomData = {
      type: dragElementId,
      required: true
    }
    freeTexttwo.FontSize = '14pt';
    freeTexttwo.TextColor = new Annotations.Color(0, 0, 0),
      freeTexttwo.Author = 'unassigned'; //'???'

    annotManager.addAnnotation(freeTexttwo);
    annotManager.redrawAnnotation(freeTexttwo);
    this.changeMessage('drop annotation') //???
  }
  buildAnnotationList(annotations, annotManager, docMembers) {
    console.log(annotations);
    var annotationsPrep = [];
    var a = 0;
    for (let i = 0; i < annotations.length; i++) {
      if (annotations[i].Subject === '' || annotations[i].Subject === null){
        console.log('lost boys')
      }
      else if (annotations[i].Subject != 'Signature' ) {
        annotationsPrep[a] = {
          id: '',
          type: '',
          author: '',
          required: true
        };
        console.log(annotations[i].ou)
        annotationsPrep[a].id = annotations[i].ou;

        annotationsPrep[a].type = annotations[i].CustomData.type;
        annotationsPrep[a].author = annotManager.getDisplayAuthor(annotations[i]);
        annotationsPrep[a].required = annotations[i].CustomData.required;
        a++;
      }
    }
    this.annotationsList = annotationsPrep;
    this.changeAnnotationList(annotationsPrep);
    console.log('annotation List');
    console.log(annotationsPrep);
    this.getFieldCounts(annotationsPrep, docMembers);
  }
  getFieldCounts(annotations, documentMembers) {
    console.log('getFieldCounts');
    this.fieldCounts[0].author = 'unassigned';
    this.fieldCounts[0].required = 0;
    this.fieldCounts[0].optional = 0;
    var memberAnnotations = annotations.filter((e) => {
      return (e.author === null || e.author === 'unassigned');
    });
    for (let ii = 0; ii < memberAnnotations.length; ii++) {
      if (memberAnnotations[ii].required === true) {
        this.fieldCounts[0].required = this.fieldCounts[0].required + 1;
      }
      else {
        this.fieldCounts[0].optional = this.fieldCounts[0].optional + 1;
      }
    }
    for (let i = 0; i < documentMembers.length; i++) {
      this.fieldCounts[i + 1] = {
        author: '',
        required: 0,
        optional: 0
      };
      this.fieldCounts[i + 1].author = documentMembers[i].id;
      this.fieldCounts[i + 1].required = 0;
      this.fieldCounts[i + 1].optional = 0;
      var memberAnnotations = annotations.filter((e) => {
        return e.author === documentMembers[i].email;
      });
      for (let ii = 0; ii < memberAnnotations.length; ii++) {
        if (memberAnnotations[ii].required === true) {
          this.fieldCounts[i + 1].required = this.fieldCounts[i + 1].required + 1;
        }
        else {
          this.fieldCounts[i + 1].optional = this.fieldCounts[i + 1].optional + 1;
        }
      }
    }
    this.changeFieldCountList(this.fieldCounts);
  }
  async updateField(annotationId, settingName, dataChange, docMembers) {
    console.log('updateField editorService');
    const annotManager = this.instance.annotManager;
    const docViewer = this.instance.docViewer;
    this.instance.docViewer.getAnnotationManager()
    const getAnnots = this.annotManager.getAnnotationById(annotationId);
    console.log(getAnnots);
    var lockedContentsState = getAnnots.LockedContents;

    if (settingName === 'member' || settingName==='memberFromTemplate') {
      const { Annotations } = this.instance;
      getAnnots.Author = dataChange;

      var memberIndex = docMembers.findIndex(function (item) {
        return item.email === dataChange;
      })
      console.log(memberIndex)
      // Removed on 2020-04-27 for the Text and Number Field Update
      // if (getAnnots.CustomData.type === 'text-field' || getAnnots.CustomData.type === 'number-field') {
      //   if (lockedContentsState === true && memberIndex != -1) {

      //     console.log(lockedContentsState)
      //     console.log(getAnnots.CustomData.type)
      //     getAnnots.LockedContents = false;
      //     await this.annotManager.deleteAnnotation(getAnnots);
      //     await this.annotManager.addAnnotation(getAnnots);
      //     await this.annotManager.redrawAnnotation(getAnnots);
      //   }
      // }
      // else {
      // }
      switch (memberIndex) {
        case 0:
          getAnnots.StrokeColor = new Annotations.Color(0, 119, 251);
          break;
        case 1:
          getAnnots.StrokeColor = new Annotations.Color(0, 217, 119);
          break;
        case 2:
          getAnnots.StrokeColor = new Annotations.Color(118, 89, 255);
          break;
        case 3:
          getAnnots.StrokeColor = new Annotations.Color(255, 107, 199);
          break;
        default:
          getAnnots.StrokeColor = new Annotations.Color(255, 56, 106);
          getAnnots.LockedContents = true;
          break
      }
      if(settingName==='memberFromTemplate'){
        console.log('set No Move')
        getAnnots.NoMove = true;
        getAnnots.NoResize = true;
      }
      await this.annotManager.updateAnnotation(getAnnots);
      await this.annotManager.redrawAnnotation(getAnnots);
      this.annotManager.trigger('annotationChanged', [[getAnnots], 'modify', {}]);
    }
    else if (settingName === 'required') {
      if (lockedContentsState === true) {
        getAnnots.LockedContents = false;
        await this.annotManager.deleteAnnotation(getAnnots);
        await this.annotManager.addAnnotation(getAnnots);
        await this.annotManager.redrawAnnotation(getAnnots);
        getAnnots.LockedContents = true;
      }
      getAnnots.CustomData.required = dataChange;
      console.log(getAnnots.CustomData);
      console.log(getAnnots);
      await this.annotManager.deleteAnnotation(getAnnots);
      await this.annotManager.addAnnotation(getAnnots);
      await this.annotManager.redrawAnnotation(getAnnots);
    }
    this.annotManager.trigger('updateAnnotationPermission', getAnnots);
  }
  async setAnnotationsAsNoMove(templateAnnotations, documentMembers){
    var templatesToUpdate = templateAnnotations;
    console.log('setAnnotationsAsNoMove')
    for(let i=0;i<templatesToUpdate.length;i++){
      console.log('setAnnotationsAsNoMove loop' + i)
      console.log(templatesToUpdate[i].author)
      console.log(templatesToUpdate[i])
      this.updateField(templatesToUpdate[i].id, 'memberFromTemplate', templatesToUpdate[i].author, documentMembers)
    }
    this.instance.hotkeys.off();
  }
  async assignFields(author, annotations, docMembers) {
    const { Annotations } = this.instance;
    console.log('assignFields in webviewer editor service');
    const unassignedFields = annotations.filter((e) => {
      return (e.author === null || e.author === "" || e.author === "unassigned");
    });
    console.log(unassignedFields);
    for (let i = 0; i < unassignedFields.length; i++) {
      const getAnnots = this.annotManager.getAnnotationById(unassignedFields[i].id);
      getAnnots.Author = author;
      var memberIndex = docMembers.findIndex(function (item) {
        return item.email === author;
      })
      switch (memberIndex) {
        case 0:
          getAnnots.StrokeColor = new Annotations.Color(0, 119, 251);
          break;
        case 1:
          getAnnots.StrokeColor = new Annotations.Color(0, 217, 119);
          break;
        case 2:
          getAnnots.StrokeColor = new Annotations.Color(118, 89, 255);
          break;
        case 3:
          getAnnots.StrokeColor = new Annotations.Color(255, 107, 199);
          break;
        default:
          getAnnots.StrokeColor = new Annotations.Color(255, 56, 106);
          break
      }
      await this.annotManager.updateAnnotation(getAnnots);
      await this.annotManager.redrawAnnotation(getAnnots)
      this.annotManager.trigger('annotationChanged', [[getAnnots], 'modify', {}]);
      console.log('redraw complete');
    }
    const xfdfString = await this.annotManager.exportAnnotations();
    annotations = this.annotManager.getAnnotationsList();
    this.annotManager.trigger('updateAnnotationPermission', annotations);
    this.buildAnnotationList(annotations, this.annotManager, docMembers);
  }
  async getXfdfString() {
    const xfdfString = await this.annotManager.exportAnnotations();
    console.log('getXfdfString');
    return xfdfString;
  }
  putAnnotations(fileMetaId, documentData, xfdfString) {
    documentData.annotations = xfdfString;
    this.documentService.UpdateDocumentDetails(fileMetaId, documentData).subscribe(
      fileMeta => {
        console.log('Update Success');
      },
      err => console.log(err)
    )
  }
  async redrawAnnotationBordersAfterDelete(documentMembers, annotations) {
    const { Annotations } = this.instance
    console.log('redrawAnnotationBordersAfterDelete');
    console.log(annotations);
    console.log(documentMembers);
    for (let i = 0; i < annotations.length; i++)
      for (let ii = 0; ii < documentMembers.length; ii++) {
        if (annotations[i].Author === documentMembers[ii].email) {
          switch (ii) {
            case 0:
              annotations[i].StrokeColor = new Annotations.Color(0, 119, 251);
              break;
            case 1:
              annotations[i].StrokeColor = new Annotations.Color(0, 217, 119);
              break;
            case 2:
              annotations[i].StrokeColor = new Annotations.Color(118, 89, 255);
              break;
            case 3:
              annotations[i].StrokeColor = new Annotations.Color(255, 107, 199);
              break;
            default:
              annotations[i].StrokeColor = new Annotations.Color(255, 56, 106);
              break
          }
        }
        await this.annotManager.updateAnnotation(annotations[i]);
        await this.annotManager.redrawAnnotation(annotations[i]);
      }
  }
  IsMyAnnotationsComplete(annotationList) {
    const myAnnotations = annotationList.filter((e) => {
      return (e.author === this.annotManager.getCurrentUser());
    });
    console.log(myAnnotations)
    var currentAnnotation;
    for (let i = 0; i < myAnnotations.length; i++) {
      currentAnnotation = this.annotManager.getAnnotationById(myAnnotations[i].id);
      console.log(myAnnotations)
      console.log(currentAnnotation)
      if (myAnnotations[i].required === false){
        console.log('field not required');
        //alert('return optional');
      }
      else if (myAnnotations[i].type === 'full-signature' || myAnnotations[i].type === 'initial-signature') {
        if (!currentAnnotation.isGrouped() && !currentAnnotation.getGroupedChildren()[0]) {
          console.log(currentAnnotation.isGrouped())
          console.log(currentAnnotation.getGroupedChildren())
          //alert('return False');
          return false;
        }
        else {
          console.log(currentAnnotation.isGrouped())
          console.log(currentAnnotation.getGroupedChildren()[0])
          console.log('pass round ' + i)
          //alert('pass');
        }
      }
      else {
        if (!currentAnnotation.getContents()) {
          //alert('return False');
          return false;
          
        }
        else {
          console.log('pass round ' + i)
          //alert('pass');
        }
      }
    }
    return true;
  }
  putSavedSignature(annotationPaths, signatureType, userData) {
    console.log('Put the signature');
    console.log(userData);
    console.log(annotationPaths);
    console.log(signatureType);
    if (!userData.savedSignatures) {
      userData.savedSignatures = {
        fullSignature: '',
        initials: ''
      }
    }
    console.log(userData);
    if (signatureType === 'full-signature') {
      userData.savedSignatures.fullSignature = annotationPaths;
    }
    else if (signatureType === 'initial-signature') {
      userData.savedSignatures.initials = annotationPaths;
    }
    else {
      console.log('Error no signature Type found');
    }
    console.log(userData);
    this.formrlyUserService.updateMyContactInfo(userData).subscribe(
      fileMeta => {
        console.log('Signature Update Success');
        console.log(fileMeta);
        this.changeMyContactInfo(fileMeta);
      },
      err => console.log(err)
    );
  }
  deleteSavedSignature(signatureType, userData) {
    console.log('Put the signature');
    console.log(userData);
    console.log(signatureType);
    console.log(userData);
    if (signatureType === 'full-signature') {
      userData.savedSignatures.fullSignature = '';
    }
    else if (signatureType === 'initial-signature') {
      userData.savedSignatures.initials = '';
    }
    else {
      console.log('Signature To Delete Not Found');
    }
    console.log(userData);
    //this.changeMemberData(userData);

    this.formrlyUserService.updateMyContactInfo(userData).subscribe(
      fileMeta => {
        console.log('Signature Update Success');
        console.log(fileMeta);
        this.changeMyContactInfo(fileMeta);
      },
      err => console.log(err)
    );
  }
}