import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild } from '@angular/router';
import { NbAuthService, NbAuthToken } from '@nebular/auth';
import { map } from 'rxjs/operators';

/**
 * Implements guards that indicate if routes/subroutes can be activated.
 */
@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

	/**
	 * Initializes a new instance.
	 * 
	 * @param authSvc Nebular authorization service.
	 * @param router Router.
	 */
	constructor(private authSvc: NbAuthService, private router: Router) {}

	/**
	 * Indicates if a route can be activated.
	 */
	canActivate() {
		return this.isNavigationAllowed();
	}

	/**
	 * Indicates if children of a route can be activated.
	 */
	canActivateChild() {
		return this.isNavigationAllowed();
	}

	/**
	 * Returns if navigation is allowed under the current context.
	 */
	protected isNavigationAllowed(){
		return this.authSvc.isAuthenticated().pipe(
			map(isAuthenticated => {
				if(!isAuthenticated){
					this.router.navigate(['/auth/login']);
					return false;
				}

				this.authSvc.getToken().subscribe(token => {
					if(!AuthGuard.isAuthTokenValid(token)){
						this.router.navigate(['/auth/logout']);
					}
				});

				return true;
			})
		);
	}

	/**
	 * Returns if an authorization token is valid.
	 * 
	 * @param token The token to be verified.
	 */
	public static isAuthTokenValid(token:NbAuthToken):boolean{

		const TTL = 72 * 60 * 60 * 1000; // 3 days in milliseconds
		let isExpired = (Date.now() - token.getCreatedAt().getTime()) >= TTL;

		return token.isValid() && !isExpired;
	}
}

/**
 * Implements a guard that indicates if the Login route can be activated.
 */
@Injectable({
	providedIn: 'root'
})
export class LoginGuard implements CanActivate {

	/**
	 * Initializes a new instance.
	 * 
	 * @param authSvc Nebular authorization service.
	 * @param router Router.
	 */
	constructor(private authSvc: NbAuthService, private router: Router) {}

	/**
	 * Indicates if the Login route can be activated.
	 */
	canActivate() {
		return this.authSvc.isAuthenticated().pipe(
			map(isAuthenticated => {
				if(isAuthenticated){
					this.authSvc.getToken().subscribe(token => {
						if(AuthGuard.isAuthTokenValid(token)){
							this.router.navigate(['/dashboard']); 
						}
					});
				}
				return true;
			})
		);
	}
}
