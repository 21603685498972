import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { Documents } from '../../../services/http/interfaces/documents';
import { UserData } from '../../../data/users';
import { NbDialogService } from '@nebular/theme';
import { DocumentService } from '../../../services/http/document.service';
import { concatMap } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { FormrlyUsersService } from '../../../services/http/formrly-users.service';
import { DocumentContextService } from '../document-context.service';
import { VisitService } from  '../../../services/http/visit.service';


@Component({
  selector: 'ngx-my-document-view',
  templateUrl: './my-document-view.component.html',
  styleUrls: ['./my-document-view.component.scss']
})
export class MyDocumentViewComponent implements OnInit {
  //public fileMetaId: string;
  public document: Documents;
  @Input('parentData') public fileMetaId;
  @Input('parentDataFolderName') public currentFolderName;
  @Input('parentDataDocumentType') public documentType;
  @Output() notifyParentFolder: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('downloader') downloader: ElementRef;
  @ViewChild('popupClose') popupClose: ElementRef;

  /** Object to retrieve visits to a document */
  @ViewChild('visitsGetter') visitsGetter:any;
  
  /** Base URL for share links */
  shareLinksBaseUrl:string;

  /** Number of times a rename action has been triggered */
  renameClick = 0;
  
  /** Display name for the document */
  documentDisplayName = '';

  constructor(
    private userSvc: UserData,
    private dialogService: NbDialogService,
    private documentService: DocumentService,
    private router: Router,
    private formrlyUsersService: FormrlyUsersService,
    private docContextService: DocumentContextService,
    private visitService: VisitService
  ) { }
  ngOnInit() {
    this.shareLinksBaseUrl = window.location.origin + '/signer/view-share/';
  }
  getDocument(data) {
    console.log('My Documents getDocuments data');
    console.log(data);
    this.document = data;
    if (this.document.folderId) {
      this.notifyParentFolder.emit(this.document.folderId);
    }
    else {
      this.notifyParentFolder.emit(this.document.status);
    }
    if (this.document.ShareLinks) {
      console.log('shareLinks');
      console.log(this.document.ShareLinks.length);
      console.log(this.document.ShareLinks);
      this.updateVisitsToShareLinks();
    }
  }

  /**
   * Updates the number of visits to each share link.
   */
  updateVisitsToShareLinks(){
    if (this.document.ShareLinks){
      this.document.ShareLinks.forEach(
        item => {
          this.visitService.getVisitsToDocumentbyContact(this.fileMetaId, item.FormrlyUserContactId).subscribe(
            visits => { item.visitCount = visits.length; }
          );
        },
        error => { console.log(error); }
      );
    }
  }

  open(dialog: TemplateRef<any>) {
    console.log(dialog);
    this.dialogService.open(dialog, { context: 'this is some additional data' });
  }
  closeDialog(dialog) {
    this.dialogService.open(dialog, { context: 'this is some additional data' }).close;
  }
  closeMemberDialog() {
    let el: HTMLElement = this.popupClose.nativeElement;
    el.click();
  }
  getMember(data) {
    console.log('getMember');
    console.log(data);
    this.closeMemberDialog();
    this.addShareLink(data);
  }
  addShareLink(data) {
    var insertRow = 0;
    var now = Date.now();
    console.log(now);
    if (this.document.ShareLinks) {
      console.log(this.document.ShareLinks.length)
      insertRow = this.document.ShareLinks.length;
      this.document.ShareLinks[insertRow] = {
        shareLink: '123',
        active: true,
        FormrlyUserContactId: data.id,
        formrlyUserContact: data.name.first + ' ' + data.name.last,
        timestamp: now
      };
    }
    else {
      this.document.ShareLinks = [{
        shareLink: '123',
        active: true,
        FormrlyUserContactId: data.id,
        formrlyUserContact: data.name.first + ' ' + data.name.last,
        timestamp: now
      }];
    }
    console.log(this.document);
    this.updateDocument(this.document);
    this.addShareDocumentMember(this.document.id,data);

    this.docContextService.sendShareLinkEnabled(this.document, data.id, false);
    this.updateVisitsToShareLinks();

    // this.documentService.UpdateDocumentDetails(this.document.id, this.document).subscribe(documentMeta =>{
    //     console.log('Update Document Success');
    //     console.log(documentMeta);
    //     this.document = documentMeta;
    //   }
    // );
  }
  addShareDocumentMember(fileMetaId,contactInfo){
    console.log(fileMember);
    var fileMember ={
      memberId:"123",
      shareLink : '123',
      access : 'share', 
      FormrlyUsersContactId: contactInfo.id
    };
  //  fileMember.memberId = '123';
  //  fileMember.shareLink = '123';
  //  fileMember.access = 'share';

    console.log('The File Member before adding new member')
    console.log(fileMember);
 
    this.documentService.addDocumentMember(fileMetaId, fileMember).pipe(
      concatMap(fileMember => {
        console.log('Member Details');
        console.log(fileMember);
        return this.documentService.getDocumentMemberByMemberId(fileMetaId, fileMember.FormrlyUsersContactId);
      })
    ).subscribe(
      membersData => {
        console.log('successful update');
        console.log(membersData);
      },
      err => console.error(err)
    );

  }
  toggleShareLink(shareLink) {
    console.log('toggle share link' + shareLink.active);
    console.log(shareLink)
    console.log(this.document.ShareLinks);
    this.updateDocument(this.document);
    
    if(shareLink.active){
      this.docContextService.sendShareLinkEnabled(this.document, shareLink.FormrlyUserContactId, true);
    }else{
      this.docContextService.sendShareLinkDisabled(this.document, shareLink.FormrlyUserContactId, true);
    }

    // Refresh the number of visits to each share link
    this.updateVisitsToShareLinks();
  }

  viewFolder(currentFolderName){
    if(this.document.fileType==='myDocument' && this.document.folderId){
      this.router.navigate(['/my-documents/folders/' + this.document.folderId])
    }
    else if(this.document.fileType==='myDocument' && !this.document.folderId){
      this.router.navigate(['/my-documents/folders/0'])
    }
    else{
      this.router.navigate(['/documents/' + this.document.status])
    }
    
  }
  onContextMenuItemClick(event, action) {
    console.log(event.data);
    switch (action) {
      case 'edit':
        this.docContextService.goToWebEditor(event.data.location, event.data.id);
        break;

      case 'details':
        this.docContextService.goToView(event.data);
        break;
      case 'sign':
        //??Go to Web Signer
        this.docContextService.goToWebSigner(event.data.id);

        break;
      case 'reminder':
        this.visitsGetter.getDocumentMembersVisits(event.data.id); // This will trigger the process to send a reminder. See 'listenOrderedMembers' below
        break;
      case 'download':
      console.log('download');
      
      //this.router.navigate(['signer/download/' + event]);
        this.docContextService.downloadFile(event.data, this.downloader);
        break;
      case 'resend':
        this.docContextService.sendDownloadLinkToAllMembers(event.data);
        break;
      case 'void':
        this.voidDocument(event.data)
        break;
      case 'myDocuments':
        this.docContextService.addToMyDocuments(event.data, true);
        break;
      case 'resend-share':
        event.data.active = true;
        this.toggleShareLink(event.data);
        break;
      case 'rename':
        this.documentDisplayName = event.data.displayName;
        this.renameClick++;
        break;
    }
  }

  /**
	 * Updates the name of the current document.
	 * @param newName The new name for the current document.
	 */
	updateDocumentName(newName:string):void{
    this.document.displayName = newName;
    this.updateDocument(this.document);
	}

  voidDocument(document) {
    console.log('voidDocument')
    if (document.status === 'completed') {
      alert('You cannot void a completed document.')
    }
    else {
      if (confirm('Are you sure you want to void the document: ' + document.displayName + '?')) {
        document.status = 'void';
        this.documentService.UpdateDocumentDetails(document.id, document).subscribe(
          fileMeta => {
            console.log('Void Success');
            //console.log(fileMeta);
            this.router.navigate(['/documents/ready']);
            //alert('Success: Your document has been voided.');
          },
          err => console.log(err)
        )
      }
    }





  }

  /**
	 * Listen for changes in the list of members of the document, ordered by signing priority.
	 * @param members The new members being received.
	 */
	listenOrderedMembers(members){
		this.docContextService.sendReminder(this.document, members);
	}


  // onContextMenuItemClick(event, action) {
  // 	console.log(event.data);
  // 	switch (action) {
  // 		case 'edit':
  // 			this.goToWebEditor(event.data.id);
  // 			break;

  // 		case 'details':
  // 			this.goToView(event.data.id);
  // 			break;
  // 		case 'sign':
  // 			//??Go to Web Signer
  // 			this.goToWebSigner(event.data.id);

  // 			break;
  // 		case 'reminder':
  // 			//??Create later when creating email functionality
  // 			//this.sendDocument(event.data)
  // 			break;
  // 		case 'download':
  // 			this.downloadFile(event.data.name);
  // 			break;
  // 		case 'resend':
  // 			//??Create later when creating email functionality
  // 			//this.resendDocument(event.data)
  // 			break;
  // 		case 'void':
  // 			this.voidDocument(event.data)
  // 			break;
  // 		case 'myDocuments':
  // 			this.addToMyDocuments(event.data);
  // 			break;
  // 	}
  // }



  // goToView(fileMetaId) {
  // 	this.router.navigate(['/documents/view/', fileMetaId]);
  // }

  // goToWebEditor(fileMetaId) {
  // 	this.router.navigate(['/editor/', fileMetaId]);
  // }
  // goToWebSigner(fileMetaId) {
  // 	this.router.navigate(['/signer/', fileMetaId]);
  // }

  // goToWebViewer(pdfLocation, fileMetaId) {
  // 	this.router.navigate(['/view', { location: pdfLocation, id: fileMetaId }]);
  // }


  downloadFile(document) {
    this.docContextService.downloadFile(document, this.downloader);
  	// this.userSvc.downloadFile(fileName).subscribe(
  	// 	data => {
  	// 		const URL = window.URL.createObjectURL(data);
  	// 		const link = this.downloader.nativeElement;
  	// 		link.href = URL;
  	// 		link.download = fileName;
  	// 		link.click();
  	// 		window.URL.revokeObjectURL(URL);
  	// 	},
  	// 	err => console.error(err)
  	// );
  }

  // deleteFile(fileName, fileId) {
  // 	this.userSvc.deleteFile(fileName)
  // 		.pipe(
  // 			concatMap(response => {
  // 				let result$;
  // 				switch (response.status) {
  // 					case 204:
  // 						// bug?: are 204s being converted to 200 by angular http client?
  // 						result$ = this.userSvc.deleteFileMeta(fileId);
  // 						break;
  // 					case 200:
  // 						// bug: loopback logs errors and still returns 200 status code?
  // 						result$ = this.userSvc.deleteFileMeta(fileId);
  // 						break;
  // 					default:
  // 						result$ = observableOf(Error('unhandled response code'));
  // 				}
  // 				return result$;
  // 			})
  // 		).subscribe(
  // 			data => {
  // 				// TODO: remove single item from UI instead of refreshing
  // 				//this.getFileMetas();
  // 				console.log(data);
  // 				console.log('file deleted');
  // 			},
  // 			err => console.error(err)
  // 		);
  // }

  addToMyDocuments(document) {
    console.log('addToMyDocuments')
    document.fileType = 'myDocument';
    this.updateDocument(document);

  }
  updateDocument(document) {
    this.documentService.UpdateDocumentDetails(document.id, document).subscribe(
      fileMeta => {
        console.log('Update Success');
      },
      err => console.log(err)
    )
  }
  // voidDocument(document) {
  // 	console.log('voidDocument')
  // 	if (confirm('Are you Sure you want to void the Document: ' + document.displayName + '?')) {
  //     var documentOldStatus = document.status;
  //     document.status = 'void';
  // 		this.documentService.UpdateDocumentDetails(document.id, document).subscribe(
  // 			fileMeta => {
  //         console.log('Void Success');
  //         if (this.documentType === 'myDocument'){
  //           this.router.navigate(['/my-documents/folders/0']);
  //         }
  //         else{
  //           this.router.navigate(['documents/', documentOldStatus])
  //         }


  // 				//console.log(fileMeta);
  // 				// var spliceId = this.documentsData.findIndex(function (item) {
  // 				// 	return item.id === document.id;
  // 				// })
  // 				// this.documentsData.splice(spliceId, 1);
  // 				alert('Success: Your document Has been Voided');
  // 			},
  // 			err => console.log(err)
  // 		)
  // 	}
  // }
  greet(data) {
    var dataSample =
    {
      userFirstName: "John",
      userEmail: "raj.narayan.sitar@gmail.com",
      senderFirstName: "Jon",
      senderLastName: "Jon",
      documentUrl: "https://rkanswers.com",
      emailBody: '',
      emailSubject: ''
    }

    this.formrlyUsersService.greet(data).subscribe(greet => {
      console.log('greet Success');
      console.log(greet);
    },
      err => console.log(err)
    )
  }
}
