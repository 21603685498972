import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VisitsAddService {

  constructor() { }
  createVisit(userId,fileMetaId,fileMetaFormrlyUserId){

  }

  getTime(){
    //Returns Unix time stamp in seconds
    var dateNow;
    dateNow = Date.now() / 1000;
    dateNow = parseInt(dateNow);
    return dateNow;
  }
  getTimestamp(){
    var timestamp = Date.now();
    return timestamp;
  }
  updateVisitCheck(userId,visitId,currentPage,startTime,endTime){
    var totalTime;
    if(endTime > 0 && endTime - startTime >= 1 ){
      console.log('Visit Detail to Update');
      console.log('User Id of Visit' + userId);
      console.log('Current Page is'+ currentPage);
      console.log('Start Time' + startTime +'End Time' + endTime);
      totalTime = endTime - startTime;

      console.log('total time is' + totalTime);
      this.updateVisitDetails(userId,visitId);
    }
    else{
      console.log('No Visit Detail to Update');
      console.log('Visit Detail to Update');
      console.log('User Id of Visit' + userId);
      console.log('Current Page is'+ currentPage);
      console.log('Start Time' + startTime +'End Time' + endTime);
      totalTime = 1;
    }

  }
  updateVisitDetails(userId,visitId){

  }
}
