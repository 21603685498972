import { Component, OnInit } from '@angular/core';
import{Router} from '@angular/router'

@Component({
  selector: 'ngx-visits-recent',
  templateUrl: './visits-recent.component.html',
  styleUrls: ['./visits-recent.component.scss']
})
export class VisitsRecentComponent implements OnInit {
  public visits;
  public members;
  public documents;
  visitsData=[]
  loadPage
  constructor(
    private router: Router

  ) { }

  ngOnInit() {
  }
  open(page){
    this.router.navigate(['/' + page]);
  }
  viewDocument(id){
    this.router.navigate(['/documents/view/' + id]);
  }


  getVisits(data){
    this.visits = data;
    console.log('visits recent component');
		console.log(data);
		this.checkLoading();
	}

	getDocuments(data){
		this.documents = data;
		console.log(data);
		this.checkLoading();
	}
	getMembers(data){
		this.members = data;
		console.log(data);
		this.checkLoading();

  }
  checkLoading(){
		if(this.documents && this.visits && this.members){
			this.loadPage = true;
			console.log('pageLoaded');
		//	this.loadBarChart();
			this.prepareVisits(this.visits,this.documents);
		}
  }
  
  prepareVisits(visitsRaw, documents){
		var i;
		//visitsRaw Loop
		var thisVisitDataRowCurrent = 0;
		for(i=0; i<visitsRaw.length; i++){
			var currentVisitPageCount;
			var memberVisitTotalTime = 0;
			
			//If Visit has data
			if(visitsRaw[i].visitData[0].time){
				this.visitsData[thisVisitDataRowCurrent] = visitsRaw[i]
				this.visitsData[thisVisitDataRowCurrent].visitDataForGraph= []

				//this.visitsData[i].visitData = [];
				var ii;
				//Document Loop
				for (ii=0; ii<documents.length; ii++){
					//If same document
					if (visitsRaw[i].fileMetaId === documents[ii].id){
						currentVisitPageCount = documents[ii].pageCount;
						//if value doesn't exist???
						//??? Build the object of visit data for the chart
						var j;
						var currentPage=1;

						//Initial Build of Visit Data of pages without time
						for(j=0; j< currentVisitPageCount;j++){
							currentPage = j + 1;
							
							this.visitsData[thisVisitDataRowCurrent].visitDataForGraph[j] = {
								page: currentPage,
          						time: 0
							}
						}
						console.log('this.visitsData');
						console.log(this.visitsData);
						ii = documents.length;
					}	
				}
				var iii;
				var cccount = visitsRaw[i].visitData.length;
				
				//Page Visits Loop
            	for (iii = 0; iii < cccount; iii++) {
					
					//If the Page Visit has time
              		if (visitsRaw[i].visitData[iii].time) {
						memberVisitTotalTime = memberVisitTotalTime + visitsRaw[i].visitData[iii].time;
						console.log('memberVisitTotalTime')
						console.log(memberVisitTotalTime)
						console.log(visitsRaw[i].visitData[iii].time)
                		//console.log(members[i].visitData[visitDataRow].time);
                		var visitDataRow;
                		visitDataRow = visitsRaw[i].visitData[iii].pageNumber - 1;		
						// Page Count Box not used, this would need to be modified for the current page
	                	// if(this.visitsData[i].visitData[visitDataRow].time === 0 || members[i].visitData[visitDataRow].time === null){
    		            //   	memberVisitPageCount = memberVisitPageCount + 1;
            	    	// }
						console.log('Visit Row is' +visitDataRow);
						console.log(visitsRaw[i].visitData[iii].time);
						console.log(this.visitsData[thisVisitDataRowCurrent].visitDataForGraph[visitDataRow]);
						
						this.visitsData[thisVisitDataRowCurrent].totalTime = memberVisitTotalTime;
						this.visitsData[thisVisitDataRowCurrent].visitDataForGraph[visitDataRow].time = this.visitsData[thisVisitDataRowCurrent].visitDataForGraph[visitDataRow].time + visitsRaw[i].visitData[iii].time;
						console.log('raw visits row ' + i)
						console.log('build visits row ' + thisVisitDataRowCurrent)
						console.log(visitsRaw[i]);
						console.log(this.visitsData[i]);
						
              		}
				}
				thisVisitDataRowCurrent++;
			}
			
		
		}
		console.log('end of build visits');
		console.log(this.visitsData);
		
	}
}
