import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SignWVRKComponent} from './wv-signer.component';
import {SignatureCompletedComponent} from '../../../@core/components/signature-completed/signature-completed.component';
import {WebviewerFullComponent} from '../../../@core/components/webviewer-full/webviewer-full.component';
import {DownloadComponent} from '../../download/download.component';
import { ViewShareComponent } from '../../view-share/view-share.component';

const routes: Routes = [
  {
    path: '',
    component: SignWVRKComponent
  },
  // {
  //   path: 'download/:id',
  //   component: WebviewerFullComponent
  // },
  {
    path: 'download/:id/:memberId',
    component: DownloadComponent
  },
  {
    path: 'view-share/:fileMetaId/:contactId',
    component: ViewShareComponent
  },
  {
    path: 'completed',
    component: SignatureCompletedComponent
  },
  {
    path: ':id',
    component: SignWVRKComponent
  },
  {
    path: ':id/:memberId',
    component: SignWVRKComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SignWVRKRoutingModule { }
