import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, Input } from '@angular/core';
import { PageSettingService } from '../../../services/http/page-setting.service';
import { PageSettings } from '../../../services/http/interfaces/page-settings';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-tutorial-popover',
  templateUrl: './tutorial-popover.component.html',
  styleUrls: ['./tutorial-popover.component.scss']
})
export class TutorialPopoverComponent implements OnInit, AfterViewInit {
  images = new Array('/assets/images/tutorial-step-1.png', '/assets/images/tutorial-step-2.png', '/assets/images/tutorial-step-3.png');
  pageSettings;
  changeTutorial = 0;
  @ViewChild('myCarousel') myCarousel: NgbCarousel;
  @ViewChild('clicker') clicker: ElementRef<HTMLElement>;

  /** Reference to the popup */
  @Input() ref; 

  triggerClick() {
    let el: HTMLElement = this.clicker.nativeElement;
    el.click();
  }
  clickAction() {
    console.log('false click')
    this.myCarousel.select('dashboard');
  }
  constructor(
    private pageService: PageSettingService,
    private router: Router
  ) { }

  ngOnInit() {

    this.getPageSettings();
    // this.myCarousel.activeId='slide-getting-started';
    console.log(this.router.url)
    //this.setActiveId(this.router.url);  
  }
  ngAfterViewInit() {
  }

  /**
   * Closes the popover.
   */
  close():void{

    if(this.ref){
      this.ref.close();
    }
  }

  setActiveId(url) {
    console.log(url);
    var activeId = null;
    if (url === '/dashboard') {
      console.log('dashboard')
      activeId = 'dashboard';
      //this.triggerClick();
    }
    else if (url.slice(1, 7) === 'editor') {
      console.log('editor')
      activeId = 'editor';
    }
    else if (url.slice(1, 15) === 'documents/view') {
      console.log('document-view')
      activeId = 'document-view';
    }
    else if (url.slice(1, 10) === 'documents') {
      console.log('documents')
      activeId = 'documents'
    }
    else if (url.slice(1, 7) === 'visits') {
      console.log('visits')
      activeId = 'visits';
    }


    else if (url.slice(1, 15) === 'templates/edit') {
      console.log('templates-edit')
      activeId = 'templates-edit';
    }
    else if (url.slice(1, 10) === 'templates') {
      console.log('templates')
      activeId = 'templates';
    }
    else if (url.slice(1, 21) === 'my-documents/folders') {
      console.log('my-documents')
      activeId = 'my-documents';
    }
    else if (url.slice(1, 13) === 'my-documents') {
      console.log('my-document-view')
      activeId = 'my-document-view';
    }
    else if (url.slice(1, 14) === 'contacts/view') {
      console.log('contacts-view')
      activeId = 'contacts-view';
    }
    else if (url.slice(1, 9) === 'contacts') {
      console.log('contacts')
      activeId = 'contacts';
    }
    else if (url.slice(1, 8) === 'account') {
      console.log('account')
      activeId = 'account';
    }
    else { }
    if (activeId) {
      if (this.changeTutorial === 0) {
        this.myCarousel.activeId = activeId;
        this.changeTutorial = 1;
      }
      else {
        this.myCarousel.select(activeId);
      }
    }
  }
  changeSlide(slideId) {
    this.myCarousel.select(slideId)
  }
  previousSlide() {
    this.myCarousel.prev()
  }
  nextSlide() {
    this.myCarousel.next()
  }
  getPageSettings() {
    this.pageService.getPageSettings().subscribe(
      pageMeta => {
        console.log('success get');
        this.pageSettings = pageMeta;
        console.log('The file meta' + pageMeta);
        console.log(pageMeta);
        this.setActiveId(this.router.url);
        //this.setActiveId(this.router.url);
        //console.log (this.documentsByStatus.name);
        //this.notify.emit(this.documentsByStatus);
        //console.log('The docstatus' + this.documentsByStatus);
      },
      err => console.error(err)
    );
  }



}
