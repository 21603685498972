import { Component, OnInit, Input, ViewChild, ElementRef, TemplateRef, EventEmitter, Output } from '@angular/core';
import { TemplateService } from '../../../services/http/template.service';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { FormrlyUsersService } from '../../../services/http/formrly-users.service';
import { concatMap } from 'rxjs/operators';
import { DocumentService } from '../../../services/http/document.service';
import { UploadService } from '../../../services/common/upload.service';

@Component({
	selector: 'ngx-templates-status-view',
	templateUrl: './templates-status-view.component.html',
	styleUrls: ['./templates-status-view.component.scss']
})
export class TemplatesStatusViewComponent implements OnInit {
	@Input('parentDataTemplates') public templates;
	@Input('parentDataStatus') public status;
	@Output() notifyStatus: EventEmitter<string> = new EventEmitter<string>();
	public renameClick = 0;
	currentTemplateName;
	currentTemplate;

	@ViewChild('popupDocumentNameClose') public popupDocumentNameClose: ElementRef<HTMLElement>;
	@ViewChild('buttonTemplatePopupOpen') public buttonTemplatePopupOpen: ElementRef<HTMLElement>;

	/** The current page being displayed */
	currentPage:number = 1;

	/** The number of items to display per page */
	itemsPerPage:number = 10;

	constructor(
		private templateService: TemplateService,
		private router: Router,
		private dialogService: NbDialogService,
		private formrlyUserService: FormrlyUsersService,
		private documentService: DocumentService,
		private uploadService: UploadService
	) { }

	ngOnInit() {

	}
	open(dialog: TemplateRef<any>) {
		this.dialogService.open(dialog, { context: 'this is some additional data' });
	}

	triggerPopupClose() {
		let el: HTMLElement = this.popupDocumentNameClose.nativeElement;
		el.click();
	}

	triggerPopupOpen() {
		let el: HTMLElement = this.buttonTemplatePopupOpen.nativeElement;
		el.click();
	}

	onContextMenuItemClick(event, action) {
		console.log(event.data);
		switch (action) {
			case 'edit':
				this.goToTemplateBuilder(event.data.id);
				break;

			case 'copy':
				this.copyTemplate(event.data);
				break;
			case 'rename':
				//??Go to Web Signer
				this.renameTemplate(event.data);


				break;
			case 'view':
				this.preview(event.data);
				//??Create later when creating email functionality
				//this.sendDocument(event.data)
				break;
			case 'createDoc':
				this.createDocFromTempalte(event.data.name);
				break;
			case 'remove':
				this.voidTemplate(event.data)
				break;

			case 'addSystemTemplate':
				this.addSystemTemplateToSaved(event.data, 'add')
				break;

			case 'removeSystemTemplate':
				this.addSystemTemplateToSaved(event.data.id, 'remove')
				break;

		}
	}

	addSystemTemplateToSaved(systemTemplate, action) {
		this.formrlyUserService.getCurrentUser().subscribe(
			userData => {
				console.log(userData);
				var userSystemTemplates = {
					systemTemplatesId: userData.systemTemplatesId
				};
				this.addSystemTemplateProcessData(systemTemplate, action, userSystemTemplates)
			},
			err => console.log(err)
		)
	}
	addSystemTemplateProcessData(systemTemplate, action, userSystemTemplates) {
		this.copyTemplate(systemTemplate)

	}
	updateCurrentUser(userMeta) {
		console.log(userMeta);
		this.formrlyUserService.patchCurrentUser(userMeta).subscribe(
			userUpdate => {
				console.log('update System Data Saved')
				console.log(userUpdate);
			},
			err => console.log(err)
		)
	}

	goToTemplateBuilder(fileMetaId) {
		this.router.navigate(['/template-builder/', fileMetaId]);
	}
	goToView(fileMetaId) {
		this.router.navigate(['/editor/', fileMetaId]);
	}
	preview(data) {
		this.currentTemplate = data;
		this.triggerPopupOpen();
	}

	renameTemplate(template) {
		this.currentTemplateName = template.displayName;
		this.currentTemplate = template;
		this.renameClick++;
	}
	updateName(data) {
		console.log('rename' + data);
		this.currentTemplate.displayName = data;
		this.templateService.updateMyTemplateDetail(this.currentTemplate.id, this.currentTemplate).subscribe(
			templateMeta => {
				var spliceId = this.templates.findIndex(function (item) {
					return item.id === templateMeta.id;
				})
				this.templates[spliceId].displayName = templateMeta.displayName
			}
		)
	}
	createDocFromTempalte(template) {

	}
	removeTemplate(template) {

	}
	voidTemplate(template) {
		console.log('voidDocument')
		if (confirm('Are you Sure you want to void the Document: ' + template.displayName + '?')) {
			template.status = 'void';
			this.templateService.updateMyTemplateDetail(template.id, template).subscribe(
				fileMeta => {
					console.log('Void Success');
					//console.log(fileMeta);
					var spliceId = this.templates.findIndex(function (item) {
						return item.id === template.id;
					})
					this.templates.splice(spliceId, 1);
					alert('Success: Your Template Has been Voided');
				},
				err => console.log(err)
			)
		}
	}
	copyTemplate(template){
		console.log(template.name);
		this.documentService.downloadFile(template.name).pipe(
			concatMap( download =>{
				console.log(download);
				//filename service
				var uploadFileName = this.uploadService.setUploadName(template.name, 'template', '123');//??? last needs user ID when that is fixed
				download.name = uploadFileName;
				return this.documentService.uploadFile(download, '');
			}
		)).pipe(
			concatMap( upload =>{
				var displayName = 'Copy of ' + template.displayName;
				var newTemplate = upload.result.files.file[0];
				newTemplate.pageThumbnails = template.pageThumbnails;
				newTemplate.annotations = template.annotations;
				return this.templateService.saveTemplateMetaNew(newTemplate,displayName);
			}
		)).subscribe(
			template =>{
				console.log('successfull write')
				console.log(template);
				if(this.status === 'active' || this.status === 'draft'){
					this.templates.push(template);
				}
				else{
					this.notifyStatus.emit('saved');
				}
			},
		err => console.log(err)
		);
	}
}
