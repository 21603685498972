import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { UserData } from '../../../data/users';
import { concatMap } from 'rxjs/operators';
import { UploadService } from '../../../services/common/upload.service';
import { Router } from '@angular/router';
import { DocumentService } from '../../../services/http/document.service';
@Component({
  selector: 'ngx-documents-my-add',
  templateUrl: './documents-my-add.component.html',
  styleUrls: ['./documents-my-add.component.scss']
})
export class DocumentsMyAddComponent implements OnInit {
  @Input('parentDataFolderId') public folderId;
  @Output() notifyUploadMyDocument: EventEmitter<any> = new EventEmitter<any>()

  // Indicates if a new picture is being uploaded
	isUploading:boolean;

  constructor(
    private userSvc: UserData,
    private documentService: DocumentService,
    private uploadService: UploadService,
    private router: Router,
  ) { }
  file
  ngOnInit() {
    this.isUploading = false;
  }
  selectFile(event) {
    this.file = event.target.files[0];
    let isImage = (this.file.type.match(/image\/*/) !== null);
    let isAllowedDocumentType = (this.file.type === 'application/pdf');

    if(isImage || isAllowedDocumentType){
      console.log(this.file);
      //this.dialogRef.close();
      this.file.status = 'draft';
      //this.closeDialog('popupnewdoc');
      this.uploadFile(event.target.files[0]);
    }else{
      alert('Please choose an image or a PDF file.');
    }
  }
  uploadFile(file) {
    this.isUploading = true;
    var userId, uploadFileName, displayFileName, userMeta;
    displayFileName = file.name;

    this.userSvc.getCurrentUser().pipe(
      concatMap(
        userData => {
          userId = userData.id
          userMeta = userData
          console.log(userData);
          console.log(userId);
          uploadFileName = this.uploadService.setUploadName(file.name, 'file', userId);
          console.log(uploadFileName);
          return this.userSvc.uploadFileNew(file, uploadFileName, '')
        }))
      .pipe(
        concatMap(uploadMeta => {
          var fileMeta = uploadMeta.result.files.file[0];
          fileMeta.fileType = 'myDocument';
          fileMeta.displayName = displayFileName
          if(this.folderId === 0 || this.folderId === '0'){

          }
          else{
            fileMeta.folderId = this.folderId;
          }
          return this.documentService.AddDocument(fileMeta);
          //saveFileMetaNew(fileMeta.result.files.file[0], displayFileName);
        })
      ).subscribe(
        fileMeta => {
          this.isUploading = false;
          this.notifyUploadMyDocument.emit(fileMeta);
          console.log('notify uploaded my document')
          //this.files.push({ name: fileMeta.name, location: fileMeta.providerResponse.location, id: fileMeta.id, status: 'draft' });
          

          

          
        },
        err => console.error(err)
      );






  }


}
