import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
	selector: 'ngx-chart-bar',
	templateUrl: './chart-bar.component.html',
	styleUrls: ['./chart-bar.component.scss']
})
export class ChartBarComponent implements OnInit, AfterViewInit {
	@Input('parentData') public counter;
	@Input('parentDataThumbnails') public thumbnails;
	@Input('parentDataVisits') public visits;

	public xData;
	public xLabels;

	barChart = [];

	constructor(
		private elementRef: ElementRef
	) { }
	ngOnInit() {
	}
	ngAfterViewInit() {
		console.log('chart on changes' + this.counter);

		this.prepareData(this.visits);
		var thumbnails = this.thumbnails;

		// Custom tooltip configuration
		var custom_tooltips = function (tooltip) {

			// Get or create a tooltip HTML element
			var tooltipEl_Id = this._chart.canvas.id + '-tooltip';
			var tooltipEl = document.getElementById(tooltipEl_Id);

			if (!tooltipEl) {
				tooltipEl = document.createElement('div');
				tooltipEl.id = tooltipEl_Id;
				tooltipEl.classList.add('chartjs-tooltip');
				this._chart.canvas.parentNode.appendChild(tooltipEl);
			}

			// Hide if no tooltip is available
			if (tooltip.opacity === 0) {
				tooltipEl.style.display = 'none';
				return;
			}

			var page_index = parseInt(tooltip.dataPoints[0].index, 10);
			var page_number = page_index + 1;
			var total_pages = this._chart.data.labels.length;
			var time_spent = parseInt(tooltip.dataPoints[0].value, 10);

			var thumbnail_url = thumbnails[page_index].providerResponse.location;
			var details = '<p class="no-thumbnail-text py-3 font-italic text-white text-center">(No preview)</p>';
			if(thumbnail_url){
				details = '<img src="' + thumbnails[page_index].providerResponse.location + '" class="w-100 mb-2" alt="Template preview">';
			}
			
			// Set tooltip element HTML
			tooltipEl.innerHTML =
				'<div class="tooltip-image mb-1">' + 
					details + 
				'</div>' + 
				'<div class="tooltip-text">' + 
					'<div class="header-row row no-gutters mb-0">' +
						'<div class="col-6 text-left">' +
							'Page' +
						'</div>' +
						'<div class="col-6 text-right">' +
							'Time' +
						'</div>' +
					'</div>' +
					'<div class="row no-gutters mb-0">' +
						'<div class="col-6 text-left">' +
							page_number + ' / ' + total_pages +
						'</div>' +
						'<div class="col-6 text-right">' +
							getTimeString(time_spent) +
						'</div>' +
					'</div>' + 
				'</div>';

			// Display and position the tooltip
			tooltipEl.style.display = 'block';
			tooltipEl.style.position = 'absolute';

			var canvas_position = this._chart.canvas.getBoundingClientRect();
			var tooltip_text_height = (tooltipEl.getElementsByClassName('tooltip-text')[0] as HTMLElement).offsetHeight;
			var chart_area_height = Math.abs(this._chart.chartArea.top - this._chart.chartArea.bottom);
			var tooltip_image = tooltipEl.getElementsByClassName('tooltip-image')[0] as HTMLElement;

			tooltipEl.style.top = 'auto';
			tooltipEl.style.bottom = '30px';
			var row_el = tooltipEl.closest("[id^='details-row-']");
			if(row_el !== null){
				tooltip_image.style.maxHeight = (Math.round((row_el as HTMLElement).offsetHeight * 0.8) - tooltip_text_height - 30) + 'px';
			}else{
				tooltip_image.style.maxHeight = (chart_area_height - tooltip_text_height - 30) + 'px'; // In theory this will never occur
			}

			var tooltip_width = tooltipEl.offsetWidth;
			var half_bar_width = this._chart.getDatasetMeta(0).data[0]._model.width / 2;

			if ((tooltip.dataPoints[0].x + half_bar_width + tooltip_width) >= canvas_position.width) {
				tooltipEl.style.left = Math.max(0, tooltip.dataPoints[0].x - tooltip_width - half_bar_width)  + 'px';
			} else {
				tooltipEl.style.left = tooltip.dataPoints[0].x + half_bar_width + 'px';
			}
		};

		let htmlRef = this.elementRef.nativeElement.querySelector(`chart-row-${this.counter}`);

		console.log('the counter' + this.counter);
		var counting = this.counter + 1;
		this.barChart = new Chart(`chart-row-${this.counter}`, {
			//this.barChart = new Chart(htmlRef, {
			type: 'bar',
			data: {
				//labels: ['Page 1', 'Page 2', 'Page 3', 'Page 4', 'Page 5', 'Page 6', 'Page 7'],
				labels: this.xLabels,

				datasets: [{
					label: 'Time Spent (mm:ss)',
					backgroundColor: 'rgb(58,215,138)',
					borderColor: 'rgb(58,215,138)',
					//data: [80, 141, 50, 30, 40, 70, 30] // Values are in minutes
					data: this.xData // Values are in seconds
				}]
			},
			options: {
				scales: {
					yAxes: [{
						ticks: {
							callback: function (value) {
								return getTimeString(value);
								//return value;
							},
							suggestedMin: 0,
							suggestedMax: 10
						}
					}]
				},
				tooltips: {
					enabled: false,
					custom: custom_tooltips
				}
			}
		});
	function getTimeString(timeInSeconds) {
			var minutes = Math.floor(timeInSeconds / 60);
			var seconds = timeInSeconds % 60;
			return minutes + ':' + ('00' + seconds).substr(-2, 2);
		}	

	}
	prepareData(visits){
		console.log('prepare data');
		console.log(visits);
		var i;
		this.xData = []
		this.xLabels = [] 
		var page
		for(i=0;i<visits.length;i++){
			page = i+ 1;
			this.xData[i]= visits[i].time;
			this.xLabels[i]= 'Page ' + page;
		}
		console.log(this.xData);
	}
}

