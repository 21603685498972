import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map, filter } from 'rxjs/operators';

import { FormrlyUsersContact } from '../../../services/http/interfaces/formrly-users-contact';
import { FormrlyUsersService } from '../../../services/http/formrly-users.service';
import { liveSearch } from './live-search.operator';

@Component({
  selector: 'ngx-formrly-user-contacts-search',
  templateUrl: './formrly-user-contacts-search.component.html',
  styleUrls: ['./formrly-user-contacts-search.component.scss']
})
export class FormrlyUserContactsSearchComponent implements OnInit {
  @Input('filterContacts') public filterContacts;

  /** Indicates if the Skip Step link should be hidden */
  @Input() public hideSkipStep = false; 
  
  private userIdSubject = new Subject<string>();
  private searchCriteria:string = '';

  private testSubject = new Subject<any>();
  public searchActivated: boolean = false;
  public searchPage: boolean = true;
  public myContactList;
  public cancelClick = 0;

  @Output() notifyMember: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyCancelClick: EventEmitter<any> = new EventEmitter<any>();
  

  tab = 'search'; //search,add
  public members: FormrlyUsersContact;
  public test;
  readonly blogPosts$: Observable<any> = this.userIdSubject.pipe(
    liveSearch(email => this.formrlyUserService.FetchMyContactsList(email))
    // debounceTime(250),
    // distinctUntilChanged(),
    // switchMap(
    //   email => this.formrlyUserService.FetchMyContactsList(email))
  );
  public blog$: Observable<FormrlyUsersContact> = this.testSubject.pipe(
    liveSearch(email => this.formrlyUserService.FetchMyContactsList(email)
    )
    // debounceTime(250),
    // distinctUntilChanged(),
    // switchMap(
    //   email => this.formrlyUserService.FetchMyContactsList(email))
  );


  constructor(
    private formrlyUserService: FormrlyUsersService,

  ) {
  }
  isNotFilteredMember(memberId) {
    if (this.filterContacts && this.filterContacts[0]) {
      for (let i = 0; i < this.filterContacts.length; i++) {
        if (memberId === this.filterContacts[i].id || memberId === this.filterContacts[i].FormrlyUserContactId) {
          return false;
        }
      }
    }
    return true;
  }
  changeTab(data){
    this.tab = data;
  }
  searchMembers(test) {
    this.test = 'test';
    console.log('serached in constructor')
    console.log(test);
    return null;
  }
  searchPosts(userId: string) {
    console.log('before search' + userId);
    console.log(userId);

    //console.log(this.userIdSubject);
    this.userIdSubject.next(userId);
    if (userId === '') {
      console.log('no userId value in search');
      this.searchActivated = true;
    }
    else {
      console.log('userId value in search');
      this.searchActivated = true;
    }

    // Save the latest search criteria
    this.searchCriteria = userId ? userId : '';

    console.log('aftersearch');
    console.log(this.userIdSubject);
    console.log(this.blogPosts$);

    //console.log(this.members[0].name);
  }

  ngOnInit() {
    this.userIdSubject.next('');
    this.searchActivated = true;
  }
  setMemberSearchResult(member) {
    console.log('setMemberSearch Result');
    console.log(member);
    this.notifyMember.emit(member);
  }
  sendCancel(){
    this.cancelClick++
    this.notifyCancelClick.emit(this.cancelClick);
  }

  getAllMyContacts(data) {
    this.myContactList = data;
  }
  getChangeTab(data){
    this.tab = 'search';
  }
  getNewContact(data){
    this.notifyMember.emit(data);
  }
}








