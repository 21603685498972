import { Component, OnInit, Input, TemplateRef, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { UserData } from '../../../data/users';
import { concatMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validator } from '@angular/forms';
import { FormrlyUsersContact } from '../../../services/http/interfaces/formrly-users-contact';
import { FilesMembers } from '../../../services/http/interfaces/files-members';
import { DocumentService } from '../../../services/http/document.service';
import { NbDialogService, NbDialogRef } from '@nebular/theme';
import { UploadService } from '../../../services/common/upload.service';
import { FormrlyUsersService } from '../../../services/http/formrly-users.service';

@Component({
  selector: 'ngx-document-add-popover',
  templateUrl: './document-add-popover.component.html',
  styleUrls: ['./document-add-popover.component.scss']
})
export class DocumentAddPopoverComponent implements OnInit {
  @Input('buttonType') public buttonType;
  @Output() notifyButtonClick: EventEmitter<any> = new EventEmitter<any>();
  click = 0;
  profile: FormGroup;

  // Indicates if a file is being uploaded
  isUploading:boolean;
  
  profileData: FormrlyUsersContact;
  contactsList: FormrlyUsersContact;
  memberResult: any;
  filesMembers: FilesMembers;
  fileMetaId;
  pdfLocation;
  templates
  selectedTemplate

  member: FormGroup;
  tabUpload = 'upload';
  tabStep = 'fileSelection';//Turn off for Testing
  // tabStep ='memberSelection';//Turn on for Testing
  memberSelection = 'searchMember';
  files;
  public file;
  protected dialogRef: NbDialogRef<any>;
  @ViewChild('popupClose') popupClose: ElementRef<HTMLElement>;
  @ViewChild('popupOpen') popupOpen: ElementRef<HTMLElement>;
  @ViewChild('buttonUpload') buttonUpload: ElementRef<HTMLElement>;


  triggerUploadClick() {
    if(!this.isUploading){
      let el: HTMLElement = this.buttonUpload.nativeElement;
      el.click();
    }
  }

  triggerFalseClick() {
    let el: HTMLElement = this.popupClose.nativeElement;
    el.click();
  }


  triggerOpenClick() {
    let el: HTMLElement = this.popupOpen.nativeElement;
    el.click();
  }

  constructor(
    private userSvc: UserData,
    private formrlyUsersService: FormrlyUsersService,
    private documentService: DocumentService,
    private fb: FormBuilder,
    private router: Router,
    private dialogService: NbDialogService,
    //protected dialogRef: NbDialogRef
    private uploadService: UploadService
  ) {
    this.profile = this.fb.group({
      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
      companyPosition: '',
    });
    this.member = this.fb.group({
      memberId: "123",
      shareLink: "123",
      order: 0,
      FormrlyUsersContactId: "",
      FileMetaId: ""
    });
  }

  ngOnInit() {
    // const dialogRef = this.dialogService.open(popupnewdoc, { context:'this is text' });
    this.isUploading = false;
  }
  open(dialog: TemplateRef<any>) {
    console.log(dialog);

    //this.tabStep ='memberSelection';//testing
    //this.tabStep ='fileSelection';//live
    this.dialogService.open(dialog, { context: 'this is some additional data' })
      .onClose.subscribe(name => {
        if (this.tabStep === 'memberSelection') { 
          this.router.navigate(['/blank/'], {skipLocationChange:true}).then(
            () => { this.router.navigate(['/editor/', this.fileMetaId]); }
          );
        }
      });
    if (this.tabStep === 'fileSelection') {
      this.triggerUploadClick()
    }


  }
  openPopup(tab) {

    this.tabStep = tab;//live
    this.isUploading= false;
    //this.tabStep = 'memberSelection'
    this.triggerOpenClick();
  }

  openConfirmationPopup(tab) {

    this.tabStep = tab;//live
    this.click++;
    this.notifyButtonClick.emit(this.click);
    //this.tabStep = 'memberSelection'
    this.triggerOpenClick();
  }

  closeDialog(dialog) {
    this.dialogService.open(dialog, { context: 'this is some additional data' }).close;
  }
  changeTabUpload(tabName) {
    this.tabUpload = tabName;
  }

  selectFile(event) {

    this.file = event.target.files[0];
    let isImage = (this.file.type.match(/image\/*/) !== null);
    let isAllowedDocumentType = (this.file.type === 'application/pdf');

    if(isImage || isAllowedDocumentType){
      console.log(this.file);
      //this.dialogRef.close();
      this.file.status = 'draft';
      //this.closeDialog('popupnewdoc');
      this.uploadFile(event.target.files[0]);
    }else{
      alert('Please choose an image or a PDF file.');
    }
  }

  uploadFile(file) {
    this.isUploading = true;
    var userId, uploadFileName, displayFileName, userMeta;
    displayFileName = file.name;
    this.userSvc.getCurrentUser().pipe(
      concatMap(
        userData => {
          userId = userData.id
          userMeta = userData
          console.log(userData);
          console.log(userId);
          uploadFileName = this.uploadService.setUploadName(file.name, 'file', userId);
          console.log(uploadFileName);
          return this.userSvc.uploadFileNew(file, uploadFileName, '')
        }))
      .pipe(
        concatMap(fileMeta => {
          return this.userSvc.saveFileMetaNew(fileMeta.result.files.file[0], displayFileName);
        })
      ).subscribe(
        fileMeta => {
          this.files = { name: fileMeta.name, location: fileMeta.providerResponse.location, id: fileMeta.id, status: 'draft' };
          console.log('Update and File Meta Success Add');
          console.log(fileMeta.id);

          //this.profile.controls['firstName'].setValue(this.profileData.name.first);

          this.member.controls['FileMetaId'].setValue(this.files.name);
          this.pdfLocation = this.files.location;
          this.fileMetaId = this.files.id;
          this.addMeToDocument(fileMeta.id)
        },
        err => console.error(err)
      );
  }
  addMeToDocument(fileMetaId) {
    var fileMember = {
      memberId: "123",
      shareLink: "123",
      order: 0,
      access: 'member',
      FormrlyUsersContactId: "",
      FileMetaId: fileMetaId
    }
    this.formrlyUsersService.getMyContactInfo().pipe(
      concatMap(
        userData => {
          var myContactId = userData.id
          fileMember.FormrlyUsersContactId = userData.id;
          //fileMember.controls['FileMetaId'].setValue(fileMetaId); 

          console.log('The File Member before adding new member')
          console.log(fileMember);
          return this.documentService.addDocumentMember(fileMetaId, fileMember)
        })).subscribe(
          result => {
            this.isUploading = false;
            console.log('successful update');
            console.log(this.tabStep);
            if (this.tabStep === "fileSelection") {
              this.tabStep = 'memberSelection';
            }
            else if (this.tabStep === "templateMemberSelection") {
              this.addDocumentMember(result.FileMetaId,this.member.value);
            }
          },
          err => console.error(err)
        );
  }

  getTemplates(data) {
    this.templates = data;
  }
  selectTemplate(template) {
    template.templateId = template.id;
    template.id = null;
    this.selectedTemplate = template;
    console.log(this.selectedTemplate)
    this.tabStep = 'templateMemberSelection';


  }

  getTemplateMember(data) {
    this.memberResult = data;
    this.member.controls['FormrlyUsersContactId'].setValue(data.id);
    console.log(this.member.value)
    this.createDocumentFromTemplate(this.selectedTemplate);
  }
  createDocumentFromTemplate(template) {
    console.log(template);
    this.userSvc.saveFileMetaNew(template, template.displayName).subscribe(
      fileMeta => {
        console.log('document created Success'); 
        this.addMeToDocument(fileMeta.id);
      },
      err => console.log(err)
    )
  }

  getContacts(data: FormrlyUsersContact) {
    console.log("getContacts");
    console.log(data);
    this.contactsList = data;
    console.log('contactList tirgger');
  }
  getMember(data: any) {
    console.log("This is data");
    console.log(data);
    //this.contactsList = data;
    this.memberResult = data;
    this.member.controls['FormrlyUsersContactId'].setValue(data.id);
    console.log('memberResult tirgger');
    console.log(this.member.value);
    this.addDocumentMember(this.fileMetaId, this.member.value);

  }
  getCancelClick(data) {
    this.triggerFalseClick()
  }
  addDocumentMember(fileMetaId, fileMember) {
    console.log(fileMember);
    //fileMember.FileMetaId = fileMetaId;
    fileMember.memberId = '123';
    fileMember.shareLink = '123';
    fileMember.order = 1;
    fileMember.access= 'member';
    //fileMember.controls['FileMetaId'].setValue(fileMetaId); 

    console.log('The File Member before adding new member')
    console.log(fileMember);
    this.documentService.addDocumentMember(fileMetaId, fileMember).subscribe(
      result => {
        console.log('successful update');
        //this.dialogRef.close();
        // this.ref.close()
        this.triggerFalseClick();
        //this.router.navigate(['/view', { location: pdfLocation, id: fileMetaId }]);
        this.router.navigate(['/blank/'], {skipLocationChange:true}).then(
          () => { this.router.navigate(['/editor/', fileMetaId]); }
        );
      },
      err => console.error(err)
    );

  }
  skipThisStep() {
    this.closeDialog('popupnewdoc');
    this.router.navigate(['/blank/'], {skipLocationChange:true}).then(
      () => { this.router.navigate(['/editor/', this.fileMetaId]); }
    );
  }
  close() {
    //this.dialogRef.close();
  }

  //   open(dialog: TemplateRef<any>) {
  //   this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  // }

}