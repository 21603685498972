import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { NbAuthService } from '@nebular/auth';
import { RecentUsers, UserData, User } from '../data/users';
import { environment } from '../../../environments/environment';
import * as path from 'path';


@Injectable()
export class UserService extends UserData {

	private time: Date = new Date;
	private api: string = environment.rest;

	private users = {};
	// 	nick: { name: 'Jack Williams', displayName: '', tagline: '', email: '', phoneNum: '', picture: 'assets/images/nick.png' },
	// 	eva: { name: 'Eva Moor', displayName: '', tagline: '', email: '', phoneNum: '', picture: 'assets/images/eva.png' },
	// 	jack: { name: 'Jack Williams', displayName: '', tagline: '', email: '', phoneNum: '', picture: 'assets/images/jack.png' },
	// 	lee: { name: 'Lee Wong', displayName: '', tagline: '', email: '', phoneNum: '', picture: 'assets/images/lee.png' },
	// 	alan: { name: 'Alan Thompson', displayName: '', tagline: '', email: '', phoneNum: '', picture: 'assets/images/alan.png' },
	// 	kate: { name: 'Kate Martinez', displayName: '', tagline: '', email: '', phoneNum: '', picture: 'assets/images/kate.png' },
	// };
	private types = {};
	// 	mobile: 'mobile',
	// 	home: 'home',
	// 	work: 'work',
	// };
	private recentUsers: RecentUsers[]  = [];
	// 	{ user: this.users.alan, type: this.types.home, time: this.time.setHours(21, 12)},
	// 	{ user: this.users.eva, type: this.types.home, time: this.time.setHours(17, 45)},
	// 	{ user: this.users.nick, type: this.types.mobile, time: this.time.setHours(5, 29)},
	// 	{ user: this.users.lee, type: this.types.mobile, time: this.time.setHours(11, 24)},
	// 	{ user: this.users.jack, type: this.types.mobile, time: this.time.setHours(10, 45)},
	// 	{ user: this.users.kate, type: this.types.work, time: this.time.setHours(9, 42)},
	// 	{ user: this.users.kate, type: this.types.work, time: this.time.setHours(9, 31)},
	// 	{ user: this.users.jack, type: this.types.mobile, time: this.time.setHours(8, 0)},
	// ];

	constructor(
		private http: HttpClient,
	) {
		super();
	}

	getCurrentUser(): Observable<any> {
		return this.http.get(`${this.api}/FormrlyUsers/me`);
	}

	updateCurrentUser(user: User): Observable<any> {
		return this.http.patch(`${this.api}/FormrlyUsers/me`, user);
	}

	getUsers(): Observable<any> {
		return observableOf(this.users);
	}

	getContacts(): Observable<any> {
		// return observableOf(this.contacts);
		return this.http.get(`${this.api}/FormrlyUsers/me/Contacts`);
	}

	getFileMetas(): Observable<any> {
		return this.http.get(`${this.api}/FormrlyUsers/me/FileMeta?filter={"where": {"folderId": { "exists": true }}}`);
	}

	getFileMeta(id): Observable<any> {
		return this.http.get(`${this.api}/FormrlyUsers/me/FileMeta/${id}`);
	}

	saveFileMeta(fileMeta: any): Observable<any> {
		fileMeta.status = 'draft';
		return this.http.post(`${this.api}/FormrlyUsers/me/FileMeta`, fileMeta);
	}
	saveFileMetaNew(fileMeta: any, displayFileName): Observable<any> {
		fileMeta.status = 'draft';
		fileMeta.displayName = displayFileName;
		return this.http.post(`${this.api}/FormrlyUsers/me/FileMeta`, fileMeta);
	}

	deleteFileMeta(fileMetaId: any): Observable<any> {
		return this.http.delete(`${this.api}/FormrlyUsers/me/FileMeta/${fileMetaId}`);
	}

	updateFileMeta(fileMetaId: any, fileMeta: any): Observable<any> {
		return this.http.put(`${this.api}/FormrlyUsers/me/FileMeta/${fileMetaId}`, fileMeta);
	}

	uploadAvatar(image, path?): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('file', image, `${path}${image.name}`);
		//return this.http.post(`${this.api}/Uploads/formrly-public/upload`, formData);//temp
		return this.http.post(`${this.api}/Uploads/rk-formrly-public/upload`, formData);
	}

	uploadThumbnail(image, fileName): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('file', image, `${fileName}`);
		//return this.http.post(`${this.api}/Uploads/formrly-public/upload`, formData);//temp
		return this.http.post(`${this.api}/Uploads/rk-formrly-public/upload`, formData);
	}

	uploadFile(file, path?): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('file', file, `${path}${file.name}`);
		//return this.http.post(`${this.api}/Uploads/formrly-public/upload`, formData);//temp
		return this.http.post(`${this.api}/Uploads/rk-formrly-public/upload`, formData);

	}
	uploadFileNew(file,  fileName, path?): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('file', file, `${path}${fileName}`);
		//return this.http.post(`${this.api}/Uploads/formrly-public/upload`, formData);//temp
		return this.http.post(`${this.api}/Uploads/rk-formrly-public/upload`, formData);
	}

	downloadFile(fileName: string): Observable<any> {
		//return this.http.get(`${this.api}/Uploads/formrly-public/download/${fileName}`, { responseType: 'blob' });//temp
		return this.http.get(`${this.api}/Uploads/rk-formrly-public/download/${fileName}`, { responseType: 'blob' });
	}

	deleteFile(fileName: string): Observable<any> {
		//return this.http.delete(`${this.api}/Uploads/formrly-public/files/${fileName}`, { observe: 'response' });//temp
		return this.http.delete(`${this.api}/Uploads/rk-formrly-public/files/${fileName}`, { observe: 'response' });
	}

	moveFile(location: string, destination: string): Observable<any> {
		// TODO
		// download from s3 (location)
		// delete from s3 (location)
		// upload to s3 (destination)
		return observableOf();
	}
	
	renameFile(location: string, newName: string): Observable<any> {
		// unix-like: move file to same location with new filename
		const destination = `${path.dirname(location)}/${newName}`;
		return this.moveFile(location, destination);
	}

}
