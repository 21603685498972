import { Observable } from 'rxjs';

export interface User {
	name: string;
	displayName: string;
	tagline: string;
	email: string;
	phoneNum: string;
	picture: string;
}

export interface Contacts {
	user: User;
	type: string;
}

export interface RecentUsers extends Contacts {
	time: number;
}

export abstract class UserData {
	abstract getCurrentUser(): Observable<any>;
	abstract updateCurrentUser(user: User): Observable<any>;
	abstract getUsers(): Observable<User[]>;
	abstract getContacts(): Observable<any>;
	// abstract getRecentUsers(): Observable<RecentUsers[]>;
	abstract getFileMetas(): Observable<any>;
	abstract saveFileMeta(fileMeta: any): Observable<any>;
	abstract saveFileMetaNew(fileMeta: any, displayFileName): Observable<any>;
	abstract deleteFileMeta(fileMetaId: any): Observable<any>;
	abstract uploadAvatar(image: any, path: string): Observable<any>;
	abstract uploadThumbnail(image: any, fileName: string): Observable<any>;
	abstract uploadFile(file: any, path: string): Observable<any>;
	abstract uploadFileNew(file: any, fileName:string, path: string): Observable<any>;
	abstract downloadFile(fileName: string): Observable<any>;
	abstract deleteFile(fileName: string): Observable<any>;
	abstract moveFile(location: string, destination: string): Observable<any>;
	abstract renameFile(location: string, newName: string): Observable<any>;
	abstract updateFileMeta(fileMetaId: any, fileMeta: any): Observable<any>;
	// abstract createFolder(folderName: string): Observable<any>;
	// abstract deleteFolder(folderName: string): Observable<any>;
	// abstract moveFolder(folderName: string, destination: string): Observable<any>;
	// abstract renameFolder(folderName: string): Observable<any>;
}
