import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormrlyUsersContacts} from '../../../../services/http/interfaces/formrly-users-contacts';
import { DocumentService } from '../../../../services/http/document.service';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'ngx-documents-members-get',
  templateUrl: './documents-members-get.component.html',
  styleUrls: ['./documents-members-get.component.scss']
})
export class DocumentsMembersGetComponent implements OnInit {
  // public documentMembers:FormrlyUsersContacts;
  public documentMembers =[]
  public fileMembers;
  //public documentId = '5de18d8bfa5e2c78428271ac';
  @Input('parentData') public documentId;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  
  constructor(
    private documentService:DocumentService
  ) { }

  ngOnInit() {
    //this.getDocumentMembers(this.documentId);
    this.getDocumentMembersOrdered(this.documentId);
   
  }
  // getDocumentMembers(fileMetaId){
	// 	this.documentService.getDocumentMembers(fileMetaId).subscribe(
  //     fileMeta => {
  //       this.documentMembers = fileMeta;
  //       //console.log('The document mbers' + fileMeta[0].name.first);
  //       console.log(this.documentMembers);
  //       //console.log (this.documentMembers[0].name.first);
  //       this.notify.emit(this.documentMembers);
  //       console.log('The docstatus' + this.documentMembers);
  //     },
  //       err => console.error(err)
  //   );
  // }
  getDocumentMembersOrdered(fileMetaId){
		this.documentService.getDocumentMembersOrdered(fileMetaId).pipe(
      concatMap(fileMembers => {
        console.log('getDocumentMembersOrdered');
        //console.log(fileMembers);
        this.fileMembers = fileMembers
        return this.documentService.getDocumentMembers(fileMetaId);
      })
    ).subscribe(
      members => {
        console.log('getDocumentMembers FormrlyUsersContact')
        //console.log(members);
        var membersOrdered = this.fileMembers.sort((a, b) => (a.order > b.order) ? 1 : -1)
        //console.log(membersOrdered);
        const memberData = members;


        for(let i =0; i< membersOrdered.length;i++){
          for(let ii=0; ii<membersOrdered.length; ii++){
            if(membersOrdered[i].FormrlyUsersContactId === members[ii].id){
              //console.log(membersOrdered[i]);
              //console.log(members[ii]);
              this.documentMembers[i] = members[ii]
              ii = membersOrdered.length
            }
          }
        }
        //console.log(this.documentMembers);
        this.notify.emit(this.documentMembers);
      },
        err => console.error(err)
    );
  }
}
