import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-tutorials-get',
  templateUrl: './tutorials-get.component.html',
  styleUrls: ['./tutorials-get.component.scss']
})
export class TutorialsGetComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
