import { Component, OnInit, Input, ViewChild, ElementRef, TemplateRef, AfterViewInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { NbSidebarService, NbDialogService } from '@nebular/theme';
import { concatMap } from 'rxjs/operators';

import { Documents } from '../../services/http/interfaces/documents';
import { DocumentService } from '../../services/http/document.service';
import { UserData } from '../../data/users';
import { WebviewerFinalService } from './webviewer-final.service'
declare const WebViewer: any;


@Component({
  selector: 'ngx-webviewer-final',
  templateUrl: './webviewer-final.component.html',
  styleUrls: ['./webviewer-final.component.scss']
})
export class WebviewerFinalComponent implements OnInit, AfterViewInit {
  @Input('documentData') public documentData: Documents;
  @Input('documentMembers') public documentMembers: any;
  @Input('webviewerMode') public webviewerMode: string;

  @ViewChild('viewer') viewer: ElementRef;
  //  @ViewChild('myThumbnail') canvasRef: ElementRef;
  //  @ViewChild('dataContainer') dataContainer: ElementRef;
  @ViewChild('savedSignature') public savedSignatureCanvas: ElementRef;

  @ViewChild('popupClose') popupClose: ElementRef<HTMLElement>;
  @ViewChild('popupOpen') popupOpen: ElementRef<HTMLElement>;
  @ViewChild('popupnewdoc') popupnewdoc: TemplateRef<HTMLElement>;
  @ViewChild('selectedAnnotation') selectedAnnotation: ElementRef<HTMLElement>;
  @ViewChild('sendAnnotationsToChild') sendAnnotationsToChild: ElementRef<HTMLElement>;

  public savedSignature;
  public testInitial = false;
  //public initialTool: any;
  //public imageURL;
  licenseKey = 'Formrly LLC(formrly.co):OEM:Formrly::B+:AMS(20200529):12A5746204B7C80A3360B13AC9A2737860612FE3A926CDEAC56C051BD7B500CE64AAB6F5C7';

  wvInstance: any;
  wvTools: any;
  wvDocViewer: any;
  public pdfNetInitialize: any;

  public selectedAnnotaionFE;
  public xfdfStringLoader;
  public annotationsList;
  public annotationsSend;

  public dropPoint;
  public dragElementId;
  public fieldProperties;


  public currentPage;
  public pageCount;
  //Temporary trigger
  changeFieldProperties(data) {
    this.fieldProperties = data;
    console.log('changeFieldProperties');
    console.log(data);
  }
  changeAnnotations(annotationsList) {
    this.annotationsSend = annotationsList;
    console.log('changeannotations');
    console.log(annotationsList);
  }

  triggerFalseClick() {
    let el: HTMLElement = this.popupClose.nativeElement;
    el.click();
  }

  triggerFalseOpenClick() {
    let el: HTMLElement = this.popupOpen.nativeElement;
    el.click();
  }
  triggerFalseSelectAnnotation() {
    let el: HTMLElement = this.selectedAnnotation.nativeElement;
    el.click();
  }
  triggerFSendAnnotationsToChild() {
    let el: HTMLElement = this.sendAnnotationsToChild.nativeElement;
    el.click();
  }



  open(dialog: TemplateRef<any>) {
    //console.log(dialog);
    this.dialogService.open(dialog, { context: 'this is some additional data' });
  }


  constructor(
    public breakpointObserver: BreakpointObserver,
    private sidebarService: NbSidebarService,
    private documentService: DocumentService,
    private userSvc: UserData,
    private dialogService: NbDialogService,
    private webViewerService: WebviewerFinalService

  ) { }

  //Outputs

  changeFieldMember(member) {
    //this.dragElementId = element;
    console.log('Change Field Member');
    console.log(member);
    this.updateField(this.selectedAnnotaionFE, 'member', member);
  }
  changeFieldRequired(required: boolean) {
    //this.dragElementId = element;
    console.log('Change Field required');
    console.log(required);
  }
  getAssignFields(data) {
    console.log('getAssigned Fields received in webviewerFinal component')
    console.log(data);
    this.assignFields(data)
    // console.log(this.annotationsSend);
    // const unassignedFields = this.annotationsSend.filter((e) => {
    //   return (e.author===null || e.author==="" || e.author==="unnasigned");
    // });
    // console.log(unassignedFields);
    // for(let i = 0; i< unassignedFields.length; i++){
    //   this.updateField(unassignedFields[i].id,'member',data);
    //   console.log('updateField');
    // }
  }
  getSendDocument(data) {
    console.log('getSendDocument received in webviewerFinal component')
    console.log(data);
  }

  //Drag Drop Basics
  onDragElement(element) {
    this.dragElementId = element;
    console.log('On Drag Output Received');
    console.log(element);
  }
  allowDrop(ev) {
    ev.preventDefault();
  }
  drop2(event) {
    console.log('drop2');
  }

  ngOnInit() {
  }
  async ngAfterViewInit() {

    const location = 'https://formrly-public.s3.amazonaws.com/webviewer-demo-annotated.pdf';
    //this.documentData.id = '5dfc405e8a0a8003a6fbabc0';
    const viewerElement = document.getElementById('viewer');


    await this.webViewerService.loadInstance(this.viewer.nativeElement, this.documentData.providerResponse.location);
    const instance = this.webViewerService.instance;
    //const instance = this.webViewerService.getInstance();
    //monsef test
    // WebViewer({
    //   path: '../../../../lib',
    //   //initialDoc: `https://cors-anywhere.herokuapp.com/${location}`,
    //   initialDoc: `https://cors-anywhere.herokuapp.com/${this.documentData.providerResponse.location}`,
    //   fullAPI: true,
    //   licenseKey: this.licenseKey,
    // },
    //   this.viewer.nativeElement).then(instance => {
    const { PDFNet, docViewer } = instance;

    this.wvInstance = instance;
    this.wvDocViewer = instance;
    const { Annotations } = this.wvInstance;
    this.wvTools = instance.Tools;
    const tools = this.wvTools;
    const annotManager = instance.annotManager;
    const { Tools } = instance;
    annotManager.setCurrentUser('pcsmoak1@gmail.coom');
    annotManager.setIsAdminUser(true);
    //docViewer.setReadyOnly(true);

    console.log('current user annotmanager');
    console.log(annotManager.getCurrentUser());


    let lastSignatureClicked;

    const createSignHereElement = Annotations.SignatureWidgetAnnotation.prototype.createSignHereElement;

    Annotations.SignatureWidgetAnnotation.prototype.createSignHereElement = function () {
      const signatureWidget = this;

      // signHereElement is the default one with dark blue background
      const signHereElement = createSignHereElement.apply(this, arguments);

      signHereElement.addEventListener('click', () => {
        lastSignatureClicked = signatureWidget.fieldName;
        //signatureWidget.fieldName ="fullSignature.UserID.FieldID"
        console.log(lastSignatureClicked);
        console.log('click sign element');
      });

      return signHereElement;
    }


    const originalMouseLeftUp = Tools.SignatureCreateTool.prototype.mouseLeftUp;

    // console.log('test version');
    // console.log(originalMouseLeftUp);
    // console.log(tools.SignatureCreateTool);


    Tools.SignatureCreateTool.prototype.mouseLeftUp = function (e) {
      console.log('mouse left up');
      console.log(this);
      console.log(e);

      const sigs = this.getSavedSignatures();

      if (sigs && sigs.length > 0) {
        console.log('saved then don\'t show modal', sigs);
        openSavedSignatureCustomModal.call(this, sigs, e, docViewer);
        originalMouseLeftUp.call(this, e);
        this.setSignature(sigs[0]);
        this.addSignature();
        console.log('added signature');

      } else {
        console.log('no saved then show modal');
        return originalMouseLeftUp.call(this, e);
      }
    };

    //Event on Signature Saved
    docViewer.getTool('AnnotationCreateSignature').on('signatureSaved', function (annotation) {
      console.log('savedSignature')
      console.log(annotation);

      console.log(annotation[0].getPaths());



      //console.log(annotation[0].Yb.x - annotation[0].Yb.);
      console.log(annotation[0].Yb.y);
      console.log(annotation[0].bt);
      console.log(annotation[0].$s);
      console.log(annotation[0]);
      //console.log(annotation[0].draw(this.savedSignatureCanvas, annotation[0].draw(this.canvas,docViewer.getDocument().getPageMatrix())));
      console.log(this);
    });

    docViewer.getTool('AnnotationCreateSignature').on('annotationAdded', function (annotation) {
      //console.log()
      console.log(annotation);

      const signatureWidgetAnnots = annotManager.getAnnotationsList().filter(
        annot => annot instanceof Annotations.SignatureWidgetAnnotation
      );

      console.log(signatureWidgetAnnots);
      signatureWidgetAnnots.forEach(annotSign => {
        console.log(annotSign);
        //annotSign.isSignedDigitally().then(isSigned => {
        if (annotSign.annot) {
          // if this signature field is signed initially
          console.log('isSigned')
        } else {
          console.log('notSigned')
          // if this signature field is not signed initially
        }
        //})
      })
      flatten();
      flatten2();

    });

    const flatten2 = async () => {
      //  document.getFileData('flaten':true)

    }
    const flatten = async () => {
      //global instance call
      //const instance = WebViewer.getInstance();
      const webDoc = docViewer.getDocument();
      const doc = await webDoc.getPDFDoc();
      doc.initSecurityHandler();
      doc.lock();
      await docViewer.getTool('AnnotationCreateFreeHand').complete();
      const xfdfString = await annotManager.exportAnnotations({ links: false, widgets: false });
      const fdfDoc = await PDFNet.FDFDoc.createFromXFDF(xfdfString);
      await doc.fdfMerge(fdfDoc);
      await doc.flattenAnnotations();

      const docbuf = await doc.saveMemoryBuffer(1);
      instance.loadDocument(new Blob([docbuf], {
        type: 'application/pdf'
      }), {
          filename: 'myfile_flattened.pdf'
        });
      const blob = new Blob([docbuf], {
        type: 'application/pdf'
      });
      console.log('flatten file');
      console.log(blob);
      // this.uploadThumbnail(blob, 'flattenfile.pdf', 1);

    }

    //??? I am not sure what this is
    var DOCUMENT_ID = 'webviewer-demo-1';
    instance.iframeWindow.document.body.ondragover = function (e) {
      e.preventDefault();
      console.log('on dragover');
      return false;
    };

    //var dropPoint = {};
    instance.iframeWindow.document.body.ondrop = (e) => {
      console.log('on drop');
      var scrollElement = instance.docViewer.getScrollViewElement();
      console.log(scrollElement.scrollLeft);
      console.log(scrollElement.scrollTop);
      var scrollLeft = scrollElement.scrollLeft || 0;
      var scrollTop = scrollElement.scrollTop || 0;
      //963 screen width fit screen
      console.log(e);

      console.log('pdfCoordinates');
      console.log(docViewer.getDocument().getPDFCoordinates(0, e.pageX, e.pageY));

      //this.dropPoint = { x: e.pageX + scrollLeft - 13, y: e.pageY + scrollTop - 55 };
      this.dropPoint = { x: e.offsetX, y: e.offsetY };
      console.log(this.dropPoint);
      e.preventDefault();
      //offnow
      this.dropFreeTextAnnotation(this.dropPoint, this.dragElementId);
      return false;
    };


    annotManager.on('annotationChanged', async function (annots, action, options) {
      // ...??? what is the difference between the following variables
      console.log('annotationChanged');
      console.log(annots);
      console.log(action);
      console.log(options);

      const xfdfString = await annotManager.exportAnnotations();
      console.log(xfdfString);
      updateAnnotations(xfdfString);

    });
    annotManager.on('annotationDrawn', async function (page) {
      // ...??? what is the difference between the following variables
      console.log('annotationDrawn');
      // console.log(annots);
      // console.log(action);
      // console.log(options);

      const xfdfString = await annotManager.exportAnnotations();
      //console.log(xfdfString);
      //updateAnnotations(xfdfString);

    });

    const updateAnnotations = (xfdfString) => {
      console.log(xfdfString);
      this.putAnnotations(this.documentData.id, this.documentData, xfdfString)
    }


    //  Selects Only One Annotation, if 2 are selected it deslects the first selected item
    const selectedAnnots = annotManager.getSelectedAnnotations();
    if (selectedAnnots.length > 1) {
      console.log(selectedAnnots);
      const firstSelectedAnnot = selectedAnnots[0];
      annotManager.deselectAnnotation(firstSelectedAnnot);

    }
    annotManager.on('annotationSelected', (annotations, action) => {
      console.log('annotationSelected');
      if (annotations) {
        console.log('action selected');
        console.log(annotations[0].wt);

        //??off now
        //this.onAnnotationUpdated(annotations[0].wt);
        this.selectedAnnotaionFE = annotations[0].wt;
        // this.checkFreeTextAnnotation(action[0]);
        this.fieldProperties = annotations[0].wt;
        console.log(this.fieldProperties);
        this.triggerFalseSelectAnnotation();
        //            this.changeFieldProperties(this.selectedAnnotaionFE)
      }
      else if (!annotations) {
        console.log('action deselected');
        this.fieldProperties = null;
        this.triggerFalseSelectAnnotation();
        //this.changeFieldProperties('');
        //Close Annotation Panel
      }
    });
    // now you can access APIs through this.webviewer.getInstance()
    //instance.openElement('notesPanel');
    instance.disableElements(['contextMenuPopup', 'annotationCommentButton', 'annotationStyleEditButton']);

    instance.annotationPopup.add({
      type: 'actionButton',
      title: 'Duplicate',
      img: '/assets/images/icons/pdftron-icons/copy-outline.svg',
      onClick: () => {
        console.log(annotManager.getAnnotationById(this.selectedAnnotaionFE));
        const originalAnnotation = annotManager.getAnnotationById(this.selectedAnnotaionFE);
        const newAnnotationPosition = {
          x: originalAnnotation.getX(),
          y: originalAnnotation.getY() + originalAnnotation.getHeight() + 10
        }
        const originalAnnotationType = originalAnnotation.getContents();
        console.log(newAnnotationPosition)
        this.dropFreeTextAnnotation(newAnnotationPosition, originalAnnotationType)
        // console.log(newAnnotationY)

        //alert('Hello world Settings!');

      },
    });
    instance.annotationPopup.add({
      type: 'actionButton',
      title: 'Settings',
      img: '/assets/images/icons/pdftron-icons/settings-2-outline.svg',
      onClick: () => {
        //alert('Hello world Settings Panel should open!');
        this.fieldProperties = null;
        this.triggerFalseSelectAnnotation()
        this.fieldProperties = this.selectedAnnotaionFE;
        this.triggerFalseSelectAnnotation()

      },
    });

    instance.enableElements(['actionButton']);

    //this.triangleTool(instance, tools, Annotations );

    //Event Listner Listen Page Change Add Visit Details
    this.viewer.nativeElement.addEventListener('pageChanged', (e) => {
      const [pageNumber] = e.detail + 1;
      console.log(`Current page is ${pageNumber}`);
      //console.log(annotManager.get)
      //update visit
      //this.updateVisits();
      this.currentPage = pageNumber;
    });

    instance.docViewer.on('annotationsLoaded', () => {
      console.log('annotations loaded');

    });


    instance.docViewer.on('documentLoaded', async (f) => {
      console.log('documentLoaded');
      this.pdfNetInitialize = await PDFNet.initialize();
      const doc = docViewer.getDocument();
      const pdfDoc = await doc.getPDFDoc();

      console.log(this.wvInstance)
      console.log(this.wvTools)
      console.log(tools)

      console.log('Import Annotations');
      console.log(this.documentData.annotations);
      await annotManager.importAnnotations(this.documentData.annotations);

      //get page count, and Add thumbnails if first load
      console.log('Get Page Count' + docViewer.getPageCount());
      this.pageCount = docViewer.getPageCount();
      if (!this.documentData.pageCount || !this.documentData.pageThumbnails) {
        console.log('first Load, setting page count and generating thumbnails');
        this.documentData.pageCount = this.pageCount;
        await this.getThumbnails(doc, this.pageCount);
      }
      else {
        console.log('return visit');
      }

      // Ensure that we have our first page.
      await pdfDoc.requirePage(1);

      // Run our main function using 'runWithCleanup'
      await PDFNet.runWithCleanup(async () => await main(pdfDoc));

      // Refresh the cache with the newly updated document
      docViewer.refreshAll();
      // Update viewer with new document
      docViewer.updateView();
      // you can access docViewer object for low-level APIs
      docViewer.getDocument().refreshTextData();

      console.log('export annotations right after');
      console.log(annotManager.getAnnotationsList());
      var annotations = annotManager.getAnnotationsList();
      getAnnotationList(annotations, annotManager);
    })
    console.log('last of webviewer');
    //monsef test
    //   //end of this.viewer.nativeElement.then(instance =>{
    // });
    async function main(pdfDoc) {
      //alert("Hello WebViewer!");
    }
    const openSavedSignatureCustomModal = (sigs, e, docViewer) => {
      console.log('openSavedSignatureCustomModal');
      console.log(sigs);
      console.log(e);
      this.savedSignature = sigs;
      //this.savedSignature.draw(this.savedSignatureCanvas,);
      //var savedSignaturePageMatrix = docViewer.getDocument().getPageMatrix();
      console.log('savedSignaturePageMatrix');
      //console.log(savedSignaturePageMatrix);


      // this.savedSignature.getPaths()
      this.triggerFalseOpenClick();
      //this.savedSignature[0].draw(this.savedSignatureCanvas,savedSignaturePageMatrix);

      const canvasEl: HTMLCanvasElement = this.savedSignatureCanvas.nativeElement;
      var cx = canvasEl.getContext('2d');
      cx.beginPath;

      var savedSignaturePaths = sigs[0].getPaths();

      function getYs() {
        return savedSignaturePaths[0].map(d => d.y);
      }
      function getMinY() {
        return Math.min(...getYs());
      }


      function getXs() {
        return savedSignaturePaths[0].map(d => d.x);
      }
      function getMinX() {
        return Math.min(...getXs());
      }


      function getMaxY() {
        return Math.max(...getYs());
      }
      console.log('getMinY')
      console.log(getYs());
      console.log(getMinY());

      console.log(savedSignaturePaths[0]);
      var count = savedSignaturePaths[0].length;
      var xFactor;
      var yFactor;
      for (let i = 0; i < count; i++) {
        if (i === 0) {
          //??? How to get these dynamically.  
          //It is the position of the original sign widget where a signature is inserted.
          xFactor = 397;
          yFactor = 350;
          cx.moveTo(savedSignaturePaths[0][i].x - getMinX(), savedSignaturePaths[0][i].y - getMinY());
        }
        else {
          cx.lineTo(savedSignaturePaths[0][i].x - getMinX(), savedSignaturePaths[0][i].y - getMinY());
          console.log(savedSignaturePaths[0][i].x)
        }
      }
      cx.stroke();
    }
    const getAnnotationList = (annotations, annotManager) => {

      console.log(annotations);
      var annotationsPrep = [];
      for (let a = 0; a < annotations.length; a++) {
        annotationsPrep[a] = {
          id: '',
          type: '',
          author: '',
          required: true
        };
        console.log()
        annotationsPrep[a].id = annotations[a].wt;
        annotationsPrep[a].type = annotations[a].getContents();
        annotationsPrep[a].author = annotManager.getDisplayAuthor(annotations[a]);
        annotationsPrep[a].required = true;


        console.log('test');
        console.log(annotations[a].wt);
        console.log(annotations[a].getContents());
        console.log(annotations[a].Subject);
        console.log(annotManager.getDisplayAuthor(annotations[a]))
        if (annotations[a].Subject == 'Widget') {
          console.log(annotations[a].getField());
          //Set to Field to Read only
          //annotationTest[a].fieldFlags.set('ReadOnly', true);

        }
      }
      this.annotationsList = annotationsPrep;
      console.log('annotation List');
      console.log(this.annotationsList);
      this.triggerFSendAnnotationsToChild()
      console.log('annotation List');
      //console.log()
      //trigger button click to transfer data to child
    }

  }
  //Initial Load Functions
  async getThumbnails(doc, pageCount) {
    console.log('get Thumbbnails function' + pageCount)
    var i;
    var thumbNail = []
    var $thumbNail = []
    this.documentData.pageThumbnails = [{}];
    for (i = 0; i < pageCount; i++) {
      console.log('looop' + i);
      thumbNail[i] = i
      //$thumbNail[i] = await doc.loadThumbnailAsync(i, async (result) => await this.displayThumbnail(result, i));
      //await console.log(thumbNail[i]);
      console.log('test await' + i)
      this.documentData.pageThumbnails[i] = {};
    }
    for (const item of thumbNail) {
      console.log('async loop test for')
      await doc.loadThumbnailAsync(item, async (result) => this.displayThumbnail(result, item));
      console.log('async loop test for end')
    }

  }

  async displayThumbnail(result, iCount) {
    await console.log('Async Display Thumbnail' + iCount);
    console.log(result);
    //this.dataContainer.nativeElement.innerHTML = result;

    //  this.canvasRef.nativeElement = result;

    //Set Thumbnail Canvas to a URL for displaying Test
    var test2 = result.toDataURL();
    console.log(test2);
    console.log('end test 2');
    //this.imageURL = test2;

    //create a blob and store in database
    result.toBlob(data => this.blober(data, iCount));
  }

  blober(data, iCount) {
    console.log('blobber');
    console.log(data);
    var fileName;
    fileName = this.documentData.id + '-TH-' + iCount + '.png';
    this.uploadThumbnail(data, fileName, iCount);

  }
  //S3 Conector function
  uploadThumbnail(file, fileName, iCount) {
    console.log('upload Avatar' + iCount);
    console.log(file);
    console.log(fileName);
    console.log(iCount);
    this.userSvc.uploadThumbnail(file, fileName)
      .pipe(
        concatMap(fileMeta => {
          console.log(iCount + 'concatmap');
          this.documentData.pageThumbnails[iCount] = fileMeta.result.files.file[0];
          console.log(this.documentData);
          console.log('before the return');
          //return 't';
          return this.documentService.UpdateDocumentDetails(this.documentData.id, this.documentData);
        })
      )
      .subscribe(
        fileMeta => {
          console.log(fileMeta);
          console.log('Upload thumbnail ' + iCount + ' success');

        },
        err => console.error(err)
      );
  }

  //Left Sidebar Functions
  changeCurrentPage(pageNumber) {
    //this.wvInstance.docViewer.setCurrentPage(pageNumber);
    this.wvInstance.docViewer.displayPageLocation(pageNumber, 0, 0, false)
  }



  //Right Sidebar Functions
  buildAnnotationList(annotations, annotManager) {
    console.log(annotations);
    var annotationsPrep = [];
    for (let a = 0; a < annotations.length; a++) {
      annotationsPrep[a] = {
        id: '',
        type: '',
        author: '',
        required: true
      };
      console.log()
      annotationsPrep[a].id = annotations[a].wt;
      annotationsPrep[a].type = annotations[a].getContents();
      annotationsPrep[a].author = annotManager.getDisplayAuthor(annotations[a]);
      annotationsPrep[a].required = true;


      console.log('test');
      console.log(annotations[a].wt);
      console.log(annotations[a].getContents());
      console.log(annotations[a].Subject);
      console.log(annotManager.getDisplayAuthor(annotations[a]))
      if (annotations[a].Subject == 'Widget') {
        console.log(annotations[a].getField());
        //Set to Field to Read only
        //annotationTest[a].fieldFlags.set('ReadOnly', true);

      }
    }
    this.annotationsList = annotationsPrep;
    console.log('annotation List');
    console.log(this.annotationsList);
    this.triggerFSendAnnotationsToChild()
    console.log('annotation List');
    //console.log()
    //trigger button click to transfer data to child

  }

  dropFreeTextAnnotation(point, dragElementId) {
    const docViewer = this.wvInstance.docViewer;
    const annotManager = this.wvInstance.annotManager;

    const { Annotations } = this.wvInstance;
    const freeTexttwo = new Annotations.FreeTextAnnotation();
    freeTexttwo.PageNumber = docViewer.getCurrentPage();
    freeTexttwo.X = point.x;
    freeTexttwo.Y = point.y;
    freeTexttwo.Width = 150;
    freeTexttwo.userName = 'Test User Name';
    freeTexttwo.Height = 50;
    freeTexttwo.setPadding(new Annotations.Rect(0, 0, 0, 0));
    freeTexttwo.setContents(dragElementId);
    freeTexttwo.FillColor = new Annotations.Color(0, 255, 255);
    freeTexttwo.FontSize = '16pt';
    freeTexttwo.Author = 'unassigned';
    //freeTexttwo.NoMove = false;
    //freeTexttwo.NoResize = true;
    freeTexttwo.LockedContents = true
    console.log('get current user of annotmanager');
    console.log(annotManager.getCurrentUser());

    freeTexttwo.FillColor = new Annotations.Color(255, 255, 255);
    annotManager.addAnnotation(freeTexttwo);
    annotManager.redrawAnnotation(freeTexttwo);
    console.log('Get Annotations List Test')
    //console.log(annotManager.getAnnotationsList());
    //console.log(annotManager.getSelectedAnnotations());
    //console.log(annotManager.getAnnotationById('f2b7a74b-20ff-41c4-4287-a6f109283dfb'));
    this.buildAnnotationList(annotManager.getAnnotationsList(), annotManager)

  }

  async updateField(annotationId, settingName, dataChange) {
    const annotManager = this.wvInstance.annotManager;
    const getAnnots = annotManager.getAnnotationById(annotationId);
    const docViewer = this.wvInstance;
    //getAnnots.authorId
    console.log(getAnnots.Author);
    if (settingName === 'member') {
      getAnnots.Author = dataChange;
    }
    console.log(getAnnots.Id);
    console.log(getAnnots.Author);
    annotManager.redrawAnnotation(getAnnots);
    console.log('redraw complete');
    const xfdfString = await annotManager.exportAnnotations();
    console.log(xfdfString);
    this.putAnnotations(this.documentData.id, this.documentData, xfdfString);

    const annotations = annotManager.getAnnotationsList();
    this.buildAnnotationList(annotations, annotManager);
    //  this.triggerFSendAnnotationsToChild();
    //  this.triggerFalseSelectAnnotation();

    // annotManager.deselectAnnotation(getAnnots);
    // annotManager.selectAnnotation(getAnnots);



  }
  async assignFields(author) {
    const annotManager = this.wvInstance.annotManager;
    const docViewer = this.wvInstance.docViewer;
    console.log(this.annotationsSend);
    const unassignedFields = this.annotationsSend.filter((e) => {
      return (e.author === null || e.author === "" || e.author === "unassigned");
    });
    console.log(unassignedFields);
    for (let i = 0; i < unassignedFields.length; i++) {
      //this.updateField(unassignedFields[i].id,'member',data);
      console.log('updateField');
      const getAnnots = annotManager.getAnnotationById(unassignedFields[i].id);
      getAnnots.Author = author;
      annotManager.redrawAnnotation(getAnnots)
      console.log('redraw complete');

    }
    const xfdfString = await annotManager.exportAnnotations();
    console.log(xfdfString);
    this.putAnnotations(this.documentData.id, this.documentData, xfdfString);

    const annotations = annotManager.getAnnotationsList();
    this.buildAnnotationList(annotations, annotManager);
    this.triggerFSendAnnotationsToChild();



    //getAnnots.authorId
    // console.log(getAnnots.Author);
    // // if (settingName === 'member'){
    //   
    // // }
    // console.log(getAnnots.Id);
    // console.log(getAnnots.Author);
    // annotManager.redrawAnnotation(getAnnots);
    // console.log('redraw complete');
    // const xfdfString = await annotManager.exportAnnotations();
    // console.log(xfdfString);
    // this.putAnnotations(this.documentData.id, this.documentData, xfdfString);

    // const annotations = annotManager.getAnnotationsList();
    // this.buildAnnotationList(annotations, annotManager);
    //  this.triggerFSendAnnotationsToChild();
    //  this.triggerFalseSelectAnnotation();

    // annotManager.deselectAnnotation(getAnnots);
    // annotManager.selectAnnotation(getAnnots);



  }


  //write to databse function
  putAnnotations(fileMetaId, documentData, xfdfString) {
    console.log('patchAnnotations');
    documentData.annotations = xfdfString;
    console.log(documentData.annotations);
    console.log(documentData);
    console.log('patchAnnotations End');
    this.documentService.UpdateDocumentDetails(fileMetaId, documentData).subscribe(
      fileMeta => {
        console.log('Update Success');
        console.log(fileMeta);
      },
      err => console.log(err)
    )

  }



}
