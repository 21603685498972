import { Component, OnInit, ElementRef, TemplateRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NbDialogService, NbDialogRef } from '@nebular/theme';
import { WebviewerEditorService } from '../../webviewer-final/webviewer-editor/webviewer-editor.service'
import { FormBuilder, FormGroup, Validator, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormrlyUsersService } from '../../../services/http/formrly-users.service';
import { concatMap } from 'rxjs/operators';
import { MembersVisitService } from '../../../services/members/members-visit.service';
import { DocumentContextService } from '../../document/document-context.service';

@Component({
  selector: 'ngx-pdf-send-popovers',
  templateUrl: './pdf-send-popovers.component.html',
  styleUrls: ['./pdf-send-popovers.component.scss']
})
export class PdfSendPopoversComponent implements OnInit {
  @Input('parentDataTemplateFields') public templateFields;
  //@Input() public documentMembers;
  //@Input() public fieldCounts;
  //  @Output() notifyAssignFields: EventEmitter<any> = new EventEmitter<any>();
  //  @Output() notifySendDocument: EventEmitter<any> = new EventEmitter<any>();
  documentSendDetails;
  formDisplayName: any;
  documentDisplayName = {
    displayName: ''
  };

  // <select> element to assign fields to a member
  @ViewChild('selectMemberDropdown') selectMemberDropdown;

  // Stores the extension of the current document
  private documentExtension:string;

  // Indicates if the process to send the document is running
  isSendDocumentRunning:boolean = false;

  formSendMessage: any;
  documentSendMessage = {
    messageSubject: '',
    emailBody: ''
  };
  newDocumentDisplayName;
  profileData: any;
  constructor(
    private dialogService: NbDialogService,
    private wvEditorService: WebviewerEditorService,
    private fb: FormBuilder,
    private router: Router,
    private formrlyUsersService: FormrlyUsersService,
    private membersService: MembersVisitService, 
    private docContextService: DocumentContextService
  ) { }
  allowDrop(ev) {
    ev.preventDefault();
  }

  drop(event) {
    event.preventDefault();
    console.log('drop');
    //    this.dropFreeTextAnnotation(this.dropPoint);
  }
  drag(event) {
    console.log('dragstart');
    event.preventDefault;
    //  this.dragEndCheck = false;
  }

  drop2(event) {
    console.log('drop2');
  }



  @ViewChild('popupDocumentNameClose') popupDocumentNameClose: ElementRef<HTMLElement>;
  @ViewChild('popupDocumentName') popupDocumentName: TemplateRef<HTMLElement>;
  @ViewChild('popupSendDocument') popupSendDocument: TemplateRef<HTMLElement>;
  @ViewChild('popupSendConfirmation') popupSendConfirmation: TemplateRef<HTMLElement>;

  @ViewChild('popupSendDocumentClose') popupSendDocumentClose: ElementRef<HTMLElement>;
  @ViewChild('popupSendDocumentClose') popupSendDocumentCloseTemp: TemplateRef<HTMLElement>;

  @ViewChild('popupSendConfirmationClose') popupSendConfirmationClose: ElementRef<HTMLElement>;
  //@ViewChild('popupSendConfirmationClose') popupSendConfirmationClose: ElementRef<HTMLElement>;

  checkTemplateFields() {
    if (this.templateFields && this.templateFields[0] && !this.isTemplateFieldsCompleted(this.templateFields)) {
      console.log('error Message: Unassigned Fields Exist');
      alert('Cannot send document. You must fill in all Template Fields before sending.')
    }
    else {
      let fileNameParts = this.getFileNameParts(this.documentData.displayName);
      this.documentDisplayName.displayName = fileNameParts.name;
      this.documentExtension = fileNameParts.extension;
      this.documentSendMessage.messageSubject = 'ACTION NEEDED: ' + this.documentDisplayName.displayName;
      this.open(this.popupDocumentName)
    }
  }
  triggerFalseConfirmationClick() {
    let el: HTMLElement = this.popupSendConfirmationClose.nativeElement;
    el.click();
  }

  triggerFalseClick() {
    let el: HTMLElement = this.popupSendDocumentClose.nativeElement;
    el.click();
  }
  triggerFalseClickDocumentName(popup) {
    console.log('popup=' + popup);
    if (popup === "document name") {
      let el: HTMLElement = this.popupDocumentNameClose.nativeElement;
      el.click();
      this.open(this.popupSendDocument);
    }
    else if (popup === "send document") {
      let el: HTMLElement = this.popupSendDocumentClose.nativeElement;
      el.click();
      console.log(this.popupSendConfirmation);
      //this.open(this.popupSendConfirmation);
      console.log('lets see what happened');
    }
    else if (popup === "send confirmation") {
      let el: HTMLElement = this.popupSendConfirmationClose.nativeElement;
      el.click();
    }
    else {
      let el: HTMLElement = this.popupDocumentNameClose.nativeElement;
      el.click();
    }
    console.log('open second popup');
    //   this.openSendDocument();
    console.log('open second popup');


  }
  open(dialog: TemplateRef<any>) {
    console.log(dialog);
    this.dialogService.open(dialog, { context: 'this is some additional data' });
    //this.triggerFalseClickDocumentName(popup);
  }

  openSendDocument() {
    //console.log(dialog);
    this.dialogService.open(this.popupDocumentName, { context: 'this is some additional data' });
    //this.triggerFalseClickDocumentName(popup);
  }
  documentMembers
  fieldCountList
  xfdfString
  documentData
  annotationList
  myContactInfo
  ngOnInit() {
    this.wvEditorService.currentDocumentMembers.subscribe(message => this.documentMembers = message)
    this.wvEditorService.currentFieldCountList.subscribe(message => this.fieldCountList = message)
    this.wvEditorService.currentXfdfString.subscribe(message => this.xfdfString = message)
    this.wvEditorService.currentDocumentData.subscribe(message => this.documentData = message)
    this.wvEditorService.currentAnnotationList.subscribe(message => this.annotationList = message)
    this.wvEditorService.currentMyContactInfo.subscribe(message => this.myContactInfo = message)

    // console.log(this.documentDisplayName)
    //form
    // this.formDisplayName = new FormGroup({
    //   'displayName': new FormControl(this.documentDisplayName.displayName, [
    //     Validators.required,
    //     Validators.minLength(4)

    //   ])
    // });
  }
  //form
  //get name (){ return this.formDisplayName.get('displayName')}
  onSelectAssignFieldsToMember(value) {
    console.log('onSelectAssignFieldsToMember')
    console.log(value);
    if (value) {
      let result = confirm("Please confirm you would like to assign all unassigned fields to: " + this.documentMembers[value].name.first + " " + this.documentMembers[value].name.last + "?");
      //this.notifyAssignFields.emit(this.documentMembers[value].email)
      if(result){
        this.wvEditorService.assignFields(this.documentMembers[value].email, this.annotationList, this.documentMembers);
      }else{
        this.selectMemberDropdown.nativeElement.selectedIndex = 0;
      }
      // this.wvEditorService.putAnnotations(this.documentData.id, this.documentData, this.xfdfString)
    }
  }
  changeDocumentDisplayName(documentDisplayName) {
    console.log(documentDisplayName);
    if (!documentDisplayName) {
      alert('Cannot send document. You must give the document a file name.')
      this.documentDisplayName.displayName = this.getFileNameParts(this.documentData.displayName).name; 
      return false;
    }
    if (this.documentDisplayName.displayName != this.documentData.displayName){
      var documentData = this.documentData;
      documentData.displayName = documentDisplayName + this.documentExtension;
      this.documentSendMessage.messageSubject = 'Action Needed: ' + this.documentDisplayName.displayName;
      this.wvEditorService.changeDocumentData(documentData);
      console.log(this.documentData);
    }
    this.triggerFalseClickDocumentName('document name');
  }
  sendDocument(documentSendMessage) {
    var documentUrl = 'http://formrly.adawebexperts.com/signer/' + this.documentData.id + '/';
    //var documentUrl = 'http://localhost:4200/signer/' + this.documentData.id + '/' ;
    console.log(documentSendMessage)
    documentSendMessage.senderFirstName = this.documentMembers[0].name.first;
    documentSendMessage.senderLastName = this.documentMembers[0].name.last;
    documentSendMessage.documentUrl = documentUrl;
    //???
    console.log(documentSendMessage)
    console.log(this.templateFields);
    if (this.templateFields && this.templateFields[0] && !this.isTemplateFieldsCompleted(this.templateFields)) {
      console.log('error Message: Unassigned Fields Exist');
      alert('Cannot send document. You must fill in all Template Fields before sending.')
    }
    //Temporary Fix to prevent documents that have random included fields
    else if (this.fieldCountList[0].required + this.fieldCountList[0].optional > 0) {
    //else if (this.fieldCountList[0].required > 0) {
      console.log('error Message: Unassigned Fields Exist');
      alert('Cannot send document. You must assign all fields to a member before sending.')
    }
    else if (!this.annotationList[0]) {
      console.log('error Message: Unassigned Fields Exist');
      alert('Cannot send document. You must have at least one field for each member.')
    }
    else if (this.checkAllMembersHaveFields() === false) {
      console.log('error Message: Members without fields');
      alert('Cannot send document. You must assign at least one field to each member before sending.')
      console.log(this.annotationList);
    }
    else if (!this.documentSendMessage.messageSubject) {
      alert('Cannot send document. You must have an email subject.')
      this.documentSendMessage.messageSubject = 'Action Needed: ' + this.documentData.displayName
    }
    else {
      this.isSendDocumentRunning = true; // Send Document tasks just started
      console.log('sendDocument Success No Unassigned Fields');
      console.log(documentSendMessage.messageSubject);
      console.log(this.annotationList);
      var documentData = this.documentData;
      documentData.email = {
        subject: documentSendMessage.messageSubject,
        body: documentSendMessage.emailBody,
        senderFirstName: this.myContactInfo.name.first,
        senderLastName: this.myContactInfo.name.last,
      };

      documentSendMessage.emailSubject = documentSendMessage.messageSubject;
      //check who will receive email
      if (this.wvEditorService.IsMyAnnotationsComplete(this.annotationList) && this.documentMembers.length === 1){
        console.log('completed');
        documentData.status = 'completed';
        this.wvEditorService.changeDocumentData(documentData);
        documentSendMessage.userFirstName = this.documentMembers[0].name.first;
        documentSendMessage.userEmail = this.documentMembers[0].email;
        
        //???
        documentSendMessage.documentUrl= documentSendMessage.documentUrl + this.documentMembers[0].id;
        this.documentSendDetails = documentSendMessage;
        ///????WRite the signature record.
        ///???
        this.saveMySignatureRecord(this.xfdfString, this.documentData)
      }
      
      else if (this.wvEditorService.IsMyAnnotationsComplete(this.annotationList) && this.documentMembers.length > 1) {
        console.log('ready for Members');
        documentData.status = 'awaiting';
        this.wvEditorService.changeDocumentData(documentData);
        documentSendMessage.userFirstName = this.documentMembers[1].name.first;
        documentSendMessage.userEmail = this.documentMembers[1].email;
        
        //???
        documentSendMessage.documentUrl= documentSendMessage.documentUrl + this.documentMembers[1].id;
        this.documentSendDetails = documentSendMessage;
        ///????WRite the signature record.
        ///???
        this.saveMySignatureRecord(this.xfdfString, this.documentData)
      }
      else {
        console.log('ready for owner');
        documentData.status = 'ready';
        this.wvEditorService.changeDocumentData(documentData);
        documentSendMessage.documentUrl= documentSendMessage.documentUrl + this.documentMembers[0].id;
        documentSendMessage.userFirstName = this.documentMembers[0].name.first;

        documentSendMessage.userEmail = this.documentMembers[0].email;
        ///!!!RK!!! Email Change
        //documentSendMessage.userEmail = 'raj.narayan.sitar@gmail.com,rafael@rafaelcardero.com';
        this.wvEditorService.putAnnotations(this.documentData.id, this.documentData, this.xfdfString);
        this.triggerFalseClickDocumentName('send document');
        this.greet(documentSendMessage);
        this.router.navigate(['/documents/', documentData.status]);
        this.router.navigate(['/editor/sent']);
      }
      console.log(documentSendMessage)


      console.log('this.documentData before save')
      console.log(this.documentData);
    }
  }
  checkAllMembersHaveFields() {
    //i=0 is unassigned this.fieldCountList[i]
    console.log(this.fieldCountList);
    for (let i = 1; i < this.fieldCountList.length; i++) {
      if (this.fieldCountList[i].required + this.fieldCountList[i].optional === 0) {
        return false;
      }
    }
    return true;
  }
  isTemplateFieldsCompleted(templateFields) {
    for (let i = 0; i < templateFields.length; i++) {
      console.log(templateFields[i].contents)
      if (templateFields[i].contents === '') {
        return false;
      }
    }
    return true;
  }
  greet(data) {
    var dataSample =
    {
      userFirstName: "John",
      userEmail: "raj.narayan.sitar@gmail.com",
      senderFirstName: "Jon",
      senderLastName: "Jon",
      documentUrl: "https://rkanswers.com",
      emailBody: '',
      messageSubject: ''
    }

    this.formrlyUsersService.greet(data).subscribe(greet => {
      console.log('greet Success');
      console.log(greet);
    },
      err => console.log(err)
    )
  }

  saveMySignatureRecord(xfdfString, documentData) {
    console.log('saveAnnotations');
    console.log(documentData)
    //console.log(xfdfString)
    var status
    var signerCount
    var fileMembersData = {
      annotationData: xfdfString,
      timeStamp: '2020-03-03T16:00:06.468Z',
      ipAddress: '123',
      formrlyUsersContactId: this.myContactInfo.id,//???
      filesMemberId: '',
      fileMetaId: this.documentData.id
    };
    documentData.annotations = xfdfString;



    // this.myContactInfo.id ='5a4282f0-58d8-11ea-836e-45a4d5708ac3'

    this.membersService.getDocumentMembersAndDataCount(this.myContactInfo.id, documentData.id).pipe(
      concatMap(counts => {
        console.log('getCounts');
        console.log(counts);
        signerCount = counts[1].count + 1;
        if (counts[0].count === counts[1].count + 1) {
          status = 'completed';
        }
        else if (counts[0].count > counts[1].count + 1) {
          status = 'awaiting';
        }
        return this.membersService.getMyDocumentMembers(this.myContactInfo.id, documentData.id);
      })
    ).pipe(
      concatMap(documentMembers => {
        console.log('getMyDocumentMembers');
        console.log(documentMembers);
        console.log(documentMembers[0].id)
        console.log(fileMembersData.filesMemberId)
        fileMembersData.filesMemberId = documentMembers[0].id;
        console.log(fileMembersData);
        return this.membersService.createFileMembersData(this.myContactInfo.id, documentData.id, fileMembersData);
      })
    ).pipe(
      concatMap(fileMembersData => {
        console.log('Create fileMembersData Success')
        documentData.status = status;
        documentData.annotations = xfdfString;
        console.log(documentData)
        //return this.documentService.UpdateDocumentDetails(documentData.id, documentData);
        return this.membersService.updateDocumentDetails(this.myContactInfo.id, documentData.id, documentData);
      })
    ).pipe(
      concatMap(documentMeta => {
        console.log('Update FileMeta Success')
        console.log(signerCount)
        return this.membersService.getNextDocumentMember(this.myContactInfo.id, documentData.id, signerCount);
      })
    ).subscribe(nextDocumentMember => {
      console.log('getNextDocumentMember')
      console.log(nextDocumentMember);
      if (status != 'completed') {
        var nextMemberId = nextDocumentMember[0].FormrlyUsersContactId;
        this.getNextDocumentMember(documentData, nextMemberId)
      }
      else {
        //this.flattenPdf()
        //alert('You have finished signing and document is completed.  Awaiting Email Functionality.  Flattening and download about to start.');
        this.triggerFalseClick();
        this.docContextService.sendDownloadLinkToAllMembers(this.documentData, false);
        this.router.navigate(['/signer/download/' + this.documentData.id + '/' + this.myContactInfo.id]);
      }
    });
  }
  getNextDocumentMember(documentData, nextMemberId) {
    var nextMemberContact;
    this.membersService.getNextDocumentMemberContact(this.myContactInfo.id, documentData.id, nextMemberId).subscribe(
      nextMemberContactMeta => {
        console.log('getNextMemberContact');
        nextMemberContact = nextMemberContactMeta;
        if (documentData.status === 'awaiting') {
          this.greet(this.documentSendDetails);
          //this.triggerFalseClick()
          this.triggerFalseClickDocumentName('send document');
          //alert('Document Has Been Signed.  I will Setup a redirect to confirmation page later.');
          //this.router.navigate(['/documents/', documentData.status]);
          this.router.navigate(['/editor/sent']);
          //???redirect
        }
        else {
          //this.triggerFalseClick()
          alert('I am not sure what happened.');
        }
      },
      err => console.log(err)
    )
    //????signerCount

  }
  receiveButtonClick(data){
    console.log('receiveButtonClick')
    this.triggerFalseConfirmationClick()

  }

  /**
   * Returns the parts of a filename.
   * @param fileName The filename.
   */
  private getFileNameParts(fileName:string){

    fileName = fileName.trim();
    let sepIndex = fileName.lastIndexOf('.');
    if(sepIndex < 0){
      return {name: fileName, extension: ''};
    }

    return {
      name: fileName.substr(0, sepIndex), 
      extension: fileName.substr(sepIndex)
    };
  }
}
