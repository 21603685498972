import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minuteSeconds'
})
export class MinuteSecondsPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const minutes: number = Math.floor(value / 60);
    const seconds: number = value - minutes * 60;
    if(seconds < 10){
      return minutes + ':0' + seconds;
    }
    else{
      return minutes + ':' + seconds;
    }
      
  }

}