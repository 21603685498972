import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-my-contact-info-update',
  templateUrl: './my-contact-info-update.component.html',
  styleUrls: ['./my-contact-info-update.component.scss']
})
export class MyContactInfoUpdateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
