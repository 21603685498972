import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TemplateService } from '../../../../services/http/template.service';
import { Templates } from '../../../../services/http/interfaces/templates';
@Component({
  selector: 'ngx-my-template-get',
  templateUrl: './my-template-get.component.html',
  styleUrls: ['./my-template-get.component.scss']
})
export class MyTemplateGetComponent implements OnInit {
  public document: Templates;
  @Input('parentData') public templateId;
  @Output() notify: EventEmitter<Templates> = new EventEmitter<Templates>();

  constructor(
    private templateService: TemplateService
  ) { }

  ngOnInit() {
    this.getMyTemplateDetails(this.templateId);
  }
  getMyTemplateDetails(id) {
    this.templateService.getMyTemplateDetails(id).subscribe(
      templateMeta => {
        this.document = templateMeta;
        console.log('getDocumentDetails Success')
        //console.log(fileMeta);
        this.notify.emit(this.document);
      },
      err => console.error(err)
    );
  }
}
