import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
	private api: string = environment.rest;

  constructor(
    private http: HttpClient

  ) { }
  getMyTemplates(status): Observable<any> {
    if(status){
      return this.http.get(`${this.api}/FormrlyUsers/me/FormrlyUsersTemplates?filter={"where":{"status":"${status}"}}`);
    }
    else{
      return this.http.get(`${this.api}/FormrlyUsers/me/FormrlyUsersTemplates?filter={"where":{"or":[{"status":"active"},{"status":"draft"}]}}`);
    }
		
  }
  getSystemTemplates(): Observable<any> {
      return this.http.get(`${this.api}/SystemTemplates`);
  }
  getMySystemTemplates(): Observable<any> {
    return this.http.get(`${this.api}/FormrlyUsers/me/SystemTemplates`);
  }

  getMyTemplateDetails(id): Observable<any> {
		return this.http.get(`${this.api}/FormrlyUsers/me/FormrlyUsersTemplates/${id}`);
  }
  
  updateMyTemplateDetail(id, templateMeta: any): Observable<any>{
    return this.http.put(`${this.api}/FormrlyUsers/me/FormrlyUsersTemplates/${id}`, templateMeta);
  }
  saveTemplateMeta(fileMeta: any): Observable<any> {
		return this.http.post(`${this.api}/FormrlyUsers/me/FormrlyUsersTemplates/`, fileMeta);
  }
  saveTemplateMetaNew(fileMeta: any, displayName): Observable<any> {
    fileMeta.displayName = displayName;
    fileMeta.status = 'draft';
		return this.http.post(`${this.api}/FormrlyUsers/me/FormrlyUsersTemplates/`, fileMeta);
  }
  deleteTemplateMeta(id: any): Observable<any> {
		return this.http.delete(`${this.api}/FormrlyUsers/me/FormrlyUsersTemplates/${id}`);
  }
  uploadFile(file, path?): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('file', file, `${path}${file.name}`);
    //return this.http.post(`${this.api}/Uploads/formrly-public/upload`, formData);//temp
    return this.http.post(`${this.api}/Uploads/rk-formrly-public/upload`, formData);
  }
  
	downloadFile(fileName: string): Observable<any> {
    //return this.http.get(`${this.api}/Uploads/formrly-public/download/${fileName}`, { responseType: 'blob' });//temp
    return this.http.get(`${this.api}/Uploads/rk-formrly-public/download/${fileName}`, { responseType: 'blob' });
  }
  
//   Get myTemplates
// Get Template Categories
// Get Templates by Category
// Add Template
// Update Template

}
