import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Folders } from '../../../services/http/interfaces/folders';
import { FolderService } from '../../../services/http/folder.service';
import { DocumentService } from '../../../services/http/document.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-my-document-change-folder',
  templateUrl: './my-document-change-folder.component.html',
  styleUrls: ['./my-document-change-folder.component.scss']
})
export class MyDocumentChangeFolderComponent implements OnInit {
  @Input('parentData') public currentFolderId;
  @Input('parentDataDocument') public document;
  @Output() notifyChangeFolder: EventEmitter<string>  = new EventEmitter<string>();
  public parentFolders: Folders;
  public childFolders: Folders;
  public currentFolderName;
  public currentParentId
  public parentRefresh = 0;
  currentFolder;

  constructor(
    private folderService: FolderService,
    private documentService: DocumentService,
    private router: Router,
  ) { }

  ngOnInit() {
  }
  getParentFolders(data: Folders) {
    console.log("This is data of parent folders");
    console.log(data);
    this.parentFolders = data;
    console.log(this.parentFolders[0]);
    console.log('getparent folders tirgger');
    console.log(this.currentFolderId);
    this.getCurrentFolderName(this.currentFolderId, this.parentFolders)
  }
  getChildFolders(data: Folders) {
    console.log("This is data of child folders");
    console.log(data);
    this.childFolders = data;
    //this.currentParentId = data.folderId;
    console.log('getdocuments tirgger');
  }

  getCurrentFolderName(currentFolderId, parentFolders) {
    this.folderService.getCurrentFolder(currentFolderId).subscribe(
      folderMeta => {
        this.currentFolder = folderMeta;
        if (folderMeta.folderId) {
          this.currentParentId = folderMeta.folderId;
          //this.notifyHasParentFolder.emit(true);
        }
        else {
          this.currentParentId = null;
          //this.notifyHasParentFolder.emit(false);
        }
        console.log("This is the folder name");
        console.log(this.currentParentId);
        console.log(folderMeta);
        //this.notifyFolderName.emit(folderMeta.folderName);
      },
      err => console.error(err)

    );
  }

  changeCurrentFolder(folderId, folderName) {
    console.log(folderId);
    // this.currentParentId = null;
     this.currentFolderId = folderId
    // this.childFolders = null;
    if (this.currentParentId === folderId){

    }
    else{
      this.currentParentId = folderId;
      this.childFolders = null;
    }
    // this.notifyFolderChange.emit(folderId);
    // this.notifyFolderName.emit(folderName);
    // this.notifyHasParentFolder.emit(false);
  }
  changeChildFolder(folderId, folderName, parentId) {
    console.log(folderId);
    this.currentParentId = parentId;
    this.currentFolderId = folderId
    // this.notifyFolderChange.emit(folderId);
    // this.notifyFolderName.emit(folderName);
    // this.notifyHasParentFolder.emit(true);
  }
  updateDocumentFolder(folderId) {
    console.log(folderId);
    var folderChange = folderId;
    if(folderId === 'close'){
      this.notifyChangeFolder.emit(folderChange);
      return false;
    }
    if(folderId===0){
      this.document.folderId = null;
      folderChange = 0
    }
    console.log(this.document);
    this.document.folderId = folderId;
    console.log(this.document);
    this.documentService.UpdateDocumentDetails(this.document.id, this.document)
      .subscribe(fileMeta => {
        console.log('updateDocumentFolder Success');
        this.router.navigate(['/my-documents/folders', folderChange]);
        this.notifyChangeFolder.emit(folderChange);
      },
      err => console.log(err)
      );
  }


}
