import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router } from '@angular/router';
import { ThemeModule } from '../../../@theme/theme.module';



import { SignWVRKRoutingModule } from './wv-signer-routing.module';
import { SignWVRKComponent } from './wv-signer.component';
import {DownloadComponent} from '../../download/download.component';
import {SharedModule } from '../../../@core/components/shared.module'
import { ViewShareComponent } from '../../view-share/view-share.component';

import { Injector, ModuleWithProviders } from '@angular/core';
import { HttpRequest } from '@angular/common/http';


@NgModule({
  declarations: [SignWVRKComponent, DownloadComponent, ViewShareComponent],
  imports: [
    CommonModule,
    SignWVRKRoutingModule,
    SharedModule,
RouterModule,
ThemeModule,
//HttpRequest
  ]
})

export class SignWVRKModule { }
//export declare function nbNoOpInterceptorFilter(req: HttpRequest<any>): boolean;