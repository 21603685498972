import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, TemplateRef, HostListener } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { WebviewerSignerService } from './webviewer-signer.service';
import { WvMemberSignatureService } from '../wv-signature/wv-member-signature.service'
import { MembersVisitService } from '../../../services/members/members-visit.service';
import { VisitsAddService } from '../../webviewer/visits-add.service';
import { FileMemberVisits } from '../../../services/http/interfaces/file-members-visits';
import { WvSignGuideComponent } from '../wv-sign-guide/wv-sign-guide.component';

//import { WvSignatureService } from '../wv-signature/wv-member-signature.service';
@Component({
  selector: 'ngx-webviewer-signer',
  templateUrl: './webviewer-signer.component.html',
  styleUrls: ['./webviewer-signer.component.scss']
})
export class WebviewerSignerComponent implements OnInit, AfterViewInit {
  @ViewChild('viewer') viewer: ElementRef;
  @ViewChild('savedSignature') public savedSignatureCanvas: ElementRef;
  @ViewChild('popupOpen') popupOpen: ElementRef<HTMLElement>;
  @ViewChild('popupClose') popupClose: ElementRef<HTMLElement>;
  @ViewChild('popupnewdoc') popupnewdoc: TemplateRef<HTMLElement>;
  @ViewChild('falseButton') falseButton: ElementRef<HTMLElement>;
  @ViewChild('popupUpdateField') popupUpdateField: TemplateRef<HTMLElement>;
  @ViewChild('openPopupUpdateField') openPopupUpdateField: ElementRef<HTMLElement>;
  @ViewChild('wvSignGuide') wvSignGuide:WvSignGuideComponent;

  pdfNetInitialize

  public documentData;
  public myContactInfo;
  myAnnotationList;
  isComplete
  wvInstance
  wvTools;
  wvDocViewer
  eValue
  selectedSignatureAnnotaion
  selectedSignatureAnnotationField

  lastSignatureClicked
  wvNewMouseLeftUp
  wvOriginalMouseLeftUp
  signToolTipTitle

  savedSignatureFullPublic
  savedSignatureInitialPublic
  mouseLeftUpThis
  clikedAddSavedAnnotation
  currentSignatureEvent
  savedSignaturePath

  currentFieldType 

  currentPage=1;
  annotationsList

  public timestamp;
  public startTime = 0
  public endTime;
  public totalTime;
  public fileMembersVisit: FileMemberVisits = {
    memberVisitorId: '',
    id: null,
    formrlyUserId: '',
    formrlyUsersContactId: '',
    filesMemberId: '',
    fileMetaId: '',
    visitData: [{}],
    timestamp: null
  };
  signatureWidget
  // Indicates if a standard field is being updated
  isUpdatingStandardField:boolean = false; 

  constructor(
    private dialogService: NbDialogService,
    private wvSignerService: WebviewerSignerService,
    private signatureService: WvMemberSignatureService,
    private memberVisitsService: MembersVisitService,
    private visitsAddService: VisitsAddService,


  ) {
    this.timestamp = this.visitsAddService.getTimestamp();
    this.startTime = this.visitsAddService.getTime();
   }

   /**
   * Handles 'KeyUp' events.
   * @param event Data about the event.
   */
  @HostListener('window:keyup', ['$event'])
  onKeyUp(event:KeyboardEvent){
    if(!this.isUpdatingStandardField){
      this.wvSignGuide.onKeyUp(event);
    }
  }

  open(dialog: TemplateRef<any>) {
    //console.log(dialog);
    this.dialogService.open(dialog, { context: 'this is some additional data' })
      .onClose.subscribe(name => this.checkCloseDialog(dialog)
      );
    console.log('test')
  }
  openUpdateField(dialog: TemplateRef<any>) {
    //console.log(dialog);
    this.isUpdatingStandardField = true;
    this.updateFieldData = {};
    this.dialogService.open(dialog, { context: 'this is some additional data' })
    //this.formUpdateField.reset();
      //.onClose.subscribe(name => this.checkCloseDialog(dialog)
      //);
      
    console.log('test')
  }


  triggerFalseOpenClick() {
    let el: HTMLElement = this.popupOpen.nativeElement;
    el.click();
  }
  triggerFalseClick() {
    let el: HTMLElement = this.popupClose.nativeElement;
    el.click();
  }
  triggerFalseClickButton(){
    let el: HTMLElement = this.falseButton.nativeElement;
    el.click();
  }
  triggerOpenPopupUpdateFieldClick(fieldType){
    this.currentFieldType = fieldType
    let el: HTMLElement = this.openPopupUpdateField.nativeElement
    el.click();
  }

  falseClickButton(){
    console.log('this click should do something...')
  }
  checkCloseDialog(dialog) {
    if (dialog === this.popupnewdoc) {
      console.log('checkClose Dialog')
      //this.wvOriginalMouseLeftUp(this.mouseLeftUpThis, this.eValue);
      this.wvDocViewer.setToolMode('TextSelect');
    }
  }
  selectedAnnotationId
  ngOnInit() {
    this.wvSignerService.currentDocumentData.subscribe(message => this.documentData = message)
    this.signatureService.currentMyContactInfo.subscribe(message => this.myContactInfo = message)
    this.wvSignerService.currentMyAnnotationList.subscribe(message => {this.myAnnotationList = message
      this.triggerFalseClickButton();
    })
    this.wvSignerService.currentIsComplete.subscribe(message => this.isComplete = message)

    this.wvSignerService.currentSelectedAnnotationId.subscribe(message => this.selectedAnnotationId = message)
  }
  async ngAfterViewInit() {
    await this.wvSignerService.loadWebViewerSigner(this.viewer.nativeElement, this.documentData.providerResponse.location);
    const instance = this.wvSignerService.instance;
    const { PDFNet, docViewer } = instance;

    this.wvInstance = instance;
    this.wvDocViewer = instance;
    const { Annotations } = this.wvInstance;
    this.wvTools = instance.Tools;
    const tools = this.wvTools;
    const annotManager = instance.annotManager;
    const { Tools } = instance;

    annotManager.setCurrentUser(this.myContactInfo.email);
    instance.hotkeys.off();
    console.log(annotManager.getCurrentUser());
    console.log(this.myContactInfo.email);
    instance.disableElements(['annotationDeleteButton','textSignaturePanelButton','imageSignaturePanelButton']);
    instance.disableElements(['contextMenuPopup', 'annotationCommentButton', 'annotationStyleEditButton']);
    instance.disableElements([
      'linkButton',
      'textHighlightToolButton',
      'textUnderlineToolButton',
      'textSquigglyToolButton',
      'textStrikeoutToolButton',
      'panToolButton',
      'stickyToolButton',
      'highlightToolButton',
      'freeHandToolButton',
      'freeTextToolButton',
      'zoomOutButton',
      'zoomInButton',
      'annotationUngroupButton',
      'freeHandToolGroupButton',
      'textToolGroupButton','shapeToolGroupButton','miscToolGroupButton',
      'annotationGroupButton',
    ]);
    //annotManager.setCurrentUser('phil@formrly.co');

    instance.docViewer.on('keyUp', e => {
      this.onKeyUp(e);
    });

    instance.docViewer.on('documentLoaded', async (f) => {
      this.buildVisit();
      // console.log('documentLoaded');
      // await annotManager.importAnnotations(this.documentData.annotations);      
      // console.log('export annotations right after');
      // //console.log(annotManager.getAnnotationsList());
      // var annotations = annotManager.getAnnotationsList();
      // getMyAnnotationList(annotations, annotManager);
      // //changeXfdf(this.documentData.annotations);

      //this.pdfNetInitialize = await PDFNet.initialize();
      const doc = docViewer.getDocument();
      //const pdfDoc = await doc.getPDFDoc();

      console.log(this.wvInstance)
      console.log(this.wvTools)
      console.log(tools)

      console.log('Import Annotations');
      console.log(this.documentData.annotations);
      await annotManager.importAnnotations(this.documentData.annotations);

      //get page count, and Add thumbnails if first load
      console.log('Get Page Count' + docViewer.getPageCount());
      //this.pageCount = docViewer.getPageCount();
      if (!this.documentData.pageCount || !this.documentData.pageThumbnails) {
        console.log('first Load, setting page count and generating thumbnails');
        //this.documentData.pageCount = this.pageCount;
        //await this.getThumbnails(doc, this.pageCount);
      }
      else {
        console.log('return visit');
      }

      // Ensure that we have our first page.
      //await pdfDoc.requirePage(1);

      // Run our main function using 'runWithCleanup'
      //await PDFNet.runWithCleanup(async () => await main(pdfDoc));

      // Refresh the cache with the newly updated document
      //docViewer.refreshAll();
      // Update viewer with new document
      //docViewer.updateView();
      // you can access docViewer object for low-level APIs
      //docViewer.getDocument().refreshTextData();
      var annotations = annotManager.getAnnotationsList();

      getMyAnnotationList(annotations, annotManager);
      setMyAnnotationsAsNoMove(this.myAnnotationList, annotManager);
      
    });
    async function main(pdfDoc) {
      //alert("Hello WebViewer!");
    }

    let lastSignatureClicked;
    
    const renderSliderName = () => {
      var selectedAnnotation

      if (this.eValue || this.selectedAnnotationId) {
        console.log(this.selectedAnnotationId)
        if(this.selectedAnnotationId){selectedAnnotation = annotManager.getAnnotationById(this.selectedAnnotationId);}
        else{selectedAnnotation = annotManager.getAnnotationByMouseEvent(this.eValue);}
        if (selectedAnnotation) {
          //??? no custom data
          console.log(selectedAnnotation.getCustomData());
          console.log(selectedAnnotation.CustomData);
          if (selectedAnnotation.Subject === 'Signature' || selectedAnnotation.CustomData && (selectedAnnotation.CustomData.type === 'full-signature' || selectedAnnotation.CustomData.type === 'initial-signature')) {
            this.selectedSignatureAnnotaion = selectedAnnotation;
            lastSignatureClicked = selectedAnnotation.CustomData.type;
            this.lastSignatureClicked = lastSignatureClicked
            console.log('sig selected set');
          }
          else {
            this.selectedSignatureAnnotaion = null;
            lastSignatureClicked = null;
            this.lastSignatureClicked = null;
            console.log('sig selected not set')
          }
        }
      }
      else {
        const span = document.createElement("span");
        return span;
      }

      var innerHTML;
      //If Signature Field

      if (this.selectedSignatureAnnotaion && this.selectedSignatureAnnotaion.Author === annotManager.getCurrentUser()) {
        if((this.selectedSignatureAnnotaion.Subject === 'Signature') && this.selectedSignatureAnnotaion.isGrouped()){
          let children  = annotManager.getGroupAnnotations(this.selectedSignatureAnnotaion);
          let targetChild = children.find( item => { return item.Subject === 'Free text'; });
          if(targetChild.CustomData.type === 'full-signature'){
            innerHTML = 'Signature';  
          }else if(targetChild.CustomData.type === 'initial-signature'){
            innerHTML = 'Initials';  
          }
        }else if (this.selectedSignatureAnnotaion.CustomData && this.selectedSignatureAnnotaion.CustomData.type==='full-signature' ){
          console.log(this.selectedSignatureAnnotaion.CustomData.type)
          innerHTML = 'Signature';
        }
        else if(this.selectedSignatureAnnotaion.CustomData && this.selectedSignatureAnnotaion.CustomData.type==='initial-signature' ){
          innerHTML = 'Initials';
        }
          //
        

      }
      //If Date Field
      else if (selectedAnnotation && selectedAnnotation.CustomData && selectedAnnotation.CustomData.type === 'date-field' && selectedAnnotation.Author === annotManager.getCurrentUser()) {
        innerHTML = 'Date';
      }
      //If User Data Field
      else if (selectedAnnotation && selectedAnnotation.Author === annotManager.getCurrentUser() && selectedAnnotation.CustomData &&
         (selectedAnnotation.CustomData.type === 'name-field' || selectedAnnotation.CustomData.type === 'company-field' || selectedAnnotation.CustomData.type === 'title-field' || selectedAnnotation.CustomData.type === 'email-field')) {
          
          switch(selectedAnnotation.CustomData.type){
            case 'name-field':
            innerHTML = 'Name';
            break;
            case 'company-field':
            innerHTML = 'Company';
            break;
            case 'title-field':
            innerHTML = 'Title';
            break;
            case 'email-field':
            innerHTML = 'Email';
            break;
            default:
            innerHTML = 'Name';
            break;
            
          }
      }
      //If Standard Field
      else if (selectedAnnotation && selectedAnnotation.Author === annotManager.getCurrentUser() && selectedAnnotation.CustomData &&
      (selectedAnnotation.CustomData.type === 'text-field' || selectedAnnotation.CustomData.type === 'number-field' )) {

        var innerHTML;
          switch(selectedAnnotation.CustomData.type){
            case 'text-field':
            innerHTML = 'Text';
            break;
            case 'number-field':
            innerHTML = 'Number';
            break;
            default:
            innerHTML = 'text';
            break;
            
          }
      }
      else {
        const span = document.createElement("span");
        return span;
      }

      const div = document.createElement("div");
      div.innerHTML = innerHTML;
      div.className ='popupLabel';
      return div;
    };
    const renderSlider = () => {
      console.log('renderSlider')
      var selectedAnnotation
      if (this.eValue || this.selectedAnnotationId) {
        console.log(this.selectedAnnotationId)
        if(this.selectedAnnotationId){selectedAnnotation = annotManager.getAnnotationById(this.selectedAnnotationId);}
        else{selectedAnnotation = annotManager.getAnnotationByMouseEvent(this.eValue);}
        if (selectedAnnotation) {
          //??? no custom data
          console.log(selectedAnnotation.getCustomData());
          console.log(selectedAnnotation.CustomData);
          if (selectedAnnotation.Subject === 'Signature' || selectedAnnotation.CustomData && (selectedAnnotation.CustomData.type === 'full-signature' || selectedAnnotation.CustomData.type === 'initial-signature')) {
            this.selectedSignatureAnnotaion = selectedAnnotation;
            lastSignatureClicked = selectedAnnotation.CustomData.type;
            this.lastSignatureClicked = lastSignatureClicked
            console.log('sig selected set');
          }
          else {
            this.selectedSignatureAnnotaion = null;
            lastSignatureClicked = null;
            this.lastSignatureClicked = null;
            console.log('sig selected not set')
          }
        }
      }
      else {
        const span = document.createElement("span");
        return span;
      }
      //If Signature Field
      if (this.selectedSignatureAnnotaion && this.selectedSignatureAnnotaion.Author === annotManager.getCurrentUser()) {
        console.log(this.selectedSignatureAnnotaion)
        //if has annotation signature, show clear box
        if (this.selectedSignatureAnnotaion && (this.selectedSignatureAnnotaion.isGrouped() || this.selectedSignatureAnnotaion.getGroupedChildren()[0])) {
          const imageIcon = document.createElement("img");     // Create a <button> element
          imageIcon.src = '/assets/images/icons/pdf-mobile-icons/times-solid.svg';
          const div = document.createElement("div");
          div.onclick = () => {
            // Do something: Clearn Signature, ungroup and delete the proper field.???
            console.log('clicked the click me')
            //var groupedAnnotation = annotManager.getGroupAnnotations(this.selectedSignatureAnnotaion);
            var groupedAnnotation = annotManager.getSelectedAnnotations();
            var signatureToDelete;
            var signatureWidget
            console.log(groupedAnnotation);
            if (isSignatureOrWidget(groupedAnnotation[0]) === 'full-signature' || isSignatureOrWidget(groupedAnnotation[0]) === 'initial-signature') {
              signatureToDelete = groupedAnnotation[1];
              signatureWidget = groupedAnnotation[0];

              console.log('delete second')
            }
            else {
              signatureToDelete = groupedAnnotation[0]
              signatureWidget = groupedAnnotation[1];
              console.log('delete first')
            }
            this.signatureWidget = signatureWidget;
              console.log(this.signatureWidget)
            console.log(groupedAnnotation)
            annotManager.ungroupAnnotations(groupedAnnotation);
            annotManager.deleteAnnotation(signatureToDelete);

            signatureWidget.FillColor = new Annotations.Color(255, 251, 204);
            signatureWidget.StrokeThickness = 2;

            annotManager.deselectAnnotation(signatureWidget)
            //annotManager.selectAnnotation(signatureWidget)
            
            
          };
          div.className = "Button";
          div.appendChild(imageIcon);
          this.signToolTipTitle = 'Clear Signature';
          return div;
        }
        //if does not have annotation signature, show Add siganture button
        else {
          console.log(this.selectedSignatureAnnotaion);
          const imageIcon = document.createElement("img");     // Create a <button> element
          imageIcon.src = '/assets/images/icons/pdf-mobile-icons/pdf-signature.png';
          const div = document.createElement("div");
          div.onclick = (e) => {
            console.log(e);
            //if(!this.eValue){
            this.eValue = e;
            //}
            const signatureCreateTool = docViewer.getTool('AnnotationCreateSignature');
            docViewer.setToolMode(signatureCreateTool);
            this.wvNewMouseLeftUp.call(signatureCreateTool, this.eValue);
          };
          div.className = "Button";
          div.appendChild(imageIcon);
          this.signToolTipTitle = 'Sign';
          return div;
        }
      }
      //If Date Field
      else if (selectedAnnotation && selectedAnnotation.CustomData && selectedAnnotation.CustomData.type === 'date-field' && selectedAnnotation.Author === annotManager.getCurrentUser()) {
        if (selectedAnnotation.getContents() === '' || !selectedAnnotation.getContents()) {
          console.log('get contents');
          console.log(selectedAnnotation.getContents())
          const imageIcon = document.createElement("img");     // Create a <button> element
          imageIcon.src = '/assets/images/icons/pdf-mobile-icons/pdf-date.png';
          const div = document.createElement("div");
          div.onclick = () => {
            console.log('date add click')
            var today = new Date();
            var setDate = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear();
            selectedAnnotation.setContents(setDate);
            selectedAnnotation.FillColor = new Annotations.Color(255, 255, 255);
            selectedAnnotation.StrokeThickness = 0;   
            annotManager.updateAnnotation(selectedAnnotation);
            annotManager.redrawAnnotation(selectedAnnotation);
            annotManager.trigger('annotationChanged', [[selectedAnnotation], 'modify', {}]);
            annotManager.trigger('updateAnnotationPermission', selectedAnnotation);
            
          };
          div.className = "Button";
          div.appendChild(imageIcon);
          this.signToolTipTitle = 'Clear Signature';
          return div;
        }
        else {
          const imageIcon = document.createElement("img");     // Create a <button> element
          imageIcon.src = '/assets/images/icons/pdf-mobile-icons/times-solid.svg';
          const div = document.createElement("div");
          div.onclick = () => {
            console.log('date add click')
            var today = new Date();
            var setDate = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear();
            selectedAnnotation.setContents('');
            selectedAnnotation.FillColor = new Annotations.Color(255, 251, 204);
            selectedAnnotation.StrokeThickness = 2;         
            annotManager.updateAnnotation(selectedAnnotation);
            annotManager.redrawAnnotation(selectedAnnotation);
            annotManager.trigger('annotationChanged', [[selectedAnnotation], 'modify', {}]);
            annotManager.trigger('updateAnnotationPermission', selectedAnnotation);
          };
          div.className = "Button";
          div.appendChild(imageIcon);
          this.signToolTipTitle = 'Clear Signature';
          return div;
        }
      }
      //If User Data Field
      else if (selectedAnnotation && selectedAnnotation.Author === annotManager.getCurrentUser() && selectedAnnotation.CustomData &&
        (selectedAnnotation.CustomData.type === 'name-field' || selectedAnnotation.CustomData.type === 'company-field' || selectedAnnotation.CustomData.type === 'title-field' || selectedAnnotation.CustomData.type === 'email-field')) {
        //if has data, show clear button
        if (selectedAnnotation.getContents() === '' || !selectedAnnotation.getContents()) {
          console.log('get contents');
          console.log(selectedAnnotation.getContents())
          const imageIcon = document.createElement("img");     // Create a <button> element
          var imgType;
          switch(selectedAnnotation.CustomData.type){
            case 'name-field':
            imgType = 'name';
            break;
            case 'company-field':
            imgType = 'company';
            break;
            case 'title-field':
            imgType = 'title';
            break;
            case 'email-field':
            imgType = 'email';
            break;
            default:
            imgType = 'name';
            break;
            
          }
          imageIcon.src = '/assets/images/icons/pdf-mobile-icons/pdf-'+ imgType +'.png';
          
          const div = document.createElement("div");
          div.onclick = () => {
            var setContents = setFormrlyContents(selectedAnnotation.CustomData.type);

            setContents = setContents ? setContents.trim() : '';
            let launchPopup = ((imgType == 'company') || (imgType == 'title')) && !setContents.length;
            if(launchPopup){
              this.triggerOpenPopupUpdateFieldClick(imgType);
            }else{
              selectedAnnotation.setContents(setContents);
              selectedAnnotation.FillColor = new Annotations.Color(255, 255, 255);
              selectedAnnotation.StrokeThickness = 0;   
              annotManager.updateAnnotation(selectedAnnotation);
              annotManager.redrawAnnotation(selectedAnnotation);
              annotManager.trigger('annotationChanged', [[selectedAnnotation], 'modify', {}]);
              annotManager.trigger('updateAnnotationPermission', selectedAnnotation);
            }
          };
          div.className = "Button";
          div.appendChild(imageIcon);
          this.signToolTipTitle = 'Clear Signature';
          return div;
        }
        //If has no data, show add button
        else {
          const imageIcon = document.createElement("img");     // Create a <button> element
          imageIcon.src = '/assets/images/icons/pdf-mobile-icons/times-solid.svg';
          const div = document.createElement("div");
          div.onclick = () => {
            console.log('date add click')
            selectedAnnotation.setContents('');
            selectedAnnotation.FillColor = new Annotations.Color(255, 251, 204);
            selectedAnnotation.StrokeThickness = 2;  
            annotManager.updateAnnotation(selectedAnnotation);
            annotManager.redrawAnnotation(selectedAnnotation);
            annotManager.trigger('annotationChanged', [[selectedAnnotation], 'modify', {}]);
            annotManager.trigger('updateAnnotationPermission', selectedAnnotation);
          };
          div.className = "Button";
          div.appendChild(imageIcon);
          this.signToolTipTitle = 'Clear Signature';
          return div;
        }
      }
      //If Standard Field
      else if (selectedAnnotation && selectedAnnotation.Author === annotManager.getCurrentUser() && selectedAnnotation.CustomData &&
      (selectedAnnotation.CustomData.type === 'text-field' || selectedAnnotation.CustomData.type === 'number-field' )) {
        //If Has No Data Show Add Button
        if (selectedAnnotation.getContents() === '' || !selectedAnnotation.getContents()) {
          console.log('get contents');
          console.log(selectedAnnotation.getContents())
          const imageIcon = document.createElement("img"); 
          var imgType;
          switch(selectedAnnotation.CustomData.type){
            case 'text-field':
            imgType = 'text';
            break;
            case 'number-field':
            imgType = 'number';
            break;
            default:
            imgType = 'text';
            break;
            
          }
          imageIcon.src = '/assets/images/icons/pdf-mobile-icons/pdf-'+ imgType +'.png';
          
          const div = document.createElement("div");
          div.onclick = () => {
            //Trigger popup
            this.triggerOpenPopupUpdateFieldClick(imgType);
            // var setContents = setFormrlyContents(selectedAnnotation.CustomData.type);
            // selectedAnnotation.setContents(setContents);
            // selectedAnnotation.FillColor = new Annotations.Color(255, 255, 255);
            // selectedAnnotation.StrokeThickness = 0;   
            // annotManager.updateAnnotation(selectedAnnotation);
            // annotManager.redrawAnnotation(selectedAnnotation);
            // annotManager.trigger('annotationChanged', [[selectedAnnotation], 'modify', {}]);
            // annotManager.trigger('updateAnnotationPermission', selectedAnnotation);
          };
          div.className = "Button";
          div.appendChild(imageIcon);
          this.signToolTipTitle = 'Clear Signature';
          return div;
        }
        //If Has Data Show Clear
        else {
          const imageIcon = document.createElement("img");     // Create a <button> element
          imageIcon.src = '/assets/images/icons/pdf-mobile-icons/times-solid.svg';
          const div = document.createElement("div");
          div.onclick = () => {
            console.log('date add click')
            selectedAnnotation.setContents('');
            selectedAnnotation.FillColor = new Annotations.Color(255, 251, 204);
            selectedAnnotation.StrokeThickness = 2;  
            annotManager.updateAnnotation(selectedAnnotation);
            annotManager.redrawAnnotation(selectedAnnotation);
            annotManager.trigger('annotationChanged', [[selectedAnnotation], 'modify', {}]);
            annotManager.trigger('updateAnnotationPermission', selectedAnnotation);
          };
          div.className = "Button";
          div.appendChild(imageIcon);
          this.signToolTipTitle = 'Clear Signature';
          return div;
        }
      }
      else {
        const span = document.createElement("span");
        return span;
      }
    }

    // const renderSlider = () => {
    //   console.log('renderSlider')
    //   console.log(annotManager.getSelectedAnnotations());
    //   var selectedAnnotation = annotManager.getSelectedAnnotations()[0];
    //   if (selectedAnnotation) {
    //     //??? no custom data
    //     console.log(selectedAnnotation.getCustomData());
    //     console.log(selectedAnnotation.CustomData);
    //     if (selectedAnnotation.Subject === 'Signature' || selectedAnnotation.CustomData && (selectedAnnotation.CustomData.type === 'full-signature' || selectedAnnotation.CustomData.type === 'initial-signature')) {
    //       this.selectedSignatureAnnotaion = selectedAnnotation;
    //       lastSignatureClicked = selectedAnnotation.CustomData.type;
    //       this.lastSignatureClicked = lastSignatureClicked
    //       console.log('sig selected set');
    //     }
    //     else {
    //       this.selectedSignatureAnnotaion = null;
    //       lastSignatureClicked = null;
    //       this.lastSignatureClicked = null;
    //       console.log('sig selected not set')
    //     }
    //   }
    //   else {
    //     const span = document.createElement("span");
    //     return span;
    //   }
    //   if (this.selectedSignatureAnnotaion && this.selectedSignatureAnnotaion.Author === annotManager.getCurrentUser()) {
    //     console.log(this.selectedSignatureAnnotaion)
    //     if (this.selectedSignatureAnnotaion && (this.selectedSignatureAnnotaion.isGrouped() || this.selectedSignatureAnnotaion.getGroupedChildren()[0])) {
    //       const imageIcon = document.createElement("img");     // Create a <button> element
    //       imageIcon.src = '/assets/images/icons/pdf-mobile-icons/pdf-initial.png';
    //       const div = document.createElement("div");
    //       div.onclick = (e) => {
    //         // Do something: Clearn Signature, ungroup and delete the proper field.???
    //         console.log('clicked the click me')
    //         console.log(e);
    //         //var groupedAnnotation = annotManager.getGroupAnnotations(this.selectedSignatureAnnotaion);
    //         var groupedAnnotation = annotManager.getSelectedAnnotations();
    //         var signatureToDelete;
    //         console.log(groupedAnnotation);
    //         if (isSignatureOrWidget(groupedAnnotation[0]) === 'full-signature' || isSignatureOrWidget(groupedAnnotation[0]) === 'initial-signature') {
    //           signatureToDelete = groupedAnnotation[1];
    //           var signatureWidget = groupedAnnotation[0];

    //           console.log('delete second')
    //         }
    //         else {
    //           signatureToDelete = groupedAnnotation[0]
    //           var signatureWidget = groupedAnnotation[1];
    //           console.log('delete first')
    //         }
    //         console.log(groupedAnnotation)
    //         annotManager.ungroupAnnotations(groupedAnnotation);
    //         annotManager.deleteAnnotation(signatureToDelete);

    //         signatureWidget.FillColor = new Annotations.Color(255, 251, 204);
    //         signatureWidget.StrokeThickness = 2;


    //         annotManager.deselectAnnotation(signatureWidget)
    //         annotManager.selectAnnotation(signatureWidget)

    //       };
    //       div.className = "Button";
    //       div.appendChild(imageIcon);
    //       this.signToolTipTitle = 'Clear Signature';
    //       return div;
    //     }
    //     else {
    //       console.log(this.selectedSignatureAnnotaion);
    //       const imageIcon = document.createElement("img");     // Create a <button> element
    //       imageIcon.src = '/assets/images/icons/pdf-mobile-icons/pdf-signature.png';
    //       const div = document.createElement("div");
    //       div.onclick = (e) => {
    //         // Do something: Sign Functionality
    //         console.log('clicked the click me')
    //         const signatureCreateTool = docViewer.getTool('AnnotationCreateSignature');
    //         docViewer.setToolMode(signatureCreateTool);
    //         // console.log(docViewer.getToolMode());
    //         // console.log(this.eValue)
    //         this.wvNewMouseLeftUp.call(signatureCreateTool, e);
    //       };
    //       div.className = "Button";
    //       div.appendChild(imageIcon);
    //       this.signToolTipTitle = 'Sign';
    //       return div;
    //     }
    //   }
    //   else if (selectedAnnotation && selectedAnnotation.CustomData && selectedAnnotation.CustomData.type === 'date-field' && selectedAnnotation.Author === annotManager.getCurrentUser()) {
    //     if (selectedAnnotation.getContents() === '' || !selectedAnnotation.getContents()) {
    //       console.log('get contents');
    //       console.log(selectedAnnotation.getContents())
    //       const imageIcon = document.createElement("img");     // Create a <button> element
    //       imageIcon.src = '/assets/images/icons/pdf-mobile-icons/pdf-date.png';
    //       const div = document.createElement("div");
    //       div.onclick = () => {
    //         console.log('date add click')
    //         var today = new Date();
    //         var setDate = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear();
    //         selectedAnnotation.setContents(setDate);

            
    //         selectedAnnotation.FillColor = new Annotations.Color(255, 255, 255);
    //         selectedAnnotation.StrokeThickness = 0;         
    //         annotManager.updateAnnotation(selectedAnnotation);
    //         annotManager.redrawAnnotation(selectedAnnotation);
    //         annotManager.trigger('annotationChanged', [[selectedAnnotation], 'modify', {}]);
    //         annotManager.trigger('updateAnnotationPermission', selectedAnnotation);


    //       };
    //       div.className = "Button";
    //       div.appendChild(imageIcon);
    //       this.signToolTipTitle = 'Clear Signature';
    //       return div;
    //     }
    //     else {
    //       const imageIcon = document.createElement("img");     // Create a <button> element
    //       imageIcon.src = '/assets/images/icons/pdf-mobile-icons/pdf-email.png';
    //       const div = document.createElement("div");
    //       div.onclick = () => {
    //         console.log('date add click')
    //         var today = new Date();
    //         var setDate = today.getMonth() + 1 + '/' + today.getDate() + '/' + today.getFullYear();
    //         selectedAnnotation.setContents('');
    //         selectedAnnotation.FillColor = new Annotations.Color(255, 251, 204);
    //         selectedAnnotation.StrokeThickness = 2;         

    //         annotManager.updateAnnotation(selectedAnnotation);
    //         annotManager.redrawAnnotation(selectedAnnotation);
    //         annotManager.trigger('annotationChanged', [[selectedAnnotation], 'modify', {}]);
    //         annotManager.trigger('updateAnnotationPermission', selectedAnnotation);
    //       };
    //       div.className = "Button";
    //       div.appendChild(imageIcon);
    //       this.signToolTipTitle = 'Clear Signature';
    //       return div;
    //     }
    //   }
    //   else if (selectedAnnotation && selectedAnnotation.Author === annotManager.getCurrentUser() && selectedAnnotation.CustomData &&
    //     (selectedAnnotation.CustomData.type === 'name-field' || selectedAnnotation.CustomData.type === 'company-field' || selectedAnnotation.CustomData.type === 'title-field' || selectedAnnotation.CustomData.type === 'email-field')) {
    //     if (selectedAnnotation.getContents() === '' || !selectedAnnotation.getContents()) {
    //       console.log('get contents');
    //       console.log(selectedAnnotation.getContents())
    //       const imageIcon = document.createElement("img");     // Create a <button> element
    //       imageIcon.src = '/assets/images/icons/pdf-mobile-icons/pdf-date.png';
    //       const div = document.createElement("div");
    //       div.onclick = () => {
    //         var setContents = setFormrlyContents(selectedAnnotation.CustomData.type);
    //         selectedAnnotation.setContents(setContents);
    //         selectedAnnotation.FillColor = new Annotations.Color(255, 255, 255);
    //         selectedAnnotation.StrokeThickness = 0;   
    //         annotManager.updateAnnotation(selectedAnnotation);
    //         annotManager.redrawAnnotation(selectedAnnotation);
    //         annotManager.trigger('annotationChanged', [[selectedAnnotation], 'modify', {}]);
    //         annotManager.trigger('updateAnnotationPermission', selectedAnnotation);
    //       };
    //       div.className = "Button";
    //       div.appendChild(imageIcon);
    //       this.signToolTipTitle = 'Clear Signature';
    //       return div;
    //     }
    //     else {
    //       const imageIcon = document.createElement("img");     // Create a <button> element
    //       imageIcon.src = '/assets/images/icons/pdf-mobile-icons/pdf-email.png';
    //       const div = document.createElement("div");
    //       div.onclick = () => {
    //         console.log('date add click')
    //         selectedAnnotation.setContents('');
    //         selectedAnnotation.FillColor = new Annotations.Color(255, 251, 204);
    //         selectedAnnotation.StrokeThickness = 2;   
    //         annotManager.updateAnnotation(selectedAnnotation);
    //         annotManager.redrawAnnotation(selectedAnnotation);
    //         annotManager.trigger('annotationChanged', [[selectedAnnotation], 'modify', {}]);
    //         annotManager.trigger('updateAnnotationPermission', selectedAnnotation);
    //       };
    //       div.className = "Button";
    //       div.appendChild(imageIcon);
    //       this.signToolTipTitle = 'Clear Signature';
    //       return div;
    //     }
    //   }
    //   else {
    //     const span = document.createElement("span");
    //     return span;
    //   }
    // }
    const newCustomElement = {
      type: 'customElement',
      render: renderSlider,
      title: 'Test Title',
    };
    const newCustomElementName = {
      type: 'customElement',
      render: renderSliderName,
    }
    instance.annotationPopup.add(newCustomElement);

    instance.annotationPopup.add(newCustomElementName);
    instance.enableElements(['customElement'])
    const isSignatureOrWidget = (selectedAnnotation) => {
      console.log(selectedAnnotation);
      if (selectedAnnotation.Subject === 'Signature') {
        return 'user-signature'
      }
      else if (selectedAnnotation.Subject === 'Free text') {
        if (selectedAnnotation.CustomData.type === 'initial-signature') {
          return 'initial-signature'
        }
        else if (selectedAnnotation.CustomData.type === 'full-signature') {
          return 'full-signature'
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    }
    const setFormrlyContents = (annotationType) => {
      var result;
      switch (annotationType) {
        case 'name-field':
          result = this.myContactInfo.name.first + ' ' + this.myContactInfo.name.last;
          break;
        case 'company-field':
          result = this.myContactInfo.company.name;
          break;
        case 'title-field':
          result = this.myContactInfo.company.position;
          break;
        case 'email-field':
          result = this.myContactInfo.email;
          break;
      }
      return result;
    };
    //??? just added

    const annotSelectOriginalKeyDown = Tools.AnnotationSelectTool.prototype.keyDown;
    Tools.AnnotationSelectTool.prototype.keyDown = function (e) {
      console.log('override keyDown mouseLeftUp');
      console.log(e.key);
      console.log(this);
      console.log(annotManager.getSelectedAnnotations());
      // if(){
        
      // }
      //return annotSelectOriginalKeyDown.call(this, e);
    }

    const annotSelectOriginalMouseLeftDown = Tools.AnnotationSelectTool.prototype.mouseLeftDown;
    Tools.AnnotationSelectTool.prototype.mouseLeftDown = function (e) {
      console.log('override FreeTextCreateTool mouseLeftUp');
      setEValue(e);
      console.log(this);
      return annotSelectOriginalMouseLeftDown.call(this, e);
    }
    const setEValue = (e) => {
      this.eValue = e;
      var tempAnnotation = this.wvInstance.annotManager.getAnnotationByMouseEvent(e);
      if(tempAnnotation){
        this.wvSignerService.changeSelectedAnnotationId(tempAnnotation.ou);
      }
    }

    annotManager.on('annotationChanged', async (annotation, action, options) => {
      console.log('annotationChanged');
      console.log(annotation)
      console.log(action)
      console.log(options)
      console.log(this.selectedSignatureAnnotaion)
      if (!options.imported) {

        this.changeAnnotationBackground(annotation, action);
        if(action === 'delete'){
          this.wvSignerService.updateMyAnnotationList(annotation, this.signatureWidget, action, this.myAnnotationList);
          
        }
        else{
          this.wvSignerService.updateMyAnnotationList(annotation, this.selectedSignatureAnnotaion, action, this.myAnnotationList);
        }
        
        const xfdfString = await annotManager.exportAnnotations();
        //console.log(xfdfString);
        this.wvSignerService.changeXfdfString(xfdfString);
      }
    });
    annotManager.on('updateAnnotationPermission', async (annotation) => {
      console.log('updateAnnotationPermission');
      // console.log(annotation);
      if(this.isComplete === true){
        this.updateVisits();
      }
      annotManager.deselectAnnotation(annotation);
      annotManager.selectAnnotation(annotation);
      this.wvInstance.hotkeys.off();
      
    });

    instance.docViewer.on('annotationsLoaded', () => {
      console.log('annotations loaded');

    });
    annotManager.on('annotationSelected', async (annotations, action) => {
      console.log('annotationSelected');
      console.log(action);

      let currentlySelectedAnnots = annotManager.getSelectedAnnotations();
      let multipleAnnotsSelected = currentlySelectedAnnots.length > 1;
      if((currentlySelectedAnnots.length === 2) && currentlySelectedAnnots[0].isGrouped()){
        multipleAnnotsSelected = false;
      }

      if(multipleAnnotsSelected){
        annotManager.deselectAllAnnotations();
        annotManager.selectAnnotation(annotations[0]);
        return;
      }

      if (annotations) {
        console.log('action selected');
        if (annotations[0].CustomData.type === 'full-signature' || annotations[0].CustomData.type === 'initial-signature') {
          console.log('full signature selected');
          this.selectedSignatureAnnotationField = annotations[0];
          console.log(this.selectedSignatureAnnotationField);
        }
        else if (annotations[0].Subject === 'Signature') {
          console.log('signature')
          console.log(annotations);
          return;
        }
        else {
          this.selectedSignatureAnnotationField = null;
        }
        console.log(annotations[0].ou);
        this.wvSignerService.changeSelectedAnnotation(annotations[0]);
      }
      else if (!annotations) {
        console.log('action deselected');
      }
    });

    docViewer.getTool('AnnotationCreateSignature').on('annotationAdded', function (annotation) {
      console.log('signatureAdded')
      groupSignatureToFreeText(annotation);
      annotManager.deselectAllAnnotations();
    });

    const originalMouseLeftUp = Tools.SignatureCreateTool.prototype.mouseLeftUp;
    this.wvOriginalMouseLeftUp = Tools.SignatureCreateTool.prototype.mouseLeftUp;
    let addSignatureFromDB = false;
    //console.log(originalMouseLeftUp);
    Tools.SignatureCreateTool.prototype.mouseLeftUp = async function (e) {
      console.log('mouse left up');
      console.log(e);
      console.log(this);
      saveMouseLeftUpThis(this);
      console.log(lastSignatureClicked);

      const sigsTest = docViewer.getTool('AnnotationCreateSignature').getSavedSignatures();
      console.log(sigsTest);

      const savedSig = getMemberSavedSignature(lastSignatureClicked);
      console.log(savedSig);

      if (savedSig) {
        var savedSignature = JSON.parse(savedSig)
      }
      console.log(savedSignature);

      //checking if user clicked button in custom signature modal to add saved signature to widget
      if (addSavedIsClicked() === true) {
        console.log('click trigger');
        const sigss = this.getSavedSignatures();
        console.log(sigss);
        addSignatureFromDB = true;
        this.deleteSavedSignature(1);
        this.deleteSavedSignature(0);
        const empty = this.getSavedSignatures();
        console.log(empty);
        await importSavedSignature(this, savedSignature);
        const publicSavedSignature = getPublicSavedSignature(lastSignatureClicked);

        const sigs = this.getSavedSignatures();
        console.log(sigs);
        console.log(publicSavedSignature)
        originalMouseLeftUp.call(this, e);
        if (sigs[0]) {
          console.log('db sig imported')
          this.setSignature(sigs[0]);
          this.addSignature();
          originalMouseLeftUp.call(this, e);
          docViewer.setToolMode(docViewer.getTool('AnnotationEdit'))
          docViewer.setToolMode(docViewer.getTool('AnnotationEdit'))
          console.log(docViewer.getToolMode())
          console.log('dbSig added')
        }
        else if (publicSavedSignature && publicSavedSignature[0]) {
          console.log('db sig option 2')
          this.setSignature(publicSavedSignature[0]);
          this.addSignature();
          originalMouseLeftUp.call(this, e);
          docViewer.setToolMode(docViewer.getTool('AnnotationEdit'))
          docViewer.setToolMode(docViewer.getTool('AnnotationEdit'))
          console.log(docViewer.getToolMode())
          console.log('dbSig added')
        }
        else {
          console.log('error signature not added');
          originalMouseLeftUp.call(this, e);
          docViewer.setToolMode(docViewer.getTool('AnnotationEdit'))
          docViewer.setToolMode(docViewer.getTool('AnnotationEdit'))

        }
        return;
      }
      if (savedSignature) {
        console.log('saved then don\'t show modal', savedSignature);
        console.log(sigsTest);
        return openSavedSignatureCustomModal.call(this, savedSignature, e, docViewer);
      }
      else {
        console.log('no saved then show modal');
        this.deleteSavedSignature(0);
        this.deleteSavedSignature(0);
        addSignatureFromDB = false;
        return originalMouseLeftUp.call(this, e);
      }
    };
    //For Usage outside WebViewer
    this.wvNewMouseLeftUp = Tools.SignatureCreateTool.prototype.mouseLeftUp;
    const getPublicSavedSignature = (lastSignatureClicked) => {
      console.log('getPublicSavedSignature')
      console.log(lastSignatureClicked);
      if (lastSignatureClicked === 'full-signature') {
        console.log('return full signature')
        return this.savedSignatureFullPublic;
      }
      else if (lastSignatureClicked === 'initial-signature') {
        console.log('return initial signature')
        console.log(this.savedSignatureInitialPublic)
        return this.savedSignatureInitialPublic;
      }
    }
    const addSavedIsClicked = () => {
      if (this.clikedAddSavedAnnotation === true) {
        this.clikedAddSavedAnnotation = false;
        return true;
      }
      else { return false };
    }
    const saveMouseLeftUpThis = (data) => {
      console.log('saveMouseLeftUpThis');
      //console.log(data); 
      this.mouseLeftUpThis = data;
    };
    const importSavedSignature = async (signatureToolThis, savedSignature) => {
      await signatureToolThis.importSignatures(savedSignature);
      await signatureToolThis.importSignatures(savedSignature);
      const sigs = signatureToolThis.getSavedSignatures();
      console.log(sigs);
      console.log('importSavedSiganture Success')
    }
    const openSavedSignatureCustomModal = async (sigs, e, docViewer) => {
      console.log('openSavedSignatureCustomModal');
      await this.triggerFalseOpenClick();
      console.log(this.savedSignatureCanvas);
      console.log(sigs);
      this.currentSignatureEvent = e;
      var savedSignaturePaths = sigs[0];
      function getYs() {
        var result = [];
        savedSignaturePaths.forEach(item => {
          result = result.concat(item.map(point => point.y));
        });
        return result;
      }
      function getMinY() {
        return Math.min(...getYs());
      }
      function getMaxY() {
        return Math.max(...getYs());
      }
      function getXs() {
        var result = [];
        savedSignaturePaths.forEach(item => {
          result = result.concat(item.map(point => point.x));
        });
        return result;
      }
      function getMinX() {
        return Math.min(...getXs());
      }
      function getMaxX() {
        return Math.max(...getXs());
      }
      const annotationCoordinates = {
        annotation: {
          Left: 0,
          Bottom: 50,
          Width: 150,
          Height: 50,
          //PageNumber: this.selectedSignatureAnnotaion.getPageNumber(),
        },

        signature: {
          Left: getMinX(),
          Bottom: getMaxY(),
          Width: getMaxX() - getMinX(),
          Height: getMaxY() - getMinY(),
          //PageNumber: savedSignaturePaths.getPageNumber(),
        }
      };
      const newSavedSignaturePaths = changeSignaturePaths(savedSignaturePaths, annotationCoordinates)
      console.log(savedSignaturePaths[0]);
      setTimeout(() => {
        console.log("Hello from setTimeout");
        const canvasEl: HTMLCanvasElement = this.savedSignatureCanvas.nativeElement;
        var cx = canvasEl.getContext('2d');
        cx.beginPath;
        for (let i = 0; i < newSavedSignaturePaths.length; i++) {
          for (let ii = 0; ii < newSavedSignaturePaths[i].length; ii++) {
            if (ii === 0) {
              cx.moveTo(newSavedSignaturePaths[i][ii].x, newSavedSignaturePaths[i][ii].y);
            }
            else {
              cx.lineTo(newSavedSignaturePaths[i][ii].x, newSavedSignaturePaths[i][ii].y);
            }
          }
        }
        cx.stroke();
      }, 10);
    }
    //Event on Signature Saved
    docViewer.getTool('AnnotationCreateSignature').on('signatureSaved', function (annotation) {
      console.log('savedSignature')
      //console.log(annotation);
      //console.log(addSignatureFromDB);
      //console.log(lastSignatureClicked);
      const savedSignatureJson = JSON.stringify(this.exportSignatures());
      // console.log(savedSignatureJson);//event coordinates with offset

      if (addSignatureFromDB === false) {
        console.log('new saved signature')
        saveUserSignature(savedSignatureJson, lastSignatureClicked);
        saveUserSignatureFalse(annotation, lastSignatureClicked);
      }
      else {
        console.log('db saved signature')
        saveUserSignatureFalse(annotation, lastSignatureClicked);
      }
    });
    const saveUserSignatureFalse = (annotation, lastSignatureClicked) => {
      console.log('save User Sugnature');
      //console.log(annotation)
      if (lastSignatureClicked === 'full-signature') {
        this.savedSignatureFullPublic = annotation;
        //console.log(this.savedSignatureFullPublic);
      }
      else if (lastSignatureClicked === 'initial-signature') {
        this.savedSignatureInitialPublic = annotation;
        // console.log(this.savedSignatureInitialPublic);
      }
    };
    // docViewer.getTool('AnnotationCreateSignature').on('annotationAdded', function (annotation) {
    //   console.log(annotation);
    //   const signatureWidgetAnnots = annotManager.getAnnotationsList().filter(
    //     annot => annot instanceof Annotations.SignatureWidgetAnnotation
    //   );
    //   //Update or remove????
    //   console.log(signatureWidgetAnnots);
    //   signatureWidgetAnnots.forEach(annotSign => {
    //     console.log(annotSign);
    //     if (annotSign.annot) {
    //       // if this signature field is signed initially
    //       console.log('isSigned')
    //     } else {
    //       console.log('notSigned')
    //     }
    //   })
    // });
    const getMemberSavedSignature = (signatureType) => {
      console.log('getMemberSavedSignature');
      //console.log(signatureType)
      //console.log(this.memberData.savedSignatures)
      //console.log(this.myContactInfo.savedSignatures)
      if (!this.myContactInfo.savedSignatures) {
        return null;
      }
      else {

      }
      if (signatureType === 'full-signature' && this.myContactInfo.savedSignatures.fullSignature) {
        return this.myContactInfo.savedSignatures.fullSignature
      }
      else if (signatureType === 'initial-signature' && this.myContactInfo.savedSignatures.initials) {
        return this.myContactInfo.savedSignatures.initials
      }
      else {
        console.log('error no recognized signature type or no signature data');
        return null;
      }
    }
    const saveUserSignature = (annotation, lastSignatureClicked) => {

      console.log('saveUserSignature');
      //const lastSignatureNameSplit = lastSignatureClicked.split('-');
      const lastSignatureNameSplit = lastSignatureClicked;
      var signatureType
      console.log(lastSignatureNameSplit);
      if (lastSignatureNameSplit === 'initial-signature') {
        signatureType = 'initial-signature';

      }
      else if (lastSignatureNameSplit === 'full-signature') {
        signatureType = 'full-signature';
      }
      else {
        console.log('signature type not recognized')
        return false;
      }
      this.savedSignaturePath = annotation;
      console.log(annotation);
      console.log(annotation[0].x);
      //var updatedAnnotation = this.remapSignature(annotation[0]);
      //Temporary for testing
      //this.wvSignService.putSavedSignature(annotation, 'initial', this.memberData);
      this.signatureService.putSavedSignature(annotation, signatureType, this.myContactInfo);
    };

    //Event Listner Listen Page Change Add Visit Details
    this.viewer.nativeElement.addEventListener('pageChanged', (e) => {
      const pageNumber = e.detail[0];
      console.log(`Current page is ${pageNumber}`);
      this.updateVisits();
      this.currentPage = pageNumber;
      //???? Add Save Visit Strategy

    });

    const getMyAnnotationList = async (annotations, annotManager) => {
      console.log('getMyAnnotationList');
      //console.log(annotations);

      var myAnnotationList = [];
      let a = 0;
      for (let i = 0; i < annotations.length; i++) {
        if (annotManager.getDisplayAuthor(annotations[i]) === annotManager.getCurrentUser()) {
          console.log('myAnnotation' + a)
          if (annotations[i].Subject === 'Signature') { }
          else {
            myAnnotationList[a] = {
              id: annotations[i].ou,
              type: annotations[i].CustomData.type,
              required: annotations[i].CustomData.required,
              x: annotations[i].getX(),
              y: annotations[i].getY(),
              pageNumber: annotations[i].getPageNumber(),
              completed: this.wvSignerService.getAnnotationStatus(annotations[i])
            }
            a++;
            // if (annotations[i].Locked === true) {
            //   await annotManager.deleteAnnotation(annotations[i]);
            //   annotations[i].Locked = false;
            //   await annotManager.addAnnotation(annotations[i]);
            //   await annotManager.redrawAnnotation(annotations[i]);
            // }
          }


          if (annotations[i].lockedContents === true) {
            await annotManager.deleteAnnotation(annotations[i]);
            annotations[i].lockedContents = true;
            annotations[i].Locked = true;
            await annotManager.addAnnotation(annotations[i]);
            await annotManager.redrawAnnotation(annotations[i]);
          }
          else{
            annotations[i].NoMove = true;
            await annotManager.updateAnnotation(annotations[i]);
            await annotManager.redrawAnnotation(annotations[i]);
          }

        }
        else {
          console.log('notMyAnnotation')
          if (annotations[i].lockedContents === false) {
            annotations[i].lockedContents = true;
            annotations[i].Locked = true;
            await annotManager.updateAnnotation(annotations[i]);
            await annotManager.redrawAnnotation(annotations[i]);
            annotManager.trigger('annotationChanged', [[annotations[i]], 'modify', {}]);
          }
        }
      }
      console.log(myAnnotationList);
      this.wvSignerService.sortWidgetsByLocation(myAnnotationList);

    }
    const setMyAnnotationsAsNoMove = async (myAnnotationList, annotManager) => {
      var myLocalAnnotationList = myAnnotationList;
      
      for (let i = 0; i < myLocalAnnotationList.length; i++) {
        var annotation = annotManager.getAnnotationById(myLocalAnnotationList[i].id);
        console.log(annotation);
        annotation.NoMove = true;
        await annotManager.updateAnnotation(annotation);
        await annotManager.redrawAnnotation(annotation );
        annotManager.trigger('annotationChanged', [[annotation], 'modify', {}]);
        
      }
      this.triggerFalseClickButton()
    }
    const groupSignatureToFreeText = async (signatureAnnotation) => {
      console.log('group signature annotation')

      console.log(docViewer.getDocument().getPageMatrix(0));

      console.log(this.selectedSignatureAnnotationField)
      //console.log(this.selectedSignatureAnnotationField.getX())
      //console.log(this.selectedSignatureAnnotationField.getY())
      //console.log(this.selectedSignatureAnnotationField.getWidth())
      //console.log(this.selectedSignatureAnnotationField.getHeight())
      //console.log(this.selectedSignatureAnnotationField.getPageNumber())

      const annotationCoordinates = {
        annotation: {
          Left: this.selectedSignatureAnnotationField.getLeft(),
          Bottom: this.selectedSignatureAnnotationField.getY(),
          Width: this.selectedSignatureAnnotationField.getWidth(),
          Height: this.selectedSignatureAnnotationField.getHeight(),
          PageNumber: this.selectedSignatureAnnotationField.getPageNumber(),
        },

        signature: {
          Left: signatureAnnotation.getLeft(),
          Bottom: signatureAnnotation.getY(),
          Width: signatureAnnotation.getWidth(),
          Height: signatureAnnotation.getHeight(),
          PageNumber: signatureAnnotation.getPageNumber(),
        }
      };

      console.log(signatureAnnotation.getX())
      console.log(signatureAnnotation.getLeft())
      console.log(signatureAnnotation.getBottom())
      console.log(signatureAnnotation.getWidth())
      console.log(signatureAnnotation.getHeight())
      //console.log(signatureAnnotation.getPaths())
      var oldPath = signatureAnnotation.getPaths();
      console.log(oldPath);

      const newPaths = changeSignaturePaths(signatureAnnotation.getPaths(), annotationCoordinates);
      signatureAnnotation.setPath(newPaths[0], 0)
      console.log('new Paths')
      console.log(newPaths);
      signatureAnnotation.setWidth(this.selectedSignatureAnnotationField.getWidth())
      signatureAnnotation.setHeight(this.selectedSignatureAnnotationField.getHeight())
      signatureAnnotation.NoMove = true;
      signatureAnnotation.NoResize = true;
      
      //signatureAnnotation.setRectPadding(this.selectedSignatureAnnotaion.getRectPadding())
      
      //Wrong Page Fix
      if(this.selectedSignatureAnnotationField.getPageNumber() !== signatureAnnotation.getPageNumber() ){
        console.log('wrong page patch');
        await annotManager.deleteAnnotation(signatureAnnotation);
        signatureAnnotation.setPageNumber(this.selectedSignatureAnnotationField.getPageNumber())
        await annotManager.addAnnotation(signatureAnnotation);
        await annotManager.redrawAnnotation(signatureAnnotation);
      }
      else{
        console.log('update on correct page');
        await annotManager.updateAnnotation(signatureAnnotation); 
        await annotManager.redrawAnnotation(signatureAnnotation);
      }
      
      console.log(signatureAnnotation.getX())
      console.log(signatureAnnotation.getY())
      console.log(signatureAnnotation.getWidth())
      console.log(signatureAnnotation.getHeight())
      

      this.selectedSignatureAnnotationField.StrokeThickness = 0;
      this.selectedSignatureAnnotationField.FillColor = new Annotations.Color(255, 255, 255);
      await annotManager.updateAnnotation(this.selectedSignatureAnnotationField);
      await annotManager.redrawAnnotation(this.selectedSignatureAnnotationField);


      annotManager.groupAnnotations(this.selectedSignatureAnnotationField, [signatureAnnotation]);
      console.log('group annotations')
      console.log(annotManager.getGroupAnnotations(this.selectedSignatureAnnotationField))

    };

    const changeSignaturePaths = (signatureAnnotationPaths, annotationCoordinates) => {
      console.log('changeSignaturePaths');

      var annotationRatio = annotationCoordinates.annotation.Width / annotationCoordinates.annotation.Height
      var signatureRatio = annotationCoordinates.signature.Width / annotationCoordinates.signature.Height
      console.log(annotationRatio);
      console.log(signatureRatio);
      var scaling;
      if (annotationRatio < signatureRatio) {
        console.log('width scaling');
        scaling = annotationCoordinates.annotation.Width / annotationCoordinates.signature.Width;
      }
      else {
        //if ratios equal doesn't matter for scaling
        console.log('height scaling');
        scaling = annotationCoordinates.annotation.Height / annotationCoordinates.signature.Height;
      }

      var newPath = signatureAnnotationPaths;
      for (let i = 0; i < signatureAnnotationPaths.length; i++) {
        for (let ii = 0; ii < signatureAnnotationPaths[i].length; ii++) {
          newPath[i][ii].x = annotationCoordinates.annotation.Left + scaling * (signatureAnnotationPaths[i][ii].x - annotationCoordinates.signature.Left);
          newPath[i][ii].y = annotationCoordinates.annotation.Bottom + scaling * (signatureAnnotationPaths[i][ii].y - annotationCoordinates.signature.Bottom);
        }
      }
      return newPath;
    };

  }
  changeAnnotationBackground(annotation, action){
    const { Annotations } = this.wvInstance
    console.log(annotation);
    console.log('changeAnnotationsBackground');

    if(action === 'modify' && (annotation[0].CustomData.type === 'text-field' || annotation[0].CustomData.type === 'number-field')){
      console.log('change the background');
      if(annotation[0].getContents()=== ''|| !(annotation[0].getContents()) ) {
        annotation[0].FillColor = new Annotations.Color(255, 251, 204);
        annotation[0].StrokeThickness = 2;
      }
      else{
        annotation[0].FillColor = new Annotations.Color(255, 255, 255);
        annotation[0].StrokeThickness = 0;
      }
    }
  }
  updateFieldData={}
  formUpdateField:any;
    updateStandardField(updateFieldData, currentFieldType){
    console.log('updateStandardField')
    console.log(updateFieldData);
    console.log(currentFieldType);
    console.log(this.wvInstance.annotManager.getSelectedAnnotations()[0]);
    var annotationToUpdate = this.wvInstance.annotManager.getSelectedAnnotations()[0];
    if(currentFieldType === 'number'){
      console.log(updateFieldData.number.toString());
      annotationToUpdate.setContents(updateFieldData.number.toString());
      //let number = parseInt('1234', 10);//string to int example
      this.wvInstance.annotManager.updateAnnotation(annotationToUpdate);
      this.wvInstance.annotManager.redrawAnnotation(annotationToUpdate);
      this.wvInstance.annotManager.trigger('annotationChanged', [[annotationToUpdate], 'modify', {}]);
      this.wvInstance.annotManager.deselectAnnotation(annotationToUpdate);
      this.wvInstance.annotManager.selectAnnotation(annotationToUpdate);
      this.wvInstance.hotkeys.off();
      //this.formUpdateField.reset()
    }
    else{
      annotationToUpdate.setContents(updateFieldData.text)
      this.wvInstance.annotManager.updateAnnotation(annotationToUpdate);
      this.wvInstance.annotManager.redrawAnnotation(annotationToUpdate);
      this.wvInstance.annotManager.trigger('annotationChanged', [[annotationToUpdate], 'modify', {}]);
      this.wvInstance.annotManager.deselectAnnotation(annotationToUpdate);
      this.wvInstance.annotManager.selectAnnotation(annotationToUpdate);
      this.wvInstance.hotkeys.off();
      //this.formUpdateField.reset()
    }
    this.isUpdatingStandardField = false;
  }

  //Left Sidebar Functions
  changeCurrentPage(pageNumber) {
    this.wvInstance.docViewer.displayPageLocation(pageNumber, 0, 0, false)
  }
  addSavedSignatureToField(savedSignature, currentSignatureEvent) {
    console.log('addSavedSignatureToField');
    this.clikedAddSavedAnnotation = true;
    this.wvNewMouseLeftUp.call(this.mouseLeftUpThis, currentSignatureEvent);
    this.triggerFalseClick();
  }
  deleteMySavedSignature(lastSignatureClicked) {
    this.signatureService.deleteSavedSignature(lastSignatureClicked, this.myContactInfo);
    this.triggerFalseClick();
    this.wvNewMouseLeftUp.call(this.mouseLeftUpThis, this.eValue)

  }

///Visits Functions
buildVisit() {
  //this.fileMembersVisit.id = '123';
  this.fileMembersVisit.fileMetaId = this.documentData.id;
  this.fileMembersVisit.filesMemberId = '123';
  this.fileMembersVisit.formrlyUserId = this.documentData.formrlyUserId;
  

  this.fileMembersVisit.formrlyUsersContactId = this.myContactInfo.id;
  // jeff saak
  //this.fileMembersVisit.formrlyUsersContactId = '5de47cbf45e663398ed36d75';
  this.fileMembersVisit.timestamp = this.timestamp;
  //Dead weight need to remove all over
  this.fileMembersVisit.memberVisitorId = '123';
  console.log(this.fileMembersVisit);
  console.log(this.myContactInfo);
  this.postVisit(this.fileMembersVisit);
}
postVisit(fileMembersVisit: FileMemberVisits) {
  console.log('create fileMemberVisit');
  console.log(fileMembersVisit);
  //!!!Create the service
  this.memberVisitsService.createVisit(fileMembersVisit).subscribe(
    result => {
      console.log('successful add');
      console.log(result);
      this.fileMembersVisit = result;
      console.log(this.fileMembersVisit);
      //!!!
    },
    err => console.error(err)
  );


}
updateVisits() {
  if (this.startTime === 0) {
    this.startTime = this.visitsAddService.getTime();
    console.log('first load of page change');
  }
  else {
    this.endTime = this.visitsAddService.getTime();
    //this.visitsAddService.updateVisitCheck(this.userId, this.visitId, this.currentPage, this.startTime, this.endTime);
    var totalTime;
    if (this.endTime > 0 && this.endTime - this.startTime >= 1) {
      totalTime = this.endTime - this.startTime;
    }
    else {
      totalTime = 1;
    }
    this.updateVisitData(this.currentPage, totalTime);
    this.startTime = this.endTime;
  }
}
updateVisitData(currentPage, totalTime) {
  //this.fileMembersVisit.id = '123';
  var row;
  if (this.fileMembersVisit.visitData && this.fileMembersVisit.visitData[0].pageNumber) {
    row = this.fileMembersVisit.visitData.length;

  }
  else {
    row = 0;
  }
  console.log('row' + row);
  this.fileMembersVisit.visitData[row] = {
    pageNumber: currentPage,
    time: totalTime
  }
  this.updateVisitDetails(this.fileMembersVisit);
}

updateVisitDetails(fileMembersVisit: FileMemberVisits) {
  console.log('create fileMemberVisit');
  console.log(fileMembersVisit);
  //!!!Create the service
  this.memberVisitsService.updateVisit(fileMembersVisit).subscribe(
    result => {
      console.log('successful add');
      console.log(result);
      //!!!
    },
    err => console.error(err)
  );
}
}
