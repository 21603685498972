import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WebviewerSignerService } from '../../../@core/components/webviewer-final/webviewer-signer/webviewer-signer.service';
import { WvMemberSignatureService } from '../../../@core/components/webviewer-final/wv-signature/wv-member-signature.service'
import { NbSpinnerService } from '@nebular/theme/services/spinner.service';
//import { NbSpinnerService } from '@nebular/theme';
@Component({
  selector: 'ngx-sign-wvrk',
  templateUrl: './wv-signer.component.html',
  styleUrls: ['./wv-signer.component.scss']
})
export class SignWVRKComponent implements OnInit {
  documentData
  documentMembers
  invalid = null;
  wrongStatus = null;
  public documentId
  //public memberId = '5e549b27327b6c1edf2b0b0f';//temporary
  memberId
  public memberKey
  constructor(
    private route: ActivatedRoute,
    private wvSignerService: WebviewerSignerService,
    private signatureService: WvMemberSignatureService,
    private spinner$: NbSpinnerService
  ) {
    this.spinner$.load();
    this.route.paramMap.subscribe(params => {
      this.documentId = params.get('id');

      if (params.get('memberId')) {
        this.memberId = params.get('memberId');
      }
      //this.memberKey = params.get('memberKey');
      console.log('documentId')
    });
  }

  ngOnInit() {
  }
  getFormrlyUserContactInfo(data){
    this.memberId = data.id;
  }
  getDocument(data) {
    if(data.status === 'awaiting' || data.status === 'ready'){
      this.documentData = data;
      this.wvSignerService.changeDocumentData(data)
      console.log(data);
    }
    else{
      console.log(data)
      this.wrongStatus = true;
    }
  }
  getContactDetail(data) {
    this.documentMembers = data;
    if (!this.documentMembers.savedSignatures) {
      this.documentMembers.savedSignatures = {};
      this.documentMembers.savedSignatures.fullSignature = '';
      this.documentMembers.savedSignatures.initials = '';
    }
    this.signatureService.changeMyContactInfo(this.documentMembers);
    console.log(data);

  }
  getInvalid(data) {
    this.invalid = data;
    console.log(this.invalid)
    console.log('invalid received');
  }
}
