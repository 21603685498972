import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
//import { FormrlyUsersService } from '../../../services/http/formrly-users.service';
import {MembersVisitService } from '../../../services/members/members-visit.service';

@Injectable({
  providedIn: 'root'
})
export class WvMemberSignatureService {
 
  private myContactInfo = new BehaviorSubject<any[]>([]);
  currentMyContactInfo = this.myContactInfo.asObservable();

  changeMyContactInfo(data: any) {
    this.myContactInfo.next(data)
  }
  constructor(
    private membersService: MembersVisitService
  ) { }
  putSavedSignature(annotationPaths, signatureType, userData) {
    console.log('Put the signature');
    console.log(userData);
    console.log(annotationPaths);
    console.log(signatureType);
    if (!userData.savedSignatures) {
      userData.savedSignatures = {
        fullSignature: '',
        initials: ''
      }
    }
    console.log(userData);
    if (signatureType === 'full-signature') {
      userData.savedSignatures.fullSignature = annotationPaths;
    }
    else if (signatureType === 'initial-signature') {
      userData.savedSignatures.initials = annotationPaths;
    }
    else {
      console.log('Error no signature Type found');
    }
    console.log(userData);
    this.updateMyContactInfo(userData);
  }
  deleteSavedSignature(signatureType, userData) {
    console.log('Put the signature');
    console.log(userData);
    console.log(signatureType);
    console.log(userData);
    if (signatureType === 'full-signature') {
      userData.savedSignatures.fullSignature = '';
    }
    else if (signatureType === 'initial-signature') {
      userData.savedSignatures.initials = '';
    }
    else {
      console.log('Signature To Delete Not Found');
    }
    console.log(userData);
    this.updateMyContactInfo(userData)
  }
  updateMyContactInfo(userData){
    this.membersService.updateMyMembersContactInfo(userData.id, userData).subscribe(
      fileMeta => {
        console.log('Signature Update Success');
        console.log(fileMeta);
        this.changeMyContactInfo(fileMeta);
      },
      err => console.log(err)
    );
  }
}
