import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {MembersVisitService } from '../../../../services/members/members-visit.service';
import { Documents } from '../../../../services/http/interfaces/documents';

@Component({
  selector: 'ngx-member-document-get',
  templateUrl: './member-document-get.component.html',
  styleUrls: ['./member-document-get.component.scss']
})
export class MemberDocumentGetComponent implements OnInit {

  public document: Documents;
  @Input('parentData') public fileMetaId;
  @Input('parentDataMemberId') public memberId;

  @Output() notify: EventEmitter<Documents> = new EventEmitter<Documents>();

  constructor(
    private membersService: MembersVisitService
  ) { }

  ngOnInit() {
    this.getDocumentDetails(this.memberId, this.fileMetaId);
  }
  getDocumentDetails(memberId, fileMetaId) {
    this.membersService.getDocumentDetails(memberId, fileMetaId).subscribe(
      fileMeta => {
        this.document = fileMeta;
        console.log('getDocumentDetails Success')
        //console.log(fileMeta);
        this.notify.emit(this.document);
      },
      err => console.error(err)
    );
  }
}
