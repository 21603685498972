import { Component, OnInit, Input } from '@angular/core';
import { FormrlyUsersContact } from '../../../services/http/interfaces/formrly-users-contact';

@Component({
  selector: 'ngx-documents-members-view',
  templateUrl: './documents-members-view.component.html',
  styleUrls: ['./documents-members-view.component.scss']
})
export class DocumentsMembersViewComponent implements OnInit {
  //public documentMembers: FormrlyUsersContact;
  public documentMembers;
  public documentMembersData;
  public currentMemberName = '';
  public currentOrder;
  currentTimestamp;
  //public documentId ='5de18d8bfa5e2c78428271ac';
  @Input('parentData') public documentId;
  @Input('parentDataDocument') public document;

  constructor() { }

  ngOnInit() {
  }
  matchComplete = false;
  ngOnChanges() {
  }
  matchMembersAndData() {
    for(let i = 0 ; i< this.documentMembers.length; i++){
      var signatureIndex = this.documentMembersData.findIndex(item => {return item.formrlyUsersContactId === this.documentMembers[i].id})
      console.log(signatureIndex + ' signindex of' + i);

      if(signatureIndex >= 0){
        if(this.documentMembersData[signatureIndex].timeStamp){
          this.documentMembers[i].signatureDate = this.documentMembersData[signatureIndex].timeStamp;
        }
        else{
          this.documentMembers[i].signatureDate = 0;
        }
          console.log(signatureIndex);
        console.log(this.documentMembersData[signatureIndex].timeStamp + ' round' + i )
        console.log(this.documentMembers[i].signatureDate)
      }
    }
    //console.log(this.documentMembers);
  }
  
  isMatchComplete() {
    console.log('isMatchComplete')
    if (this.matchComplete === false) {

      if (this.documentMembers && this.documentMembersData && this.documentMembers[0]) {
        console.log('isMatched')
        this.matchMembersAndData();
        this.matchComplete = true;
      }
    }
    else { }
  }
  getDocumentMembers(data: FormrlyUsersContact) {
    // getDocumentMembers(data: FormrlyUsersContact) {
    console.log("Get Document Members");
    console.log(data);
    this.documentMembers = data;
    console.log('Document Members received in view');
    this.isMatchComplete()
  }
  
  getDocumentMembersData(data) {
    console.log("Get Document Members Data");
    console.log(data);
    this.documentMembersData = data;
    console.log('Document Members received in view');
    this.isMatchComplete()
  }
  updateCurrentMember(firstName, lastName, currentTimeStamp, id) {
    console.log('Update Current Member')
    this.currentMemberName = firstName + ' ' + lastName;
    this.currentTimestamp = currentTimeStamp;
    this.currentOrder =this.documentMembers.findIndex( item => {return item.id === id})

  }
}
