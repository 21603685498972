import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { NgxPaginationModule } from 'ngx-pagination';
import { AngularCropperjsModule } from 'angular-cropperjs';

import { NbAccordionModule, 
  NbCheckboxModule, 
  NbListModule, 
  NbInputModule, 
  NbTabsetModule, 
  NbContextMenuModule, 
  NbPopoverModule, 
  NbDialogModule ,
  NbCardModule,
  NbSidebarModule,
  NbSpinnerModule
} from '@nebular/theme';

import { ShContextMenuModule } from 'ng2-right-click-menu';

import {DocumentAddPopoverComponent} from './document/document-add-popover/document-add-popover.component';
import {DocumentViewGetComponent} from './document/crud/document-view-get/document-view-get.component';
import {DocumentViewComponent} from './document/document-view/document-view.component';
import { DocumentsStatusCountComponent } from './document/crud/documents-status-count/documents-status-count.component';
import { DocumentsStatusViewComponent } from './document/documents-status-view/documents-status-view.component';
import { DocumentsStatusGetComponent } from './document/crud/documents-status-get/documents-status-get.component';
import { DocumentVisitsCountComponent } from './visit/crud/document-visits-count/document-visits-count.component';
import { DocumentsMembersViewComponent } from './document/documents-members-view/documents-members-view.component';
import { DocumentsMembersGetComponent } from './document/crud/documents-members-get/documents-members-get.component';

import { FormrlyUserContactGetComponent } from './formrly-user-contact/crud/formrly-user-contact-get/formrly-user-contact-get.component';
import { FormrlyUserContactsGetComponent } from './formrly-user-contact/crud/formrly-user-contacts-get/formrly-user-contacts-get.component';
import { FormrlyUserContactsViewComponent } from './formrly-user-contact/formrly-user-contacts-view/formrly-user-contacts-view.component';
import { FormrlyUserContactDetailComponent } from './formrly-user-contact/formrly-user-contact-detail/formrly-user-contact-detail.component';
import { FormrlyUserContactAddComponent } from './formrly-user-contact/formrly-user-contact-add/formrly-user-contact-add.component';
import { FormrlyUserContactAddCrudComponent } from './formrly-user-contact/crud/formrly-user-contact-add/formrly-user-contact-add.component';
import { FormrlyUserContactEditComponent } from './formrly-user-contact/formrly-user-contact-edit/formrly-user-contact-edit.component';
import { FormrlyUserContactUpdateComponent } from './formrly-user-contact/crud/formrly-user-contact-update/formrly-user-contact-update.component';
import { FormrlyUserContactRemoveComponent } from './formrly-user-contact/crud/formrly-user-contact-remove/formrly-user-contact-remove.component';

import { ParentFolderComponent } from './folder/crud/parent-folder/parent-folder.component';
import { ChildFolderComponent } from './folder/crud/child-folder/child-folder.component';
import { FolderChildDocumentsComponent } from './folder/crud/folder-child-documents/folder-child-documents.component';
import { CreateFolderComponent } from './folder/crud/create-folder/create-folder.component';
import { UpdateFolderComponent } from './folder/crud/update-folder/update-folder.component';

import { SidebarDocumentsComponent } from './sidebar/sidebar-documents/sidebar-documents.component';
import { SidebarMyDocumentsComponent } from './sidebar/sidebar-my-documents/sidebar-my-documents.component';

import { TooltipDocumentComponent } from './chart/tooltip-document/tooltip-document.component';
import { ChartBarComponent } from './chart/chart-bar/chart-bar.component';
import { ChartLineComponent } from './chart/chart-line/chart-line.component';

import { DocumentVisitsMembersComponent } from './visit/crud/document-visits-members/document-visits-members.component';
import { DocumentVisitsMembersViewComponent } from './visit/document-visits-members-view/document-visits-members-view.component';
import { MemberVisitsGetComponent } from './visit/crud/member-visits-get/member-visits-get.component';
import { MemberVisitsViewComponent } from './visit/member-visits-view/member-visits-view.component';
import { VisitsGetComponent } from './visit/crud/visits-get/visits-get.component';
import { VisitsViewComponent } from './visit/visits-view.component';
import { OwnerVisitCountDaterangeComponent } from './visit/crud/owner-visit-count-daterange/owner-visit-count-daterange.component';
import { TutorialsGetComponent } from './tutorial/crud/tutorials-get/tutorials-get.component';
import { TutorialPopoverComponent } from './tutorial/tutorial-popover/tutorial-popover.component';

import { DocumentAddComponent } from './document/document-add/document-add.component';
import { DocumentsStatusCountsViewComponent } from './document/documents-status-counts-view/documents-status-counts-view.component';
import { VisitsRecentComponent } from './visit/visits-recent/visits-recent.component';
import { VisitStatsComponent } from './visit/visit-stats/visit-stats.component';
import { VisitStatsGetComponent } from './visit/crud/visit-stats-get/visit-stats-get.component';
import { FormrlyUserContactsSearchComponent } from './formrly-user-contact/formrly-user-contacts-search/formrly-user-contacts-search.component';
import { PdfSendPopoversComponent } from './pdf-viewer/pdf-send-popovers/pdf-send-popovers.component';
import { MyDocumentViewComponent } from './document/my-document-view/my-document-view.component';
import { MyDocumentsViewComponent } from './document/my-documents-view/my-documents-view.component';
import { WebviewerTestComponent } from './webviewer/webviewer.component';
import { DateAgoPipe } from '../pipes/date-ago.pipe';
import { MinuteSecondsPipe } from '../pipes/minute-seconds.pipe';
import { ReversePipe } from '../pipes/reverse.pipe';
import { WebviewerFinalComponent } from './webviewer-final/webviewer-final.component';
import { WebviewerSidebarRightComponent } from './webviewer-sidebar-right/webviewer-sidebar-right.component';
import { WebviewerSidebarLeftComponent } from './webviewer-sidebar-left/webviewer-sidebar-left.component';
import { WebviewerEditorComponent } from './webviewer-final/webviewer-editor/webviewer-editor.component';
import { WebviewerSignComponent } from './webviewer-final/webviewer-sign/webviewer-sign.component';
import { WebviewerViewComponent } from './webviewer-final/webviewer-view/webviewer-view.component';


import { WebviewerSignTestComponent } from '../tests/webviewer-sign-test/webviewer-sign-test.component';
import { MyContactInfoGetComponent } from './formrly-user/crud/my-contact-info-get/my-contact-info-get.component';
import { MyContactInfoUpdateComponent } from './formrly-user/crud/my-contact-info-update/my-contact-info-update.component';
import { MyDocumentChangeFolderComponent } from './document/my-document-change-folder/my-document-change-folder.component';
import { WebviewerSignerComponent } from './webviewer-final/webviewer-signer/webviewer-signer.component';
import { WvSignGuideComponent } from './webviewer-final/wv-sign-guide/wv-sign-guide.component';
import { WebviewerTemplateComponent } from './webviewer-final/webviewer-template/webviewer-template.component';
import { WvSidebarTemplateComponent } from './webviewer-final/wv-sidebar-template/wv-sidebar-template.component';
import { MyTemplateGetComponent } from './templates/crud/my-template-get/my-template-get.component';
import { MyTemplatesGetComponent } from './templates/crud/my-templates-get/my-templates-get.component';
import { SystemTemplatesGetComponent } from './templates/crud/system-templates-get/system-templates-get.component';
import { SystemTemplateCategoriesGetComponent } from './templates/crud/system-template-categories-get/system-template-categories-get.component';
import { TemplateAddPopoverComponent } from './templates/template-add-popover/template-add-popover.component';
import { MemberDocumentGetComponent } from './members/crud/member-document-get/member-document-get.component';
import { MemberMyContactGetComponent } from './members/crud/member-my-contact-get/member-my-contact-get.component';
import { MemberDocumentMemberGetComponent } from './members/crud/member-document-member-get/member-document-member-get.component';
import { WebviewerFullComponent } from './webviewer-full/webviewer-full.component';
import { WebviewerTemplateEditorComponent } from './webviewer-final/webviewer-template-editor/webviewer-template-editor.component';
import { WvSidebarTemplateEditorComponent } from './webviewer-final/wv-sidebar-template-editor/wv-sidebar-template-editor.component';

import { DynamicFormComponent }         from './dynamic-forms/dynamic-form.component';
import { DynamicFormQuestionComponent } from './dynamic-forms/dynamic-form-question.component';
import { QuestionService } from './dynamic-forms/question.service';
import { TemplatesStatusViewComponent } from './templates/templates-status-view/templates-status-view.component';
import { DocumentsMembersDataGetComponent } from './document/crud/documents-members-data-get/documents-members-data-get.component';
import { WebviewerPreviewComponent } from './webviewer-final/webviewer-preview/webviewer-preview.component';
import { NameChangePopoverComponent } from './popovers/name-change-popover/name-change-popover.component';
import { ConfirmationPopoverComponent } from './popovers/confirmation-popover/confirmation-popover.component';
import { AlertPopoverComponent } from './popovers/alert-popover/alert-popover.component';
import { SignatureCompletedComponent } from './signature-completed/signature-completed.component';
import { WvHeaderComponent } from './webviewer-final/wv-header/wv-header.component';
import { DocumentsMyAddComponent } from './document/documents-my-add/documents-my-add.component';
import { ChooseAvatarPopoverComponent } from './popovers/choose-avatar-popover/choose-avatar-popover.component';
//import { WvEditorCompletedComponent } from './webviewer-final/wv-editor-completed/wv-editor-completed.component';

@NgModule({
  declarations: [
    DocumentAddPopoverComponent,
    DocumentViewComponent,
    DocumentViewGetComponent,
    DocumentsStatusCountComponent, 
    DocumentsStatusViewComponent, 
    DocumentsStatusGetComponent,
    VisitsGetComponent,
    VisitsViewComponent,
    DocumentVisitsCountComponent,
    OwnerVisitCountDaterangeComponent,
    ParentFolderComponent,
    ChildFolderComponent,
    FolderChildDocumentsComponent,
    CreateFolderComponent,
    UpdateFolderComponent,
    SidebarDocumentsComponent,
    SidebarMyDocumentsComponent,
    DocumentsMembersViewComponent,
    DocumentsMembersGetComponent,
    DocumentVisitsMembersComponent,
    DocumentVisitsMembersViewComponent,
    TooltipDocumentComponent,
    ChartBarComponent,
    ChartLineComponent,
    FormrlyUserContactGetComponent,
    FormrlyUserContactsGetComponent,
    FormrlyUserContactsViewComponent,
    FormrlyUserContactDetailComponent,
    FormrlyUserContactAddComponent,
    FormrlyUserContactEditComponent,
    FormrlyUserContactUpdateComponent,
    FormrlyUserContactRemoveComponent,
    FormrlyUserContactAddCrudComponent,
    MemberVisitsGetComponent,
    MemberVisitsViewComponent,
    TutorialsGetComponent,
    TutorialPopoverComponent,
    DocumentAddComponent,
    DocumentsStatusCountsViewComponent,
    VisitsRecentComponent,
    VisitStatsComponent,
    VisitStatsGetComponent,
    FormrlyUserContactsSearchComponent,
    PdfSendPopoversComponent,
    MyDocumentViewComponent,
    MyDocumentsViewComponent,
    WebviewerTestComponent,
    DateAgoPipe,
    MinuteSecondsPipe,
    ReversePipe,
    WebviewerFinalComponent,
    WebviewerSidebarRightComponent,
    WebviewerSidebarLeftComponent,
    WebviewerEditorComponent,
    WebviewerSignComponent,
    WebviewerViewComponent,
    WebviewerSignTestComponent,
    MyContactInfoGetComponent,
    MyContactInfoUpdateComponent,
    MyDocumentChangeFolderComponent,
    WebviewerSignerComponent,
    WvSignGuideComponent,
    WebviewerTemplateComponent,
    WvSidebarTemplateComponent,
    MyTemplateGetComponent,
    MyTemplatesGetComponent,
    SystemTemplatesGetComponent,
    SystemTemplateCategoriesGetComponent,
    TemplateAddPopoverComponent,
    MemberDocumentGetComponent,
    MemberMyContactGetComponent,
    MemberDocumentMemberGetComponent,
    WebviewerFullComponent,
    WebviewerTemplateEditorComponent,
    WvSidebarTemplateEditorComponent,
    DynamicFormComponent, 
    DynamicFormQuestionComponent, TemplatesStatusViewComponent, DocumentsMembersDataGetComponent, WebviewerPreviewComponent, NameChangePopoverComponent, ConfirmationPopoverComponent, AlertPopoverComponent, SignatureCompletedComponent, WvHeaderComponent, DocumentsMyAddComponent, ChooseAvatarPopoverComponent, 
    //WvEditorCompletedComponent//this file needs to be moved to the appropriate /pages/webviewer/editor/ folder
  ],
  exports: [
    DocumentAddPopoverComponent,
    DocumentViewComponent,
    DocumentViewGetComponent,
    DocumentsStatusCountComponent, 
    DocumentsStatusViewComponent, 
    DocumentsStatusGetComponent,
    VisitsGetComponent,
    VisitsViewComponent,
    DocumentVisitsCountComponent,
    OwnerVisitCountDaterangeComponent,
    ParentFolderComponent,
    ChildFolderComponent,
    FolderChildDocumentsComponent,
    CreateFolderComponent,
    UpdateFolderComponent,
    SidebarDocumentsComponent,
    SidebarMyDocumentsComponent,
    DocumentsMembersViewComponent,
    DocumentsMembersGetComponent,
    DocumentVisitsMembersComponent,
    DocumentVisitsMembersViewComponent,
    TooltipDocumentComponent,
    ChartBarComponent,
    ChartLineComponent,
    FormrlyUserContactGetComponent,
    FormrlyUserContactsGetComponent,
    FormrlyUserContactsViewComponent,
    FormrlyUserContactDetailComponent,
    FormrlyUserContactAddComponent,
    FormrlyUserContactEditComponent,
    FormrlyUserContactUpdateComponent,
    FormrlyUserContactRemoveComponent,
    FormrlyUserContactAddCrudComponent,
    MemberVisitsGetComponent,
    MemberVisitsViewComponent,
    TutorialsGetComponent,
    TutorialPopoverComponent,
    DocumentAddComponent,
    DocumentsStatusCountsViewComponent,
    VisitsRecentComponent,
    VisitStatsComponent,
    VisitStatsGetComponent,
    FormrlyUserContactsSearchComponent,
    PdfSendPopoversComponent,
    MyDocumentViewComponent,
    MyDocumentsViewComponent,
    WebviewerTestComponent,
    DateAgoPipe,
    MinuteSecondsPipe,
    ReversePipe,
    WebviewerFinalComponent,
    WebviewerEditorComponent,
    WebviewerSignComponent,
    WebviewerViewComponent,
    WebviewerSignTestComponent,
    MyContactInfoGetComponent,
    MyContactInfoUpdateComponent,
    WebviewerSignerComponent,
    WvSignGuideComponent,
    WebviewerTemplateComponent,
    WvSidebarTemplateComponent,
    MyTemplateGetComponent,
    MyTemplatesGetComponent,
    SystemTemplatesGetComponent,
    SystemTemplateCategoriesGetComponent,
    TemplateAddPopoverComponent,
    MemberDocumentGetComponent,
    MemberMyContactGetComponent,
    MemberDocumentMemberGetComponent,
    WebviewerFullComponent,
    WebviewerTemplateEditorComponent,
    WvSidebarTemplateEditorComponent,
    DynamicFormComponent, 
    DynamicFormQuestionComponent,   TemplatesStatusViewComponent, DocumentsMembersDataGetComponent, WebviewerPreviewComponent

    , DocumentsMyAddComponent, ChooseAvatarPopoverComponent, 
 ],
  imports: [
    CommonModule,
    NbAccordionModule, 
  NbCheckboxModule, 
  NbListModule, 
  NbInputModule, 
  NbTabsetModule, 
  NbContextMenuModule, 
  NbPopoverModule, 
  NbDialogModule ,
  NbCardModule,
  NgbModule,
  FormsModule,
  ReactiveFormsModule,
  ShContextMenuModule,
  NbSidebarModule,
  NbSpinnerModule,
  NgxPaginationModule,
  AngularCropperjsModule
  
  ],
  providers: [
    QuestionService]
})
export class SharedModule { }
