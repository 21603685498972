import { Injectable } from '@angular/core';
import {DocumentService} from '../../services/http/document.service'
declare const WebViewer: any;

@Injectable({
  providedIn: 'root'
})
export class WebviewerFinalService {
  public instance;
  licenseKey = 'Formrly LLC(formrly.co):OEM:Formrly::B+:AMS(20200529):12A5746204B7C80A3360B13AC9A2737860612FE3A926CDEAC56C051BD7B500CE64AAB6F5C7';

  constructor(
    private documentService: DocumentService
  ) { }
  async loadInstance(viewerNativeElement, location) {
    await WebViewer({
      path: '../../../../lib',
      initialDoc: `https://cors-anywhere.herokuapp.com/${location}`,
      //initialDoc: `${location}`,
      //initialDoc: `https://cors-anywhere.herokuapp.com/${this.documentData.providerResponse.location}`,
     // fullAPI: true,
     
      licenseKey: this.licenseKey,
      css: window.location.origin + '/assets/web-viewer/custom-styles.css?ver=2'
    },
      viewerNativeElement).then(instance => {
        //instance.disableElements(['header'])
        // instance.hotkeys.off('F');
        // instance.hotkeys.off('f');
        instance.hotkeys.off();
        this.instance = instance;
        console.log('load webviewer service')
        
        //return this.instance;
      });
      
      
  }

}
