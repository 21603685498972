import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Folders } from '../../../../services/http/interfaces/folders'
import { FolderService } from '../../../../services/http/folder.service';

@Component({
  selector: 'ngx-child-folder',
  templateUrl: './child-folder.component.html',
  styleUrls: ['./child-folder.component.scss']
})
export class ChildFolderComponent implements OnInit {
  @Input('parentData') public parentFolderId;
  @Input('parentDataChildRefresh') public childRefresh;
  @Output() notifyCFolder: EventEmitter<Folders> = new EventEmitter<Folders>();
  public myChildFolders: Folders;



  constructor(
    private folderService: FolderService,
  ) { }

  ngOnInit() {
  }
  ngOnChanges(){
    this.getFolders(this.parentFolderId);
  }
  getFolders(parentFolderId){
    if(parentFolderId==="0" || parentFolderId === null){
      this.myChildFolders=null;
    }
    else{
      this.getMyChildFolders(this.parentFolderId);
    }
  }
  getMyChildFolders(parentFolderId){
		this.folderService.getMyChildFolders(parentFolderId).subscribe(
      folderMeta => {
        this.myChildFolders = folderMeta;
        console.log("These are the child folders");
        console.log(this.myChildFolders[0]);
        this.notifyCFolder.emit(folderMeta);
      },
        err => console.error(err)
      
    );
  }
}
