import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor() { }

  /**
   * Returns a promise that resolves when a file is created from a data URL. 
   * @param dataUrl The data URL.
   */
  dataUrlToFile(dataUrl:string):Promise<File>{
    let mimeType = dataUrl.match(/:(.*?);/)[1];
    let extension = mimeType.substr(mimeType.indexOf('/') + 1);
    let filename = Date.now() + '-' + this.getRandomInt(0,99999) + '.' + extension;
    return (fetch(dataUrl)
        .then(response => response.arrayBuffer())
        .then(buf => new File([buf], filename, { type:mimeType }))
    );
  }

  /**
   * Returns a promise that resolves when a resource at a web location is retrieved as a data URL.
   * @param url The URL to retrieve the resource from. 
   */
  getAsDataUrl(url:string):Promise<any>{

    let corsProxy = 'https://cors-anywhere.herokuapp.com/';
    url = corsProxy + url;

    return (fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result)
            };
            reader.onerror = () => { 
                console.error(reader.error); 
                reject(reader.error)
            };
            reader.readAsDataURL(blob);
        }))
    );
  }

  setUploadName(displayFileName, fileType, userId) {
    var uploadName;
    var timestamp;
    timestamp =Date.now() / 1000;
    timestamp = parseInt(timestamp);
    console.log('this is date');
    console.log(timestamp);
    switch (fileType) {
        case "file":
            uploadName = userId + "-" + timestamp + "-" + displayFileName;
            break;
        case "template":
            uploadName = userId + "-" + timestamp + "-" + displayFileName;
            break;
        case "thumbnail":
            uploadName = userId + "-" + timestamp + "-" + displayFileName;
            break;
        case "avatar":
            uploadName = userId + "-" + timestamp + "-" + displayFileName;
            break;
        default:
            uploadName = userId + "-" + timestamp + "-" + displayFileName;
        break;
    }


    return uploadName;
}

/**
 * Returns a random integer number in the interval [min,max).
 * @param min The minimum value.
 * @param max The maximum value. 
 */
private getRandomInt(min:number, max:number):number{
    return Math.floor(Math.random() * (max - min)) + min;
}
}
