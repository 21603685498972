import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { of, Observable, Subject } from 'rxjs';
import { FormrlyUsersService } from '../../../services/http/formrly-users.service';
import { UploadService } from '../../../services/common/upload.service';
import { FormrlyUsersContact } from '../../../services/http/interfaces/formrly-users-contact';
import { FormrlyUser } from '../../../services/http/interfaces/formrly-users';
import { UserData } from '../../../data/users';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { concatMap } from 'rxjs/operators';
// import { VisitsAddService } from '../../../services/members/visits-add.service';
//??? update to work from older commit

@Component({
  selector: 'ngx-formrly-user-contact-add',
  templateUrl: './formrly-user-contact-add.component.html',
  styleUrls: ['./formrly-user-contact-add.component.scss']
})
export class FormrlyUserContactAddComponent implements OnInit {
  @Input('parentDataMyContacts') public myContactList;
  private _defaultEmail:string = '';
  email:string;

  // Indicates if the form is being saved
  isSaving:boolean;

  @Output() notifyNewContact: EventEmitter<FormrlyUsersContact> = new EventEmitter<FormrlyUsersContact>();
  @Output() notifyChangeTab: EventEmitter<string> = new EventEmitter<string>();
  public profile: any;
  newPhoto;
  profileData: FormrlyUsersContact;
  searchUserContact: any;
  searchField = {
    email: ''
  }
  userContact: FormrlyUsersContact;
  tab = 'checkEmail';
  resultStatus;
  objectURL

  constructor(
    private formrlyUsersService: FormrlyUsersService,
    private fb: FormBuilder,
    private userSvc: UserData,
    private uploadService: UploadService
  ) {
  }

  ngOnInit() {
    this.email = this.DefaultEmail;
    this.isSaving = false;
  }

  /**
   * Returns the default email.
   */
  get DefaultEmail():string{
    return this._defaultEmail;
  }

  /**
   * Sets the default email.
   */
  @Input()
  set DefaultEmail(value:string){
    this._defaultEmail = value ? value.trim().toLowerCase() : '';
  }

  /**
   * Handles the 'avatarChosen' event triggered by the Choose Avatar Popover component. 
   * @param avatarSrc 
   */
  onAvatarChosen(avatarSrc:string){
    this.uploadService.dataUrlToFile(avatarSrc).then(
      file => {
        this.newPhoto = file;
        this.objectURL = avatarSrc;
      }
    );
  }
  
  getFormrlyContactUser(value) {
    console.log(value)
    this.formrlyUsersService.getFormrlyUserContact(value.email).subscribe(contactMeta => {
      console.log('get Formrly User');
      console.log(contactMeta);
      if (!contactMeta[0]) {
        this.tab = 'addNewContact'
        this.userContact = {
          name: {
            first: '',
            last: ''
          },
          email: value.email,
          company: {
            position: '',
            name: ''
          }
        }
      }
      else {
        this.userContact = contactMeta;
        this.tab = 'checkResult';
      }

    });

  }
  checkIfMyContact(contact) {
    console.log('addToMyContacts');
    console.log(contact);
    this.formrlyUsersService.getCurrentUser().subscribe(currentUser => {
      console.log('getCurrentUser Success');
      var findContact = currentUser.formrlyUserContactId.find(element => element === contact.id)
      if (findContact) {
        alert('This Contact is already in your list');
        this.sendChangeTab('view');
      }
      else {
        var myContactList = currentUser.formrlyUserContactId
        myContactList.push(contact.id);
        console.log(myContactList)
        this.patchMyContactList(myContactList, contact);
      }
      //if()
      // then nothing
      // else push and patchages.find(
    });
  }
  patchMyContactList(myContactList, contact) {
    var insertContactList = {
      formrlyUserContactId: myContactList
    };
    this.formrlyUsersService.patchMyContactList(insertContactList).subscribe(
      result => {
        console.log('successful contact patch');
        console.log(result);
        this.sendNewContact(contact);
      }
    )

  }
  changeTab(tab) {
    this.tab = tab;
  }

  /**
   * Adds a new contact to the contact list of the current user.
   * @param value The contact to be added.
   */
  createFormrlyUsersContact(value: FormrlyUsersContact) {

    let hasEmptyFields = !value.name.first.length || !value.name.last.length || !value.email.length;

    if(hasEmptyFields){
      alert('Please enter a value in required fields.');
    }else{
      let isValidEmail = this.formrlyUsersService.validateEmailAddress(value.email);
      if(!isValidEmail){
        alert('Please enter a valid email address.');
      }else{
        let foundContactByEmail = this.myContactList.find((contact) => { return contact.email === value.email; });
        if(foundContactByEmail){
          alert('You already have a contact with this email address.');
        }else{
          this.isSaving = true;
          this.formrlyUsersService.createFormrlyUsersContact(value).subscribe(
            result => {
              if (this.newPhoto) {
                this.uploadAvatar(this.newPhoto, result);
              }
              this.sendNewContact(result);
              this.isSaving = false;
            },
            err => console.error(err)
          );
        }
      }
    } 
  }

  selectImage(event) {
    this.newPhoto = event.target.files[0];

    if (event.target.files.length === 0)
      return;

    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      //this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    //this.imagePath = event.target.files;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.objectURL = reader.result;
    }


    //this.objectURL = URL.createObjectURL(this.newPhoto).slice(5)
    //this.objectURL = URL.createObjectURL(new Blob([this.newPhoto], {type: this.newPhoto.type}))
    //var reader = new FileReader();
    //console.log(reader.readAsDataURL(event.target.files[0]));
    //reader.readAsDataURL(event.target.files[0]);
    //console.log(reader.result);
    //this.objectURL = reader;
    console.log(this.objectURL)
    console.log(this.newPhoto);
    //	this.uploadAvatar(event.target.files[0], profileData);
  }
  uploadAvatar(file, profileData) {
    this.userSvc.uploadAvatar(file, '')
      .pipe(
        concatMap(fileMeta => {
          profileData.image = fileMeta.result.files.file[0];
          console.log(profileData);
          return this.formrlyUsersService.updateFormrlyUsersContact(profileData)
        })
      ).subscribe(
        fileMeta => {
          console.log('Update and File Meta Success Add');
        },
        err => console.error(err)
      );
  }


  sendNewContact(result) {
    this.notifyNewContact.emit(result);
  }
  sendChangeTab(result) {
    this.notifyChangeTab.emit(result);
  }

}
