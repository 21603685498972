import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DocumentService } from '../../@core/services/http/document.service';

@Component({
  selector: 'ngx-view-share',
  templateUrl: './view-share.component.html',
  styleUrls: ['./view-share.component.scss']
})
export class ViewShareComponent implements OnInit {

  /** Indicates if the current request is valid */
  isAuthorized:boolean = false;

  /** The ID of the document being requested */
  fileMetaId:string = '';

  /** Data about the document being requested */
  documentData:any = null;

  /** The ID of the contact who is accessing the page */
  contactId:string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private documentService: DocumentService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(
      params => {
        this.fileMetaId = params.get('fileMetaId').trim().toLowerCase();
        this.contactId = params.get('contactId').trim().toLowerCase();
        this.validateRequest();
      }
    );
  }

  /**
   * Validates if the current request is authorized. 
   */
  validateRequest():void{

    if(!this.fileMetaId || !this.contactId){
      this.router.navigate(['/auth/login']);
      return;
    }

    this.documentService.getDocumentObject(this.fileMetaId).subscribe(
      documentObj => {
        let shareLinks = documentObj.ShareLinks;
        if(!shareLinks){
          this.router.navigate(['/auth/login']);
          return;
        }

        let i = 0;
        while((i < shareLinks.length) && !this.isAuthorized){
          this.isAuthorized = shareLinks[i].active && (shareLinks[i].FormrlyUserContactId === this.contactId);
          i++;
        }

        if(!this.isAuthorized){
          this.router.navigate(['/auth/login']);
          return;
        }

        this.documentData = documentObj;
      },
      error => {
        console.log(error);
        this.router.navigate(['/auth/login']); // Most probable error reason: Document ID is not valid
      }
    );
  }
}