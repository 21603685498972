import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor() { }

  private myContactInfo = new BehaviorSubject<any[]>([]);
  currentMyContactInfo = this.myContactInfo.asObservable();

  changeMyContactInfo(data) {
    this.myContactInfo.next(data)
  }


}
