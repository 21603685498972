import { Component, OnInit, Input, TemplateRef, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validator } from '@angular/forms';
import { Router } from '@angular/router'
import { Observable, Subject } from 'rxjs';
import { NbAuthService } from '@nebular/auth';
import { UserData } from '../../../data/users';
import { of as observableOf } from 'rxjs';

import { DocumentService } from '../../../services/http/document.service';
import { FolderService } from '../../../services/http/folder.service';
import { Documents } from '../../../services/http/interfaces/documents';
import { liveSearch } from '../../formrly-user-contact/formrly-user-contacts-search/live-search.operator';
import { NbDialogService } from '@nebular/theme';
import { Folders } from '../../../services/http/interfaces/folders';
import { concatMap } from 'rxjs/operators';


@Component({
  selector: 'ngx-my-documents-view',
  templateUrl: './my-documents-view.component.html',
  styleUrls: ['./my-documents-view.component.scss']
})
export class MyDocumentsViewComponent implements OnInit {
  @Input('parentData') public currentFolderId;
  @Input('parentDataFolderName') public currentFolderName;
  @Input('parentDataHasParentFolder') public hasParentFolder;
  @Output() notifyAddFolder: EventEmitter<string> = new EventEmitter<string>();
  @Output() notifyChangeFolder: EventEmitter<string> = new EventEmitter<string>();
  @Output() notifyHasDocuments: EventEmitter<boolean> = new EventEmitter<boolean>();
  //@Output() notifyTabView: EventEmitter<string>  = new EventEmitter<string>();
  //@ViewChild('popupClose') popupClose: ElementRef<HTMLElement>;
  //@ViewChild('popupOpen') popupOpen: ElementRef<HTMLElement>;
  @ViewChild('popupMoveDocument') popupMoveDocument: TemplateRef<HTMLElement>;
  @ViewChild('shareDocument') popupShareDocument: TemplateRef<HTMLElement>;
  
  @ViewChild('popupMoveDocumentClose') popupMoveDocumentClose: ElementRef<HTMLElement>;
  @ViewChild('popupShareClose') popupShareClose: ElementRef<HTMLElement>;
  @ViewChild('popupNewFolderClose') popupNewFolderClose: ElementRef<HTMLElement>;
  @ViewChild('popupPreview') popupPreview: TemplateRef<HTMLElement>;
  currentFile
  public documents;
  addNewHeader = 'normal';
  searchActivated = false;
  searchResults = false;
  private searchTerm = new Subject<string>();
  readonly searchResults$: Observable<any> = this.searchTerm.pipe(
    liveSearch(searchTerm => this.documentService.searchMyDocuments(searchTerm))
    // debounceTime(250),
    // distinctUntilChanged(),
    // switchMap(
    //   email => this.formrlyUserService.FetchMyContactsList(email))
  );
  public folderForm: FormGroup;
  public folderForms: Folders;
  public folderData: Folders;

  /** Number of times a rename action has been triggered */
	renameClick = 0;

	/** Display name of the current document */
	currentDocumentDisplayName = '';

  constructor(
    private documentService: DocumentService,
    private folderService: FolderService,
    private dialogService: NbDialogService,
    private fb: FormBuilder,
    private router: Router,
    private userSvc: UserData,

  ) {
    this.folderForm = this.fb.group({
      folderName: ['']
    });
  }

  open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, { context: 'this is some additional data' });
  }

  ngOnInit() {
    console.log('NgOnInit My DocumentsView');
    // this.getMyFolderChildDocuments(this.currentFolderId);
  }

  ngOnChanges() {
    console.log('NgOnChanges My DocumentsView');
    //this.getMyFolderChildDocuments(this.currentFolderId);
    //this.getMyDocumentsInRoot();
    console.log(this.currentFolderId);
    this.getDocuments(this.currentFolderId);
  }

  getDocuments(folderId) {
    if (folderId === '0' || folderId === null) {
      this.getMyDocumentsInRoot();
    }
    else {
      this.getMyFolderChildDocuments(folderId);
    }
  }
  getMyFolderChildDocuments(folderId) {
    this.documentService.getMyFolderChildDocuments(folderId).subscribe(
      fileMetas => {
        console.log('getMyFolderChildDocuments');
        this.documents = fileMetas;
        console.log(this.documents);
        this.sendNotifyHasDocuments();
      },
      err => console.error(err)
    );
  }

  getMyDocumentsInRoot() {
    this.documentService.getMyDocumentsInRoot().subscribe(
      fileMetas => {
        console.log('getMyDocumentsInRoot');
        this.documents = fileMetas;
        console.log(this.documents);
        this.sendNotifyHasDocuments();
      },
      err => console.error(err)
    );
  }
  sendNotifyHasDocuments(){
    if(this.documents[0]){
      this.notifyHasDocuments.emit(true);
    }
    else{
      this.notifyHasDocuments.emit(false);
    }
  }
  popupClose() {
    let el: HTMLElement = this.popupMoveDocumentClose.nativeElement;
    el.click();
  }
  popupAddFolderClose(){
		let el: HTMLElement = this.popupNewFolderClose.nativeElement;
    el.click();
	}
  getChangeFolder(data) {
    if (data === 'close') {
      this.popupClose();
    }
    else {
      console.log(data)
      this.notifyChangeFolder.emit(data);
      this.popupClose();
    }
  }
  searchMyDocuments(searchValue) {
    console.log('value');
    console.log(searchValue);
    console.log('after searchValue');
    this.searchTerm.next(searchValue);
    if (searchValue === '') {
      this.searchActivated = false;
    }
    else {
      this.searchActivated = true;
    }
  }
  createFolder(value: Folders) {
    console.log('create folder');
    console.log(value);
    if (this.currentFolderId === 0 || this.currentFolderId === '0') {
      console.log('you made it');
    }
    else if (this.currentFolderId != '0' || this.currentFolderId != null || this.currentFolderId != '' || this.currentFolderId != 0) {
      console.log('this is a test');
      console.log(this.currentFolderId);
      value.folderId = this.currentFolderId;

    }
    //const updateProfileData = new FormData();
    //var updateProfileData: FormrlyUsersContact;
    //	console.log(updateProfileData);
    //updateProfileData.append('name.first', value.firstName);
    // updateProfileData.append('name.last', value.lasttName);
    // updateProfileData.append('email', value.email);
    // updateProfileData.append('company.name', value.fcompanyName);
    // updateProfileData.append('company.position', value.companyPosition);
    // updateProfileData.name.first = value.firstName;
    // updateProfileData.name.last = value.lasttName;
    // updateProfileData.email = value.email;
    // updateProfileData.company.name = value.fcompanyName;
    // updateProfileData.company.position = value.companyPosition;
    //	console.log(updateProfileData);
    this.folderService.createFolder(value).subscribe(
      result => {
        console.log('successful update');
        //        this.router.navigate(['my-documents/folders/' + this.currentFolderId]);
        //      this.router.navigate(['my-documents/']);
        console.log(result);
        var folderType = null;
        if (this.currentFolderId === 0 || this.currentFolderId === '0'){
          folderType = 'parent';
        }
        else if (value.folderId !== null) {
          folderType = 'child';
        }
        else {
          console.log('error on folder type, add folder button should be disabled here.');
        }
        this.notifyAddFolder.emit(folderType);
        this.popupAddFolderClose()
      },
      err => console.error(err)
    );
  }



  onContextMenuItemClick(event, action) {
    switch (action) {
      case 'preview':
        //this.goToWebViewer(event.data.providerResponse.location, event.data.id);
        this.openPreview(event.data)
        break;
      case 'download':
        //		this.downloadFile(event.data.name);
        break;
      case 'delete':
        //       this.open(popupOpen);
        if (confirm('Are you sure you want to delete the document: ' + event.data.displayName + '?')){
          event.data.displayName = 'Deleting ' + event.data.displayName + '...';
          this.deleteFile(event.data.name, event.data.id);
        }
        break;
      case 'changeFolder':
        this.openChangeFolder(event.data);
        break;

      case 'details':
        this.goToDocumentView(event.data.id);
        //router.navigate(['/forms-library-single', {id: event.data.id}])
        //this.deleteFile(event.data.name, event.data.id);
        break;
      case 'share':
        this.openShareDialog(event.data);
        break;
      case 'rename':
        this.currentFile = event.data;
        this.currentDocumentDisplayName = this.currentFile.displayName;
				this.renameClick++;
        break;
    }
  }

  /**
	 * Updates the name of the current document.
	 * @param newName The new name for the current document.
	 */
	updateCurrentDocumentName(newName:string):void{
    this.currentFile.displayName = newName;
    this.updateDocument(this.currentFile);
	}

  goToWebViewer(pdfLocation, fileMetaId) {
    this.router.navigate(['/view', { location: pdfLocation, id: fileMetaId }]);
  }
  goToDocumentView(fileMetaId) {
    console.log('View file with id: ' + fileMetaId);
    //	this.currentDocumentId = fileMetaId;
    this.router.navigate(['my-documents/', fileMetaId])
    //	this.tabView = 'document';
    //this.notifyTabView.emit('document');

  }
  openChangeFolder(data) {
    //this.open()
    this.currentFile = data
    this.open(this.popupMoveDocument);
  }

  openShareDialog(data) {
    //this.open()
    this.currentFile = data
    this.open(this.popupShareDocument);
  }


  openPreview(data) {
    this.currentFile = data
    this.open(this.popupPreview);
  }

  deleteFile(fileName, fileId) {
    this.userSvc.deleteFile(fileName)
      .pipe(
        concatMap(response => {
          let result$;
          switch (response.status) {
            case 204:
              // bug?: are 204s being converted to 200 by angular http client?
              result$ = this.userSvc.deleteFileMeta(fileId);
              break;
            case 200:
              // bug: loopback logs errors and still returns 200 status code?
              result$ = this.userSvc.deleteFileMeta(fileId);
              break;
            default:
              result$ = observableOf(Error('unhandled response code'));
          }
          return result$;
        })
      ).subscribe(
        data => {
          console.log(data);
          console.log('your file has been deleted');
          var spliceId = this.documents.findIndex(function (item) {
            return item.id === fileId;
          })
          this.documents.splice(spliceId, 1);
          // TODO: remove single item from UI instead of refreshing
          //		this.getFileMetas();
        },
        err => console.error(err)
      );
  }
  getUploadedDocument(data){
    console.log('getUploadedDocument')
    console.log(data)
    this.documents.push(data)
  }
  closeMemberDialog() {
    let el: HTMLElement = this.popupShareClose.nativeElement;
    el.click();
  }
  getMember(data) {
    console.log('getMember');
    console.log(data);
    this.closeMemberDialog();
    this.addShareLink(data);
  }
  addShareLink(data) {
    var insertRow = 0;
    var now = Date.now();
    console.log(now);
    if (this.currentFile.ShareLinks) {
      console.log(this.currentFile.ShareLinks.length)
      insertRow = this.currentFile.ShareLinks.length;
      this.currentFile.ShareLinks[insertRow] = {
        shareLink: '123',
        active: true,
        FormrlyUserContactId: data.id,
        formrlyUserContact: data.name.first + ' ' + data.name.last,
        timestamp: now
      };
    }
    else {
      this.currentFile.ShareLinks = [{
        shareLink: '123',
        active: true,
        FormrlyUserContactId: data.id,
        formrlyUserContact: data.name.first + ' ' + data.name.last,
        timestamp: now
      }];
    }
    console.log(this.currentFile);
    this.updateDocument(this.currentFile);
    this.addShareDocumentMember(this.currentFile.id,data)
    // this.documentService.UpdateDocumentDetails(this.document.id, this.document).subscribe(documentMeta =>{
    //     console.log('Update Document Success');
    //     console.log(documentMeta);
    //     this.document = documentMeta;
    //   }
    // );
  }
  updateDocument(document) {
    this.documentService.UpdateDocumentDetails(document.id, document).subscribe(
      fileMeta => {
        console.log('Update Success');
      },
      err => console.log(err)
    )
  }
  addShareDocumentMember(fileMetaId,contactInfo){
    console.log(fileMember);
    var fileMember ={
      memberId:"123",
      shareLink : '123',
      access : 'share',
      FormrlyUsersContactId : contactInfo.id
    };
  //  fileMember.memberId = '123';
  //  fileMember.shareLink = '123';
  //  fileMember.access = 'share';

    console.log('The File Member before adding new member')
    console.log(fileMember);
 
    this.documentService.addDocumentMember(fileMetaId, fileMember).pipe(
      concatMap(fileMember => {
        console.log('Member Details');
        console.log(fileMember);
        return this.documentService.getDocumentMemberByMemberId(fileMetaId, fileMember.FormrlyUsersContactId);
      })
    ).subscribe(
      membersData => {
        console.log('successful update');
        console.log(membersData);
      },
      err => console.error(err)
    );

  }

}
