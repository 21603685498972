import { Component, OnInit } from '@angular/core';
import { UserData } from '../../../data/users';
import { concatMap } from 'rxjs/operators';
import { UploadService } from '../../../services/common/upload.service';
import { Router } from '@angular/router';
import { TemplateService } from '../../../services/http/template.service';

@Component({
  selector: 'ngx-template-add-popover',
  templateUrl: './template-add-popover.component.html',
  styleUrls: ['./template-add-popover.component.scss']
})
export class TemplateAddPopoverComponent implements OnInit {
  
templateId

  // Indicates if a file is being uploaded
  isUploading:boolean;

  constructor(
    private userSvc: UserData,
    private templateService: TemplateService,
    private uploadService: UploadService,
    private router:Router,
  ) { }
file
  ngOnInit() {
    this.isUploading = false;
  }
  selectFile(event) {

    this.file = event.target.files[0];
    let isImage = (this.file.type.match(/image\/*/) !== null);
    let isAllowedDocumentType = (this.file.type === 'application/pdf');

    if(isImage || isAllowedDocumentType){
      console.log(this.file);
      //this.dialogRef.close();
      this.file.status = 'draft';
    //this.closeDialog('popupnewdoc');
      this.uploadFile(event.target.files[0]);
    }else{
      alert('Please choose an image or a PDF file.');
    }
  }
  uploadFile(file) {
    this.isUploading = true;
    var userId, uploadFileName, displayFileName, userMeta ;
    displayFileName = file.name;
    this.userSvc.getCurrentUser().pipe(
      concatMap(
        userData =>{
        userId = userData.id
        userMeta = userData
        console.log(userData);
        console.log(userId);
        uploadFileName = this.uploadService.setUploadName(file.name, 'template', userId);
        console.log(uploadFileName);
          return this.userSvc.uploadFileNew(file, uploadFileName, '')
        }))
            .pipe(
              concatMap(fileMeta => {
                return this.templateService.saveTemplateMetaNew(fileMeta.result.files.file[0], displayFileName);
              })
            ).subscribe(
              fileMeta => {
                this.isUploading = false;
              this.router.navigate(['/template-builder/', fileMeta.id ]);
            },
            err => console.error(err)
            );
  }
}
