import { Component, OnInit, TemplateRef } from '@angular/core';
import{NbClickTriggerStrategy} from '@nebular/theme';
import{Router} from '@angular/router';
@Component({
  selector: 'ngx-documents-status-counts-view',
  templateUrl: './documents-status-counts-view.component.html',
  styleUrls: ['./documents-status-counts-view.component.scss']
})
export class DocumentsStatusCountsViewComponent implements OnInit {
public ready="ready";
public awaiting="awaiting";
public completed="completed";
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }
  //
  open(page){
    this.router.navigate(['/' + page]);
    //this.dialogService.open(dialog, { context: 'this is some additional data'});
  }

}
