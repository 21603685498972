import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-document-add',
  templateUrl: './document-add.component.html',
  styleUrls: ['./document-add.component.scss']
})
export class DocumentAddComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
