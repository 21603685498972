import { Component, OnInit, ViewChild } from '@angular/core';
//import { DocumentsStatusGetComponent} from '../crud/documents-status-get/documents-status-get.component'
import {Documents} from '../../../services/http/interfaces/documents';
@Component({
  selector: 'ngx-document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.scss']
})
export class DocumentViewComponent implements OnInit {
  public documentsData:Documents;
  datafromchild: any;
  
  //@ViewChild(DocumentStatusGetComponent) private webview: DocumentStatusGetComponent;
  public status = 'status test';
  constructor() { }

  ngOnInit() {
  //  console.log(this.documents);
  }
  getDocuments(data:Documents){
    console.log("This is data");
    console.log(data);
    this.documentsData = data;
    console.log('getdocuments tirgger');
  }

}
