import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-visit-stats',
  templateUrl: './visit-stats.component.html',
  styleUrls: ['./visit-stats.component.scss']
})
export class VisitStatsComponent implements OnInit {
  chartFilter="month";
  //chartFilters = ['week','month','year'];
  month='month';
  year='year';
  week='week';
  public chartDataWeek = [];
  public chartDataMonth = [];
  public chartDataYear = [];
  public chartDataYearget = false;
  constructor() { }

  ngOnInit() {
    console.log('visit Stats');
    console.log(this.chartFilter);
  }

  changeFilter(filter){
    this.chartFilter = filter;
  }
  getVisitStatsWeek(data){
    console.log('getVisitStatsWeek');
    this.chartDataWeek = data;
  }
  getVisitStatsMonth(data){
    console.log('getVisitStatsWeek');
    this.chartDataMonth = data;
  }
  getVisitStatsYear(data){
    console.log('getVisitStatsWeek');
    this.chartDataYear = data;
    this.chartDataYearget = true;
  }
  
}
