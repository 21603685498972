import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable} from 'rxjs';
import { Folders } from './interfaces/folders';


@Injectable({
  providedIn: 'root'
})
export class FolderService {
	private api: string = environment.rest;

  constructor(
    private http: HttpClient

  ) { }
  getMyParentFolders(): Observable<Folders> {
    //parent function
  //  var parent;
		return this.http.get<Folders>(`${this.api}/FormrlyUsers/me/Folder?filter={"where":{ "folderId":{"exists":false}}}`);
  }
  getMyChildFolders(parentFolderId:string): Observable<any> {
		return this.http.get(`${this.api}/FormrlyUsers/me/Folder/${parentFolderId}/Folder`);
  }
  getCurrentFolder(folderId:string): Observable<any> {
		return this.http.get(`${this.api}/FormrlyUsers/me/Folder/${folderId}`);
	}
  createFolder(folderMeta: any): Observable<any> {
		return this.http.post(`${this.api}/FormrlyUsers/me/Folder`, folderMeta);
  }
  updateFolder(folderId:any, folderMeta: any): Observable<any>{
    return this.http.put(`${this.api}/FormrlyUsers/me/Folder/${folderId}`, folderMeta);
  }
  deleteFolder(folderId: any): Observable<any> {
		return this.http.delete(`${this.api}/FormrlyUsers/me/Folder/${folderId}`);
  }
  // getMyFolderChildDocuments(folderId:any): Observable<any> {
	// 	return this.http.get(`${this.api}/FormrlyUsers/me/Folder/${folderId}/FileMeta`);
  // }
  searchMyDocuments(searchParam): Observable<any> {
		return this.http.get(`${this.api}/FormrlyUsers/me/FileMeta`, searchParam);
  }
}
