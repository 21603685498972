import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Documents} from '../../../../services/http/interfaces/documents';
import {Visits} from '../../../../services/http/interfaces/visits';
import {VisitService} from '../../../../services/http/visit.service';
@Component({
  selector: 'ngx-member-visits-get',
  templateUrl: './member-visits-get.component.html',
  styleUrls: ['./member-visits-get.component.scss']
})
export class MemberVisitsGetComponent implements OnInit {
  @Input('parentData') public memberId;
  @Output() notifyVisits: EventEmitter<Visits> = new EventEmitter<Visits>()
  @Output() notifyDocuments: EventEmitter<Documents> = new EventEmitter<Documents>()
  
  public memberVisits;
  public documents;
  constructor(
    public visitService:VisitService
  ) { }
  ngOnInit() {
    this.getVisitsByMember(this.memberId);
  }
  getVisitsByMember(memberId){
		this.visitService.getVisitsByMember(memberId).subscribe(
      contactListMeta => {
        this.memberVisits = contactListMeta[0];
        this.documents = contactListMeta[1];
        console.log('The file meta')
        console.log( contactListMeta);
        console.log (this.memberVisits);
        this.notifyVisits.emit(this.memberVisits);
        this.notifyDocuments.emit(this.documents);
        //console.log('The docstatus' + this.documentsByStatus);
      },
        err => console.error(err)
    );
  }
}