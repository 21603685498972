import { Component, OnInit, Input } from '@angular/core';
import {DocumentService} from '../../../../services/http/document.service';
@Component({
  selector: 'ngx-document-status-count',
  templateUrl: './documents-status-count.component.html',
  styleUrls: ['./documents-status-count.component.scss']
})
export class DocumentsStatusCountComponent implements OnInit {
  @Input('parentData') public status;
  documentStatusCount:any;
  //documentId:any;


  constructor(
    private documentService : DocumentService
  ) { }

  ngOnInit() {
    console.log(this.status);
    this.getDocumentCountbyStatus(this.status);
  }
  getDocumentCountbyStatus(status) {
		this.documentService.getDocumentCountbyStatus(status).subscribe(
			count => {
        console.log(status + 'count is: ' + count.count);
				this.documentStatusCount = count.count
			},
			err => console.error(err)
		);
	}
}
