import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { VisitService } from '../../../../services/http/visit.service';

@Component({
  selector: 'ngx-visit-stats-get',
  templateUrl: './visit-stats-get.component.html',
  styleUrls: ['./visit-stats-get.component.scss']
})
export class VisitStatsGetComponent implements OnInit {
  @Input('parentData') public filter;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  public visitCounts = {};
  public result = {
    date: [],
    visitCount: []
  };
  constructor(
    private visitService: VisitService
  ) { }

  ngOnInit() {
    this.getVisitStats(this.filter);
  }
  async getVisitStats(filter) {
    console.log('query fired');
    //Set Filter Details
    var filterLength;
    var filterData;
    var increment
    var startDate;
    var dateNow = Date.now();
    var today = new Date(dateNow);
    today = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0, 0, 0, 0);

    if (filter === 'year') {
      console.log('year today');
      today = new Date(today.getFullYear(), today.getMonth() + 1, 1, 0, 0, 0, 0);
    }
    dateNow = today.getTime();
    console.log(today.getHours() + ':' + today.getMinutes() + ' ' + today.getFullYear() + ' ' + today.getMonth() + ' ' + today.getDate());

    switch (filter) {
      case 'week':
        console.log('week case')
        filterLength = 7;
        increment = 86400000;
        //increment = 24 * 60 * 60 * 1000;
        startDate = dateNow - (increment * (filterLength));
        break;
      case 'year':
        console.log('year case')
        //special case look for way to handle it.
        filterLength = 12;
        increment = 2628000000;
        startDate = dateNow - 31536000000;
        startDate = dateNow - (increment * (filterLength));
        break;
      default:
        console.log('default case')
        filterLength = 30;
        increment = 86400000;
        startDate = dateNow - 2592000000;
        startDate = dateNow - (increment * (filterLength));
        break;
    }

    console.log('startdate' + startDate + '   ' + dateNow)
    var stoday = new Date(startDate)
    console.log(stoday.getHours() + ' ' + stoday.getDate());
    //getVisitCountByOwnerByDateRange(startDate, endDate)
    //result :Visits
    var visitMetaData = [];
    this.visitService.getVisitCountByOwnerByDateRange(startDate, dateNow).subscribe(
      visitMeta => {
        //Adjust properly as needed
        console.log('visit Stats Get Meta');
        console.log(visitMeta);

        visitMetaData = visitMeta;
        //return result;
        // let condition = value => value.timestamp > 1578952173000; // value is less than 10
        // let some = visitMetaData.filter(condition);

        // let condition2 = value => value.timestamp < 1578962173000; // value is less than 10
        // let some2 = some.filter(condition2);

        this.processVisitCounts(visitMetaData, filterLength, increment, startDate, dateNow);

        //        console.log(some.length);
        //      console.log(some2.length);
      },
      err => console.log(err)
    );

    var i = 0;

    var endDate;
    var dateRange = [];
    var date
    i = 0;

    console.log('before visit service date range of' + filter);
    console.log(dateRange);


    //this.visitCounts = result;
    console.log('visit stat get results');
    //console.log(result);
    //this.notify.emit(result);
  }
  processVisitCounts(visitMetaData, filterLength, increment, startDate, dateNow) {
    var i = 0;
    var endDate;
    var date;
    for (i = 0; i < filterLength; i++) {
      date = new Date(startDate);
      endDate = startDate + increment;
      var days = [];
      days[0] = 'Sunday';
      days[1] = 'Monday';
      days[2] = 'Tuesday';
      days[3] = 'Wednesday';
      days[4] = 'Thursday';
      days[5] = 'Friday';
      days[6] = 'Saturday';
      const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];

      var r = days[date.getDay()];
      var m = monthNames[date.getMonth()];

      if (filterLength === 12) {
        //this.result.date[i] = m + ' ' + date.getFullYear();
        var sStartDate = new Date(startDate);
        if(sStartDate.getMonth() < 11){
          endDate = new Date(sStartDate.getFullYear(), sStartDate.getMonth() + 1, 1, 0, 0, 0, 0).getTime();
        }
        else{
          endDate = new Date(sStartDate.getFullYear() + 1, 0, 1, 0, 0, 0, 0).getTime();
        }

        this.result.date[i] = startDate

        let condition = value => value.timestamp > startDate; // value is less than 10
        let some = visitMetaData.filter(condition);
  
        let condition2 = value => value.timestamp < endDate; // value is less than 10
        let some2 = some.filter(condition2);
        this.result.visitCount[i] = some2.length;
  
  
        //var todayMMYY = startDate.getMonth() + ' ' + startDate.getFullYear();
       
        
        startDate = endDate;

      }
      else {
        this.result.date[i] = startDate
        //this.result.date[i] = r + ', ' + m + ' ' + date.getDate() + ', ' + date.getFullYear();
        let condition = value => value.timestamp > startDate; // value is less than 10
        let some = visitMetaData.filter(condition);
  
        let condition2 = value => value.timestamp < endDate; // value is less than 10
        let some2 = some.filter(condition2);
        this.result.visitCount[i] = some2.length;
  
  
        startDate = startDate + increment;

      }
    }
    console.log('processVisitCounts');
    console.log(this.result);
    this.notify.emit(this.result);
  }
}
