import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
	NbAuthComponent,
	NbLoginComponent,
	NbLogoutComponent,
	NbRegisterComponent,
	NbRequestPasswordComponent,
	NbResetPasswordComponent,
} from '@nebular/auth';
import { NgxLoginComponent } from './auth/login/login.component'; // <---
import { NgxAuthComponent } from './auth/auth.component'; // <---

//import { NgxAuthBlockComponent } from './auth/auth-block/auth-block.component'; // <---

import { AuthGuard } from './@core/auth.guard';

const routes: Routes = [
	
	// {
	// 	path: 'auth',
	// 	component: NgxAuthComponent,
	// 	children: [
	// 		{
	// 			path: '',
	// 			component: NbLoginComponent,
	// 		},
	// 		{
	// 			path: 'login',
	// 			component: NgxLoginComponent,
	// 		},
	// 		{
	// 			path: 'register',
	// 			component: NbRegisterComponent,
	// 		},
	// 		{
	// 			path: 'logout',
	// 			component: NbLogoutComponent,
	// 		},
	// 		{
	// 			path: 'request-password',
	// 			component: NbRequestPasswordComponent,
	// 		},
	// 		{
	// 			path: 'reset-password',
	// 			component: NbResetPasswordComponent,
	// 		},
	// 	],
	// },
	{
		path: 'auth',
		loadChildren: './auth/auth.module#NgxAuthModule',
	  },
	  {
		path: 'signer',
		// loadChildren: './pages/webviewer/wv-signer/wv-signer.module#SignWVRKModule',
		loadChildren: './guest/guest.module#GuestModule',
	  },
	  { 
		  path: '', 
		  loadChildren: 'app/pages/pages.module#PagesModule', 
		  canActivate: [AuthGuard], 
		  canActivateChild: [AuthGuard] 
	  },
	// { path: 'view', component:  },
	{ path: '**', redirectTo: '' },
];

const config: ExtraOptions = {
	useHash: false,
};

@NgModule({
	imports: [RouterModule.forRoot(routes, config)],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
