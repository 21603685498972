import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable} from 'rxjs';

import { FormrlyUser }  from './interfaces/formrly-users';
import { FormrlyUsersContact }  from './interfaces/formrly-users-contact';


@Injectable({
  providedIn: 'root'
})
export class FormrlyUsersService {
  private time: Date = new Date;
	private api: string = environment.rest;

  constructor(
    private http: HttpClient
  ) {
  }
  ///Change observable to: FormrlyUser
  getCurrentUser(): Observable<any> {
		return this.http.get(`${this.api}/FormrlyUsers/me`);
  }

  ///Change observable to: FormrlyUser
  updateCurrentUser(userMeta: any): Observable<any>{
    return this.http.put(`${this.api}/FormrlyUsers/me`, userMeta);
  }

  patchCurrentUser(userMeta: any): Observable<any>{
    return this.http.patch(`${this.api}/FormrlyUsers/me`, userMeta);
  }

  patchMyContactList(userMeta: any): Observable<any>{
    return this.http.patch(`${this.api}/FormrlyUsers/me`, userMeta);
  }

  ///Change observable to: FormrlyUsersContact
  getMyContactInfo(): Observable<any> {
		return this.http.get(`${this.api}/FormrlyUsers/me/FormrlyUserMyContactInfo`);
  }
  getFormrlyUserContact(email): Observable<any> {
		return this.http.get(`${this.api}/FormrlyUsersContacts?filter={"where":{"email":"${email}"}}`);
  }
  updateMyContactInfo(contactMeta: any): Observable<any>{
    console.log(contactMeta);
    return this.http.put(`${this.api}/FormrlyUsers/me/FormrlyUserMyContactInfo`, contactMeta);
  }  

  //Get Subscription Observable
  getMySubscription(): Observable<any> {
		return this.http.get(`${this.api}/FormrlyUsers/me/FormrlyUsersSubscription`);
  }
  getMyContactList(): Observable<FormrlyUsersContact> {
		return this.http.get<FormrlyUsersContact>(`${this.api}/FormrlyUsers/me/FormrlyUserMyContactList`);
  }
  FetchMyContactsList(criteria): Observable<FormrlyUsersContact> {
    if (!criteria){
      console.log('no value inside the service');
      return this.http.get<FormrlyUsersContact>(`${this.api}/FormrlyUsers/me/FormrlyUserMyContactList`);
    }
    return this.http.get<FormrlyUsersContact>(`${this.api}/FormrlyUsers/me/FormrlyUserMyContactList?filter={"where":{"or":[{"email":{"like": "${criteria}", "options":"i"}}, {"name.first":{"like": "${criteria}", "options":"i"}}, {"name.last":{"like": "${criteria}", "options":"i"}}]}}`);
  }

  getMyContactListDetail(contactId): Observable<FormrlyUsersContact> {
		return this.http.get<FormrlyUsersContact>(`${this.api}/FormrlyUsers/me/FormrlyUserMyContactList/${contactId}`);
  }
  createFormrlyUsersContact(contactMeta: any): Observable<FormrlyUsersContact> {
		return this.http.post<FormrlyUsersContact>(`${this.api}/FormrlyUsers/me/FormrlyUserMyContactList`, contactMeta);
  }
  updateFormrlyUsersContact(contactMeta: any): Observable<FormrlyUsersContact> {
		return this.http.put<FormrlyUsersContact>(`${this.api}/FormrlyUsers/me/FormrlyUserMyContactList/${contactMeta.id}`, contactMeta);
  }

  greet(data): Observable<any> {
		return this.http.post<any>(`${this.api}/FormrlyUsers/greet`, data);
  }

  /**
   * Verifies if a contact exists.
   * @param contactId The ID of the contact. 
   */
  verifyContactExists(contactId):Observable<any>{
    return this.http.get<any>(`${this.api}/FormrlyUsersContacts/${contactId}/exists`, contactId);
  }

  /**
   * Returns details about a user contact.
   * @param contactId The ID of the contact.
   */
  getUserContactDetails(contactId):Observable<any>{
    return this.http.get<any>(`${this.api}/FormrlyUsersContacts/${contactId}`, contactId);
  }

  /**
   * Sends a message to a user indicating that a document needs to be signed.
   * @param data Data required to compose the email message. 
   */
  sendReminder(data:any):Observable<any>{
    return this.http.post<any>(`${this.api}/FormrlyUsers/sendreminder`, data);
  }

  /**
   * Sends a message containing a link to download a document. 
   * @param data Data required to compose the email message.
   */
  sendDownloadLink(data:any):Observable<any>{
    return this.http.post<any>(`${this.api}/FormrlyUsers/senddownloadlink`, data);
  }

  /**
   * Sends a message containing a link to view a document. 
   * @param data Data required to compose the email message.
   */
  sendShareLinkEnabled(data:any):Observable<any>{
    return this.http.post<any>(`${this.api}/FormrlyUsers/sendsharelinkenabled`, data);
  }

  /**
   * Sends a message indicating that access to a document has been disabled.
   * @param data Data required to compose the email message.
   */
  sendShareLinkDisabled(data:any):Observable<any>{
    return this.http.post<any>(`${this.api}/FormrlyUsers/sendsharelinkdisabled`, data);
  }

  /**
   * Retrieves a user given his email address.
   * @param email The email address.
   */
  getUserByEmail(email:string):Observable<any>{
    email = email.trim();
    return this.http.get(`${this.api}/FormrlyUsers?filter={"where":{"email":"${email}"}}`);
  }

  /**
   * Validates if a value is a valid email address.
   * @param value The value to be validated.
   */
  validateEmailAddress(value:string):boolean{
    // See: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(value.toLowerCase());
  }
}
