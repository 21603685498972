import { Injectable }   from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { QuestionBase } from './question-base';
import { TextboxQuestion } from './question-textbox';

@Injectable()
export class QuestionControlService {
  constructor() { }

  toFormGroup(questions: QuestionBase<any>[] ) {
    let group: any = {};

    questions.forEach(question => {
      let validators = [];
      
      if(question.required){
        validators.push(Validators.required);
      }

      let textboxQuestion = question as TextboxQuestion;
      if(textboxQuestion.type === 'email'){
        validators.push(Validators.email);
      }

      group[question.key] = new FormControl(question.value, validators);
    });
    return new FormGroup(group);
  }
}
