import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DocumentService } from '../../../../services/http/document.service';
//import { EventEmitter } from 'events';
import { Documents } from '../../../../services/http/interfaces/documents';
@Component({
  selector: 'ngx-document-view-get',
  templateUrl: './document-view-get.component.html',
  //styleUrls: ['./document-view-get.component.scss']
})
export class DocumentViewGetComponent implements OnInit {
  public document: Documents;
  @Input('parentData') public fileMetaId;
  @Output() notify: EventEmitter<Documents> = new EventEmitter<Documents>();

  constructor(
    private documentService: DocumentService
  ) { }

  ngOnInit() {
    this.getDocumentDetails(this.fileMetaId);
  }
  getDocumentDetails(fileMetaId) {
    this.documentService.getDocumentDetails(fileMetaId).subscribe(
      fileMeta => {
        this.document = fileMeta;
        console.log('getDocumentDetails Success')
        //console.log(fileMeta);
        this.notify.emit(this.document);
      },
      err => console.error(err)
    );
  }
}
