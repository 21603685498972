import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Templates } from '../../../../services/http/interfaces/templates';
import { TemplateService } from '../../../../services/http/template.service';

@Component({
  selector: 'ngx-system-templates-get',
  templateUrl: './system-templates-get.component.html',
  styleUrls: ['./system-templates-get.component.scss']
})
export class SystemTemplatesGetComponent implements OnInit {
  public templates: Templates;
  @Output() notify: EventEmitter<Templates> = new EventEmitter<Templates>();
  //@Input('parentDataStatus') public status;
  constructor(
    private templateService: TemplateService
  ) { }

  ngOnInit() {
    this.getSystemTemplates();
  }
  getSystemTemplates() {
    this.templateService.getSystemTemplates().subscribe(
      templateMeta => {
        this.templates = templateMeta;
        console.log('getMyTemplates Success')
        //console.log(fileMeta);
        this.notify.emit(this.templates);
      },
      err => console.error(err)
    );
  }

}
