import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {MembersVisitService } from '../../../../services/members/members-visit.service';
import { Documents } from '../../../../services/http/interfaces/documents';

@Component({
  selector: 'ngx-member-document-member-get',
  templateUrl: './member-document-member-get.component.html',
  styleUrls: ['./member-document-member-get.component.scss']
})
export class MemberDocumentMemberGetComponent implements OnInit {
documentMember
@Input('parentData') public fileMetaId;
@Input('parentDataMemberId') public memberId;

@Output() notify: EventEmitter<Documents> = new EventEmitter<Documents>();
@Output() notifyInvalid: EventEmitter<boolean> = new EventEmitter<boolean>();

constructor(
  private membersService: MembersVisitService
) { }

  ngOnInit() {
    this.getMyDocumentMembers(this.memberId, this.fileMetaId)
  }
  getMyDocumentMembers(memberId, fileMetaId) {
    this.membersService.getMyDocumentMembers(memberId, fileMetaId).subscribe(
      memberMeta => {
        this.documentMember = memberMeta[0];
        console.log('getDocumentMember Success')
        console.log(memberMeta);
        this.checkIfMyDocumentMemberData(memberId, this.documentMember, fileMetaId);
      },
      err => console.error(err)
    );
  }
  checkIfMyDocumentMemberData(memberId, fileMember, fileMetaId) {
    this.membersService.checkIfMyDocumentMemberData(memberId, fileMember.id, fileMetaId).subscribe(
      meta => {
        var memberMeta = meta[0]
        var countMeta = meta[1];
        console.log(countMeta);
        console.log('getDocumentMemberData Success')
        console.log(memberMeta)
        if(!memberMeta[0] && countMeta.count=== fileMember.order){
          this.notifyInvalid.emit(false);
        }
        else{
          this.notifyInvalid.emit(true);
          //this.notify.emit(this.documentMember);
        }
        
      },
      err => console.error(err)
    );
  }

  
}
