import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import {NbMenuService} from '@nebular/theme'
@Component({
  selector: 'ngx-sidebar-documents',
  templateUrl: './sidebar-documents.component.html',
  styleUrls: ['./sidebar-documents.component.scss']
})
export class SidebarDocumentsComponent implements OnInit {
   @Input('parentData') public tab;
  @Output() notify: EventEmitter<string> = new EventEmitter<string>();
  public draft='draft';
  public ready='ready';
  public awaiting='awaiting';
  public completed='completed';
  //public 
  constructor(
    private nbMenuService: NbMenuService,
    private router: Router,
  ) { 
    if (!this.tab){
      //this.tab = 'ready';
    }
  }
  changeTab(tabName) {
    this.tab = tabName;
    this.fireEvent(tabName);
    this.router.navigate(['documents/', tabName]);
		// function hasClass(element, className) {
		// 	return (' ' + element.className + ' ').indexOf(' ' + className+ ' ') > -1;
		// }
		// // if(tabName == 'ready'){
		// 	document.getElementById('awaiting-link').classList.add("active");
		// 	document.getElementById('waiting-link').classList.remove("active");
		// 	document.getElementById('completed-link').classList.remove("active");
		// }
		// if(tabName == 'awaiting'){
		// 	document.getElementById('awaiting-link').classList.remove("active");
		// 	document.getElementById('waiting-link').classList.add("active");
		// 	document.getElementById('completed-link').classList.remove("active");
		// }
		// if(tabName == 'completed'){
		// 	document.getElementById('awaiting-link').classList.remove("active");
		// 	document.getElementById('waiting-link').classList.remove("active");
		// 	document.getElementById('completed-link').classList.add("active");
		// }
  }
  fireEvent(data:string){
    console.log('notify');
    this.notify.emit(data);
    
  }
  ngOnInit() {
		this.nbMenuService.onItemClick()

  }

}
