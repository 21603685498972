import { Component, OnInit, TemplateRef, ElementRef, Input, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { concatMap } from 'rxjs/operators';
import { of as observableOf } from 'rxjs';
import { NbDialogService } from '@nebular/theme';
import { Documents } from '../../../services/http/interfaces/documents';
import { UserData } from '../../../data/users';
import { DocumentService } from '../../../services/http/document.service';
import { DocumentContextService } from '../document-context.service';

@Component({
	selector: 'ngx-documents-status-view',
	templateUrl: './documents-status-view.component.html',
	styleUrls: ['./documents-status-view.component.scss']
})
export class DocumentsStatusViewComponent implements OnInit, AfterViewInit {
	@Input('parentData') public status;
	@ViewChild('downloader') downloader: ElementRef;
	@Output() notifyDocument: EventEmitter<string> = new EventEmitter<string>();
	public documentsData;
	contextNestedMenuItems = [{ title: 'Awaiting My Signature', link: '/awaiting' }, { title: 'Waiting For Others', link: '/waiting' }, { title: 'Completed', link: '/completed' }];

	/** Number of times a rename action has been triggered */
	renameClick = 0;

	/** Display name of the current document */
	currentDocumentDisplayName = '';

	/** Object to retrieve visits to a document */
	@ViewChild('visitsGetter') visitsGetter:any;

	/** Current document being processed */
	currentDocument:any;

	/** The current page being displayed */
	currentPage:number = 1;

	/** The number of items to display per page */
	itemsPerPage:number = 10;

	/** Total items to display */
	totalItems:number = -1;

	/** Indicates if a new page of data is being loaded through pagination */
	isLoadingPagedData:boolean = false;

	constructor(
		private router: Router,
		private dialogService: NbDialogService,
		private userSvc: UserData,
		private documentService: DocumentService,
		private docContextService: DocumentContextService
	) { }
	open(dialog: TemplateRef<any>) {
		this.dialogService.open(dialog, { context: 'this is some additional data' });
	}
	loading
	ngOnInit() {
		//  console.log(this.documents);
		this.loading = true;

		this.documentService.getDocumentCountbyStatus(this.status).subscribe(
			result => { this.totalItems = result.count; }
		);
	}
	ngAfterViewInit() {
		console.log('afterViewInit')
	}

	/**
	 * Executes actions when a new page of data is requested in the pagination.
	 * @param page The new page index. First index 1.
	 */
	onPageChanged(page:number){
		this.isLoadingPagedData = true;
		this.currentPage = page;
		this.documentService.getDocumentsPaginatedByStatus(this.status, this.currentPage, this.itemsPerPage).subscribe(
			documents => { 
				this.documentsData = documents; 
				this.isLoadingPagedData = false;
			}
		);
	}

	getDocuments(data: Documents) {
		console.log("getDocuments");
		console.log(data);
		this.documentsData = data;
		console.log('getdocuments tirgger');
		setTimeout(() => this.loading = false, 1000);
	}
	changeTab(status) {
		this.router.navigate(['/documents/', status]);
	}
	//Items to be moved to the Document context menu component
	onContextMenuItemClick(event, action) {
		console.log(event.data);
		switch (action) {
			case 'edit':
				this.docContextService.goToWebEditor(event.data.location, event.data.id);
				break;

			case 'details':
				this.docContextService.goToView(event.data);
				break;
			case 'sign':
				//??Go to Web Signer
				this.docContextService.goToWebSigner(event.data.id);

				break;
			case 'reminder':
				this.currentDocument = event.data;
				this.visitsGetter.getDocumentMembersVisits(event.data.id); // This will trigger the process to send a reminder. See 'listenOrderedMembers' below
				break;
			case 'download':
				this.docContextService.downloadFile(event.data, this.downloader);
				break;
			case 'resend':
				this.docContextService.sendDownloadLinkToAllMembers(event.data);
				break;
			case 'void':
				this.voidDocument(event.data)
				break;
			case 'myDocuments':
				this.docContextService.addToMyDocuments(event.data);
				break;
			case 'rename':
				this.currentDocument = event.data;
				this.currentDocumentDisplayName = this.currentDocument.displayName;
				this.renameClick++;
				break;
		}
	}

	/**
	 * Updates the name of the current document.
	 * @param newName The new name for the current document.
	 */
	updateCurrentDocumentName(newName:string):void{
		this.currentDocument.displayName = newName;
		this.documentService.UpdateDocumentDetails(this.currentDocument.id, this.currentDocument).subscribe(
			documentDetails => { },
			error => { 
				console.error('Cannot update the document specified below.');
				console.log(this.currentDocument);
			}
		);
	}

	voidDocument(document) {
		console.log('voidDocument')
		if (document.status === 'completed') {
			alert('You cannot void a completed document.')
		}
		else {
			if (confirm('Are you sure you want to void the document: ' + document.displayName + '?')) {
				document.status = 'void';
				this.documentService.UpdateDocumentDetails(document.id, document).subscribe(
					fileMeta => {
						console.log('Void Success');
						//console.log(fileMeta);
						var spliceId = this.documentsData.findIndex(function (item) {
							return item.id === document.id;
						})
						this.documentsData.splice(spliceId, 1);
						//alert('Success: Your document has been voided.');
					},
					err => console.log(err)
				)
			}
		}


	}

	/**
	 * Listen for changes in the list of members of the document, ordered by signing priority.
	 * @param members The new members being received.
	 */
	listenOrderedMembers(members){
		this.docContextService.sendReminder(this.currentDocument, members);
	}

	// goToView(fileMeta) {
	// 	if(fileMeta.fileType){
	// 		this.router.navigate(['/my-documents/', fileMeta.id]);
	// 	}
	// 	else{
	// 		this.router.navigate(['/documents/view/', fileMeta.id]);
	// 	}	
	// }
	// goToMyDocumentsView(folderId) {
	// 	if(!folderId){
	// 		folderId = 0
	// 	}
	// 	this.router.navigate(['/my-documents/folders/', folderId]);
	// }
	// goToWebEditor(pdfLocation, fileMetaId) {
	// 	this.router.navigate(['/editor/', fileMetaId]);
	// }
	// goToWebSigner(fileMetaId) {
	// 	this.router.navigate(['/signer/', fileMetaId]);
	// }
	// goToWebViewer(pdfLocation, fileMetaId) {
	// 	this.router.navigate(['/view', { location: pdfLocation, id: fileMetaId }]);
	// }
	// downloadFile(fileName) {
	// 	this.userSvc.downloadFile(fileName).subscribe(
	// 		data => {
	// 			const URL = window.URL.createObjectURL(data);
	// 			const link = this.downloader.nativeElement;
	// 			link.href = URL;
	// 			link.download = fileName;
	// 			link.click();
	// 			window.URL.revokeObjectURL(URL);
	// 		},
	// 		err => console.error(err)
	// 	);
	// }

	// deleteFile(fileName, fileId) {
	// 	this.userSvc.deleteFile(fileName)
	// 		.pipe(
	// 			concatMap(response => {
	// 				let result$;
	// 				switch (response.status) {
	// 					case 204:
	// 						// bug?: are 204s being converted to 200 by angular http client?
	// 						result$ = this.userSvc.deleteFileMeta(fileId);
	// 						break;
	// 					case 200:
	// 						// bug: loopback logs errors and still returns 200 status code?
	// 						result$ = this.userSvc.deleteFileMeta(fileId);
	// 						break;
	// 					default:
	// 						result$ = observableOf(Error('unhandled response code'));
	// 				}
	// 				return result$;
	// 			})
	// 		).subscribe(
	// 			data => {
	// 				// TODO: remove single item from UI instead of refreshing
	// 				//this.getFileMetas();
	// 				console.log(data);
	// 				console.log('file deleted');
	// 			},
	// 			err => console.error(err)
	// 		);
	// }

	// addToMyDocuments(document) {
	// 	if(document.fileType){
	// 		this.goToMyDocumentsView(document.folderId)
	// 	}
	// 	else{
	// 		console.log('addToMyDocuments')
	// 		document.fileType = 'myDocument';
	// 		this.documentService.UpdateDocumentDetails(document.id, document).subscribe(
	// 			fileMeta => {
	// 				console.log('Update Success');
	// 			},
	// 			err => console.log(err)
	// 		)	
	// 	}

	// }
	// voidDocument(document) {
	// 	console.log('voidDocument')
	// 	if (confirm('Are you sure you want to void the document: ' + document.displayName + '?')) {
	// 		document.status = 'void';
	// 		this.documentService.UpdateDocumentDetails(document.id, document).subscribe(
	// 			fileMeta => {
	// 				console.log('Void Success');
	// 				//console.log(fileMeta);
	// 				var spliceId = this.documentsData.findIndex(function (item) {
	// 					return item.id === document.id;
	// 				})
	// 				this.documentsData.splice(spliceId, 1);
	// 				//alert('Success: Your document has been voided.');
	// 			},
	// 			err => console.log(err)
	// 		)
	// 	}
	// }
}
