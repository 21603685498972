import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ngx-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})

export class DownloadComponent implements OnInit {
documentData;
fileMetaId
memberId
  constructor(
    private route:ActivatedRoute
  ) { 
    this.route.paramMap.subscribe(params => {
      this.fileMetaId = params.get('id');
      if(params.get('memberId')){
        this.memberId = params.get('memberId');
      }
    })
  }

  ngOnInit() {
    console.log('you are in download component')
  }

  getDocument(data){
    console.log(data)
    this.documentData = data;
  }
}
