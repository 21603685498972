import { Component, OnInit } from '@angular/core';
import {VisitService} from '../../../../services/http/visit.service';

@Component({
  selector: 'ngx-owner-visit-count-daterange',
  templateUrl: './owner-visit-count-daterange.component.html',
  styleUrls: ['./owner-visit-count-daterange.component.scss']
})
export class OwnerVisitCountDaterangeComponent implements OnInit {
visitsCount = [];
  constructor(
    private visitService : VisitService
  ) { }

  ngOnInit() {
  }
  getVisitCountbyOwnerbyDateRange(dateRange) {
    var count, increment;
    var query = [];
    if (dateRange == "week"){
      count = 7;
      increment = "day";
    }
    else if (dateRange == "month"){
      count = 30;
      increment = "day";
    }
    else if (dateRange =="year"){
      count = 12;
      increment = "month";
    }
    else {
      console.log('Error: Date Range Not Recognized:'+ dateRange + 'is not a valid date range');
      count = 7;
      increment = "day";
    }

    var i;
    for(i=0; i<count; i++){
      if (increment === "day"){
        //Rafael to provide a sample and copy
        //query[i]= '"date":' .today - (count - i)  ;
      }
  
      else if (increment == "month" ){
        
        //var date = today;
        //this.date.daysInMonth();
      }
      
      // this.visitService.getVisitCountbyOwnerbyDateRange(dateRange).subscribe(
      //   count => {
      //     console.log(count);
      //     this.visitsCount[i] = count
      //   },
      //   err => console.error(err)
      // );
    }
	}
}
