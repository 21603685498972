import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import{FormrlyUsersContact} from '../../../../services/http/interfaces/formrly-users-contact';
import{FormrlyUsersService} from '../../../../services/http/formrly-users.service';

@Component({
  selector: 'ngx-formrly-user-contact-get',
  templateUrl: './formrly-user-contact-get.component.html',
  styleUrls: ['./formrly-user-contact-get.component.scss']
})
export class FormrlyUserContactGetComponent implements OnInit {
  @Input('parentData') public contactId;
  @Output() notify: EventEmitter<FormrlyUsersContact> = new EventEmitter<FormrlyUsersContact>();
  public contactDetail;
  constructor(
     public contactListService: FormrlyUsersService
   ) { }

  ngOnInit() {
    this.getMyContactListDetail(this.contactId);
  }
  
  getMyContactListDetail(contactId){
		this.contactListService.getMyContactListDetail(contactId).subscribe(
      contactListMeta => {
        this.contactDetail = contactListMeta;
        console.log('getMyContactListDetail success')
        //console.log( contactListMeta);
        this.notify.emit(this.contactDetail);
      },
        err => console.error(err)
    );
  }

}
