import { Component, OnInit, OnChanges, Output, EventEmitter, Input, SimpleChange, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { NbDialogService } from '@nebular/theme';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DocumentService } from '../../../services/http/document.service';
import { WebviewerTemplateService } from '../webviewer-template/webviewer-template.service'
import { concatMap } from 'rxjs/operators';
@Component({
  selector: 'ngx-wv-sidebar-template',
  templateUrl: './wv-sidebar-template.component.html',
  styleUrls: ['./wv-sidebar-template.component.scss']
})
export class WvSidebarTemplateComponent implements OnInit {
  @ViewChild('popupClose') popupClose: ElementRef<HTMLElement>;
  @ViewChild('toggleCheckBox') toggleCheckBox: ElementRef<HTMLElement>;

  drop(event) {

  }
  checkboxValue: boolean;

  newFunction() {
    if (!this.checkboxValue) {
      console.log('not checked')
    }
    else {
      console.log('checked')
      //actually this else part shouldn't be needed
    }
  }


  member: FormGroup;

  public changeLog;
  public _fieldProperties;
  public fieldSettingRequired;
  public _annotations;
  public selectedAnnotation;

  public fieldCounts = [{
    author: '',
    required: 0,
    optional: 0
  }];

  // @Input() fieldProperties: any;
  //@Input() public documentData;
  //@Input() public documentMembers;
  public addMemberDetails;

  // @Input()
  // set annotations(annotations: any) {
  //   console.log('annotations');
  //   this._annotations = (annotations && annotations) || '';
  //   console.log('annotations');
  //   console.log(this._annotations);
  // }
  // get annotations(): any { return this._annotations; }


  // @Input()
  // set fieldProperties(fieldProperties: any) {
  //   console.log('fieldProperties');
  //   this._fieldProperties = (fieldProperties && fieldProperties) || '';
  //   // if (fieldProperties) {
  //   //   this.selectedAnnotation = this._annotations.find((e) => {
  //   //     return e.id === fieldProperties;
  //   //   });
  //   //   this.tab = 'settings';
  //   // }
  //   console.log('fieldProperties');
  //   console.log(this._fieldProperties);
  // }
  // get fieldProperties(): any { return this._fieldProperties; }

  field = 'fields';
  //tab = 'fields';
  tab
  fieldSettingMember = 'unassigned';
  changeTab(tabName) {
    //this.tab = tabName;
    this.wvTemplateService.changeTab(tabName);
  }
  async changeFieldSettingMember(member) {
    console.log('changeFieldSettingMember');
    console.log(this.selectedAnnotation);
    console.log(this.wvTemplateService.annotManager.getCurrentUser());
    if (this.selectedAnnotation.author === this.wvTemplateService.annotManager.getCurrentUser()) {
      console.log('pass myField');
      var selectedAnnotation = this.wvTemplateService.annotManager.getAnnotationById(this.selectedAnnotation.id);
      if (selectedAnnotation.Subject === 'Signature') {
        alert('You must clear signature from field before changing the assigned member.')
        return false;
      }
      else if (selectedAnnotation.CustomData && selectedAnnotation.CustomData.type) {
        switch (selectedAnnotation.CustomData.type) {
          case 'full-signature':
            console.log('Signature');
            if (selectedAnnotation.isGrouped() || selectedAnnotation.getGroupedChildren()[0]) {
              alert('You must clear signature from field before changing the assigned member.')
              return false;
            }
            break;
          case 'initial-signature':
            console.log('Signature');
            if (selectedAnnotation.isGrouped() || selectedAnnotation.getGroupedChildren()[0]) {
              alert('You must clear signature from field before changing the assigned member.')
              return false;
            }
            break;
          default:
            console.log('default')
            if (selectedAnnotation.getContents()) {
              alert('You must empty field contents before changing the assigned member.')
              return false;
            }
            break;
        }
      }
    }
    console.log('changeFieldSettingMember wvRC');
    this.fieldSettingMember = member;
    var selectedAnnotation =this.selectedAnnotation;
    selectedAnnotation.author = member;
    await this.wvTemplateService.updateField(this.selectedAnnotationId, 'member', member, this.documentMembers)
    this.wvTemplateService.changeSelectedAnnotation(selectedAnnotation);
    this.triggerFalseClick()

  }

  clickRequired(e) {
    //e.preventDefault();
    console.log('click requried');
    //Repeat from changeFieldSettingMember(member) with changes to message
    if (this.selectedAnnotation.author === this.wvTemplateService.annotManager.getCurrentUser()) {
      console.log('pass myField');
      var selectedAnnotation = this.wvTemplateService.annotManager.getAnnotationById(this.selectedAnnotation.id);
      if (selectedAnnotation.Subject === 'Signature') {
        alert('You must clear signature from field before changing the required setting.')
        //this.selectedAnnotation.required = true;
        //return false;
        e.preventDefault();
      }
      else if (selectedAnnotation.CustomData && selectedAnnotation.CustomData.type) {
        switch (selectedAnnotation.CustomData.type) {
          case 'full-signature':
            console.log('Signature');
            if (selectedAnnotation.isGrouped() || selectedAnnotation.getGroupedChildren()[0]) {
              alert('You must clear signature from field before changing the required setting.')
              e.preventDefault();
              //return false;
            }
            break;
          case 'initial-signature':
            console.log('Signature');
            if (selectedAnnotation.isGrouped() || selectedAnnotation.getGroupedChildren()[0]) {
              alert('You must clear signature from field before changing the required setting.')
              //return false;
              e.preventDefault();
            }
            break;
          default:
            console.log('default')
            if (selectedAnnotation.getContents()) {
              alert('You must empty field contents before changing the required setting.')
              e.preventDefault();
              return false;
            }
            break;
        }
      }
    }
  }
  changeFieldSettingRequired(data) {
    data.preventDefault();
    console.log('changeFieldSettingRequired')
    console.log(data)
    console.log(this.selectedAnnotation);



    this.wvTemplateService.changeSelectedAnnotation(this.selectedAnnotation);
    this.wvTemplateService.updateField(this.selectedAnnotationId, 'required', this.selectedAnnotation.required, this.documentMembers)


  }
  constructor(
    public breakpointObserver: BreakpointObserver,
    public dialogService: NbDialogService,
    public documentService: DocumentService,
    private fb: FormBuilder,
    private wvTemplateService: WebviewerTemplateService

  ) {
    this.member = this.fb.group({
      memberId: "123",
      shareLink: "123",
      order: 0,
      FormrlyUsersContactId: "",
      FileMetaId: ""
    });
  }
  drag(event) {
    console.log('dragstart');
    event.preventDefault;
    console.log(event);
    console.log(event.toElement.id);
    //Notify the Name of the button;
    this.wvTemplateService.changeDragElementId(event.toElement.id)
    //this.notifyDrag.emit(event.toElement.id);
  }
  allowDrop(ev) {
    ev.preventDefault();
    console.log('allow Drop');
  }
  open(dialog: TemplateRef<any>) {
    console.log(dialog);
    //this.tabStep ='fileSelection';
    //this.tabStep ='memberSelection';
    this.dialogService.open(dialog, { context: 'this is some additional data' });
  }
  triggerFalseClick() {
    let el: HTMLElement = this.popupClose.nativeElement;
    el.click();
  }
  triggerToggleClick() {
    let el: HTMLElement = this.toggleCheckBox.nativeElement;
    el.click();
  }


  //Tool Bar Toggle Functions
  hasClass(element, className) {
    return (' ' + element.className + ' ').indexOf(' ' + className + ' ') > -1;
  }

  toggleToolbar() {
    console.log('this was clicked');
    //		this.sidebarService.toggle(true, 'sidebarToolbar');
    if (this.hasClass(document.getElementById("frmrly-nested-sidebar-right"), 'compacted')) {
      document.getElementById("frmrly-nested-sidebar-right").classList.remove("compacted");
      document.getElementById("frmrly-nested-sidebar-right").classList.add("expanded");
      console.log('toggleif');
    }
    else {
      document.getElementById("frmrly-nested-sidebar-right").classList.add("compacted");
      document.getElementById("frmrly-nested-sidebar-right").classList.remove("expanded");
      console.log('toggleelse');

    }

    if (this.hasClass(document.getElementById("webviewer"), 'compacted-webviewer')) {
      document.getElementById("webviewer").classList.remove("compacted-webviewer");
      //document.getElementById("webviewer").classList.remove("compacted-webviewer");
    }
    else {
      document.getElementById("webviewer").classList.add("compacted-webviewer");
      //	document.getElementById("webviewer").classList.remove("compacted-webviewer");
    }
  }

  newMessage() {
    this.wvTemplateService.changeMessage("Hello from Sibling")
  }
  message;
  dragId
  documentMembers
  fieldCountList
  selectedAnnotationId
  xfdfString
  documentData
  annotationList
  oldSelectedAnnotation
  ngOnInit() {
    this.wvTemplateService.currentMessage.subscribe(message => this.message = message)

    this.wvTemplateService.currentDragElementId.subscribe(message => this.dragId = message)
    this.wvTemplateService.currentDocumentMembers.subscribe(message => this.documentMembers = message)
    this.wvTemplateService.currentFieldCountList.subscribe(message => this.fieldCountList = message)
    this.wvTemplateService.currentSelectedAnnotationId.subscribe(message => this.selectedAnnotationId = message)
    this.wvTemplateService.currentSelectedAnnotation.subscribe(message => {
      this.selectedAnnotation = message
      // if (this.selectedAnnotation.id === this.oldSelectedAnnotation.id){
      // }
      // else{
      //   this.oldSelectedAnnotation = message
      //   console.log('selectedAnnotation subscribe');
      //   console.log(this.selectedAnnotation)
      //   console.log(this.checkboxValue)
      //   this.checkboxValue = null;
      //   if (this.selectedAnnotation.required==='true'){
      //     console.log('change to true')
      //     //this.checkboxValue = true;
      //   }
      //   else if (this.selectedAnnotation.required==='false' && this.checkboxValue != false){
      //     console.log('change to false')
      //     //this.checkboxValue = false;
      //   }
      // }

    })
    this.wvTemplateService.currentXfdfString.subscribe(message => this.xfdfString = message)
    this.wvTemplateService.currentDocumentData.subscribe(message => this.documentData = message)
    this.wvTemplateService.currentAnnotationList.subscribe(message => this.annotationList = message)
    this.wvTemplateService.currentTab.subscribe(message => this.tab = message)


    //Tool Bar Functions for Breakpoint Observer
    function hasClass(element, className) {
      return (' ' + element.className + ' ').indexOf(' ' + className + ' ') > -1;
    }
    //EXPAND SIDEBAR AUTOMATICALLY FOR LARGER VIEW
    this.breakpointObserver
      .observe(['(min-width: 992px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {

          //Removes Sidebar Compacted Class
          if (hasClass(document.getElementById("frmrly-nested-sidebar-right"), 'compacted')) {
            document.getElementById("frmrly-nested-sidebar-right").classList.remove("compacted");
            document.getElementById("frmrly-nested-sidebar-right").classList.add("expanded");

            //console.log('if1');
          }
          else if (hasClass(document.getElementById("frmrly-nested-sidebar-right"), 'expanded')) {
            //console.log('elseif1');
          }
          else {
            //console.log('else1');
            document.getElementById("frmrly-nested-sidebar-right").classList.add("expanded");
          }
          //Removes Webviewer Compacted Class
          if (hasClass(document.getElementById("webviewer"), 'compacted-webviewer')) {
            document.getElementById("webviewer").classList.remove("compacted-webviewer");
            //console.log('if1b');
          }


        } else {
          if (hasClass(document.getElementById("frmrly-nested-sidebar-right"), 'compacted')) {
            console.log('if2');
          }
          else {
            document.getElementById("frmrly-nested-sidebar-right").classList.add("compacted");
            console.log('else2');
          }
        }
      });
  }

  ngOnChanges() {
    console.log('changes in webviewer sidebar right')
    // 
    // this.selectedAnnotation = this.annotationList.find((e) => {
    //   return e.id === this.selectedAnnotationId;
    // });
    // if (this.selectedAnnotationId) {
    // this.tab = 'settings';
    // }
    // }
  }

  getFieldCounts(annotations, documentMembers) {
    console.log('getFieldCounts');
    console.log(annotations);
    this.fieldCounts[0].author = 'unassigned';
    this.fieldCounts[0].required = 0;
    this.fieldCounts[0].optional = 0;
    var memberAnnotations = annotations.filter((e) => {
      //return e.author === documentMembers[i].id;
      return (e.author === null || e.author === 'unassigned');
    });
    console.log('unassignedAnnotations');
    console.log(memberAnnotations);
    for (let ii = 0; ii < memberAnnotations.length; ii++) {
      if (memberAnnotations[ii].required === 'true') {
        this.fieldCounts[0].required = this.fieldCounts[0].required + 1;
      }
      else {
        this.fieldCounts[0].optional = this.fieldCounts[0].optional + 1;
      }
    }


    for (let i = 0; i < documentMembers.length; i++) {
      this.fieldCounts[i + 1] = {
        author: '',
        required: 0,
        optional: 0
      };
      this.fieldCounts[i + 1].author = documentMembers[i].id;
      this.fieldCounts[i + 1].required = 0;
      this.fieldCounts[i + 1].optional = 0;
      var memberAnnotations = annotations.filter((e) => {
        //return e.author === documentMembers[i].id;
        return e.author === documentMembers[i].email;
      });
      console.log('memberAnnotations');
      console.log(memberAnnotations);
      for (let ii = 0; ii < memberAnnotations.length; ii++) {
        if (memberAnnotations[ii].required === 'true') {
          this.fieldCounts[i + 1].required = this.fieldCounts[i + 1].required + 1;
        }
        else {
          this.fieldCounts[i + 1].optional = this.fieldCounts[i + 1].optional + 1;
        }
      }
    }
    console.log(this.fieldCounts);
    this.wvTemplateService.changeFieldCountList(this.fieldCounts);
    if (this.documentMembers != documentMembers) {
      this.wvTemplateService.changeDocumentMembers(documentMembers);
    }


  }
  ////
  getMember(data: any) {
    console.log("This is data");
    console.log(data);
    //this.contactsList = data;
    this.addMemberDetails = data;
    this.member.controls['FormrlyUsersContactId'].setValue(data.id);
    console.log('memberResult tirgger');
    console.log(this.member.value);
    console.log(this.documentData);
    this.addDocumentMember(this.documentData.id, data, this.member.value);

  }
  getCancelClick(data) {
    this.triggerFalseClick()
  }

  addDocumentMember(fileMetaId, memberData, fileMember) {
    console.log(fileMember);
    //fileMember.FileMetaId = fileMetaId;
    fileMember.memberId = '123';
    fileMember.shareLink = '123';
    //fileMember.order = this.documentMembers.length;
    //console.log(fileMember.order);
    //fileMember.controls['FileMetaId'].setValue(fileMetaId); 

    console.log('The File Member before adding new member')
    console.log(fileMember);
    var documentMembersLast = this.documentMembers.length - 1;
    console.log(documentMembersLast);
    var documentMembersLastId
    if (documentMembersLast === -1) {
      documentMembersLastId = -1;
    }
    else {
      documentMembersLastId = this.documentMembers[documentMembersLast].id;
    }

    this.documentService.getDocumentMemberByMemberId(fileMetaId, documentMembersLastId).pipe(
      concatMap(lastMember => {
        console.log('Last Member Details');
        console.log(lastMember);
        console.log(fileMember);
        if (!lastMember[0]) {
          fileMember.order = 0;
        }
        else {
          fileMember.order = lastMember[0].order + 1;
        }

        return this.documentService.addDocumentMember(fileMetaId, fileMember);
      })
    ).pipe(
      concatMap(fileMember => {
        console.log('Member Details');
        console.log(fileMember);
        return this.documentService.getDocumentMemberByMemberId(fileMetaId, fileMember.FormrlyUsersContactId);
      })
    ).subscribe(
      membersData => {
        console.log('successful update');
        console.log(membersData);
        var docMembers = this.documentMembers


        //docMembers.push(fileMember);
        //const docMembers = membersData
        docMembers.push(memberData);
        console.log(docMembers);

        this.getFieldCounts(this.annotationList, docMembers);
        this.triggerFalseClick();
      },
      err => console.error(err)
    );

  }
  getAssignFields(data) {
    console.log('getAssigned Fields received in right side component')
    console.log(data);
    //this.notifyAssignFields.emit(data);
    //To replace???
  }
  getSendDocument(data) {
    console.log('getSendDocument received in right side component')
    console.log(data);
    //this.notifySendDocument.emit(data);
    // to replace ??
  }

  deleteDocumentMemberConfirmation(member) {
    console.log('deleteDocumentMemberConfirmation');
    var memberFieldCountList = this.fieldCountList.filter((e) => { return e.author === member.id; })
    console.log(this.fieldCountList);
    console.log(memberFieldCountList);
    if (memberFieldCountList[0].required === 0 && memberFieldCountList[0].optional === 0) {
      if (confirm('Are you sure you want to remove ' + member.name.first + ' ' + member.name.last + ' from the document?')) {
        this.deleteDocumentMember(member);
      }
    }
    else {
      alert('You cannot remove this member, while member has assigned fields');
    }

  }



  deleteDocumentMember(member) {
    console.log('deleteDocumentMember');
    console.log(member);
    console.log(this.fieldCountList);
    console.log(this.documentMembers);

    this.documentService.getDocumentMemberByMemberId(this.documentData.id, member.id).pipe(
      concatMap(fileMember => {
        console.log('Member Details');
        console.log(fileMember);
        return this.documentService.deleteDocumentMember(this.documentData.id, fileMember[0].id);
      })
    ).subscribe(
      result => {
        console.log('Delete Success');
        console.log(result);
        var docMembers = this.documentMembers;
        var fieldCountList = this.fieldCountList;

        // let tempFiles = files.slice(0);
        console.log(
          docMembers.findIndex(function (item) {
            return item.id === member.id;
          })

        );

        var memberSpliceId = docMembers.findIndex(function (item) {
          return item.id === member.id;
        })

        docMembers.splice(memberSpliceId, 1)
        this.wvTemplateService.changeDocumentMembers(docMembers);
        var countSpliceId = fieldCountList.findIndex(function (item) {
          return item.id === member.author;
        })

        fieldCountList.splice(countSpliceId, 1)
        const annotations = this.wvTemplateService.instance.annotManager.getAnnotationsList();

        this.wvTemplateService.buildAnnotationList(annotations, this.wvTemplateService.instance.annotManager, docMembers);
        this.wvTemplateService.redrawAnnotationBordersAfterDelete(docMembers, annotations)
        //this.wvTemplateService.changeFieldCountList(fieldCountList);

        // var myArray = docMembers;
        // for (var i = 0 ;i < myArray.length;  i++) {
        //   if (myArray[i].id === member.id) {
        //     myArray.splice(i, 1);
        //   }
        // }


        // var myArray = fieldCountList;
        // for (var i = 0 ;i < myArray.length;  i++) {
        //   if (myArray[i].author === member.id) {
        //     myArray.splice(i, 1);
        //   }
        // }

        // this.documentService.deleteDocumentMember(this.documentData.id, member).

        //this.dialogRef.close();
        // this.ref.close()
        //  this.router.navigate(['/view', { location: pdfLocation, id: fileMetaId }]);
        // const docMembers = this.documentData
        // //docMembers.push(memberData);
        // this.wvTemplateService.changeDocumentMembers(docMembers)
        // this.getFieldCounts(this._annotations, this.documentMembers);
        // this.triggerFalseClick();
      },
      err => console.error(err)
    );
  }


  checkBeforeSetAsActive(documentData) {
    console.log('checkBeforeSetAsActive');
    console.log(this.annotationList)
    console.log(this.fieldCountList)
    if (this.checkIfNoUnassignedFields() === false) {
      console.log('false 1');
      alert('Cannot Save, all fields must be assigned');
      //
    }
    else if (this.checkAllMembersHasFields() === false) {
      console.log('false 2')
      alert('Cannot Save, all members must be assigned at least one member field.')
    }

    else {
      console.log('true')
      if (confirm('Are you sure you want to set this template as active?  Once active you cannot make any changes.')) {
        this.setTemplateAsActive(documentData)
      }

    }
  }
  checkIfNoUnassignedFields() {
    if (this.fieldCountList[0].template + this.fieldCountList[0].required + this.fieldCountList[0].optional > 0) {
      return false;
    }
    else return true;
  }
  checkAllMembersHasFields() {
    for (let i = 1; i < this.fieldCountList.length; i++) {
      if (this.fieldCountList[i].required + this.fieldCountList[i].optional === 0) {
        console.log('false' + i)
        return false;
      }
    }
    return true;
  }
  async setTemplateAsActive(documentData) {
    documentData.status = 'active';
    await this.setAllFieldsAsNoMove()

    console.log('set template as active')


     this.wvTemplateService.setTemplateActive(documentData.id, documentData, this.xfdfString);
  }
  async setAllFieldsAsNoMove() {
    var currentAnnotation = [];
    console.log(this.annotationList.length);
    console.log(this.annotationList);
    let a = 0;
    for (let i = 0; i < this.annotationList.length; i++) {
      if(this.annotationList[i].isTempField === true || this.annotationList[i].author === 'Receiver'){
        console.log('first looped' + i)
        currentAnnotation[a] = this.wvTemplateService.annotManager.getAnnotationById(this.annotationList[i].id);
        a++;
      }
    }
    for (let i = 0; i < currentAnnotation.length; i++) {
      console.log('second loop' + i)
      console.log(currentAnnotation[i].LockedContents)
      console.log('lock check');
      console.log(currentAnnotation[i].Locked)
      
      if (currentAnnotation[i].LockedContents === true) {
        console.log('unlocking')
        await this.wvTemplateService.annotManager.deleteAnnotation(currentAnnotation[i]);
        //currentAnnotation[i].ReadOnly = true;
        //currentAnnotation[i].NoMove = true;
        //currentAnnotation[i].Locked = true;
        currentAnnotation[i].LockedContents = true;
        await this.wvTemplateService.annotManager.addAnnotation(currentAnnotation[i]);
        await this.wvTemplateService.annotManager.redrawAnnotation(currentAnnotation[i]);
        await this.wvTemplateService.annotManager.trigger('updateAnnotationPermission', currentAnnotation[i]);
      }
      else {
        console.log('locking')
        //currentAnnotation[i].ReadOnly = true;
        //currentAnnotation[i].NoMove = true;
        //currentAnnotation[i].Locked = true;
        await this.wvTemplateService.annotManager.updateAnnotation(currentAnnotation[i])
        await this.wvTemplateService.annotManager.redrawAnnotation(currentAnnotation[i]);
        await this.wvTemplateService.annotManager.trigger('annotationChanged', [[currentAnnotation[i]], 'modify', {}]);
        await this.wvTemplateService.annotManager.trigger('updateAnnotationPermission', currentAnnotation[i]);
      }
      console.log('readonly')
    }
    //trigger annotation Update

    console.log('made it.')
  }
}
