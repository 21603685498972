import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DocumentService } from '../../../../services/http/document.service';

@Component({
  selector: 'ngx-documents-members-data-get',
  templateUrl: './documents-members-data-get.component.html',
  styleUrls: ['./documents-members-data-get.component.scss']
})
export class DocumentsMembersDataGetComponent implements OnInit {
@Input('parentData') public documentId;
@Output() notifyMembersData: EventEmitter<any> = new EventEmitter<any>()
  constructor(
    private documentService:DocumentService

  ) { }

  ngOnInit() {
    this.getDocumentMembersData(this.documentId)
  }
  getDocumentMembersData(fileMetaId) {
    this.documentService.getDocumentMembersData(fileMetaId).subscribe(
      fileMembers => {
        console.log('getDocumentMembersData');
        //console.log(fileMembers);
        this.notifyMembersData.emit(fileMembers);
      })
  }
}
