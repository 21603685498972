import { Component, OnInit, ViewChild, TemplateRef, Input, Output, EventEmitter, ElementRef, AfterViewInit } from '@angular/core';
import { NbDialogService, NbDialogRef } from '@nebular/theme';
import { CropperComponent } from 'angular-cropperjs';

@Component({
  selector: 'ngx-choose-avatar-popover',
  templateUrl: './choose-avatar-popover.component.html',
  styleUrls: ['./choose-avatar-popover.component.scss']
})
export class ChooseAvatarPopoverComponent implements OnInit {

  // Size of the final image
  finalImageSize:number;

  // Source image 'src' attribute
  sourceImageSrc:any;

  // Initial value for the source image 'src' attribute
  @Input('source') initialSourceImageSrc:any;

  // Final image 'src' attribute
  @Output('avatarChosen') finalImageSrc = new EventEmitter<string>();

  // Cropper HTMl element
  @ViewChild('ngCropper')
  private ngCropper:CropperComponent;

  // Dialog HTML element
  @ViewChild('dialog')
  private dialog:TemplateRef<any>;

  // File chooser HTML element
  @ViewChild('fileChooser')
  private fileChooser:ElementRef<HTMLInputElement>;

  // Reference to the opened dialog
  private dialogRef:NbDialogRef<any>;

  // Configuration for the cropper
  private cropperConfig:object;

  // MIME type of the image being cropped
  private mimeType:string;

  // Current value of the canvas
  private canvasValue:string;

  /**
   * Initializes the instance.
   * @param dialogService Service to manipulate Nebular dialogs.
   */
  constructor(
    private dialogService:NbDialogService
  ) { }

  /**
   * Initializes the Angular component. 
   */
  ngOnInit(){
    this.cropperConfig = {
      viewMode: 1,
      aspectRatio: 1,
      autoCropArea: 0.5,
      minCropBoxWidth: 150,
      minCropBoxHeight: 150,
      crop: () => {
        this.finalImageSize = Math.round(this.ngCropper.cropper.getCropBoxData().width);
        let canvas = this.ngCropper.cropper.getCroppedCanvas();
        this.canvasValue = canvas.toDataURL(this.mimeType);
      }
    };
  }

  /**
   * Initializes the Angular component after the view is fully initialized.
   */
  ngAfterViewInit(){
    
  }

  /**
   * Opens the dialog.
   */
  open(){
    this.sourceImageSrc = this.initialSourceImageSrc ? this.initialSourceImageSrc : '';
    if(this.sourceImageSrc){
      this.mimeType = this.sourceImageSrc.match(/:(.*?);/)[1];
    }
    this.dialogRef = this.dialogService.open(this.dialog);
  }

  /**
   * Closes the dialog.
   */
  close(){
    if(this.dialogRef){
      this.dialogRef.close();
      this.dialogRef = null;
      this.clear();
    }
  }

  /**
   * Specifies actions to execute when an image is chosen.
   * @param files List of chosen files. 
   */
  chooseImage(files:FileList){
    if(files.length){
      let isImage = files[0].type.match(/image\/*/) !== null;
      if(isImage){
        let reader = new FileReader();
        reader.onload = () => { 
          this.sourceImageSrc = reader.result; 
          this.mimeType = files[0].type;
        };
        reader.onerror = () => { console.error(reader.error); };
        reader.readAsDataURL(files[0]);
      }      
    }
  }

  /**
   * Clears the dialog state. 
   */
  clear(){
    this.sourceImageSrc = '';
    this.mimeType = '';
    this.fileChooser.nativeElement.value = '';
    this.canvasValue = '';
  }

  /**
   * Apply all changes and closes the dialog.
   */
  applyChanges(){
    if(this.canvasValue){
      this.initialSourceImageSrc = this.canvasValue; // Save it just in case the dialog is reopened
      this.finalImageSrc.emit(this.canvasValue);
      this.close();
    }
  }
}