import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable, forkJoin} from 'rxjs';
import { FileMemberVisits} from '../http/interfaces/file-members-visits'

@Injectable({
  providedIn: 'root'
})
export class MembersVisitService {
	private api: string = environment.rest;

  constructor(
    private http: HttpClient
  ) { }



  getDocumentDetails(memberId, filemetaId): Observable<any> {
    return this.http.get(`${this.api}/FormrlyUsersContacts/${memberId}/FileMeta/${filemetaId}`);
  }
  updateDocumentDetails(memberId, fileMetaId, documentMeta): Observable<any> {
    return this.http.put(`${this.api}/FormrlyUsersContacts/${memberId}/FileMeta/${fileMetaId}`, documentMeta);
  }

  getMyDocumentMembers(memberId, filemetaId): Observable<any> {
    return this.http.get(`${this.api}/FormrlyUsersContacts/${memberId}/FileMeta/${filemetaId}/FilesMember?filter={"where":{"FormrlyUsersContactId":"${memberId}"}}`);
  }


  getMyMembersContactInfo(memberId): Observable<any> {
    return this.http.get(`${this.api}/FormrlyUsersContacts/${memberId}`);
  }


  updateMyMembersContactInfo(memberId, contactMeta): Observable<any> {
    return this.http.put(`${this.api}/FormrlyUsersContacts/${memberId}`, contactMeta);
  }

  checkIfMyDocumentMemberData(memberId, fileMemberId, fileMetaId): Observable<any> {
    let countData = this.http.get(`${this.api}/FormrlyUsersContacts/${memberId}/FileMeta/${fileMetaId}/FilesMembersData/count`);
    let myData =  this.http.get(`${this.api}/FormrlyUsersContacts/${memberId}/FilesMember/${fileMemberId}/FilesMembersData`);
    return forkJoin(myData, countData)
  }
  createFileMembersData(memberId, filemetaId, fileMembersData): Observable<any> {
    return this.http.post(`${this.api}/FormrlyUsersContacts/${memberId}/FileMeta/${filemetaId}/FilesMembersData`, fileMembersData);
  }

  getDocumentMembersCount(memberId, filemetaId): Observable<any> {
    return this.http.get(`${this.api}/FormrlyUsersContacts/${memberId}/FileMeta/${filemetaId}/FilesMember/count`);
  }

  getDocumentMembersAndDataCount(memberId, filemetaId): Observable<any> {
    let fileMembers = this.http.get(`${this.api}/FormrlyUsersContacts/${memberId}/FileMeta/${filemetaId}/FilesMember/count`);
    let fileMembersData = this.http.get(`${this.api}/FormrlyUsersContacts/${memberId}/FileMeta/${filemetaId}/FilesMembersData/count`);
    return forkJoin(fileMembers, fileMembersData);
  }

  getNextDocumentMember(memberId, filemetaId, nextCount): Observable<any> {
    return this.http.get(`${this.api}/FormrlyUsersContacts/${memberId}/FileMeta/${filemetaId}/FilesMember?filter={"where":{"order":${nextCount}}}`);
  }
  getNextDocumentMemberContact(myId,documentId,contactId): Observable<any> {
    return this.http.get(`${this.api}/FormrlyUsersContacts/${myId}/FileMeta/${documentId}/FormrlyUsersContact/${contactId}`)
  }
  // getDocumentMembersCount(memberId, filemetaId): Observable<any> {
  //   return this.http.get(`${this.api}/FormrlyUsersContacts/${memberId}/FileMeta/${filemetaId}/FilesMember/count`);
  // }

  getDocumentMembersDataCount(memberId, filemetaId): Observable<any> {
    return this.http.get(`${this.api}/FormrlyUsersContacts/${memberId}/FileMeta/${filemetaId}/FilesMembersData/count`);
  }


  createVisit(visitMeta: FileMemberVisits): Observable<FileMemberVisits> {
    return this.http.post<FileMemberVisits>(`${this.api}/FormrlyUsersContacts/${visitMeta.formrlyUsersContactId}/FilesMembersVisit`, visitMeta);
  }
  updateVisit(visitMeta: FileMemberVisits): Observable<FileMemberVisits> {
    return this.http.put<FileMemberVisits>(`${this.api}/FormrlyUsersContacts/${visitMeta.formrlyUsersContactId}/FilesMembersVisit/${visitMeta.id}`, visitMeta);
  }
}
