import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { VisitService } from '../../../../services/http/visit.service';
import { FormrlyUsersContact } from '../../../../services/http/interfaces/formrly-users-contact';
import { Visits } from '../../../../services/http/interfaces/visits';
import { Chart } from 'chart.js';

@Component({
  selector: 'ngx-document-visits-members',
  templateUrl: './document-visits-members.component.html',
  styleUrls: ['./document-visits-members.component.scss']
})
export class DocumentVisitsMembersComponent implements OnInit {
  @Input('parentData') public documentId;
  @Output() notifyVisits: EventEmitter<Visits> = new EventEmitter<Visits>();
  @Output() notifyMembers: EventEmitter<any> = new EventEmitter<any>();

  public visits: Visits;
  public members;
  fileMembers;
  membersData
  elementRef: any;
  //public documentId="5de18d8bfa5e2c78428271ac";
  //documentId will be an input

  constructor(
    public visitService: VisitService,
  ) { }

  ngOnInit() {
    if(this.documentId){
      this.getDocumentMembersVisits(this.documentId);
    }
    //this.chartLoad();
  }
  getDocumentMembersVisits(documentId) {
    this.visitService.getDocumentMembersVisits(documentId)
      .subscribe(
        fileMeta => {
          this.visits = fileMeta[0];
          this.members = fileMeta[1];
          this.fileMembers = fileMeta[2];
          this.membersData = fileMeta[3];
          this.orderMembers();


          this.notifyVisits.emit(fileMeta[0]);
          //this.notifyMembers.emit(fileMeta[1]);

        },
        err => console.error(err)
      );
  }
  orderMembers(){
    var membersOrdered = this.fileMembers.sort((a, b) => (a.order > b.order) ? 1 : -1);
    var orderedMembers = [];
    for(let i =0; i< this.fileMembers.length; i++){
      var memberIndex = this.members.findIndex(item =>{return item.id === this.fileMembers[i].FormrlyUsersContactId});
      var signatureIndex = this.membersData.findIndex(item =>{return item.formrlyUsersContactId === this.fileMembers[i].FormrlyUsersContactId});
      orderedMembers[i] = this.members[memberIndex];
      console.log(signatureIndex);
      console.log(this.membersData)
      console.log('signatureIndex')
      if(signatureIndex >= 0){
        orderedMembers[i].signatureDate = this.membersData[signatureIndex].timeStamp;
      }
      else{
        orderedMembers[i].signatureDate = 0;
      }
    }
    this.notifyMembers.emit(orderedMembers);
  }


}
