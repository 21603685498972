import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy ,DoCheck, Input } from '@angular/core';
import { Folders } from '../../../../services/http/interfaces/folders'
import { FolderService } from '../../../../services/http/folder.service';

@Component({
  selector: 'ngx-parent-folder',
  templateUrl: './parent-folder.component.html',
  styleUrls: ['./parent-folder.component.scss'],
//  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParentFolderComponent implements OnInit {
  @Output() notifyPFolder: EventEmitter<Folders> = new EventEmitter<Folders>();
  @Input('parentDataParentRefresh') public parentRefresh;
  public myParentFolders: Folders;
  public parentAddRefresh = 0;
  constructor(
    private folderService: FolderService,
  ) { 
    
  }

  ngOnInit() {
    this.getMyParentFolders();
  }
  ngOnChanges(){
    console.log('ngonchanges in parent folder crud');
    console.log(this.parentRefresh);
    this.getMyParentFolders();
    
  }
  // ngDoCheck(){
  //   console.log("DO CHECK");
  // }
  getMyParentFolders(){
		this.folderService.getMyParentFolders().subscribe(
      folderMeta => {
        this.myParentFolders = folderMeta;
        console.log("These are the parent folders");
        console.log(this.myParentFolders[0]);
        this.notifyPFolder.emit(folderMeta);
      },
        err => console.error(err)
      
    );
  }

}
