// import { Component, OnInit } from '@angular/core';
// import {FormrlyUsersContact} from '../../../services/http/interfaces/formrly-users-contact';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { UserService } from '../../@core/mock/users.service';
import { User, UserData } from '../../../data/users';
import { NbDialogService } from '@nebular/theme';
//import { EmailInviteDialogComponent } from './email-invite-dialog/email-invite-dialog.component';
import { FormrlyUsersContact } from '../../../services/http/interfaces/formrly-users-contact';
import { FormrlyUsersService } from '../../../services/http/formrly-users.service';
import { concatMap } from 'rxjs/operators';
//import { FormrlyUsersContactService } from '../../@core/services/http/formrly-users-contact.service';


@Component({
  selector: 'ngx-formrly-user-contacts-view',
  templateUrl: './formrly-user-contacts-view.component.html',
  styleUrls: ['./formrly-user-contacts-view.component.scss']
})

export class FormrlyUserContactsViewComponent implements OnInit {
  // public contactsList: FormrlyUsersContact;

  // constructor() { }

  // ngOnInit() {
  // }
  // getContactsList(data:FormrlyUsersContact){
  //   console.log("This is data");
  //   console.log(data);
  //   this.contactsList = data;
  //   console.log('contactList tirgger');
  // }
  public contactsList;
	contacts: User[] = [];
	contextMenuItems = [{ title: 'View Contact', link: ['/contacts/view?id:' + '5de47c8245e663398ed36d73'] }, { title: 'Delete' }];
	tab = 'view';

	/** The current page being displayed */
	currentPage:number = 1;

	/** The number of items to display per page */
	itemsPerPage:number = 10;

	constructor(
		private userService: UserData,
		private dialogService: NbDialogService,
		private router: Router,
		private formrlyUsersService: FormrlyUsersService
	) { }

	ngOnInit() {
		// this.userService.getContacts().subscribe(
		// 	contacts => this.contacts = contacts,
		// 	err => console.error(err)
		// );
	}
	getContactsList(data: FormrlyUsersContact) {
		console.log("This is data");
		console.log(data);
		this.contactsList = data;
		console.log('contactList tirgger');
	}

	openInviteDialog() {
		//this.dialogService.open(EmailInviteDialogComponent, { context: 'hi' });
	}
	onContextMenuItemClick(event, action) {
		console.log(event.data);
		switch (action) {


			case 'details':
				this.goToView(event.data.id);
				break;

			case 'remove':
				this.removeContact(event.data);
				break;
			case 'download':
				//this.openShareDialog(event.data);
				break;
			case 'send':
				//??Create later when creating email functionality
				//this.openShareDialog(event.data);
				break;
			case 'void':
				//this.openShareDialog(event.data);
				break;
		}
	}
	goToView(fileMetaId) {
		this.router.navigate(['/contacts/view', { id: fileMetaId }]);
	}
	getChangeTab(data) {
		this.tab = data;
	}
	getNewContact(data) {
		console.log('getNewContact')
		console.log(this.contactsList);
		console.log(data)
		this.contactsList.push(data)
		this.tab = 'view';
	}
	removeContact(data) {
		console.log('removeContact');
		if (confirm('Are you sure you want to remove: ' + data.name.first + ' ' + data.name.last + ' from your contact list?')) {
			this.formrlyUsersService.getCurrentUser().pipe(
				concatMap(userMeta => {
					var userUpdatedMeta = userMeta;
					var contactList = userMeta.formrlyUserContactId;

					var spliceId = contactList.findIndex(function (item) {
						return item === data.id;
					})
					contactList.splice(spliceId, 1);
					userUpdatedMeta.formrlyUserContactId = contactList;
					console.log(userUpdatedMeta)
					return this.formrlyUsersService.patchMyContactList(userUpdatedMeta)

				})
			).subscribe(contactMeta => {
				var spliceerId = this.contactsList.findIndex(function (item) {
					return item.id === data.id;
				})
				this.contactsList.splice(spliceerId, 1);
			},
				err => console.log(err)
			);
		}


	}


}
