import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WebviewerFinalService } from '../webviewer-final.service'
import { Documents } from '../../../services/http/interfaces/documents';
import { FormrlyUsersContact } from '../../../services/http/interfaces/formrly-users-contact';

@Injectable({
  providedIn: 'root'
})
export class WebviewerSignService {
  public instance
  public annotManager
  public docViewer

  private documentData = new BehaviorSubject<Documents[]>([]);
  currentDocumentData = this.documentData.asObservable();

  changeDocumentData(message: any) {
    this.documentData.next(message)
  }
  private memberData = new BehaviorSubject<FormrlyUsersContact[]>([]);
  currentMemberData = this.memberData.asObservable();

  changeMemberData(message: any) {
    this.memberData.next(message)
  }

  constructor(
    private webViewerService: WebviewerFinalService,
  ) { }
  async loadWebViewerSign(viewerNativeElement, location) {
    await this.webViewerService.loadInstance(viewerNativeElement, location);
    this.instance = this.webViewerService.instance;
    this.annotManager = this.instance.annotManager;
    this.docViewer = this.instance.docViewer;

    this.annotManager.setIsAdminUser(false);
    console.log('isadminuser?')
    console.log(this.annotManager.getIsAdminUser());
    this.instance.disableElements(['contextMenuPopup', 'annotationCommentButton', 'annotationStyleEditButton']);

  }
  putSavedSignature(annotationPaths, signatureType, userData) {
    console.log('Put the signature');
    console.log(userData);
    console.log(annotationPaths);
    console.log(signatureType);
    if (signatureType === 'full-signature') {
      userData.savedSignatures.fullSignature = annotationPaths;
    }
    else if (signatureType === 'initial-signature') {
      userData.savedSignatures.initials = annotationPaths;
    }
    else {
      console.log('Error no signature Type found');
    }
    console.log(userData);
    this.changeMemberData(userData);
    // this.formrlyMemberServices.updateMember(userData.id, userData).subscribe(
    //   fileMeta => {
    //     console.log('Signature Update Success');
    //     //console.log(fileMeta);
    //   },
    //   err => console.log(err)
    // );
  }



  isMyWidget(fieldName, userEmail) {
    //console.log('isMyWidget');
    var parsedFieldName = fieldName.split('-');
    if (parsedFieldName[0] === userEmail) {
      return true;
    }
    else {
      return false;
    }
  }
  sortWidgetsByLocation(annotationsPrepWidget) {
    //Finalize this function
    console.log('sortWidgetsByLocation');
    for (let i = 0; i < annotationsPrepWidget.length; i++) {
      console.log(annotationsPrepWidget[i].getX())
      console.log(annotationsPrepWidget[i].getY());
      console.log(annotationsPrepWidget[i].getPageNumber());

    }
    var data = [
      { name: "Stock B", pageNumber: 1, x: 2, y: 50 },
      { name: "Stock C", pageNumber: 1, x: 1, y: 50 },
      { name: "Stock D", pageNumber: 2, x: 3, y: 11 },
      { name: "Stock A", pageNumber: 2, x: 9, y: 10 },
      { name: "Stock B", pageNumber: 2, x: 7, y: 10 },
      { name: "Stock C", pageNumber: 2, x: 8, y: 10 },
      { name: "Stock A", pageNumber: 3, x: 1, y: 1 }
    ];
    var sorted = data.sort(function (a, b) {

      var aPage = a.pageNumber,
        bPage = b.pageNumber;
      var aY = a.y,
        bY = b.y;
      var aX = a.x,
        bX = b.x;
      if (aPage < bPage) {
        return -1;
      }
      if (aPage > bPage) {
        return 1;
      }
      if (aPage === bPage) {
        if (aY < bY) {
          return -1;
        }
        if (aY > bY) {
          return 1;
        }
        if (aY === bY) {
          if (aX < bX) {
            return -1;
          }
          if (aX > bX) {
            return 1;
          }
          return 0;
        }

      }

    });
    console.log(sorted);


  }

}
