import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable} from 'rxjs';
import { forkJoin } from 'rxjs';
import {Documents } from './interfaces/documents';
import { FormrlyUsersContact} from './interfaces/formrly-users-contact';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
	private api: string = environment.rest;

  constructor(
    private http: HttpClient

  ) { }  

  getDocumentCountbyStatus(status): Observable<any> {
		return this.http.get(`${this.api}/FormrlyUsers/me/FileMeta/count?where={"status":"${status}"}`);
  }


  getDocumentsByStatus(status): Observable<Documents> {
    return this.http.get<Documents>(`${this.api}/FormrlyUsers/me/FileMeta?filter=${status}`);
  }

  /**
   * Retrieves documents paginated by status.
   * @param status The status of the documents to retrieve.
   * @param page The page to retrieve. First index is 1.
   * @param itemsPerPage The number of items to return per page.
   */
  getDocumentsPaginatedByStatus(status:string, page:number, itemsPerPage: number):Observable<Documents>{
    itemsPerPage = Math.abs(itemsPerPage);
    page = Math.floor(page);
    if(page < 1){
      page = 1;
    }

    let skip = itemsPerPage * (page - 1);
    let filter = `{"where":{"status":"${status}"},"order":"id DESC","limit":"${itemsPerPage}","skip":"${skip}"}`;
    return this.http.get<Documents>(`${this.api}/FormrlyUsers/me/FileMeta?filter=${filter}`);
  }

  /**
   * Verifies if a document exists.
   * @param documentId The ID of the document.
   */
  verifyDocumentExists(documentId):Observable<any>{
    return this.http.get<any>(`${this.api}/FileMeta/${documentId}/exists`);
  }

  /**
   * Retrieves information about a document.
   * @param documentId The ID of the document.
   */
  getDocumentObject(documentId):Observable<any>{
    return this.http.get<any>(`${this.api}/FileMeta/${documentId}`);
  }

  getDocumentDetails(fileMetaId:string): Observable<Documents> {
		return this.http.get<Documents>(`${this.api}/FormrlyUsers/me/FileMeta/${fileMetaId}`);
  }

  searchMyDocuments(searchParam): Observable<Documents> {
    //return this.http.get<Documents>(`${this.api}/FormrlyUsers/me/FileMeta?filter={"where":{"displayName":{"like":"${searchParam}", "options":"i"}}}`);
    return this.http.get<Documents>(`${this.api}/FormrlyUsers/me/FileMeta?filter={"where":{"and":[{"fileType":"myDocument"},{"displayName":{"like":"${searchParam}", "options":"i"}}]}}`);
  }

  getDocumentMembers(fileMetaId:string): Observable<FormrlyUsersContact>{
    return this.http.get<FormrlyUsersContact>(`${this.api}/FormrlyUsers/me/FileMeta/${fileMetaId}/FormrlyUsersContact`);
  }

  getDocumentMembersData(fileMetaId:string): Observable<FormrlyUsersContact>{
    return this.http.get<FormrlyUsersContact>(`${this.api}/FormrlyUsers/me/FileMeta/${fileMetaId}/FilesMembersData`);
  }

  getDocumentMembersOrdered(fileMetaId:string): Observable<any>{
    //return this.http.get<FormrlyUsersContact>(`${this.api}/FormrlyUsers/me/FileMeta/${fileMetaId}/FormrlyUsersContact`);
    //let members = this.http.get(`${this.api}/FormrlyUsers/me/FileMeta/${fileMetaId}/FormrlyUsersContact`);
    return this.http.get(`${this.api}/FormrlyUsers/me/FileMeta/${fileMetaId}/FilesMember?filter={"where":{"or":[{"access":"member"},{"order":{ "neq":  null }}]}}`);
    //return forkJoin([members,order]);
    

  }

  /**
   * Updates the order of members in a document.
   * @param fileMetaId The document ID.
   * @param orderedMembers Members in the new order.
   */
  updateDocumentMembersOrder(fileMetaId:string, orderedMembers:any){
    this.getDocumentMembersOrdered(fileMetaId).subscribe(
      members => {
        orderedMembers = orderedMembers.filter(el => true); // Re-index the array to make the function robust in all scenarios
        members.forEach(
          m => {
            m.order = orderedMembers.findIndex(item => item.id === m.FormrlyUsersContactId);
            this.http.put(`${this.api}/FormrlyUsers/me/FileMeta/${fileMetaId}/FilesMember/${m.id}`, m).subscribe(
              result => { console.log('Member order has been updated.'); },
              putError => { console.log('An error ocurred while updating member order. '); }
            );
          }
        );
      },
      getError => {
        console.error('An error occurred while retrieving ordered document members.');
      }
    );
  }

  addDocumentMember(fileMetaId:string, fileMembers:any ): Observable<any>{
    return this.http.post(`${this.api}/FormrlyUsers/me/FileMeta/${fileMetaId}/FilesMember`, fileMembers);
  }
  getDocumentMemberByMemberId(fileMetaId:string, memberId:any ): Observable<any>{
    if (memberId === '-1'){
      return null;
    }
    return this.http.get(`${this.api}/FormrlyUsers/me/FileMeta/${fileMetaId}/FilesMember?filter={"where":{"FormrlyUsersContactId":"${memberId}"}}`);
  }

  deleteDocumentMember(documentId:string, memberId:string): Observable<any> {
		return this.http.delete(`${this.api}/FormrlyUsers/me/FileMeta/${documentId}/FilesMember/${memberId}`);
  }
  
//Take from existing functions
//  Add New Document
// Add New My Document

// from andrew rework
AddDocument(fileMeta: any): Observable<any> {
  return this.http.post(`${this.api}/FormrlyUsers/me/FileMeta`, fileMeta);
}

UpdateDocumentDetails(fileMetaId:any, document:any): Observable<Documents> {
  return this.http.put<Documents>(`${this.api}/FormrlyUsers/me/FileMeta/${fileMetaId}`, document);
}

//Determine where to put this function
//ChangeDocumentStatus

//From Andrew, rework.

  deleteDocument(documentId:any): Observable<any> {
		return this.http.delete(`${this.api}/FormrlyUsers/me/FileMeta/${documentId}`);
	}

  uploadFile(file, path?): Observable<any> {
		const formData: FormData = new FormData();
		formData.append('file', file, `${path}${file.name}`);
    //return this.http.post(`${this.api}/Uploads/formrly-public/upload`, formData);//temp
    return this.http.post(`${this.api}/Uploads/rk-formrly-public/upload`, formData);
  }
  
	downloadFile(fileName: string): Observable<any> {
    //return this.http.get(`${this.api}/Uploads/formrly-public/download/${fileName}`, { responseType: 'blob' });//temp
    return this.http.get(`${this.api}/Uploads/rk-formrly-public/download/${fileName}`, { responseType: 'blob' });
  }

  getMyFolderChildDocuments(folderId:any): Observable<Documents> {
		return this.http.get<Documents>(`${this.api}/FormrlyUsers/me/Folder/${folderId}/FileMeta`);
  }
  getMyDocumentsInRoot(): Observable<Documents> {
    //return this.http.get<Documents>(`${this.api}/FormrlyUsers/me/FileMeta?filter={"where":{"and":[{"fileType":"myDocument"},{"folderId": {"exists":false}}]}}`);
    return this.http.get<Documents>(`${this.api}/FormrlyUsers/me/FileMeta?filter={"where":{"and":[{"fileType":"myDocument"},{"or":[{"folderId": {"exists":false}},{"folderId":0}]}]}}`);
  }
  
  

}
// {"where":{"and":[{"fileType":"myDocument"},{"folderId": {"exists":true}}]}}

// {"where":{"and":[{"formrlyUserId":"5ddf28f0e73731564db5c44b"},{"field": "file"}]}}
// {"where":{"and":[{"formrlyUserId":"5ddf28f0e73731564db5c44b"},{"field": {"exists":false}}]}}
// {"where":{"and":[{"formrlyUserId":"5ddf28f0e73731564db5c44b"},{"field": {"exists":true}}]}}

// {"where":{"field": {"exists":true}}}