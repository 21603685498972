import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup }        from '@angular/forms';

import { QuestionBase }     from './question-base';

@Component({
  selector: 'app-question',
  templateUrl: './dynamic-form-question.component.html'
})
export class DynamicFormQuestionComponent implements AfterViewInit {
  @Input() question: QuestionBase<any>;
  @Input() form: FormGroup;
  @Output() notifySelectField: EventEmitter<string> = new EventEmitter<string>();

  /** Boolean value indicating if the field is focused or not. */
  @Input() isFocused:boolean;

  /** Reference to the label element. */
  @ViewChild('labelElement') labelElement:ElementRef<HTMLElement>;
  
  /** Reference to the form element. */
  @ViewChild('formElement') formElement:ElementRef<HTMLElement>;

  get isValid() { return this.form.controls[this.question.key].valid; }
  focusFunction(question) {
    console.log('can you focus?');
    console.log(question)
    this.notifySelectField.emit(question.key);
  }

  /**
   * Executes actions after the view is fully initialized. 
   */
  ngAfterViewInit(){
    let targetElement = this.isFocused ? this.labelElement : this.formElement;
    targetElement.nativeElement.click();
  }
}
