import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-folder-child-documents',
  templateUrl: './folder-child-documents.component.html',
  styleUrls: ['./folder-child-documents.component.scss']
})
export class FolderChildDocumentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
