import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-create-folder',
  templateUrl: './create-folder.component.html',
  styleUrls: ['./create-folder.component.scss']
})
export class CreateFolderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
