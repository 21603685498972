import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WebviewerFinalService } from '../webviewer-final.service'
import { Documents } from '../../../services/http/interfaces/documents';
import { DocumentService } from '../../../services/http/document.service';

@Injectable({
  providedIn: 'root'
})
export class WebviewerSignerService {

  public instance;
  public annotManager
  public docViewer;

  private selectedAnnotation = new BehaviorSubject<any>([]);
  currentSelectedAnnotation = this.selectedAnnotation.asObservable();

  changeSelectedAnnotation(message: any) {
    this.selectedAnnotation.next(message)
  }


  private documentData = new BehaviorSubject<Documents[]>([]);
  currentDocumentData = this.documentData.asObservable();

  changeDocumentData(message: any) {
    this.documentData.next(message)
  }
  private myAnnotationList = new BehaviorSubject<any>([]);
  currentMyAnnotationList = this.myAnnotationList.asObservable();

  changeMyAnnotationList(message: any) {
    this.myAnnotationList.next(message)
  }
  private isComplete = new BehaviorSubject(false);
  currentIsComplete = this.isComplete.asObservable();

  changeIsCompelete(message: any) {
    this.isComplete.next(message)
  }

  private xfdfString = new BehaviorSubject(false);
  currentXfdfString = this.xfdfString.asObservable();

  changeXfdfString(message: any) {
    this.xfdfString.next(message)
  }
  private memberContactInfo = new BehaviorSubject(false);
  currentMemberContactInfo = this.memberContactInfo.asObservable();

  changeMemberContactInfo(message: any) {
    this.memberContactInfo.next(message)
  }

  private selectedAnnotationId = new BehaviorSubject('');
  currentSelectedAnnotationId = this.selectedAnnotationId.asObservable();

  changeSelectedAnnotationId(message: string) {
    this.selectedAnnotationId.next(message)
  }


  constructor(
    private webViewerService: WebviewerFinalService,
    private documentService: DocumentService,
  ) { }
  async loadWebViewerSigner(viewerNativeElement, location) {
    await this.webViewerService.loadInstance(viewerNativeElement, location);
    this.instance = this.webViewerService.instance;
    this.annotManager = this.instance.annotManager;
    this.docViewer = this.instance.docViewer;

    this.annotManager.setIsAdminUser(false);
    console.log('isadminuser?')
    console.log(this.annotManager.getIsAdminUser());
    this.instance.disableElements(['contextMenuPopup', 'annotationCommentButton', 'annotationStyleEditButton']);
  }
  updateMyAnnotationList(annotation, selectedSignatureAnnotation, action, myAnnotationList) {
    console.log('updateMyAnnotationList')
    console.log(annotation);
    console.log(selectedSignatureAnnotation);
    console.log(myAnnotationList)
    var currentIndex;
    if (annotation[0].Subject === 'Signature' && (action === 'add' || action === 'delete')) {
      console.log('signature')
      currentIndex = myAnnotationList.findIndex((a) => { return a.id === selectedSignatureAnnotation.ou });
      if (currentIndex === -1){
        currentIndex = myAnnotationList.findIndex((a) => { return a.id === annotation.ou });
      }
      console.log(currentIndex)
      console.log(action)
      switch (action) {
        case 'add':
          console.log('add sig')
          myAnnotationList[currentIndex].completed = true;
          this.changeMyAnnotationList(myAnnotationList);
          this.isMyAnnotationsComplete(myAnnotationList)
          break;
        case 'delete':
          console.log('del sig')
          myAnnotationList[currentIndex].completed = false;
          this.changeMyAnnotationList(myAnnotationList);
          this.isMyAnnotationsComplete(myAnnotationList)
          break;
      }
    }
    else if (annotation[0].Subject === 'Free text' && action === 'modify' && !annotation[1]) {
      console.log('modify');
      currentIndex = myAnnotationList.findIndex((a) => { return a.id === annotation[0].ou });

      if (annotation[0].getContents()) {
        console.log('fill');
        myAnnotationList[currentIndex].completed = true;
        this.changeMyAnnotationList(myAnnotationList);
        this.isMyAnnotationsComplete(myAnnotationList)
      }
      else {
        console.log('del');
        myAnnotationList[currentIndex].completed = false;
        this.changeMyAnnotationList(myAnnotationList);
        this.isMyAnnotationsComplete(myAnnotationList)
      }

    }

  }
  isMyAnnotationsComplete(myAnnotationList) {
    var annotation;
    for (let i = 0; i < myAnnotationList.length; i++) {
      annotation = myAnnotationList[i];
      console.log(annotation);
      console.log(myAnnotationList);
      
      if (annotation.completed === false && annotation.required === true) {
        this.changeIsCompelete(false)
        console.log('isComplete False')
        return false;
      }
    }
    this.changeIsCompelete(true)
    console.log('isComplete True')
  }

  //To be updated???
  putAnnotations(fileMetaId, documentData, xfdfString) {
    documentData.annotations = xfdfString;
    this.documentService.UpdateDocumentDetails(fileMetaId, documentData).subscribe(
      fileMeta => {
        console.log('Update Success');
      },
      err => console.log(err)
    )
  }

  getAnnotationStatus(annotation) {
    var status;
    if (annotation.CustomData && (annotation.CustomData.type === 'full-signature' || annotation.CustomData.type === 'initial-signature')) {
      status = this.isSigantureGrouped(annotation);
    }
    else {
      if (annotation.getContents()) {
        status = true;
      }
      else {
        status = false;
      }
    }
    return status;
  }
  isSigantureGrouped(annotation) {
    if (annotation && (annotation.isGrouped() || annotation.getGroupedChildren()[0])) {
      return true;
    }
    else {
      return false;
    }
  }


  isMyWidget(fieldName, userEmail) {
    //console.log('isMyWidget');
    var parsedFieldName = fieldName.split('-');
    if (parsedFieldName[0] === userEmail) {
      return true;
    }
    else {
      return false;
    }
  }
  sortWidgetsByLocation(annotationsPrepWidget) {
    //Finalize this function
    console.log('sortWidgetsByLocation');
    for (let i = 0; i < annotationsPrepWidget.length; i++) {
      console.log(annotationsPrepWidget[i].x)
      console.log(annotationsPrepWidget[i].y);
      console.log(annotationsPrepWidget[i].pageNumber);
    }
    var data = [
      { name: "Stock B", pageNumber: 1, x: 2, y: 50 },
      { name: "Stock C", pageNumber: 1, x: 1, y: 50 },
      { name: "Stock D", pageNumber: 2, x: 3, y: 11 },
      { name: "Stock A", pageNumber: 2, x: 9, y: 10 },
      { name: "Stock B", pageNumber: 2, x: 7, y: 10 },
      { name: "Stock C", pageNumber: 2, x: 8, y: 10 },
      { name: "Stock A", pageNumber: 3, x: 1, y: 1 }
    ];
    var sorted = annotationsPrepWidget.sort(function (a, b) {
      var aPage = a.pageNumber,
        bPage = b.pageNumber;
      var aY = a.y,
        bY = b.y;
      var aX = a.x,
        bX = b.x;
      if (aPage < bPage) {
        return -1;
      }
      if (aPage > bPage) {
        return 1;
      }
      if (aPage === bPage) {
        if (aY < bY) {
          return -1;
        }
        if (aY > bY) {
          return 1;
        }
        if (aY === bY) {
          if (aX < bX) {
            return -1;
          }
          if (aX > bX) {
            return 1;
          }
          return 0;
        }
      }
    });
    console.log(sorted);
    this.changeMyAnnotationList(sorted)
    console.log(sorted[0].id);
    var firstAnnotation = this.annotManager.getAnnotationById(sorted[0].id)
    this.changeSelectedAnnotationId(sorted[0].id);
    this.annotManager.selectAnnotation(firstAnnotation)
  }
}
