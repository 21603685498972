import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable} from 'rxjs';
import { PageSettings } from './interfaces/page-settings';


@Injectable({
  providedIn: 'root'
})
export class PageSettingService {
	private api: string = environment.rest;

  constructor(
    private http: HttpClient

  ) { }
  getPageSettings(): Observable<PageSettings> {
    //parent function
  //  var parent;
		return this.http.get<PageSettings>(`${this.api}/PageSettings/`);
  }
}