import { Component, ViewChild, OnInit, ElementRef, AfterViewInit, Input, TemplateRef } from '@angular/core';
import { DocumentService } from '../../../@core/services/http/document.service';
import { Documents } from '../../../@core/services/http/interfaces/documents';
import { UserData } from '../../data/users';
import { VisitsAddService } from '../webviewer/visits-add.service';
import { FileMemberVisits } from '../../services/http/interfaces/file-members-visits';
import { MembersVisitService } from '../../services/members/members-visit.service';
import { concatMap } from 'rxjs/operators';
import { NbDialogService } from '@nebular/theme';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

declare const WebViewer: any;

@Component({
  selector: 'ngx-webviewer-full',
  templateUrl: './webviewer-full.component.html',
  styleUrls: ['./webviewer-full.component.scss']
})
export class WebviewerFullComponent implements OnInit {
  flattenStart = false;
  fileUrl;
  loading=true;
  @Input('parentDataDocument') public documentData: Documents;
  @Input('parentDataUserMeta') public userMeta: any;
  @Input('parentDataViewMode') public viewMode: string;

  @ViewChild('viewer') viewer: ElementRef;
  @ViewChild('myThumbnail') canvasRef: ElementRef;
  @ViewChild('dataContainer') dataContainer: ElementRef;
  @ViewChild('savedSignature') public savedSignatureCanvas: ElementRef;


  triggerFalseOpenClick() {
    let el: HTMLElement = this.popupOpen.nativeElement;
    el.click();
  }


  public savedSignature;
  public testInitial = false;
  public initialTool: any;
  public imageURL;
  wvInstance: any;
  wvTools: any;
  licenseKey = 'Formrly LLC(formrly.co):OEM:Formrly::B+:AMS(20200529):12A5746204B7C80A3360B13AC9A2737860612FE3A926CDEAC56C051BD7B500CE64AAB6F5C7';

  fileMetaId;
  //public documentData: Documents;
  public xfdfStringLoader;
  public pdfNetInitialize: any;
  public selectedAnnotaionFE;

public memberId;
  public userId;
  public visitId;
  public currentPage = 1;
  public timestamp;
  public startTime = 0
  public endTime;
  public totalTime;
  public fileMembersVisit: FileMemberVisits = {
    memberVisitorId: '',
    id: null,
    formrlyUserId: '',
    formrlyUsersContactId: '',
    filesMemberId: '',
    fileMetaId: '',
    visitData: [{}],
    timestamp: null
  };

  public pageCount;
  public thumbnails;

  //public savedSignature;
  @ViewChild('popupClose') popupClose: ElementRef<HTMLElement>;
  @ViewChild('popupOpen') popupOpen: ElementRef<HTMLElement>;
  @ViewChild('popupnewdoc') popupnewdoc: TemplateRef<HTMLElement>;

  constructor(
    private documentService: DocumentService,
    private userSvc: UserData,
    private visitsAddService: VisitsAddService,
    private memberVisitsService: MembersVisitService,
    private dialogService: NbDialogService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {
    this.timestamp = this.visitsAddService.getTimestamp();
    this.startTime = this.visitsAddService.getTime();
    this.route.paramMap.subscribe(params => {
      this.fileMetaId = params.get('id');
      if(params.get('memberId')){
        this.memberId = params.get('memberId');
      }
    })
    
  }


  ngOnInit() {
    // this.fileMetaId='5dfc405e8a0a8003a6fbabc0';
   //this.getDocumentDetails(this.fileMetaId);
    this.wvDocumentLoadedHandler = this.wvDocumentLoadedHandler.bind(this);
    this.xfdfStringLoader = this.documentData.annotations;
  }
  getDocumentDetails(fileMetaId) {
    console.log('getDocumentDetails');
    this.documentService.getDocumentDetails(fileMetaId).subscribe(
      fileMeta => {
        this.documentData = fileMeta;
        //console.log('query');
        console.log(fileMeta);
        this.xfdfStringLoader = fileMeta.annotations;

        // create visit
        this.buildVisit();
      },
      err => console.error(err)
    );
  }

  wvDocumentLoadedHandler(): void {
    //I believe this wvDocumentLoaded Handler is not doing anything now
    console.log('wvDocument LoadedHandler');
    const docViewer = this.wvInstance;
    const annotManager = this.wvInstance.annotManager;

    //Current User
    annotManager.setCurrentUser('johny');
    console.log('current user annotmanager');
    console.log(annotManager.getCurrentUser());

    // and access classes defined in the WebViewer iframe
    const { Annotations } = this.wvInstance;

    // Imports Annotations From Database Data.
    annotManager.importAnnotations(this.xfdfStringLoader);

    // Free Text Annotation Example
    const freeText = new Annotations.FreeTextAnnotation();
    freeText.PageNumber = 1;
    freeText.X = 150;
    freeText.Y = 200;
    freeText.Width = 150;
    freeText.Height = 50;
    freeText.setPadding(new Annotations.Rect(0, 0, 0, 0));
    freeText.setContents('My Text');
    freeText.FillColor = new Annotations.Color(0, 255, 255);
    freeText.FontSize = '16pt';
    freeText.Author = annotManager.getCurrentUser();
    annotManager.addAnnotation(freeText);
    annotManager.redrawAnnotation(freeText);

    //const my_field = await doc.fieldCreate("address", PDFNet.Field.Type.e_text, null);

  }


  ngAfterViewInit(): void {
    //this.fileMetaId = '5dfc405e8a0a8003a6fbabc0';
  //  this.getDocumentDetails(this.fileMetaId);

    // Different files for testing purposes
    const location = "https://formrly-public.s3.us-east-2.amazonaws.com/5ddf28f0e73731564db5c44b-1583452624-Formrly%20Handover%20Report%20-%20Part%202%20%281%29.pdf";
    //const location = this.documentData.providerResponse.location;

  
    // initWebViewer: function () {
    const viewerElement = document.getElementById('viewer');

    WebViewer({
      path: '../../../../lib',
      //initialDoc: `https://cors-anywhere.herokuapp.com/${location}`,
      initialDoc: `https://cors-anywhere.herokuapp.com/${this.documentData.providerResponse.location}`,
      fullAPI: true,
      licenseKey: this.licenseKey,
    },
      this.viewer.nativeElement).then(instance => {
        const { PDFNet, docViewer } = instance;

        this.wvInstance = instance;
        const { Annotations } = this.wvInstance;
        this.wvTools = instance.Tools;
        const tools = this.wvTools;
        const annotManager = instance.annotManager;
        const { Tools } = instance;
        annotManager.setCurrentUser('johny');
        console.log('current user annotmanager');
        console.log(annotManager.getCurrentUser());

        const flatten = async () => {
          
          //global instance call
          //const instance = WebViewer.getInstance();
          const webDoc = docViewer.getDocument();
          const doc = await webDoc.getPDFDoc();
          doc.initSecurityHandler();
          doc.lock();
          await docViewer.getTool('AnnotationCreateFreeHand').complete();
          const xfdfString = await annotManager.exportAnnotations({ links: false, widgets: false });
          const fdfDoc = await PDFNet.FDFDoc.createFromXFDF(xfdfString);
          await doc.fdfMerge(fdfDoc);
          await doc.flattenAnnotations();

          const docbuf = await doc.saveMemoryBuffer(1);
          instance.loadDocument(new Blob([docbuf], {
            type: 'application/pdf'
          }), {
              filename: this.documentData.displayName
            });
          const blob = new Blob([docbuf], {
            type: 'application/pdf'
          });
          console.log('flatten file');
          console.log(blob);
          
          this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
          (window.URL.createObjectURL(blob));
          this.flattenStart = true;
          this.loading = false;
          this.triggerFalseOpenClick()
          //this.uploadThumbnail(blob, 'flattenfile.pdf', 1);
        }


        annotManager.on('annotationChanged', async function (annots, action, options) {
          console.log('annotationChanged');
          //console.log(annots);
          //console.log(action);
          //console.log(options);

          const xfdfString = await annotManager.exportAnnotations();
          //console.log(xfdfString);
        });
        instance.docViewer.on('annotationsLoaded', () => {
          this.wvDocumentLoadedHandler
          console.log('annotations loaded');
        });
        instance.docViewer.on('documentLoaded', async (f) => {
          if( this.flattenStart === false){
          console.log('documentLoaded');
          this.pdfNetInitialize = await PDFNet.initialize();
          const doc = docViewer.getDocument();
          const pdfDoc = await doc.getPDFDoc();

          //console.log('Import Annotations');
          //console.log(this.xfdfStringLoader);
          await annotManager.importAnnotations(this.xfdfStringLoader);
          // Ensure that we have our first page.
          await pdfDoc.requirePage(1);
          // Run our main function using 'runWithCleanup'
          await PDFNet.runWithCleanup(async () => await main(pdfDoc));
          // Refresh the cache with the newly updated document
          docViewer.refreshAll();
          // Update viewer with new document
          docViewer.updateView();
          // you can access docViewer object for low-level APIs
          docViewer.getDocument().refreshTextData();
          console.log('preflatten')
            flatten();
          }
        })
        console.log('last of webviewer');
        //console.log(this.initialTool);
        //end of this.viewer.nativeElement.then(instance =>{
      });
    async function main(pdfDoc) {
      //alert("Hello WebViewer!");
    }
  }
  open(dialog: TemplateRef<any>) {
    //console.log(dialog);
    this.dialogService.open(dialog, { context: 'this is some additional data' });
  }
  buildVisit() {
    //this.fileMembersVisit.id = '123';
    this.fileMembersVisit.fileMetaId = this.documentData.id;
    this.fileMembersVisit.filesMemberId = '123';
    this.fileMembersVisit.formrlyUserId = '5ddf28f0e73731564db5c44b';

    //this.fileMembersVisit.formrlyUsersContactId = '5de47c8245e663398ed36d73';
    // jeff saak
    this.fileMembersVisit.formrlyUsersContactId = '5de47cbf45e663398ed36d75';
    this.fileMembersVisit.timestamp = this.timestamp;
    //Dead weight need to remove all over
    this.fileMembersVisit.memberVisitorId = '123';
    console.log(this.fileMembersVisit);
    this.postVisit(this.fileMembersVisit);
  }
  postVisit(fileMembersVisit: FileMemberVisits) {
    console.log('create fileMemberVisit');
    console.log(fileMembersVisit);
    //!!!Create the service
    this.memberVisitsService.createVisit(fileMembersVisit).subscribe(
      result => {
        console.log('successful add');
        console.log(result);
        this.fileMembersVisit = result;
        console.log(this.fileMembersVisit);
        //!!!
      },
      err => console.error(err)
    );


  }
  updateVisits() {
    if (this.startTime === 0) {
      this.startTime = this.visitsAddService.getTime();
      console.log('first load of page change');
    }
    else {
      this.endTime = this.visitsAddService.getTime();
      //this.visitsAddService.updateVisitCheck(this.userId, this.visitId, this.currentPage, this.startTime, this.endTime);
      var totalTime;
      if (this.endTime > 0 && this.endTime - this.startTime >= 1) {
        totalTime = this.endTime - this.startTime;
      }
      else {
        totalTime = 1;
      }
      this.updateVisitData(this.currentPage, totalTime);
      this.startTime = this.endTime;
    }
  }
  updateVisitData(currentPage, totalTime) {
    //this.fileMembersVisit.id = '123';
    var row;
    if (this.fileMembersVisit.visitData && this.fileMembersVisit.visitData[0].pageNumber) {
      row = this.fileMembersVisit.visitData.length;

    }
    else {
      row = 0;
    }
    console.log('row' + row);
    this.fileMembersVisit.visitData[row] = {
      pageNumber: currentPage,
      time: totalTime
    }
    this.updateVisitDetails(this.fileMembersVisit);
  }

  updateVisitDetails(fileMembersVisit: FileMemberVisits) {
    console.log('create fileMemberVisit');
    console.log(fileMembersVisit);
    //!!!Create the service
    this.memberVisitsService.updateVisit(fileMembersVisit).subscribe(
      result => {
        console.log('successful add');
        console.log(result);
        //!!!
      },
      err => console.error(err)
    );
  }
  async getThumbnails(doc, pageCount) {
    console.log('get Thumbbnails function' + pageCount)
    var i;
    var thumbNail = []
    var $thumbNail = []
    this.documentData.pageThumbnails = [{}];
    for (i = 0; i < pageCount; i++) {
      console.log('looop' + i);
      thumbNail[i] = i
      //$thumbNail[i] = await doc.loadThumbnailAsync(i, async (result) => await this.displayThumbnail(result, i));
      //await console.log(thumbNail[i]);
      console.log('test await' + i)
      this.documentData.pageThumbnails[i] = {};
    }
    for (const item of thumbNail) {
      console.log('async loop test for')
      await doc.loadThumbnailAsync(item, async (result) => this.displayThumbnail(result, item));
      console.log('async loop test for end')
    }

  }

  async displayThumbnail(result, iCount) {
    await console.log('Async Display Thumbnail' + iCount);
    console.log(result);
    //this.dataContainer.nativeElement.innerHTML = result;

    this.canvasRef.nativeElement = result;

    //Set Thumbnail Canvas to a URL for displaying Test
    var test2 = result.toDataURL();
    console.log(test2);
    console.log('end test 2');
    this.imageURL = test2;

    //create a blob and store in database
    result.toBlob(data => this.blober(data, iCount));
  }

  blober(data, iCount) {
    console.log('blobber');
    console.log(data);
    var fileName;
    fileName = this.documentData.id + '-TH-' + iCount + '.png';
    this.uploadThumbnail(data, fileName, iCount);

  }
  //S3 Conector function
  uploadThumbnail(file, fileName, iCount) {
    console.log('upload Avatar' + iCount);
    console.log(file);
    console.log(fileName);
    console.log(iCount);
    this.userSvc.uploadThumbnail(file, fileName)
      .pipe(
        concatMap(fileMeta => {
          console.log(iCount + 'concatmap');
          this.documentData.pageThumbnails[iCount] = fileMeta.result.files.file[0];
          console.log(this.documentData);
          console.log('before the return');
          //return 't';
          return this.documentService.UpdateDocumentDetails(this.documentData.id, this.documentData);
        })
      )
      .subscribe(
        fileMeta => {
          console.log(fileMeta);
          console.log('Upload thumbnail ' + iCount + ' success');

        },
        err => console.error(err)
      );
  }

  dropFreeTextAnnotation(point) {
    const docViewer = this.wvInstance;
    const annotManager = this.wvInstance.annotManager;

    const { Annotations } = this.wvInstance;
    const freeTexttwo = new Annotations.FreeTextAnnotation();
    freeTexttwo.PageNumber = 1;
    freeTexttwo.X = point.x;
    freeTexttwo.Y = point.y;
    freeTexttwo.Width = 150;
    freeTexttwo.userName = 'Test User Name';
    freeTexttwo.Height = 50;
    freeTexttwo.setPadding(new Annotations.Rect(0, 0, 0, 0));
    freeTexttwo.setContents('My Text 2');
    freeTexttwo.FillColor = new Annotations.Color(0, 255, 255);
    freeTexttwo.FontSize = '16pt';
    freeTexttwo.Author = 'johny';
    freeTexttwo.ReadOnly = true;
    //freeTexttwo.NoMove = false;
    //freeTexttwo.NoResize = true;
    console.log('get current user of annotmanager');
    console.log(annotManager.getCurrentUser());

    freeTexttwo.FillColor = new Annotations.Color(255, 255, 255);
    annotManager.addAnnotation(freeTexttwo);
    annotManager.redrawAnnotation(freeTexttwo);
    console.log('Get Annotations List Test')
    console.log(annotManager.getAnnotationsList());
    console.log(annotManager.getSelectedAnnotations());
    console.log(annotManager.getAnnotationById('f2b7a74b-20ff-41c4-4287-a6f109283dfb'));
  }
  //Free Text Create
  createFreeTextAnnotation() {
    const docViewer = this.wvInstance;
    const annotManager = this.wvInstance.annotManager;

    const { Annotations } = this.wvInstance;
    const freeTexttwo = new Annotations.FreeTextAnnotation();
    freeTexttwo.PageNumber = 1;
    freeTexttwo.X = 150;
    freeTexttwo.Y = 500;
    freeTexttwo.Width = 150;
    freeTexttwo.userName = 'Test User Name';
    freeTexttwo.Height = 50;
    freeTexttwo.setPadding(new Annotations.Rect(0, 0, 0, 0));
    freeTexttwo.setContents('My Text 2');
    freeTexttwo.FillColor = new Annotations.Color(0, 255, 255);
    freeTexttwo.FontSize = '16pt';
    freeTexttwo.Author = 'johny';
    //freeTexttwo.NoMove = false;
    //freeTexttwo.NoResize = true;
    console.log('get current user of annotmanager');
    console.log(annotManager.getCurrentUser());

    freeTexttwo.FillColor = new Annotations.Color(255, 255, 255);
    annotManager.addAnnotation(freeTexttwo);
    annotManager.redrawAnnotation(freeTexttwo);
    console.log('Get Annotations List Test')
    console.log(annotManager.getAnnotationsList());
    console.log(annotManager.getSelectedAnnotations());
    console.log(annotManager.getAnnotationById('f2b7a74b-20ff-41c4-4287-a6f109283dfb'));
  }

  //Convert a text field to a signature field.  This will be managed just before sending a document.
  //
  async checkFreeTextAnnotation(annotationSelected) {


    console.log('checkFreeTextAnnotation');
    const { PDFNet, docViewer } = this.wvInstance;
    const annotManager = this.wvInstance.annotManager;

    const doc = docViewer.getDocument();
    const pdfDoc = await doc.getPDFDoc();

    const annotationSelectedF = annotManager.getAnnotationById(annotationSelected);


    var test = 2;
    if (test === 1) {
      //Dead code !!!
      // Create a blank page
      const docuP = await PDFNet.PDFDoc.create();
      const blankPage = await docuP.pageCreate();

      // Create a new field (aka AcroForms).
      const empFirstName = await docuP.fieldCreateFromStrings('52ed5fc8-54ff-2ed1-2ac4-8f4a30298059', PDFNet.Field.Type.e_text, 'John', '');

      // Create text annotation
      const annot = await PDFNet.WidgetAnnot.create(docuP, (await PDFNet.Rect.init(50, 550, 350, 600)), empFirstName);

      // Add annotation to the page
      blankPage.annotPushBack(annot);

      // Add the page as the last page in the document.
      docuP.pagePushBack(blankPage);

      await pdfDoc.refreshFieldAppearances();
    }
    else if (test === 2) {
      console.log('test2');


      const field = await pdfDoc.fieldCreateFromStrings(annotationSelectedF.wt, PDFNet.Field.Type.e_text, 'John', '');
      console.log('find bug');
      //const field = await pdfDoc.fieldCreateFromStrings(annotationSelectedF.getContents() + 123, PDFNet.Field.Type.e_text, annotationSelectedF.custom.value, '');
      console.log('find bug2');
      console.log(annotationSelectedF.getContents());

      // translate coordinates
      const annotRect = await annotationSelectedF.getRect();
      const setTopLeft = doc.getPDFCoordinates(annotationSelectedF.getPageNumber() - 1, annotRect.x1, annotRect.y1);
      const setBottomRight = doc.getPDFCoordinates(annotationSelectedF.getPageNumber() - 1, annotRect.x2, annotRect.y2);

      console.log(annotRect);
      console.log(setTopLeft);
      console.log(setBottomRight);

      // create an annotation with a form field created
      const pageNumber = annotationSelectedF.getPageNumber();
      console.log('pageNumber');
      console.log(pageNumber);
      const newAnnot = await PDFNet.WidgetAnnot.create(pdfDoc, await PDFNet.Rect.init(setTopLeft.x, setTopLeft.y, setBottomRight.x, setBottomRight.y), field);

      annotManager.deleteAnnotation(annotationSelectedF, false, true);
      console.log('free text deleted');
      // draw the annotation the viewer
      const page = await pdfDoc.getPage(pageNumber);
      await page.annotPushBack(newAnnot);
      await pdfDoc.refreshFieldAppearances();

      console.log('draw in webviewer');

      // import newly created form fields
      const fdfDoc = await pdfDoc.fdfExtract(PDFNet.PDFDoc.ExtractFlag.e_both);
      const xfdf = await fdfDoc.saveAsXFDFAsString();
      await annotManager.importAnnotations(xfdf);

      console.log('import fields');
      // refresh viewer
      docViewer.refreshAll();
      docViewer.updateView();
      docViewer.getDocument().refreshTextData();
      //dropPoint = {};

      console.log('refresh viewer');
    }


  }
  //This will be done before sending and combined with above function
  async convertSignatureAnnotation(annotationSelected) {


    console.log('checkFreeTextAnnotation');
    const { PDFNet, docViewer } = this.wvInstance;
    const annotManager = this.wvInstance.annotManager;

    const doc = docViewer.getDocument();
    const pdfDoc = await doc.getPDFDoc();

    const annotationSelectedF = annotManager.getAnnotationById(annotationSelected);
    console.log(annotationSelectedF)

    var test = 2;
    if (test === 1) {
    }
    else if (test === 2) {
      console.log('test2');


      const field = await pdfDoc.fieldCreateFromStrings(annotationSelectedF.wt, PDFNet.Field.Type.e_signature, '', '');
      console.log('find bug');
      //const field = await pdfDoc.fieldCreateFromStrings(annotationSelectedF.getContents() + 123, PDFNet.Field.Type.e_text, annotationSelectedF.custom.value, '');
      console.log('find bug2');
      console.log(annotationSelectedF.getContents());

      // translate coordinates
      const annotRect = await annotationSelectedF.getRect();
      const setTopLeft = doc.getPDFCoordinates(annotationSelectedF.getPageNumber() - 1, annotRect.x1, annotRect.y1);
      const setBottomRight = doc.getPDFCoordinates(annotationSelectedF.getPageNumber() - 1, annotRect.x2, annotRect.y2);

      console.log(annotRect);
      console.log(setTopLeft);
      console.log(setBottomRight);

      // create an annotation with a form field created
      const pageNumber = annotationSelectedF.getPageNumber();
      console.log('pageNumber');
      console.log(pageNumber);
      const newAnnot = await PDFNet.WidgetAnnot.create(pdfDoc, await PDFNet.Rect.init(setTopLeft.x, setTopLeft.y, setBottomRight.x, setBottomRight.y), field);

      console.log(newAnnot);
      newAnnot.Author = 'johny';
      console.log(newAnnot);
      //newAnnot.Author = 'johny';
      annotManager.deleteAnnotation(annotationSelectedF, false, true);
      console.log('free text deleted');
      // draw the annotation the viewer
      const page = await pdfDoc.getPage(pageNumber);
      await page.annotPushBack(newAnnot);
      await pdfDoc.refreshFieldAppearances();

      console.log('draw in webviewer');

      // import newly created form fields
      const fdfDoc = await pdfDoc.fdfExtract(PDFNet.PDFDoc.ExtractFlag.e_both);
      const xfdf = await fdfDoc.saveAsXFDFAsString();
      await annotManager.importAnnotations(xfdf);

      console.log('import fields');
      // refresh viewer
      docViewer.refreshAll();
      docViewer.updateView();
      docViewer.getDocument().refreshTextData();
      console.log('annotManager.getAnnotationById(' + annotationSelectedF.wt);
      //console.log(annotManager.getAnnotationById(annotationSelectedF.wt), xfdf);
      //console.log(Annotations.Forms.FieldManager.getField(annotationSelectedF.wt));
      //dropPoint = {};



      console.log('refresh viewer');
    }


  }

  //This doesn't work
  createFormFieldAnnotation() {
    //Currently Producing Errors
    const docViewer = this.wvInstance;
    const annotManager = this.wvInstance.annotManager;

    const { Annotations } = this.wvInstance;
    const freeTexttwo = new Annotations.Forms.Field('test field');
    freeTexttwo.PageNumber = 1;
    freeTexttwo.userName = 'Test User Name';
    freeTexttwo.X = 150;
    freeTexttwo.Y = 300;
    freeTexttwo.Width = 150;
    freeTexttwo.Height = 50;
    //freeTexttwo.setPadding(new Annotations.Rect(0, 0, 0, 0));
    //freeTexttwo.setContents('Form Field');
    //freeTexttwo.FillColor = new Annotations.Color(0, 255, 255);
    //freeTexttwo.FontSize = '16pt';

    //freeTexttwo.FillColor = new Annotations.Color(255, 255, 255);
    annotManager.addAnnotation(freeTexttwo);
    annotManager.redrawAnnotation(freeTexttwo);
  }


  //Display annoations in console.log, write to databse is save is true
  async showAnnotations(save: boolean) {
    let instance = this.wvInstance;
    const annotManager = instance.annotManager;
    //const anot = annotManager.getAnnotCommand();
    const anot = await annotManager.exportAnnotCommand();
    //const xfdfString = annotManager.exportAnnotations();
    const xfdfString = await annotManager.exportAnnotations()

    console.log('get annot command');
    console.log(anot);
    console.log('get xfdfString command');
    console.log(xfdfString);
    //console.log(anot.firstChild.getAttribute('name'));
    const getAnnots = annotManager.getAnnotationById('69125488-30dc-2f5f-8255-2c45bacfcd00');
    //getAnnots.authorId
    //getAnnots.Author = 'johny';
    //console.log(getAnnots.Author);


    const parser = new DOMParser();
    const commandData = parser.parseFromString(anot, 'text/xml');
    const addedAnnots = commandData.getElementsByTagName('add')[0];
    const modifiedAnnots = commandData.getElementsByTagName('modify')[0];
    const deletedAnnots = commandData.getElementsByTagName('delete')[0];


    const fieldData = parser.parseFromString(xfdfString, 'text/xml');

    const fieldAnnots = fieldData.getElementsByTagName('pdf-info')[0];
    console.log('fieldannots')
    console.log(fieldAnnots)

    //console.log(addedAnnots);
    var i = 0;
    // List of added annotations
    //anot.childNodes.forEach((child) => {
    addedAnnots.childNodes.forEach((child) => {
      if (child.nodeType !== child.TEXT_NODE) {
        console.log('AddedAnnots number:' + i);
        console.log(child);
        console.log(child.firstChild);
        // console.log(child.getAttribute('title'));
      }
      //  sendAnnotationChange(child, 'add');

      i++;
    });

    modifiedAnnots.childNodes.forEach((child) => {
      if (child.nodeType !== child.TEXT_NODE) {
        console.log('AddedAnnots number:' + i);
        console.log(child);
        console.log(child.firstChild);
        // console.log(child.getAttribute('title'));
      }
      //  sendAnnotationChange(child, 'add');

      i++;
    });


    console.log('get annot Add');
    console.log(addedAnnots);
    console.log('get annot Modified');
    console.log(modifiedAnnots);
    console.log('get annot Deleted');
    console.log(deletedAnnots);

    //    put statuement
    if (save === true) {
      this.putAnnotations(this.fileMetaId, this.documentData, xfdfString);
    }
    else if (save === false) {
      console.log('Not saved to database');
    }
  }

  //write to databse function
  putAnnotations(fileMetaId, documentData, xfdfString) {
    console.log('patchAnnotations');
    documentData.annotations = xfdfString;
    console.log(documentData.annotations);
    console.log(documentData);
    console.log('patchAnnotations End');
    this.documentService.UpdateDocumentDetails(fileMetaId, documentData).subscribe(
      fileMeta => {
        console.log('Update Success');
        console.log(fileMeta);
      },
      err => console.log(err)
    )

  }

  //Changes Annotation User. ??? change annotation field name
  onAnnotationUpdated(annotationId) {
    // Import the annotation based on xfdf command
    const annotManager = this.wvInstance.annotManager;
    const getAnnots = annotManager.getAnnotationById(annotationId);
    //getAnnots.authorId
    getAnnots.Author = 'johny';
    console.log(getAnnots.Id);
    console.log(getAnnots.Author);
    annotManager.redrawAnnotation(getAnnots);


    //    const annotation = annotManager.importAnnotCommand(data.xfdf)[0];
    // Set a custom field authorId to be used in client-side permission check
    //annotation.authorId = data.val().authorId;
    //  annotation.authorId = 'johny';
    //annotManager.redrawAnnotation(annotation);
    console.log('redraw complete');
  }


  //Attempt to create signature tool
  oldSignatureTool() {
    const docViewer = this.wvInstance;
    //const freeText = new Annotations.FreeTextAnnotation();
    var tools = this.wvInstance.Tools;
    const { Annotations } = this.wvInstance;

    const TriangleAnnotation = function () {
      Annotations.MarkupAnnotation.call(this);
      this.Subject = 'Triangle';

    };
    TriangleAnnotation.prototype = new Annotations.MarkupAnnotation();
    TriangleAnnotation.prototype.elementName = 'triangle';

    TriangleAnnotation.prototype.draw = function (ctx, pageMatrix) {
      // the setStyles function is a function on markup annotations that sets up
      // certain properties for us on the canvas for the annotation's stroke thickness.

      this.setStyles(ctx, pageMatrix);

      // first we need to translate to the annotation's x/y coordinates so that it's
      // drawn in the correct location
      ctx.translate(this.X, this.Y);
      ctx.beginPath();
      ctx.moveTo(this.Width / 2, 0);
      ctx.lineTo(this.Width, this.Height);
      ctx.lineTo(0, this.Height);
      ctx.closePath();
      ctx.fill();
      ctx.stroke();
    };
    const TriangleCreateTool = function (docViewer) {
      // TriangleAnnotation is the constructor function for our annotation we defined previously

      tools.GenericAnnotationCreateTool.call(this, docViewer, TriangleAnnotation);
    };

    TriangleCreateTool.prototype = new tools.GenericAnnotationCreateTool();
    //const signatureToolVar = new tools.SignatureCreateTool(docViewer);
    //console.log(signatureToolVar);
    //console.log('signature tool created');

    const triangleToolName = 'AnnotationCreateTriangle';



    //console.log(e);
    //signatureToolVar.contextMenu(e);

    //      signatureToolVar.addSignature();

    // signatureToolVar.setSignature(this.imageURL)
    // signatureToolVar.getPreview();
    // //signatureToolVar.showPreview();
    // signatureToolVar.X = 150;
    // signatureToolVar.Y = 200;
    // signatureToolVar.Width = 150;
    // signatureToolVar.Height = 50;


  }
  triangleTool(instance, tools, Annotations) {
    //Triangle Tool Custom
    const TriangleAnnotation = function () {
      Annotations.MarkupAnnotation.call(this);
      this.Subject = 'Triangle';
    };
    TriangleAnnotation.prototype = new Annotations.MarkupAnnotation();
    TriangleAnnotation.prototype.elementName = 'triangle';

    TriangleAnnotation.prototype.draw = function (ctx, pageMatrix) {
      // the setStyles function is a function on markup annotations that sets up
      // certain properties for us on the canvas for the annotation's stroke thickness.
      this.setStyles(ctx, pageMatrix);

      // first we need to translate to the annotation's x/y coordinates so that it's
      // drawn in the correct location
      ctx.translate(this.X, this.Y);
      ctx.beginPath();
      ctx.moveTo(this.Width / 2, 0);
      ctx.lineTo(this.Width, this.Height);
      ctx.lineTo(0, this.Height);
      ctx.closePath();
      ctx.fill();
      ctx.stroke();
    };
    const TriangleCreateTool = function (docViewer) {
      // TriangleAnnotation is the constructor function for our annotation we defined previously
      tools.GenericAnnotationCreateTool.call(this, docViewer, TriangleAnnotation);
    };

    TriangleCreateTool.prototype = new tools.GenericAnnotationCreateTool();
    //const signatureToolVar = new tools.SignatureCreateTool(docViewer);
    //console.log(signatureToolVar);
    //console.log('signature tool created');

    const triangleToolName = 'AnnotationCreateTriangle';



    console.log('viewerLoaded');
    const am = instance.docViewer.getAnnotationManager();
    // register the annotation type so that it can be saved to XFDF files
    am.registerAnnotationType(TriangleAnnotation.prototype.elementName, TriangleAnnotation);

    const triangleTool = new TriangleCreateTool(instance.docViewer);
    instance.registerTool({
      toolName: triangleToolName,
      toolObject: triangleTool,
      buttonImage: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">' +
        '<path d="M12 7.77L18.39 18H5.61L12 7.77M12 4L2 20h20L12 4z"/>' +
        '<path fill="none" d="M0 0h24v24H0V0z"/>' +
        '</svg>',
      buttonName: 'triangleToolButton',
      tooltip: 'Triangle'
    }, TriangleAnnotation);

    instance.setHeaderItems(header => {
      const triangleButton = {
        type: 'toolButton',
        toolName: triangleToolName
      };
      header.get('freeHandToolGroupButton').insertBefore(triangleButton);
    });
    instance.setToolMode(triangleToolName);




  }
  initialTool1(instance, tools, Annotations) {
    //Triangle Tool Custom
    const InitialAnnotation = function () {
      Annotations.SignatureWidgetAnnotation.call(this);
      this.Subject = 'Initial';
    };
    InitialAnnotation.prototype = new Annotations.SignatureWidgetAnnotation('52ed5fc8-54ff-2ed1-2ac4-8f4a30298059');
    InitialAnnotation.prototype.elementName = 'initial';

    //???
    InitialAnnotation.prototype.draw = function (ctx, pageMatrix) {
      // the setStyles function is a function on markup annotations that sets up
      // certain properties for us on the canvas for the annotation's stroke thickness.
      this.setStyles(ctx, pageMatrix);

      // first we need to translate to the annotation's x/y coordinates so that it's
      // drawn in the correct location
      ctx.translate(this.X, this.Y);
      ctx.beginPath();
      ctx.moveTo(this.Width / 2, 0);
      ctx.lineTo(this.Width, this.Height);
      ctx.lineTo(0, this.Height);
      ctx.closePath();
      ctx.fill();
      ctx.stroke();
    };

    const InitialCreateTool = function (docViewer) {
      // TriangleAnnotation is the constructor function for our annotation we defined previously
      //tools.GenericAnnotationCreateTool.call(this, docViewer, InitialAnnotation);
      tools.SignatureCreateTool.call(this, docViewer, InitialAnnotation);
    };

    //InitialCreateTool.prototype = new tools.GenericAnnotationCreateTool();
    InitialCreateTool.prototype = new tools.SignatureCreateTool();
    //const signatureToolVar = new tools.SignatureCreateTool(docViewer);
    //console.log(signatureToolVar);
    //console.log('signature tool created');

    const initialToolName = 'AnnotationCreateInitial';



    console.log('viewerLoaded');
    const am = instance.docViewer.getAnnotationManager();
    // register the annotation type so that it can be saved to XFDF files
    am.registerAnnotationType(InitialAnnotation.prototype.elementName, InitialAnnotation);

    const initialTool = new InitialCreateTool(instance.docViewer);
    instance.registerTool({
      toolName: initialToolName,
      toolObject: initialTool,
      buttonImage: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">' +
        '<path d="M12 7.77L18.39 18H5.61L12 7.77M12 4L2 20h20L12 4z"/>' +
        '<path fill="none" d="M0 0h24v24H0V0z"/>' +
        '</svg>',
      buttonName: 'initialToolButton',
      tooltip: 'Initial'
    }, InitialAnnotation);

    instance.setHeaderItems(header => {
      const triangleButton = {
        type: 'toolButton',
        toolName: initialToolName
      };
      header.get('freeHandToolGroupButton').insertBefore(triangleButton);
    });
    instance.setToolMode(initialToolName);

  }
  //   initialToolv2(instance, tools, Annotations) {
  //     //Triangle Tool Custom
  //     const annotManager = instance.annotManager;
  //     const InitialAnnotation = function () {
  //       Annotations.SignatureWidgetAnnotation.call(this);
  //       this.Subject = 'Initial';
  //     };
  //     //InitialCreateTool.prototype = new tools.SignatureCreateTool();
  //     InitialAnnotation.prototype.elementName = 'initial';

  // //  InitialAnnotation.prototype.draw = function (ctx, pageMatrix) {
  // //     console.log('draw initial');
  // //     ctx = InitialAnnotation.prototype;
  // //     ctx.elementName = 'initial';

  // //     ctx.PageNumber = 1;
  // //     //ctx.X = 150;
  // //     //ctx.Y = 500;
  // //     ctx.translate(this.X, this.Y);
  // //     ctx.Width = 150;
  // //     //freeTexttwo.userName = 'Test User Name';
  // //     ctx.Height = 50;
  // //     ctx.setPadding(new Annotations.Rect(0, 0, 0, 0));
  // //     ctx.setContents('My Text 2');
  // //     ctx.FillColor = new Annotations.Color(0, 255, 255);
  // //     ctx.FontSize = '16pt';
  // //     ctx.Author = 'johny';

  // //     annotManager.addAnnotation(InitialAnnotation.prototype);
  // //     annotManager.redrawAnnotation(InitialAnnotation.prototype);
  // //  }
  //     //???
  //     // InitialAnnotation.prototype.draw = function (ctx, pageMatrix) {
  //     //   // the setStyles function is a function on markup annotations that sets up
  //     //   // certain properties for us on the canvas for the annotation's stroke thickness.
  //     //   this.setStyles(ctx, pageMatrix);

  //     //   // first we need to translate to the annotation's x/y coordinates so that it's
  //     //   // drawn in the correct location
  //     //   ctx.translate(this.X, this.Y);
  //     //   ctx.beginPath();
  //     //   ctx.moveTo(this.Width / 2, 0);
  //     //   ctx.lineTo(this.Width, this.Height);
  //     //   ctx.lineTo(0, this.Height);
  //     //   ctx.closePath();
  //     //   ctx.fill();
  //     //   ctx.stroke();
  //     // };

  //     const InitialCreateTool = function (docViewer) {
  //       // TriangleAnnotation is the constructor function for our annotation we defined previously
  //       //tools.GenericAnnotationCreateTool.call(this, docViewer, InitialAnnotation);

  //       tools.SignatureCreateTool.call(this, docViewer, InitialAnnotation);
  //     };

  //     //InitialCreateTool.prototype = new tools.GenericAnnotationCreateTool();
  //     InitialCreateTool.prototype = new tools.SignatureCreateTool(instance);
  //     InitialCreateTool.prototype.mouseLeftDown();

  //     //const signatureToolVar = new tools.SignatureCreateTool(docViewer);
  //     //console.log(signatureToolVar);
  //     //console.log('signature tool created');

  //     const initialToolName = 'AnnotationCreateInitial';



  //     console.log('viewerLoaded3');
  //     const am = instance.docViewer.getAnnotationManager();
  //     // register the annotation type so that it can be saved to XFDF files
  //     am.registerAnnotationType(InitialAnnotation.prototype.elementName, InitialAnnotation);

  //     const initialTool = new InitialCreateTool(instance.docViewer);
  //     instance.registerTool({
  //       toolName: initialToolName,
  //       toolObject: initialTool,
  //       buttonImage: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">' +
  //         '<path d="M12 7.77L18.39 18H5.61L12 7.77M12 4L2 20h20L12 4z"/>' +
  //         '<path fill="none" d="M0 0h24v24H0V0z"/>' +
  //         '</svg>',
  //       buttonName: 'initialToolButton',
  //       tooltip: 'Initial'
  //     }, InitialAnnotation);

  //     instance.setHeaderItems(header => {
  //       const triangleButton = {
  //         type: 'toolButton',
  //         toolName: initialToolName
  //       };
  //       header.get('freeHandToolGroupButton').insertBefore(triangleButton);
  //     });
  //     instance.setToolMode(initialToolName);

  //   }
  initialToolTest(value) {
    console.log(this.initialTool);
    this.testInitial = true;
    console.log(value);

  }
  initialToolvPaul(instance, tools, Annotations) {


    const InitialCreateTool = function (docViewer) {
      // TriangleAnnotation is the constructor function for our annotation we defined previously
      //tools.GenericAnnotationCreateTool.call(this, docViewer, InitialAnnotation);

      tools.SignatureCreateTool.call(this, docViewer);
      //display modal here
      this.testInitial = true;
      console.log('test paul tool');
      console.log(this.testInitial);
    };

    //InitialCreateTool.prototype = new tools.GenericAnnotationCreateTool();
    InitialCreateTool.prototype = new tools.SignatureCreateTool(instance);
    //InitialCreateTool.prototype.mouseLeftDown();
    InitialCreateTool.prototype.mouseLeftDown = function (e) {
      console.log('test with global');
      console.log(e);
      console.log(this.initialTool);
      //e.preventDefault;

    }
    InitialCreateTool.prototype.contextMenu = function (e) {
      console.log('context menu');
    }
    InitialCreateTool.prototype.mouseLeftUp = function (e) {
      console.log('test left up');
      console.log(e);
      e.preventDefault;
    }

    //no action
    InitialCreateTool.prototype.getMouseLocation = function (e) {
      console.log('test');

    }
    //no action
    // InitialCreateTool.prototype.addSignature = function () {
    //   console.log('test SetSignature');
    //   //console.log(annotation)

    // }

    //const signatureToolVar = new tools.SignatureCreateTool(docViewer);
    //console.log(signatureToolVar);
    //console.log('signature tool created');

    const initialToolName = 'AnnotationCreateInitial';



    console.log('initialLoaded3');
    const am = instance.docViewer.getAnnotationManager();
    // register the annotation type so that it can be saved to XFDF files
    //   am.registerAnnotationType(InitialAnnotation.prototype.elementName, InitialAnnotation);

    //const initialTool = new InitialCreateTool(instance.docViewer);
    this.initialTool = new InitialCreateTool(this.wvInstance.docViewer);
    this.initialTool.on('signatureSaved', function (annotation) {
      console.log('signatureSaved Test');
      console.log(annotation);

    });
    console.log(this.initialTool);
    //signa one
    //Pull the Signature saved, as annoation object to user for the custom modal
    //this.initialTool.exportSignatures()

    //
    //deleteSavedSiganture
    //initialTool.importSignature()
    //initialTool.getSavedSignatures()

    //initialTool.setSignature(annotation)
    //      getSavedSignature();


    this.wvInstance.registerTool({
      toolName: initialToolName,
      toolObject: this.initialTool,
      buttonImage: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">' +
        '<path d="M12 7.77L18.39 18H5.61L12 7.77M12 4L2 20h20L12 4z"/>' +
        '<path fill="none" d="M0 0h24v24H0V0z"/>' +
        '</svg>',
      buttonName: 'initialToolButton',
      tooltip: 'Initial'
    });

    this.wvInstance.setHeaderItems(header => {
      const triangleButton = {
        type: 'toolButton',
        toolName: initialToolName
      };
      header.push(triangleButton);
    });
    this.wvInstance.setToolMode(initialToolName);


    //instance.unregisterTool('AnnotationCreateSignature');

  }

}
