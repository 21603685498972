import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'ngx-chart-line',
  templateUrl: './chart-line.component.html',
  styleUrls: ['./chart-line.component.scss']
})
export class ChartLineComponent implements OnInit, AfterViewInit {
	@Input('parentData') public chartFilter;
	@Input('parentDataChartData') public chartData;
	
	LineChart = [];
	labels = [];
	data = [];
  constructor() { }

  ngOnInit() {
	  console.log('test chart line component');
	}
	ngOnChanges() {
		console.log(this.chartFilter);
		console.log(this.chartData);
		console.log(this.chartData.date);
		console.log(this.chartData.visitCount);
		if(this.chartData.date){
			this.buildChart();
		}
	}
	ngAfterViewInit(){
		console.log(this.chartFilter);
		console.log(this.chartData);
		console.log(this.chartData.date);
		console.log(this.chartData.visitCount);
   	 
  }
  changeFilters(chartFilter){
	  this.chartFilter = chartFilter;
	switch(chartFilter){
		case 'week':
		this.labels= [chartFilter, 'December 1', 'December 2', 'December 3', 'December 4', 'December 5', 'December 6', 'December 7'];
		this.data= [0,10,5,15,20,15,25,20];
		console.log('week');
		break;
		case 'year':
			this.labels= [chartFilter, 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
			this.data= [0,10,5,15,20,15,25,20,22,23,24,25,20];
			console.log('year');
		break;
		default:
			this.labels= [chartFilter, '1', '2','3', '4','5','6', '7','8', '9','10','11', '12','13', '14','15','16', '17','18', '19','20','21', '22','23', '24','25','26', '27','28', '29','30'];
			this.data= [0,10,5,15,20,15,25,20,22,23,24,25,20,0,10,5,15,20,15,25,20,22,23,24,25,20,0,10,5,15,20];
			console.log('month');
		break;
	}
  }

  buildChart(){

	var self = this;

	// Generic options
	var options = {
		maintainAspectRatio: false,
		responsive: true,
		scales: {
			yAxes: [{
				scaleLabel: {
					display: true, 
					labelString: 'Visits'
				}, 
				ticks: {
					suggestedMin: 0, 
					suggestedMax: 10
				}
			}]
		},
		elements: {
			line: {
				tension: 0
			}, 
			point: {
				radius: 5,
				hoverRadius: 10,
				backgroundColor: 'rgb(255,255,255)',
				borderWidth: 3,
				hoverBorderWidth: 3
			}
		}
	};

	// Add options according to chart type
	switch(this.chartFilter){
		case 'month':
		case 'week':
		default:
			options['scales']['xAxes'] = [{
				ticks: {
					callback: function(value, index){
						var step = (self.chartFilter == 'month') ? 2 : 1;
						return ((index % step) === 0) ? ChartLineComponent.getDateStrShort(value) : ''; // Change '' to null if you want to hide the corresponding line
					}
				}
			}];
			options['tooltips'] = {
				callbacks: {
					title: function(tooltipItems, data){
						var timestamp = data.labels[tooltipItems[0].index];
						return ChartLineComponent.getDateStrLong(timestamp);
					}
				}
			};
		break; 
		case 'year':
			options['scales']['xAxes'] = [{
				ticks: {
					callback: function(value){
						return ChartLineComponent.getMonthStrShort(value);
					}
				}
			}];
			options['tooltips'] = {
				callbacks: {
					title: function(tooltipItems, data){
						var timestamp = data.labels[tooltipItems[0].index];
						return ChartLineComponent.getMonthStrLong(timestamp);
					}
				}
			};
		break;
	} 

	// Create the chart
	this.LineChart = new Chart('lineChart' + this.chartFilter, {
		type: 'line',
		data: {
			labels: this.chartData.date,
			datasets: [{
					label: 'Visits',
					borderColor: 'rgb(26,53,169)', 
					backgroundColor: 'rgb(238,242,244)',
					data: this.chartData.visitCount
			}]
		},
		options: options
	  });
  }

	/*
	 * Returns a short-formatted date from a JS timestamp.
	 */
	static getDateStrShort(timestamp){
		var date = new Date(timestamp);
		var options = {month:'short', day:'2-digit'};
		var formatter = new Intl.DateTimeFormat('en-US', options);
		return formatter.format(date); // Ex: Jan 17
	}    

	/*
	 * Returns a long-formatted date from a JS timestamp.
	 */
	static getDateStrLong(timestamp){
		var date = new Date(timestamp);
		var options = {weekday: 'long', year:'numeric', month:'long', day:'2-digit'};
		var formatter = new Intl.DateTimeFormat('en-US', options);
		return formatter.format(date); // Ex: Friday, January 17, 2020
	}

	/*
	 * Returns a short-formatted month from a JS timestamp.
	 */
	static getMonthStrShort(timestamp){
		var date = new Date(timestamp);
		var options = {month:'short'};
		var formatter = new Intl.DateTimeFormat('en-US', options);
		return formatter.format(date); // Ex: Jan
	}   

	/*
	 * Returns a long-formatted month from a JS timestamp.
	 */
	static getMonthStrLong(timestamp){
		var date = new Date(timestamp);
		var options = {month:'long', year:'numeric'};
		var formatter = new Intl.DateTimeFormat('en-US', options);
		return formatter.format(date); // Ex: January 2020
	}  
}
