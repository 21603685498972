import { Component, OnInit, Input, AfterViewInit, ViewChild } from '@angular/core';
import { Visits } from '../../../services/http/interfaces/visits';
import { FormrlyUsersContact } from '../../../services/http/interfaces/formrly-users-contact';
import { Documents } from '../../../services/http/interfaces/documents';
import { DocumentContextService } from '../../document/document-context.service';

@Component({
  selector: 'ngx-document-visits-members-view',
  templateUrl: './document-visits-members-view.component.html',
  styleUrls: ['./document-visits-members-view.component.scss']
})
export class DocumentVisitsMembersViewComponent implements OnInit, AfterViewInit {
  //setup input
  // @Input('parentDataDocument') public document;
  @Input('parentData') public documents:Documents;
  @Input('parentDataDocumentType') public documentType;
  public members;
  public visits;
  public visitsMerge = false;
  public pageCount = 3;
  public popoverPagesRead;
  public popoverPageCount;
  constructor(
    private docContextService: DocumentContextService
  ) { }

  ngOnInit() {
    console.log('afterviewinit')
    console.log(this.documents);
  }
  ngAfterViewInit() {
    console.log('afterviewinit')
    console.log(this.documents);
    console.log(this.members);
  }
  getMembers(data) {
    console.log("This is members data in Visits View");
    console.log(data);
    this.members = data;
    console.log('getMembers tirgger');
    this.buildMembersVisitsPages(this.members, this.documents.pageCount);
    this.mergeVisits(this.members, this.visits);
  }
  buildMembersVisitsPages(members, documentPageCount) {
    console.log('build members trigger');
    console.log(members);
    console.log(documentPageCount);
    var i = 0;
    var count
    count = members.length;

    for (i = 0; i < count; i++) {

      members[i].visitsCount = 0;
      var memberVisitTotalTime;



      //Pages
      var ii;
      var ccount = documentPageCount;
      members[i].visitData = [{}]
      for (ii = 0; ii < ccount; ii++) {
        var currentPage = ii + 1;
        console.log(ii + ' ii i' + i);
        members[i].visitData[ii] = {
          page: currentPage,
          time: 0
        }

      }
    }
    console.log('build Visits Complete');
    console.log(members);
  }


  getVisits(data: Visits) {
    console.log("This is visits data in Visits View");
    console.log(data);
    this.visits = data;
    console.log('getMembers tirgger');
    this.mergeVisits(this.members, this.visits);
  }
  mergeVisits(members, visits) {
    if (this.visitsMerge === false && members && visits) {
      console.log('start mergeVisits');
      console.log(visits);
      console.log(members);

      //Members Loop
      var i;
      var count = members.length;
      for (i = 0; i < count; i++) {
        members[i].visitsCount = 0;
        var memberVisitTotalTime= 0;
        var memberVisitPageCount = 0;

        //Visits Loop
        var ii;
        var ccount = visits.length;
        members[i].timestamp = 0;
        console.log(members[i].id);
        for (ii = 0; ii < ccount; ii++) {
          //console.log(visits[ii].formrlyUsersContactId);
          //Check Visit to Member
          if (members[i].id === visits[ii].formrlyUsersContactId) {
            //console.log('check visit to member');
            members[i].visitsCount = members[i].visitsCount + 1;
            if (members[i].timestamp < visits[ii].timestamp){
              members[i].timestamp = visits[ii].timestamp;
            }
            //Visit Page Data Loop
            var iii;
            var cccount = visits[ii].visitData.length;
            if(cccount >0){
              for (iii = 0; iii < cccount; iii++) {
                if (visits[ii].visitData[iii].time) {
                  memberVisitTotalTime = memberVisitTotalTime + visits[ii].visitData[iii].time;
                  //console.log(members[i].visitData[visitDataRow].time);
                  var visitDataRow;
                  visitDataRow = visits[ii].visitData[iii].pageNumber - 1;
                  
                  if(members[i].visitData[visitDataRow].time === 0 || members[i].visitData[visitDataRow].time === null){
                    memberVisitPageCount = memberVisitPageCount + 1;
                  }
                  
                  //console.log('visitdatarow and time')
                  //console.log(visitDataRow);
                  //console.log(members[i].visitData[visitDataRow].time);
                  members[i].visitData[visitDataRow].time = members[i].visitData[visitDataRow].time + visits[ii].visitData[iii].time;
                }
              }
            }        
          }
        }

        members[i].memberVisitTotalTime = memberVisitTotalTime;
        members[i].visitPageCount = memberVisitPageCount;
      }
      this.visitsMerge = true;
      console.log('Merge Visits Complete');
      console.log(members);
      this.pageCount = this.documents.pageCount;
    }


  }

  updateMemberReadStatus(pagesRead,pageCount){
    console.log('Update Current Member')
    this.popoverPageCount = pageCount;
    this.popoverPagesRead = pagesRead;
  }

  onContextMenuItemClick(event, action) {
    switch (action) {
      case 'reminder':
        this.docContextService.sendReminder(this.documents, this.members);
        break;
      case 'resend':
        this.docContextService.sendDownloadLinkToMember(this.documents, event.data);
        break;
    }
  }
}
