import { Injectable } from '@angular/core';

import { DropdownQuestion } from './question-dropdown';
import { QuestionBase } from './question-base';
import { TextboxQuestion } from './question-textbox';

@Injectable()
export class QuestionService {

  // TODO: get from a remote source of question metadata
  // TODO: make asynchronous
  getQuestions(annotations) {
    let questions: QuestionBase<any>[] = [
    ];

    for (let i = 0; i < annotations.length; i++) {
      var options = {
        label:'',
        type:''
      }
      switch(annotations[i].type){
        case 'template-name':
        options.label = annotations[i].author + ': Full Name';
        options.type = 'text';
        break;
        case 'template-email':
        options.label = annotations[i].author + ': Email Address';
        options.type = 'email';
        break;
        case 'template-company':
        options.label = annotations[i].author + ': Company Name';
        options.type = 'text';
        break;
        case 'template-title':
        options.label = annotations[i].author + ': Title';
        options.type = 'text';
        break;
        case 'template-date':
        options.label = annotations[i].author + ': Date';
        options.type = 'date';
        break;
        case 'template-text':
        options.label = annotations[i].author + ': Text';
        options.type = 'text';
        break;
        case 'template-number':
        options.label = annotations[i].author + ': Number';
        options.type = 'number';
        break;
 
      }

      questions[i] =
        new TextboxQuestion({
          key: annotations[i].id,
          label: options.label,
          type: options.type,
          order: i,
          value: annotations[i].contents
        })
    }


    // new DropdownQuestion({
    //   key: 'brave',
    //   label: 'Bravery Rating',
    //   options: [
    //     {key: 'solid',  value: 'Solid'},
    //     {key: 'great',  value: 'Great'},
    //     {key: 'good',   value: 'Good'},
    //     {key: 'unproven', value: 'Unproven'}
    //   ],
    //   order: 3
    // }),

    // new TextboxQuestion({
    //   key: 'firstName',
    //   label: 'First name',
    //   value: 'Bombasto',
    //   required: true,
    //   order: 1
    // }),
    // new TextboxQuestion({
    //   key: 'emailAddress',
    //   label: 'Email',
    //   type: 'email',
    //   order: 4
    // }),
    // new TextboxQuestion({
    //   key: 'emailAddress',
    //   label: 'Email',
    //   type: 'email',
    //   order: 2
    // })




    return questions.sort((a, b) => a.order - b.order);
  }
}
