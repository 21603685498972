import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {FormrlyUsersContact} from '../../../../services/http/interfaces/formrly-users-contact';
import {FormrlyUsersService} from '../../../../services/http/formrly-users.service';

@Component({
  selector: 'ngx-formrly-user-contacts-get',
  templateUrl: './formrly-user-contacts-get.component.html',
  styleUrls: ['./formrly-user-contacts-get.component.scss']
})
export class FormrlyUserContactsGetComponent implements OnInit {
  @Output() notify: EventEmitter<FormrlyUsersContact> = new EventEmitter<FormrlyUsersContact>();
  public contactsList:FormrlyUsersContact;
  constructor(
    private contactListService: FormrlyUsersService,

  ) { }

  ngOnInit() {
    this.getMyContactList();

  }

  getMyContactList(){
		this.contactListService.getMyContactList().subscribe(
      contactListMeta => {
        this.contactsList = contactListMeta;
        console.log('getMyContactList success')
        //console.log( contactListMeta);
        this.notify.emit(this.contactsList);
      },
        err => console.error(err)
    );
  }


}
