import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import {NbDialogService} from '@nebular/theme'
@Component({
  selector: 'ngx-wv-header',
  templateUrl: './wv-header.component.html',
  styleUrls: ['./wv-header.component.scss']
})
export class WvHeaderComponent implements OnInit {
  formDisplayName: any;
  documentDisplayName = {
    displayName: ''
  };

  // Stores the extension of the current document
  private documentExtension:string;

  @Input('parentData') public displayName; 
  @Input('parentDataView') public viewMode; 
  @Output() notifyDisplayName: EventEmitter<any> = new EventEmitter<any>()
  @ViewChild('popupClose') popupClose: ElementRef<HTMLElement>;
  constructor(
    private dialogService: NbDialogService
  ) { }
  open(dialog: TemplateRef<any>) {
    //console.log(dialog);
    let fileNameParts = this.getFileNameParts(this.displayName);
    this.documentDisplayName.displayName = fileNameParts.name;
    this.documentExtension = fileNameParts.extension;
    this.dialogService.open(dialog, { context: 'this is some additional data' })
    console.log('test')
  }
  triggerClose() {
    let el: HTMLElement = this.popupClose.nativeElement;
    el.click();
  }

  ngOnInit() {
    
  }
  changeDocumentDisplayName(displayName){
    this.notifyDisplayName.emit(displayName + this.documentExtension);
    this.triggerClose()
  }

  /**
   * Returns the parts of a filename.
   * @param fileName The filename.
   */
  private getFileNameParts(fileName:string){

    fileName = fileName.trim();
    let sepIndex = fileName.lastIndexOf('.');
    if(sepIndex < 0){
      return {name: fileName, extension: ''};
    }

    return {
      name: fileName.substr(0, sepIndex), 
      extension: fileName.substr(sepIndex)
    };
  }
}
