import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { VisitService } from '../../../../services/http/visit.service';
import { map, mergeMap } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Visits } from '../../../../services/http/interfaces/visits';
import { FormrlyUsersContact } from '../../../../services/http/interfaces/formrly-users-contact';
import {Documents} from '../../../../services/http/interfaces/documents';
@Component({
  selector: 'ngx-visits-get',
  templateUrl: './visits-get.component.html',
  styleUrls: ['./visits-get.component.scss']
})
export class VisitsGetComponent implements OnInit {
@Output() notifyVisits: EventEmitter<Visits> = new EventEmitter<Visits>();
@Output() notifyMembers: EventEmitter<FormrlyUsersContact> = new EventEmitter<FormrlyUsersContact>();
@Output() notifyDocuments: EventEmitter<Documents> = new EventEmitter<Documents>();

public visits :any ;
public members :any;
  public visitors;
  public memberss;
  public documents;
  api: string = environment.rest;
  memberName: any;
  visitTime: any;
  constructor(
    private visitService : VisitService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.getRecentVisitsbyOwner('1','1');
    // this.http.get(`${this.api}/FormrlyUsers/me/FilesMembersVisit`)
    // .pipe(
    //   map( visits=>{
    //     const visit = visits[0];
    //     this.visitTime = visit.time;

    //     console.log('Member Name' + visit.time);
    //     return visits;
    //   }),
    //   mergeMap(
    //     visit => 
    //     this.http.get(`${this.api}/FormrlyUsers/me/FormrlyUserMyContactList/${visit.formrlyUsersContactId}`)
    //   //    return visits;
    //     )).subscribe(
    //       visitsAll => {
    //         this.visits = visitsAll;
    //       }
    //     );

  }
  //resultdata :Array<any> = [];

//  getsRecentVisitsbyOwner(paginationPage: any, totalDisplayedCount: any): Observable<any> {
    //let visits = this.http.get(`${this.api}/FormrlyUsers/me/FileMembersVisit/`, paginationPage);
    //  this.http.get(`${this.api}/FormrlyUsers/me/FileMeta/FormrlyMembers/`).pipe(
    //   map( members=>{
    //     const member = members[0];
    //     this.memberName = member.name;
    //     console.log(this.memberName);
    //     return member;
    //   }),
    //   mergeMap(
    //     member => 
    //       this.http.get(`${this.api}/FormrlyUsers/me/FileMembersVisit?filter={"where":{"FormrlyUsersContactId":${member.FormrlyUsersContactId}}}`)
    //   //    return visits;
    //     )).subscribe(
    //       visits => {
    //         this.visits = visits;
    //       }
    //     );


    //let visits = this.http.get(`${this.api}/FormrlyUsers/me/FilesMembersVisit/`);
    
    


    //return forkJoin([visits,members]);
//  }
   getRecentVisitsbyOwner(paginationPage: any, totalDisplayedCount: any){
     this.visitService.getRecentVisitsbyOwner(paginationPage, totalDisplayedCount)
     .subscribe(
       fileMeta => {
         this.visitors = fileMeta[0];
          this.memberss = fileMeta[1];
          this.documents = fileMeta[2];
          console.log('Visits Get getRecentVistsbyOwner');
          console.log(this.visitors);
          console.log(this.memberss);
          console.log(this.documents);
          this.notifyVisits.emit(this.visitors);
          this.notifyMembers.emit(this.memberss);
          this.notifyDocuments.emit(this.documents);
         // this.visits = this.visits.join(fileMeta[1]);
  //       console.log('The Visit Data' + fileMeta[0]);
  //       console.log (this.visits[0].time);
  //       console.log (this.members[0].name);
  //       //this.notify.emit(this.documentsByStatus);
  //       //console.log('The docstatus' + this.documentsByStatus);
        },
         err => console.error(err)
     );
   }
}
