import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import{FormrlyUsersContact} from '../../../../services/http/interfaces/formrly-users-contact';
import{FormrlyUsersService} from '../../../../services/http/formrly-users.service';


@Component({
  selector: 'ngx-my-contact-info-get',
  templateUrl: './my-contact-info-get.component.html',
  styleUrls: ['./my-contact-info-get.component.scss']
})
export class MyContactInfoGetComponent implements OnInit {
  @Output() notifyMyContactInfo: EventEmitter<FormrlyUsersContact> = new EventEmitter<FormrlyUsersContact>();

  myContactInfo;
  constructor(
    public contactListService: FormrlyUsersService

  ) { }

  ngOnInit() {
    this.getMyContactInfo();
  }
  getMyContactInfo(){
		this.contactListService. getMyContactInfo().subscribe(
      contactMeta => {
        this.myContactInfo = contactMeta;
        console.log('getMyContactInfo Success')
        // console.log( contactMeta);
        this.notifyMyContactInfo.emit(this.myContactInfo);
      },
        err => console.error(err)
    );
  }
}
